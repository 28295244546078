import {
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IdentityTabs from '../identity/IdentityTabs'

export default function IdentityTasksHeader() {
	return (
		<div className="flex flex-wrap justify-between">
			<div>
				<div className="flex">
					<div className="mr-5 mt-0.5">
						<FontAwesomeIcon
							className="text-identity-500 text-6xl"
							icon={faPassport}></FontAwesomeIcon>
					</div>
					<div>
						<h1 className="text-3xl">
							<span className="font-bold">Identity</span>
						</h1>
						<h3 className="text-lg  text-bluegray-600">ID Checks</h3>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<div className="flex">
					<div className="flex flex-wrap ml-6 mr-2 bg-gray-100 rounded-xl p-2">
						<IdentityTabs></IdentityTabs>
					</div>
				</div>
			</div>
		</div>
	)
}
