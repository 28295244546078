export default function IdentityStep(props) {
	return (
		<li className="relative md:flex-1 md:flex">
			<a className="group flex items-center w-full">
				<span className="px-6 py-4 flex items-center text-sm font-medium">
					{props.done === true && !props.current ? (
						<span className="flex-shrink-0 w-5 h-5 flex items-center justify-center bg-identity-600 rounded-full">
							<svg
								className="w-3 h-3 text-white"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true">
								<path
									fillRule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clipRule="evenodd"
								/>
							</svg>
						</span>
					) : (
						''
					)}
					{props.current ? (
						<span
							className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-identity-600 rounded-full"
							aria-current="step">
							<span className="text-identity-600 text-2xs">{props.number}</span>
						</span>
					) : (
						''
					)}

					{props.done === false && !props.current ? (
						<span className="flex-shrink-0 w-6 h-6 flex items-center justify-center border-2 border-gray-300 rounded-full">
							<span className="text-gray-500 text-2xs">{props.number}</span>
						</span>
					) : null}
					<span className="ml-4 text-sm font-medium text-gray-900">
						{props.label}
					</span>
				</span>
			</a>
			{!props.last ? (
				<div
					className="hidden md:block absolute top-0 right-0 h-full w-5"
					aria-hidden="true">
					<svg
						className="h-full w-full text-gray-300"
						viewBox="0 0 22 80"
						fill="none"
						preserveAspectRatio="none">
						<path
							d="M0 -2L20 40L0 82"
							vectorEffect="non-scaling-stroke"
							stroke="currentcolor"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			) : null}
		</li>
	)
}
