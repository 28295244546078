import { useState } from "react";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegistryNewDocument from "./RegistryNewDocument";

export default function RegistryCountrytItemNew(props) {
  const [open, setOpen] = useState(false);

  function openModal() {
    setOpen(true);
  }

  function close() {
    setOpen(false);
  }
  return (
    <>
      <div className="w-full sm:w-1/3 p-4 self-auto">
        <div className="border h-full border-gray-200 cursor-pointer shadow-sm items-center rounded-xl hover:shadow-md transition-25 hover:border-bluegray-300 transition duration-200 ease-in">
          <div className="flex justify-between">
            <div></div>
            <div>
              <p className="mt-2 mr-2">
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                  <svg
                    className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 8 8"
                  >
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                  New document
                </span>
              </p>
            </div>
          </div>
          <div className="flex flex-wrap p-4" onClick={() => openModal()}>
            <div className="w-full">
              <div className="h-48 justify-center mx-auto text-center">
                <FontAwesomeIcon
                  className="mt-14 text-7xl text-gray-300"
                  icon={faPlus}
                ></FontAwesomeIcon>
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="p-4 text-center text-regular uppercase font-regular">
              <span>Add</span>
            </p>
          </div>
        </div>
      </div>
      <RegistryNewDocument
        open={open}
        label={props.label}
        countryName={props.countryName}
        slug={props.slug}
        country={props.country}
        onClose={() => close()}
      ></RegistryNewDocument>
    </>
  );
}
