import { PanelAuth } from "@/services/axios";

export default {
  AddManager(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/managers/create`,
      body
    );
  },
  RetreiveManagersList() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/managers/list`
    );
  },
};
