import {
  faClock,
  faDoNotEnter,
  faCheckCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import * as Notification from "@/components/ui/Notification";
import IndividualService from "@/services/individual";
import { useParams } from "react-router-dom";
import { AuthContext } from "@/context/auth";

export default function IndividualPhone({ step, step_id, reset, sub_session_id }) {
  const { app_id, session_id } = useParams();
  const [auth] = useContext(AuthContext);

  async function ResetIndividualResource() {
    await IndividualService.ResetStep(session_id, step_id)
      .then(async (data) => {
        Notification.success("Onbaording updated");
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  function PhoneTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              Phone Verification
            </p>
            {sub_session_id !== "" && sub_session_id !== undefined ? (
              <p className="font-medium text-gray-400">
                #{sub_session_id}
              </p>
            ) : null}
          </div>
          <div>
            <div className="flex">
              {reset !== 0 && reset !== undefined ? (
                <>
                  {reset === 1 ? (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1 mr-4">
                      {reset} reset
                    </p>
                  ) :
                    <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1 mr-4">
                      {reset} resets
                    </p>
                  }
                </>
              ) : null}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <>
                  <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                    <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} />{" "}
                    Not started
                  </p>
                </>
              ) : null}
              {state === "FINAL_REJECTED" || state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDoNotEnter} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheckCircle} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state !== "NOT_STARTED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function PhoneFooter({ state }) {
    return (
      <div>
        <hr className="my-4 border-bluegray-200" />
        <div className="flex justify-between items-center">
          <div>
          </div>
          <div>
            <div className="flex">
              {state !== "VALIDATED" && state !== "NOT_STARTED" && reset !== 2 &&
                (auth.iam.admin === true || auth.iam.support_manager === true || auth.iam.support_agent === true) ? (
                <p
                  onClick={() => ResetIndividualResource()}
                  className="text-white cursor-pointer text-sm bg-primary-500 rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>
              ) :
                <p
                  className="text-gray-400 text-sm bg-gray-100 cursor-not-allowed rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${props.capitalize ? "capitalize" : null
                }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function PhoneVerification({ step }) {
    return (
      <div>
        <div className="mt-3">
          <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
            Verification
          </p>
          <div className="flex flex-wrap">
            <div className="w-1/3 mt-2">
              <InfoItem
                title="Phone number"
                icon={"icon-call"}
                value={`+${step.phone_country}${step.phone_number}`}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PhoneTitle state={step.state} />
      {step.state === "VALIDATED" || step.state === "REJECTED" ? (
        <PhoneVerification step_id={step_id} step={step} />
      ) : null}
      <PhoneFooter state={step.state} />
    </div>
  );
}
