import { PanelPublic } from "@/services/axios";

export default {
  Init(request, config) {
    return PanelPublic.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/init`,
      request,
      config
    );
  },
  Login(data, config) {
    return PanelPublic.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/login`,
      data,
      config
    );
  },
  Ws() {
    return PanelPublic.post(`${process.env.REACT_APP_PANEL_HOST}/v3/users/ws`);
  },
};
