export default function Badge(props) {
	let type = props.type

	let theme = {
		green: {
			bg: 'bg-green-100',
			title: 'text-green-800',
			iconColor: 'text-green-500',
		},
		red: {
			bg: 'bg-red-100',
			title: 'text-red-800',
			iconColor: 'text-red-500',
		},
		orange: {
			bg: 'bg-orange-100',
			title: 'text-orange-500',
			iconColor: 'text-orange-500',
		},
	}

	if (!theme[type]) {
		type = 'success'
	}

	const badge = theme[type]

	return (
		<>
			{props.small ? (
				<span
					className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium ${badge.title} ${badge.bg}`}>
					<svg
						className={`-ml-0.5 mr-1.5 h-2 w-2 ${badge.title}`}
						fill="currentColor"
						viewBox="0 0 8 8">
						<circle cx={4} cy={4} r={3} />
					</svg>
					{props.label}
				</span>
			) : null}
			{props.large ? (
				<span
					className={`inline-flex items-center px-3 py-0.5 rounded-md text-sm font-medium ${badge.title} ${badge.bg}`}>
					<svg
						className={`-ml-1 mr-1.5 h-2 w-2 ${badge.title}`}
						fill="currentColor"
						viewBox="0 0 8 8">
						<circle cx={4} cy={4} r={3} />
					</svg>
					{props.label}
				</span>
			) : null}
		</>
	)
}
