import Card from '@/components/ui/Card'
import {
	faLayerGroup,
	faQrcode,
} from '@fortawesome/pro-duotone-svg-icons'
import { RegistryIdentityContext } from '@/context/registry_identity'
import { useContext, useState } from 'react'
import CardTitle from '../ui/CardTitle'
import RegistryNewFeature from './RegistryNewFeature'
import RegistryFeatureItem from './RegistryFeatureItem'
import RegistryFeatureItemNew from './RegistryFeatureItemNew'
import RegistryFeatureItemNewMRZ from './RegistryFeatureItemNewMRZ'
import Label from '../ui/Label'
import RegistryFeatureItemMRZ from './RegistryFeatureItemMRZ'

export default function RegistryDocument(props) {
	const [open, setOpen] = useState(false)
	const [newFeature, setNewFeature] = useState(null)
	const [registryIdentity] = useContext(
		RegistryIdentityContext
	)

	function setFeature(ft) {
		setNewFeature(ft)
		setOpen(true)
	}

	return (
		<>
			<div className="flex">
				<Card className="w-full px-3 sm:px-0">
					<div className="p-3">
						<CardTitle
							icon={faQrcode}
							value="Machine-Readable Zone"></CardTitle>
						<div className="flex flex-wrap px-3">
							<div className="w-full sm:w-1/2 px-3 sm:pr-6">
								<div className="mb-2">
									<Label value="Lines"></Label>
								</div>
								{registryIdentity.mrz.lines.active ? (
									<RegistryFeatureItemMRZ
										onDelete={() => props.onUpdate()}
										document={registryIdentity.slug}
										type="MRZ"
										name="Lines"
										data={registryIdentity.mrz.lines}></RegistryFeatureItemMRZ>
								) : (
									<RegistryFeatureItemNewMRZ
										label="New"
										onClick={() =>
											setFeature({
												side: '',
												type: 'mrz',
												name: 'lines',
												document_type: registryIdentity.document_type,
											})
										}></RegistryFeatureItemNewMRZ>
								)}
							</div>
							<div className="w-full sm:w-1/2 px-3 sm:pl-6">
								<div className="mb-2">
									<Label value="Barcode"></Label>
								</div>
								{registryIdentity.mrz.barcode.active ? (
									<RegistryFeatureItemMRZ
										onDelete={() => props.onUpdate()}
										document={registryIdentity.slug}
										type="MRZ"
										name="Barcode"
										data={
											registryIdentity.mrz.barcode
										}></RegistryFeatureItemMRZ>
								) : (
									<RegistryFeatureItemNewMRZ
										label="New"
										onClick={() =>
											setFeature({
												side: '',
												type: 'mrz',
												name: 'barcode',
												document_type: registryIdentity.document_type,
											})
										}></RegistryFeatureItemNewMRZ>
								)}
							</div>
						</div>
					</div>

					<div>
						<div className="flex p-3">
							<div className="w-1/2">
								<CardTitle
									icon={faLayerGroup}
									value="Front features"></CardTitle>
								<div className="px-6">
									<div>
										<div className="mb-2">
											<Label value="Fields"></Label>
										</div>
										{registryIdentity.features.front.fields.length > 0
											? registryIdentity.features.front.fields.map(
												(data) => {
													return (
														<RegistryFeatureItem
															key={data.slug}
															onDelete={() => props.onUpdate()}
															document={registryIdentity.slug}
															type="FIELDS"
															data={data}></RegistryFeatureItem>
													)
												})
											: null}
										<div>
											<RegistryFeatureItemNew
												label="New"
												onClick={() =>
													setFeature({
														side: 'FRONT',
														type: 'fields',
														name: '',
														document_type: registryIdentity.document_type,
													})
												}></RegistryFeatureItemNew>
										</div>
									</div>
									<div className="mt-4">
										<div className="mb-2">
											<Label value="Security"></Label>
										</div>
										{registryIdentity.features.front.security.length > 0
											? registryIdentity.features.front.security.map(
												(data) => {
													return (
														<RegistryFeatureItem
															key={data.slug}
															onDelete={() => props.onUpdate()}
															document={registryIdentity.slug}
															type="SECURITY"
															data={data}></RegistryFeatureItem>
													)
												})
											: null}
										<div>
											<RegistryFeatureItemNew
												label="New"
												onClick={() =>
													setFeature({
														side: 'FRONT',
														type: 'security',
														name: '',
														document_type: registryIdentity.document_type,
													})
												}></RegistryFeatureItemNew>
										</div>
									</div>
								</div>
							</div>

							{registryIdentity.document_type !== 'PASSPORT' ? (
								<div className="w-1/2">
									<CardTitle
										icon={faLayerGroup}
										value="Back features"></CardTitle>
									<div className="px-6">
										<div>
											<div className="mb-2">
												<Label value="Fields"></Label>
											</div>
											{registryIdentity.features.back.fields.length > 0
												? registryIdentity.features.back.fields.map(
													(data) => {
														return (
															<RegistryFeatureItem
																key={data.slug}
																onDelete={() => props.onUpdate()}
																document={registryIdentity.slug}
																type="FIELDS"
																data={data}></RegistryFeatureItem>
														)
													})
												: null}
											<div>
												<RegistryFeatureItemNew
													label="New"
													onClick={() =>
														setFeature({
															side: 'BACK',
															type: 'fields',
															name: '',
															document_type: registryIdentity.document_type,
														})
													}></RegistryFeatureItemNew>
											</div>
										</div>
										<div className="mt-4">
											<div className="mb-2">
												<Label value="Security"></Label>
											</div>
											{registryIdentity.features.back.security.length > 0
												? registryIdentity.features.back.security.map(
													(data) => {
														return (
															<RegistryFeatureItem
																key={data.slug}
																onDelete={() => props.onUpdate()}
																document={registryIdentity.slug}
																type="SECURITY"
																data={data}></RegistryFeatureItem>
														)
													})
												: null}
											<div>
												<RegistryFeatureItemNew
													label="New"
													onClick={() =>
														setFeature({
															side: 'BACK',
															type: 'security',
															name: '',
															document_type: registryIdentity.document_type,
														})
													}></RegistryFeatureItemNew>
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					</div>
				</Card>
			</div>
			{newFeature ? (
				<RegistryNewFeature
					onSuccess={() => props.onUpdate()}
					onClose={() => setOpen(false)}
					open={open}
					type={newFeature.type}
					name={newFeature.name}
					side={newFeature.side}></RegistryNewFeature>
			) : null}
		</>
	)
}
