import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import CorporatSession from '@/components/sessions/CorporateSession'
import { SessionContextProvider } from '@/context/session'

function CorporateSessionRessource() {
    return (
        <AuthGuard>
            <IamGuard page='support'>
                <SessionContextProvider>
                    <div className='relative min-h-screen flex flex-col'>
                        <Navbar></Navbar>
                        <CorporatSession></CorporatSession>
                    </div>
                </SessionContextProvider>
            </IamGuard>
        </AuthGuard>
    )
}

export default CorporateSessionRessource
