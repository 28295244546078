import {
	faFileInvoice,
	faHeadSide,
	faPassport,
	faUniversity,
} from '@fortawesome/pro-duotone-svg-icons'
import MyVerificationItem from './MyVerificationItem'
import { useEffect, useState } from 'react'
import StatsService from '@/services/stats'

export default function MyVerificationStats() {
	const [tasks, setStats] = useState([])

	useEffect(() => {
		StatsService.RetreiveOwnTasksStats()
			.then((data) => {
				setStats(data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<div>
			<div className='flex flex-wrap items-stretch px-3 pb-3'>
				<div className='dashboard-stats'>
					<MyVerificationItem
						label='Today'
						amount={tasks.today}
						icon={faPassport}></MyVerificationItem>
				</div>

				<div className='dashboard-stats'>
					<MyVerificationItem
						label='This week'
						amount={tasks.week}
						icon={faFileInvoice}></MyVerificationItem>
				</div>

				<div className='dashboard-stats'>
					<MyVerificationItem
						label='This month'
						amount={tasks.month}
						icon={faUniversity}></MyVerificationItem>
				</div>
				<div className='dashboard-stats'>
					<MyVerificationItem
						label='This year'
						amount={tasks.year}
						icon={faHeadSide}></MyVerificationItem>
				</div>
				<div className='dashboard-stats'>
					<MyVerificationItem
						label='All time'
						amount={tasks.total}
						icon={faUniversity}></MyVerificationItem>
				</div>
			</div>
		</div>
	)
}
