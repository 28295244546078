import { faCabinetFiling } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountryFlag from '../ui/CountryFlag'

export default function RegistryHead(props) {
	return (
		<div className="flex my-4 sm:my-0 sm:mt-8">
			<div className="mr-5 mt-0.5">
				<FontAwesomeIcon
					className="text-gray-400 text-6xl"
					icon={faCabinetFiling}></FontAwesomeIcon>
			</div>
			<div>
				<h1 className="text-3xl">
					<span className="font-bold">{props.slug}</span>
				</h1>
				<div className="flex items-center mt-1">
					<div>
						<div className="text-sm flex">
							<div>
								<span className="relative top--1px">
									<CountryFlag country={props.country} size="s" />
								</span>
							</div>
							<div className="ml-2">{props.value}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
