import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SubNav from "../menu/SubNav";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import RegistryService from "@/services/registry";
import Card from "../ui/Card";
import {
  faAddressCard,
  faCar,
  faIdCard,
  faPassport,
} from "@fortawesome/pro-duotone-svg-icons";
import CardTitle from "../ui/CardTitle";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import Footer from "../menu/Footer";
import RegistryCountrytHeader from "../registry/RegistryCountryHeader";
import RegistryCountrytItem from "../registry/RegistryCountryItem";
import RegistryCountrytItemNew from "../registry/RegistryCountryItemNew";
import Alert from "../ui/Alert";

export default function RegistryListPage() {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const { country } = useParams();
  const [docs, setDocs] = useState({
    country: "",
    country_name: "",
    passport: [],
    national_id: [],
    driver_license: [],
    resident_permit: [],
    stats: {},
  });

  useEffect(async () => {
    await RegistryService.GetCountryInfo(country)
      .then(async (data) => {
        setDocs(data);
        setReady(true);
      })
      .catch((err) => {
        setError(err.message);
      });
    return () => {};
  }, []);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <div className="max-w-7xl mx-auto relative">
                <div className="dashboard-container">
                  <div className="flex">
                    <Card className="w-full px-3 sm:px-0">
                      <div className="bg-white p-6 border shadow rounded-xl w-full">
                        <RegistryCountrytHeader
                          data={docs}
                        ></RegistryCountrytHeader>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div className="p-3">
                  {error ? <Alert type="error" title={error}></Alert> : null}
                  {ready ? (
                    <>
                      {" "}
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <CardTitle
                              value={"Passport"}
                              icon={faPassport}
                            ></CardTitle>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <RegistryCountrytItem
                            country={docs.country}
                            noBack
                            docs={docs.passport}
                          ></RegistryCountrytItem>

                          <RegistryCountrytItemNew
                            label="Passport"
                            slug="PASSPORT"
                            country={docs.country}
                            countryName={docs.country_name}
                          ></RegistryCountrytItemNew>
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <CardTitle
                              value={"National ID"}
                              icon={faIdCard}
                            ></CardTitle>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <RegistryCountrytItem
                            country={country}
                            docs={docs.national_id}
                          ></RegistryCountrytItem>
                          <RegistryCountrytItemNew
                            label="National ID"
                            slug="NATIONAL_ID"
                            country={docs.country}
                            countryName={docs.country_name}
                          ></RegistryCountrytItemNew>
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <CardTitle
                              value={"Driver License"}
                              icon={faCar}
                            ></CardTitle>
                          </div>
                        </div>

                        <div className="flex flex-wrap">
                          <RegistryCountrytItem
                            country={country}
                            docs={docs.driver_license}
                          ></RegistryCountrytItem>

                          <RegistryCountrytItemNew
                            label="Driver License"
                            slug="DRIVER_LICENSE"
                            country={docs.country}
                            countryName={docs.country_name}
                          ></RegistryCountrytItemNew>
                        </div>
                      </div>
                      <div>
                        <div className="flex justify-between">
                          <div>
                            <CardTitle
                              value={"Resident Permit"}
                              icon={faAddressCard}
                            ></CardTitle>
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <RegistryCountrytItem
                            country={country}
                            docs={docs.resident_permit}
                          ></RegistryCountrytItem>
                          <RegistryCountrytItemNew
                            label="Resident Permit"
                            slug="RESIDENT_PERMIT"
                            country={docs.country}
                            countryName={docs.country_name}
                          ></RegistryCountrytItemNew>
                        </div>
                      </div>
                    </>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
