export default function Footer() {
	return (
		<div className='flex justify-between mt-6 mb-6'>
			<div>
				<p className='text-xs relative top-2px ml-4 text-priamry-500 opacity-50 hover:opacity-100 transition duration-200 ease-in'>
					&copy; 2022 - All rights reserved
				</p>
			</div>
			<div>
				<p className='text-xs mr-4'>
					<img
						className='h-5 opacity-50 hover:opacity-100 transition duration-200 ease-in'
						src='/img/logo/synaps-panel.svg'></img>
				</p>
			</div>
		</div>
	)
}
