import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import StepTitle from '@/components/ui/StepTitle'
import {
	faBurn,
	faEye,
	faPhone,
	faPlug,
	faTint,
	faUniversity,
	faWifi,
} from '@fortawesome/pro-duotone-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'
import { useHistory } from 'react-router-dom'
import ResidencyDocumentViewer from './ResidencyDocumentViewer'

export default function ResidencyFinalReview() {
	const [successLoading, setSuccessLoading] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(ResidencyVerificationContext)
	const router = useHistory()

	const keyMap = {}

	const handlers = {}

	async function validateVerification() {
		setSuccessLoading(true)
		await ResidencyService.Validate(residencyVerification.task.session_id)
			.then(() => {
				setSuccessLoading(false)
				Notification.success('Residency verified successfully!')
				router.push('/verifications/residency')
			})
			.catch((err) => {
				Notification.error(err.message)
				setSuccessLoading(false)
			})
		return () => {}
	}

	return (
		<GlobalHotKeys
			tabIndex='1'
			className='outline-none'
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type='error' title={error}></Alert>
				</div>
			) : null}
			<div className='flex flex-wrap'>
				<div className='w-3/5'>
					<ResidencyDocumentViewer></ResidencyDocumentViewer>
				</div>
				<div className='w-2/5'>
					<div>
						<StepTitle value='Review' icon={faEye}></StepTitle>
					</div>
					<div className='px-4'>
						<div className='mb-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Firstname
							</p>
							{residencyVerification.verification.is_accomodation ? (
								<p>
									{residencyVerification.verification.accomodation.firstname}
								</p>
							) : (
								<p>{residencyVerification.verification.details.firstname}</p>
							)}
						</div>
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Lastname
							</p>
							{residencyVerification.verification.is_accomodation ? (
								<p>
									{residencyVerification.verification.accomodation.lastname}
								</p>
							) : (
								<p>{residencyVerification.verification.details.lastname}</p>
							)}
						</div>
						<hr className='my-6' />
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Address
							</p>
							<p>{residencyVerification.verification.details.address}</p>
						</div>
						{residencyVerification.verification.details.zipcode ? (
							<div className='my-4'>
								<p className='uppercase text-gray-500 font-bold text-xs'>
									Zip Code
								</p>
								<p>{residencyVerification.verification.details.zipcode}</p>
							</div>
						) : null}
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>City</p>
							<p>{residencyVerification.verification.details.city}</p>
						</div>
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Country
							</p>
							<p>{residencyVerification.verification.details.country}</p>
						</div>
						<hr className='my-6' />
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Document Type
							</p>
							{residencyVerification.verification.details.document_type ===
							'ELECTRICITY_BILL' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faPlug}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Electricity Bill</p>
								</div>
							) : null}
							{residencyVerification.verification.details.document_type ===
							'INTERNET_BILL' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faWifi}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Internet Bill</p>
								</div>
							) : null}
							{residencyVerification.verification.details.document_type ===
							'LANDLINE_BILL' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faPhone}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Landline Bill</p>
								</div>
							) : null}
							{residencyVerification.verification.details.document_type ===
							'WATER_BILL' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faTint}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Water Bill</p>
								</div>
							) : null}
							{residencyVerification.verification.details.document_type ===
							'GAS_BILL' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faBurn}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Gas Bill</p>
								</div>
							) : null}
							{residencyVerification.verification.details.document_type ===
							'BANK_STATEMENT' ? (
								<div className='mt-2 flex'>
									<FontAwesomeIcon
										className='text-residency-500 relative top-1px'
										icon={faUniversity}></FontAwesomeIcon>
									<p className='font-bold ml-2'>Bank Statement</p>
								</div>
							) : null}
						</div>
						<div className='my-4'>
							<p className='uppercase text-gray-500 font-bold text-xs'>
								Issuing Date
							</p>
							<p>{residencyVerification.verification.details.issuing_date}</p>
						</div>
						<div className='flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl'>
							<div></div>
							<div>
								<Button
									loading={successLoading}
									onClick={() => validateVerification()}
									icon={faCheck}
									full
									label='Validate'
									theme='success'></Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</GlobalHotKeys>
	)
}
