import {
	faBarcodeRead,
	faCropAlt,
	faLanguage,
	faShield,
	faThumbtack,
} from '@fortawesome/pro-duotone-svg-icons'
import ReviewerHelpItem from './ReviewerHelpItem'

export default function ReviewerHelp() {
	return (
		<div>
			<div className="flex flex-wrap items-stretch">
				<div className="w-full sm:w-1/5 p-3">
					<ReviewerHelpItem
						icon={faCropAlt}
						theme="indigo"
						title="Segmentation"
						description="Tutorial on how segment ID documents"></ReviewerHelpItem>
				</div>
				<div className="w-full sm:w-1/5  p-3">
					<ReviewerHelpItem
						icon={faThumbtack}
						theme="orange"
						title="Pin"
						description="Explanation on when and what to pin"></ReviewerHelpItem>
				</div>
				<div className="w-full sm:w-1/5  p-3">
					<ReviewerHelpItem
						icon={faBarcodeRead}
						theme="gray"
						title="MRZ"
						description="How to read and write Machine Readable Zone (MRZ)"></ReviewerHelpItem>
				</div>
				<div className="w-full sm:w-1/5  p-3">
					<ReviewerHelpItem
						icon={faLanguage}
						theme="blue"
						title="Translation"
						description="Tutorial explaining how to translate a document"></ReviewerHelpItem>
				</div>
				<div className="w-full sm:w-1/5  p-3">
					<ReviewerHelpItem
						icon={faShield}
						theme="emerald"
						title="Security Features"
						description="How to check security features on an document"></ReviewerHelpItem>
				</div>
			</div>
		</div>
	)
}
