import { useContext, useState } from "react";
import { CrosscheckVerificationContext } from "@/context/crosscheck_verification";
import { GlobalHotKeys } from "react-hotkeys";
import CrosscheckService from "@/services/crosscheck";
import { faCheck, faTimes, faUsers } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";

export default function CrosscheckVisualCheck() {
  const [loading, setLoading] = useState(false);
  const [crosscheckVerification] = useContext(CrosscheckVerificationContext);
  const router = useHistory();

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function rejectCrosscheck() {
    setLoading(true);
    await CrosscheckService.RejectCrosscheck(
      crosscheckVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Crosscheck rejected successfully");
        router.push("/verifications/duplicate");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  async function validateCrosscheck() {
    setLoading(true);
    await CrosscheckService.ValidateCrosscheck(
      crosscheckVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Crosscheck validated successfully");
        router.push("/verifications/crosscheck");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  const handlers = {};

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle value="Crosscheck Visual Check" icon={faUsers}></StepTitle>
        </div>
      </div>
      <div className="flex">
        {crosscheckVerification.verification.map((session) => {
          return (
            <div key={session.session_id} className="w-1/2">
              <div className="flex flex-wrap p-6">
                <img
                  draggable={false}
                  className={"object-contain h-128 w-full"}
                  src={crosscheckVerification.task.session_id}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div className="flex justify-center">
          <div className="mr-3">
            <Button
              loading={loading}
              onClick={() => rejectCrosscheck()}
              icon={faTimes}
              full
              label="Reject"
              theme="error"
            ></Button>
          </div>
          <div className="ml-3">
            <Button
              loading={loading}
              onClick={() => validateCrosscheck()}
              icon={faCheck}
              full
              label="Match"
              theme="success"
            ></Button>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
