import { faPen } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'

export default function IdentityHeaderItem(props) {
	return (
		<>
			<div className={'h-full mb-4 px-2'}>
				<div
					className={'bg-bluegray-50 p-3 rounded-xl  border border-bluegray-100'}>
					<div className="flex items-center">
						<div className="text-3xl mr-4">
							<FontAwesomeIcon
								className="text-corporate-500"
								icon={props.icon}></FontAwesomeIcon>
						</div>
						<div className="w-full">
							<div className="flex">
								<div>
									<p className="text-xs">
										<span
											className={'uppercase font-semibold text-bluegray-700 text-xs'}>
											{props.label}
										</span>
									</p>
								</div>
							</div>
							<div className="flex justify-between">
								<p className={'text-regular font-bold text-primary-500'}>
									{props.value}
								</p>
								<Tooltip auto={true} content="Change">
									{props.editable ? (
										<p
											onClick={() => props.onEdit()}
											className={'text-regular font-bold text-sm text-gray-500 cursor-pointer hover:text-gray-800 transition duration-100 ease-in'}>
											<FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
										</p>
									) : null}
								</Tooltip>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
