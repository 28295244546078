import { useContext, useEffect, useState } from 'react'
import Empty from '../misc/Empty'
import TasksPlaceholder from '../placeholder/TasksPlaceholder'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'
import CardTitle from '../ui/CardTitle'
import { CrosscheckContext } from '@/context/crosscheck'
import CrosscheckService from '@/services/crosscheck'
import CrosscheckTaskItem from './CrosscheckTaskItem'

export default function CrosscheckTasks() {
	const [pinType] = useState('')
	const [crosscheck, crosscheckDispatch] = useContext(CrosscheckContext)
	const [ready, setReady] = useState(false)

	useEffect(async () => {
		setReady(false)
		await CrosscheckService.GetTasks(crosscheck.selectedTab.key, pinType)
			.then(async (data) => {
				crosscheckDispatch({
					type: 'SET_TASKS',
					payload: {
						tasks: data,
					},
				})
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => {}
	}, [crosscheck.selectedTab])

	return (
		<>
			<div className="flex justify-between">
				<div>
					<CardTitle
						value={crosscheck.selectedTab.title}
						icon={crosscheck.selectedTab.icon}></CardTitle>
				</div>
			</div>
			{ready ? (
				<div className="flex flex-wrap p-6 pt-3">
					{crosscheck.tasks.map((task) => {
						return (
							<CrosscheckTaskItem
								key={task.task.session_id}
								item={task}></CrosscheckTaskItem>
						)
					})}
					{crosscheck.tasks.length === 0 ? (
						<Empty value="No tasks"></Empty>
					) : null}
				</div>
			) : (
				<div className="p-4">
					<TasksPlaceholder></TasksPlaceholder>
				</div>
			)}
		</>
	)
}
