import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import ResidencyDocumentViewer from '../ResidencyDocumentViewer'
import StepTitle from '@/components/ui/StepTitle'
import {
	faCheckCircle,
	faMapMarker,
} from '@fortawesome/pro-duotone-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditDocumentTypeSidebar from '@/components/residency/EditDocumentTypeSidebar'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'

export default function ResidencyAddressVerification(props) {
	const [, setSuccessLoading] = useState(false)
	const [editDocumentOpen, setEditDocumentOpen] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)
	const keyMap = {}

	const handlers = {}

	async function validateAddress(address_id) {
		setSuccessLoading(true)
		await ResidencyService.SetPostalAddress(
			residencyVerification.task.session_id,
			address_id
		)
			.then(() => {
				setSuccessLoading(false)
				props.onUpdate()
			})
			.catch((err) => {
				Notification.error(err.message)
				setSuccessLoading(false)
			})
		return () => {}
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}
			<div className="flex flex-wrap">
				<div className="w-3/5">
					<ResidencyDocumentViewer></ResidencyDocumentViewer>
				</div>
				<div className="w-2/5">
					<div>
						<StepTitle
							value="Address verification"
							icon={faMapMarker}></StepTitle>
					</div>
					<div>
						<div className="mb-6">
							<div className="mt-6 mb-6 text-center">
								<FontAwesomeIcon
									className="text-residency-500 text-5xl"
									icon={faMapMarker}></FontAwesomeIcon>
							</div>
							<div className="px-4">
								<p className="text-center mb-4">
									Select the most accurate address
								</p>
								{residencyVerification.address_suggestions.map((address) => {
									return (
										<div
											onClick={() => validateAddress(address.id)}
											key={address.id}
											className="rounded-lg p-3 border border-gray-200 mb-6 shadow-sm cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
											<span
												className={`inline-flex items-center px-2 mb-3 py-0.5 rounded-lg text-sm font-medium bg-gray-100 text-gray-800 ${
													address.verification_level === 'street_address'
														? 'bg-green-100 text-green-800'
														: null
												} ${
													address.verification_level === 'route'
														? 'bg-green-100 text-green-800'
														: null
												} ${
													address.verification_level === 'premise'
														? 'bg-green-100 text-green-800'
														: null
												}${
													address.verification_level === 'subpremise'
														? 'bg-green-100 text-green-800'
														: null
												} ${
													address.verification_level === 'intersection'
														? 'bg-orange-100 text-orange-600'
														: null
												}
												
												${
										address.verification_level === 'neighborhood'
											? 'bg-orange-100 text-orange-600'
											: null
										}`}>
												{address.verification_level}
											</span>
											<p>{address.address}</p>
											<p className="font-bold">
												{address.zip_code ? `${address.zip_code}, ` : null}
												{address.city}
											</p>
											<p>{address.country}</p>
										</div>
									)
								})}
							</div>
						</div>
						<hr />
						<div className="p-4 pb-0">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Instructions
							</p>
							<div>
								<p className="mt-2">
									<FontAwesomeIcon
										className="text-green-600 mr-2"
										icon={faCheckCircle}></FontAwesomeIcon>
									Address should be written on the document
								</p>
							</div>
						</div>
						<div className="p-4">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Detected Keywords
							</p>
							{Object.entries(residencyVerification.suggestions).length ===
							0 ? (
									<p>None</p>
								) : null}
							{Object.entries(residencyVerification.suggestions).map(
								([key, value]) => {
									return (
										<div key={key} className="mb-3 mt-3">
											Page n°{key}
											<div>
												{value.map((item) => {
													return (
														<p
															key={item.paragraph_nb}
															className="mt-2 rounded-full text-sm font-medium text-gray-800">
															§{item.paragraph_nb} - {item.word}
														</p>
													)
												})}
											</div>
										</div>
									)
								}
							)}
						</div>
					</div>
				</div>
				<EditDocumentTypeSidebar
					open={editDocumentOpen}
					setOpen={setEditDocumentOpen}></EditDocumentTypeSidebar>
			</div>
		</GlobalHotKeys>
	)
}
