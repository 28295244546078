import {
	useContext,
	useEffect,
} from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import {
	faUserTie,
} from '@fortawesome/pro-duotone-svg-icons'
import StepTitle from '@/components/ui/StepTitle'
import { CorporateVerificationContext } from '@/context/corporate_verification'
import Badge from '@/components/ui/Badge'
import Button from '@/components/ui/Button'
export default function CorporateOfficer(props) {
	const [corporateVerification] = useContext(
		CorporateVerificationContext
	)

	useEffect(() => {}, [corporateVerification])

	const keyMap = {}

	const handlers = {}

	async function updateProgress() {
		props.onUpdate()
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			<div className="p-6">
				<div className="flex justify-between">
					<div>
						<StepTitle value="Corporate Officer" icon={faUserTie}></StepTitle>
					</div>
					<Button
					loading={false}
					onClick={() => updateProgress()}
					full
					label="Update"
					theme="primary"></Button>
				</div>

				<div>
					<div className="mt-3 flex w-full flex-wrap justify-between bg-bluegray-50 border border-bluegray-100 p-3 rounded-lg">
						<div>
							<p className="font-medium">Liveness</p>
						</div>
						<div>
							{' '}
							<p>
								{corporateVerification.verification.owner_step.liveness
									.state === 2 ? (
										<Badge label="Validated" small type="green"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.liveness
									.state === 1 ? (
										<Badge label="Pending" small type="orange"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.liveness
									.state === 0 ? (
										<Badge label="Rejected" small type="red"></Badge>
									) : null}
							</p>
						</div>
					</div>
					<div
						onClick={() =>
							window.open(
								`/verifications/identity/${corporateVerification.verification.owner_step.identity.session_id}`,
								'_blank'
							)
						}
						className={'mt-3 flex w-full cursor-pointer flex-wrap justify-between bg-bluegray-50 hover:bg-bluegray-100 transition duration-100 ease-in border border-bluegray-100 p-3 rounded-lg'}>
						<div>
							<p className="font-medium">Identity</p>
						</div>
						<div>
							{' '}
							<p>
								{corporateVerification.verification.owner_step.identity
									.state === 2 ? (
										<Badge label="Validated" small type="green"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.identity
									.state === 1 ? (
										<Badge label="Pending" small type="orange"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.identity
									.state === 0 ? (
										<Badge label="Rejected" small type="red"></Badge>
									) : null}
							</p>
						</div>
					</div>
					<div
						onClick={() =>
							window.open(
								`/verifications/residency/${corporateVerification.verification.owner_step.residency.session_id}`,
								'_blank'
							)
						}
						className="mt-3 flex w-full cursor-pointer flex-wrap justify-between bg-bluegray-50 hover:bg-bluegray-100 transition duration-100 ease-in border border-bluegray-100 p-3 rounded-lg">
						<div>
							<p className="font-medium">Residency</p>
						</div>
						<div>
							{' '}
							<p>
								{corporateVerification.verification.owner_step.residency
									.state === 2 ? (
										<Badge label="Validated" small type="green"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.residency
									.state === 1 ? (
										<Badge label="Pending" small type="orange"></Badge>
									) : null}
								{corporateVerification.verification.owner_step.residency
									.state === 0 ? (
										<Badge label="Rejected" small type="red"></Badge>
									) : null}
							</p>
						</div>
					</div>
				</div>
			</div>
		</GlobalHotKeys>
	)
}
