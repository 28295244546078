import { useCallback, useContext, useEffect, useState } from "react";
import ImageMarker from "react-image-marker";
import SegmentationMarker from "../../misc/SegmentationMarker";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faAddressCard,
  faBackspace,
  faCropAlt,
  faIdCard,
  faRedoAlt,
  faTrashUndo,
  faUndoAlt,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/Button";
import Tooltip from "@/components/ui/Tooltip";
import IdentitySegmentationStepper from "@/components/identity/IdentitySegmentationStepper";
import Alert from "@/components/ui/Alert";
import * as Notification from "@/components/ui/Notification";
import StepTitle from "@/components/ui/StepTitle";

export default function IdentitySegmentation(props) {
  const [frontMarkers, setFrontMarkers] = useState([]);
  const [frontRotation, setFrontRotation] = useState(0);
  const [backMarkers, setBackMarkers] = useState([]);
  const [backRotation, setBackRotation] = useState(0);
  const [indexRender, setIndexRender] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0);
  const [lastMarked, setLastMarked] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);

  const keyMap = {
    DELETE: "cmd+z",
    ENTER: ["enter"],
  };

  const hotClear = useCallback(() => {
    if (lastMarked === "front") {
      clearLastFront();
    } else if (lastMarked === "back") {
      clearLastBack();
    }
    return () => { };
  }, [frontMarkers, backMarkers, indexRender, lastMarked]);

  const handlers = {
    DELETE: hotClear,
    ENTER: segmentDocument,
  };

  useEffect(() => {
    let frontElement = document.getElementsByClassName("image-marker-front");
    if (frontElement.length > 0) {
      frontElement[0].setAttribute("draggable", false);
    }

    let backElement = document.getElementsByClassName("image-marker-back");
    if (backElement.length > 0) {
      backElement[0].setAttribute("draggable", false);
    }

    if (identityVerification.verification.document.type !== "PASSPORT") {
      setTotalSteps(8);
    } else {
      setTotalSteps(4);
    }
  }, [indexRender]);

  function getVertices(verticies) {
    let newVertices = [];
    verticies.map((el) => {
      newVertices.push({
        y: el.top / 100 + 0.02,
        x: el.left / 100 + 0.02,
      });
    });
    return newVertices;
  }

  async function segmentDocument() {
    setError("");
    const body = {
      front: {
        coordinates: getVertices(frontMarkers),
        angle: frontRotation,
      },
      back: {
        coordinates: getVertices(backMarkers),
        angle: backRotation,
      },
    };

    setLoading(true);
    await IdentityService.SegmentDocument(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
  }

  function clearLastFront() {
    let currentFrontMarkers = frontMarkers;
    currentFrontMarkers.pop();
    setFrontMarkers(currentFrontMarkers);
    if (currentFrontMarkers.length === 0) {
      setLastMarked("back");
    }
    setIndexRender(indexRender + 1);
  }

  function clearLastBack() {
    let currentBackMarkers = backMarkers;
    currentBackMarkers.pop();
    setBackMarkers(currentBackMarkers);
    if (currentBackMarkers.length === 0) {
      setLastMarked("front");
    }
    setIndexRender(indexRender + 1);
  }

  function clearBack() {
    setBackMarkers([]);
    setLastMarked("front");
  }

  function clearFront() {
    setFrontMarkers([]);
    setLastMarked("back");
  }

  function addFrontMarker(marker) {
    if (frontMarkers.length < 4) {
      setFrontMarkers([...frontMarkers, marker]);
      setLastMarked("front");
    }
  }

  function addBackMarker(marker) {
    if (backMarkers.length < 4) {
      setBackMarkers([...backMarkers, marker]);
      setLastMarked("back");
    }
  }

  function rotateRightFront() {
    if (frontRotation + 90 === 360) {
      setFrontRotation(0);
      clearFront();
    } else {
      setFrontRotation(frontRotation + 90);
      clearFront();
    }
  }

  function rotateLeftFront() {
    if (frontRotation - 90 === -90) {
      setFrontRotation(270);
      clearFront();
    } else {
      setFrontRotation(frontRotation - 90);
      clearFront();
    }
  }

  function rotateRightBack() {
    if (backRotation + 90 === 360) {
      setBackRotation(0);
      clearBack();
    } else {
      setBackRotation(backRotation + 90);
      clearBack();
    }
  }

  function rotateLeftBack() {
    if (backRotation - 90 === -90) {
      setBackRotation(270);
      clearBack();
    } else {
      setBackRotation(backRotation - 90);
      clearBack();
    }
  }

  return (
    <GlobalHotKeys allowChanges={true} keyMap={keyMap} handlers={handlers}>
      <div className="flex flex-wrap">
        <div
          className={`w-full mb-3 sm:w-1/2 ${identityVerification.verification.document.type === "PASSPORT"
              ? "mx-auto"
              : null
            } pr-0 sm:pr-2`}
        >
          <div className="flex justify-between">
            <div>
              <StepTitle value="Front Document" icon={faIdCard}></StepTitle>
            </div>
            <div className="mt-2">
              {frontMarkers.length > 0 ? (
                <Tooltip auto={true} content="Undo">
                  <button
                    onClick={() => clearLastFront()}
                    className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faBackspace}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
              ) : null}
              <Tooltip auto={true} content="Clear points">
                <button
                  onClick={() => clearFront()}
                  className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                >
                  <FontAwesomeIcon
                    className="outline-none"
                    icon={faTrashUndo}
                  ></FontAwesomeIcon>
                </button>
              </Tooltip>
              <Tooltip auto={true} content="Rotate Left">
                <button
                  onClick={() => rotateLeftFront()}
                  className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                >
                  <FontAwesomeIcon
                    className="outline-none"
                    icon={faUndoAlt}
                  ></FontAwesomeIcon>
                </button>
              </Tooltip>
              <Tooltip auto={true} content="Rotate Right">
                <button
                  onClick={() => rotateRightFront()}
                  className="mb-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                >
                  <FontAwesomeIcon
                    className="outline-none"
                    icon={faRedoAlt}
                  ></FontAwesomeIcon>
                </button>
              </Tooltip>
            </div>
          </div>
          <ImageMarker
            key={indexRender}
            extraClass={`image-marker-front cursor-crosshair rounded-xl transform rotate-${frontRotation}`}
            src={identityVerification.media.thumbnail.front}
            markers={frontMarkers}
            markerComponent={SegmentationMarker}
            onAddMarker={(marker) => addFrontMarker(marker)}
          />
        </div>
        {identityVerification.verification.document.type !== "PASSPORT" ? (
          <div className="w-full mb-3 sm:w-1/2 pl-0 sm:pl-2">
            <div className="flex justify-between">
              <div>
                <StepTitle
                  value="Back Document"
                  icon={faAddressCard}
                ></StepTitle>
              </div>
              <div className="mt-2">
                {backMarkers.length > 0 ? (
                  <Tooltip auto={true} content="Undo">
                    <button
                      onClick={() => clearLastBack()}
                      className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                    >
                      <FontAwesomeIcon
                        className="outline-none"
                        icon={faBackspace}
                      ></FontAwesomeIcon>
                    </button>
                  </Tooltip>
                ) : null}
                <Tooltip auto={true} content="Clear points">
                  <button
                    onClick={() => clearBack()}
                    className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faTrashUndo}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
                <Tooltip auto={true} content="Rotate Left">
                  <button
                    onClick={() => rotateLeftBack()}
                    className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faUndoAlt}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
                <Tooltip auto={true} content="Rotate Right">
                  <button
                    onClick={() => rotateRightBack()}
                    className="mb-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faRedoAlt}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
              </div>
            </div>
            <ImageMarker
              key={indexRender}
              extraClass={`image-marker-back cursor-crosshair rounded-xl transform rotate-${backRotation}`}
              src={identityVerification.media.thumbnail.back}
              markers={backMarkers}
              markerComponent={SegmentationMarker}
              onAddMarker={(marker) => addBackMarker(marker)}
            />
          </div>
        ) : null}
      </div>
      {error ? (
        <div className="mt-4">
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div className="mt-4">
          <div className="">
            <IdentitySegmentationStepper
              current={frontMarkers.length + backMarkers.length}
              total={totalSteps}
            ></IdentitySegmentationStepper>
          </div>
        </div>
        <div>
          <Button
            loading={loading}
            onClick={() => segmentDocument()}
            disabled={backMarkers.length + frontMarkers.length !== totalSteps}
            icon={faCropAlt}
            full
            label="Segment"
            type="success"
          ></Button>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
