import { useContext, useEffect, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { faUniversity } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { CorporateVerificationContext } from "@/context/corporate_verification";
import Badge from "@/components/ui/Badge";
import Textarea from "@/components/ui/Textarea";
import CorporateService from "@/services/corporate";

export default function CorporateDocumentsCheck(props) {
  const [loading, setLoading] = useState(false);
  const [corporateDocumentReview, setCorporateDocumentReview] = useState({});
  const [corporateDocumentNote, setCorporateDocumentNote] = useState({});
  const [openCorporateDocumentReview, setOpenCorporateDocumentReview] =
    useState({});
  const [corporateVerification] = useContext(CorporateVerificationContext);

  useEffect(() => {
    let initCorporateDocumentNote = {};
    let initCorporateDocumentReview = {};

    Object.entries(corporateVerification.verification.document_steps).map(
      ([key, value]) => {
        initCorporateDocumentNote[key] = value.note;
      }
    );

    Object.entries(corporateVerification.verification.document_steps).map(
      ([key, value]) => {
        initCorporateDocumentReview[key] = value.reason;
      }
    );

    setCorporateDocumentNote(initCorporateDocumentNote);
    setCorporateDocumentReview(initCorporateDocumentReview);
  }, [corporateVerification]);

  const keyMap = {};

  async function verifyDocument(key, state) {
    setLoading(true);
    const body = {
      slug: key,
      state: state,
      review: corporateDocumentReview[key],
      internal_note: corporateDocumentNote[key],
    };
    await CorporateService.CheckCorporateDocument(
      corporateVerification.task.session_id,
      body,
      "1"
    )
      .then(() => {
        Notification.success("Success");
        props.onUpdate();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  const handlers = {};

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="p-6">
        <div className="flex justify-between">
          <div>
            <StepTitle
              value="Corporate Documents"
              icon={faUniversity}
            ></StepTitle>
          </div>
        </div>

        {Object.entries(corporateVerification.verification.document_steps).map(
          ([key, value]) => {
            return (
              <div key={key}>
                <div
                  onClick={() =>
                    setOpenCorporateDocumentReview({
                      ...openCorporateDocumentReview,
                      [key]: !openCorporateDocumentReview[key],
                    })
                  }
                  className="mt-3 flex w-full cursor-pointer flex-wrap justify-between bg-bluegray-50 hover:bg-bluegray-100 transition duration-100 ease-in border border-bluegray-100 p-3 rounded-lg"
                >
                  <div>
                    <p className="font-medium">{value.name}</p>
                  </div>
                  <div>
                    {" "}
                    <p>
                      {value.state === 2 ? (
                        <Badge label="Validated" small type="green"></Badge>
                      ) : null}
                      {value.state === 1 ? (
                        <Badge label="Pending" small type="orange"></Badge>
                      ) : null}
                      {value.state === 0 ? (
                        <Badge label="Rejected" small type="red"></Badge>
                      ) : null}
                    </p>
                  </div>
                </div>
                {openCorporateDocumentReview[key] ? (
                  <div className="w-full mt-3">
                    <div className="flex flex-wrap">
                      <div className="w-3/5">
                        <div className="mt-4 mb-3">
                          {value.is_pdf ? (
                            <iframe
                              className="w-full h-screen"
                              src={
                                corporateVerification.verification
                                  .document_steps[key].filename
                              }
                            ></iframe>
                          ) : (
                            <img
                              src={
                                corporateVerification.verification
                                  .document_steps[key].filename
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="w-2/5 p-3">
                        <div>
                          <p className="text-gray-500 font-medium text-sm mb-2">
                            Document Review
                          </p>
                          <Textarea
                            value={corporateDocumentReview[key]}
                            setValue={(value) =>
                              setCorporateDocumentReview({
                                ...corporateDocumentReview,
                                [key]: value,
                              })
                            }
                            placeholder="Write review"
                          ></Textarea>
                        </div>
                        <div>
                          <p className="text-gray-500 font-medium text-sm mb-2 mt-3">
                            Internal Note
                          </p>
                          <Textarea
                            value={corporateDocumentNote[key]}
                            setValue={(value) =>
                              setCorporateDocumentNote({
                                ...corporateDocumentNote,
                                [key]: value,
                              })
                            }
                            placeholder="Write note"
                          ></Textarea>
                        </div>
                        <div className="flex flex-wrap">
                          <div className="w-1/3 mt-3">
                            {corporateDocumentReview[key] === "" ||
                            !corporateDocumentReview[key] ? (
                              <Button
                                loading={loading}
                                onClick={() => verifyDocument(key, 2)}
                                icon={faCheck}
                                label="Validate"
                                full
                                theme="success"
                              ></Button>
                            ) : (
                              <Button
                                loading={loading}
                                onClick={() => verifyDocument(key, 0)}
                                icon={faTimes}
                                label="Reject"
                                full
                                theme="error"
                              ></Button>
                            )}
                          </div>
                          <div className="w-1/3 ml-3 mt-3">
                            {corporateDocumentNote[key] !== "" ? (
                              <Button
                                loading={loading}
                                onClick={() => verifyDocument(key, value.state)}
                                icon={faCheck}
                                label="Update Note"
                                full
                                theme="pin"
                              ></Button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            );
          }
        )}
      </div>
    </GlobalHotKeys>
  );
}
