export default function TabSelector(props) {
	return (
		<div>
			<nav className="flex space-x-4" aria-label="Tabs">
				{props.choices.map((choice) => {
					return (
						<a
							key={choice.label}
							onClick={() => props.onSelect(choice.value)}
							className={`text-gray-500 hover:text-gray-700 border border-bluegray-200 hover:border hover:border-primary-300 transition ease-in px-3 py-2 font-medium text-sm rounded-xl cursor-pointer ${
								props.selected === choice.value
									? 'text-gray-700 border border-primary-300 hover:border'
									: null
							}`}>
							{choice.label}
						</a>
					)
				})}
			</nav>
		</div>
	)
}
