import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { DuplicateVerificationContextProvider } from '@/context/duplicate_verification'
import DuplicateVerification from '@/components/layout/DuplicateVerification'

function FacematchSession() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<DuplicateVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<DuplicateVerification></DuplicateVerification>
					</div>
				</DuplicateVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default FacematchSession
