import { useContext, useState } from "react";
import { AmlVerificationContext } from "@/context/aml_verification";
import { GlobalHotKeys } from "react-hotkeys";
import AmlService from "@/services/aml";
import { faCheck, faTimes, faStream } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import Textarea from "@/components/ui/Textarea";
import Label from "@/components/ui/Label";

export default function AmlVisualCheck() {
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [amlVerification] = useContext(AmlVerificationContext);
  const router = useHistory();

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function rejectAml() {
    let body = {
      "internal_note": note
    }
    setLoading(true);
    await AmlService.RejectAml(amlVerification.task.session_id, body)
      .then(() => {
        setLoading(false);
        Notification.success("Aml rejected successfully");
        router.push("/verifications/aml");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function validateAml() {
    let body = {
      "internal_note": note
    }
    setLoading(true);
    await AmlService.ValidateAml(amlVerification.task.session_id, body)
      .then(() => {
        setLoading(false);
        Notification.success("Aml validated successfully");
        router.push("/verifications/aml");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  const handlers = {};

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle value="Aml Visual Check" icon={faStream}></StepTitle>
        </div>
      </div>
      <div className="flex flex-wrap mx-3">
        <div className="w-1/2 p-3">
          <div className="mx-auto">
            <div className="flex justify-between">
              <div className="mx-auto">
                <p className="ml-3 mb-3">Id Face</p>
              </div>
            </div>
            <img
              draggable={false}
              className={"object-contain h-128 w-full"}
              src={
                amlVerification.identity.verification.document.front.segmented
              }
            />
            {amlVerification.identity.verification.document.type !==
              "PASSPORT" ? (
              <img
                draggable={false}
                className={"object-contain h-128 w-full"}
                src={
                  amlVerification.identity.verification.document.back.segmented
                }
              />
            ) : null}
          </div>
        </div>
        <div className="w-1/2 p-3">
          <div className="mx-auto">
            <div className="flex justify-between">
              <div className="mx-auto">
                <p className="ml-3 mb-3">Aml Info</p>
              </div>
            </div>
            <div className="bg-bluegray-50 mt-8 p-3 rounded-xl">
              {amlVerification.identity.session_id !== "" ? (
                <div className="mb-3">
                  <p className="ml-3 mb-1">User info :</p>
                  <p className="ml-3 mb-1">
                    {
                      amlVerification.identity.verification.details.info
                        .firstname.value
                    }
                  </p>
                  <p className="ml-3 mb-1">
                    {
                      amlVerification.identity.verification.details.info
                        .lastname.value
                    }
                  </p>
                  <p className="ml-3 mb-1">
                    {
                      amlVerification.identity.verification.details.info
                        .birth_date.value
                    }
                  </p>
                  <p className="ml-3 mb-1">
                    {
                      amlVerification.identity.verification.details.info
                        .nationality.value
                    }
                  </p>
                </div>
              ) : (
                <div>
                  <p className="ml-3 mb-1">Corporate info :</p>
                  <p className="ml-3 mb-1">
                    {amlVerification.corporate.corporate.name}
                  </p>
                  <p className="ml-3 mb-1">
                    {amlVerification.corporate.corporate.address}
                  </p>
                  <p className="ml-3 mb-1">
                    {amlVerification.corporate.corporate.city}
                  </p>
                  <p className="ml-3 mb-1">
                    {amlVerification.corporate.corporate.country}
                  </p>
                  <p className="ml-3 mb-1">
                    {amlVerification.corporate.corporate.registration_number}
                  </p>
                </div>
              )}

              <p className="ml-3 mb-1">Aml Reference :</p>
              <p className="ml-3 mb-5">{amlVerification.verification.ref}</p>
              <p className="ml-3 mb-1">Aml Match :</p>
              <p className="ml-3 mb-5">
                {amlVerification.verification.total_hits}
              </p>
              {amlVerification.verification.criterias &&
                amlVerification.verification.criterias.length > 0 ? (
                <div>
                  <p className="ml-3 mb-1">Aml Criterias :</p>
                  <div className="mb-5">
                    {amlVerification.verification.criterias.map((criteria) => {
                      return (
                        <p key={criteria} className="ml-3">
                          - {criteria}
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
              <p className="ml-3 mb-1">Aml URL :</p>
              <a
                className="ml-3 mb-3 p-3 bg-primary-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                href={amlVerification.verification.url}
                target="_blank"
                type="button"
                rel="noreferrer"
              >
                Comply Advantage
              </a>
              {/* {amlVerification.verification.triggers.length > 0 ? (
								<div>
									<p className='ml-3 mb-1'>Hit triggers :</p>
									<div className='mb-5'>
										{amlVerification.verification.triggers.map((trigger) => {
											return (
												<p key={trigger.source} className='ml-3 mb-3'>
													- {trigger.name} - {trigger.value} - {trigger.source}
												</p>
											)
										})}
									</div>
								</div>
							) : (
								''
							)} */}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-bluegray-50 p-3 rounded-xl">
        <div className="mb-2 ml-1" >
          <Label value="Action justifications (will be available to the Synaps client)"></Label>
        </div>
        <Textarea
          placeholder="Write details to justify your decision to the client. Be careful, this is not an internal note, your justification will be seen by the end client and must be in English"
          setValue={(value) => setNote(value)}
        ></Textarea>
      </div>

      <div className="bg-bluegray-50 mt-4 p-3 rounded-xl">
        <div className="flex justify-center">
          {note !== "" ?
            <>
              <div className="mr-3">
                <Button
                  loading={loading}
                  onClick={() => rejectAml()}
                  icon={faTimes}
                  full
                  label="Reject"
                  theme="error"
                ></Button>
              </div>
              <div className="ml-3">
                <Button
                  loading={loading}
                  onClick={() => validateAml()}
                  icon={faCheck}
                  full
                  label="Validate"
                  theme="success"
                ></Button>
              </div>
            </> :
            <>
              <div className="mr-3">
                <Button
                  loading={loading}
                  onClick={() => rejectAml()}
                  icon={faTimes}
                  full
                  label="Reject"
                  theme="error"
                  disabled
                ></Button>
              </div>
              <div className="ml-3">
                <Button
                  loading={loading}
                  onClick={() => validateAml()}
                  icon={faCheck}
                  full
                  label="Validate"
                  theme="success"
                  disabled
                ></Button>
              </div>
            </>}
        </div>
      </div>
    </GlobalHotKeys>
  );
}
