import { PanelAuth } from "@/services/axios";

export default {
  GetProjectNetwork() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/project`
    );
  },
  CreateProjectNetwork(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/project`,
      body
    );
  },
  UpdateProjectNetwork(body, name) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/project/${name}`,
      body
    );
  },
  GetProjectExport(name, projectType) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/export/${name}?type=${projectType}`
    );
  },
  UploadProjectNetworkLogo(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/logo`,
      body
    );
  },
  GetAppNetwork(appKey) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/network/app/${appKey}`
    );
  },
};
