import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function DocumentCard(props) {
	return (
		<div
			onClick={() => props.setPin(props.slug)}
			className="group flex flex-wrap items-center p-3 border my-4 rounded-xl hover:border-primary-200 transition ease-in duration-100 cursor-pointer">
			<div className="flex-shrink-0 w-8">
				<FontAwesomeIcon
					className="group-hover:text-primary-400 text-gray-500"
					icon={props.icon}></FontAwesomeIcon>
			</div>
			<div className="flex-1">
				<p className="group-hover:text-primary-400 text-gray-500 text-sm">
					{props.title}
				</p>
			</div>
			{props.selected === props.slug ? (
				<div className="flex-shrink-0 mr-2">
					<FontAwesomeIcon
						className="text-green-500"
						icon={faCheck}></FontAwesomeIcon>
				</div>
			) : null}
		</div>
	)
}
