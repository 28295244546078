import { useReducer, createContext } from 'react'

export const SessionContext = createContext()

const initialState = {
	ready: false
}

function setData(state, payload) {
	return {
		...state,
		...payload,
		ready: true,
	}
}

function setReady() {
	return {
		ready: false,
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_DATA':
			return setData(state, action.payload)
		case 'SET_READY':
			return setReady()
		default:
			return state
	}
}

export const SessionContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<SessionContext.Provider value={[state, dispatch]}>
			{props.children}
		</SessionContext.Provider>
	)
}
