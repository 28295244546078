import { faStream } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../ui/Tooltip";
import AmlTabs from "./AmlTabs";

export default function AmlTasksHeader(props) {
  return (
    <div className="flex flex-wrap justify-between">
      <div>
        <div className="flex">
          <div className="mr-5 mt-0.5">
            <FontAwesomeIcon
              className="text-facematch-500 text-6xl"
              icon={faStream}
            ></FontAwesomeIcon>
          </div>
          <div>
            <h1 className="text-3xl">
              <span className="font-bold">Aml</span>
            </h1>
            <h3 className="text-lg  text-bluegray-600">Aml Checks</h3>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex">
          <div className="flex -space-x-1 top-2.5 relative z-0 overflow-hidden">
            {props.synapsState && props.synapsState.online
              ? Object.entries(props.synapsState.online).map(([key, value]) => {
                  return value.service === "AML" ? (
                    <Tooltip key={key} auto={true} content={value.firstname}>
                      <img
                        className="relative z-30 inline-block h-7 w-7 rounded-full ring-2 ring-white"
                        src={value.profile_pic}
                        alt=""
                      />
                    </Tooltip>
                  ) : null;
                })
              : null}
          </div>
          <div className="flex flex-wrap ml-6 mr-2 bg-gray-100 rounded-xl p-2">
            <AmlTabs></AmlTabs>
          </div>
        </div>
      </div>
    </div>
  );
}
