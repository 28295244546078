export default function RegistryListItem(props) {
	return (
		<div className="p-6">
			<div className="text-center">
				<div className="mx-auto text-center mt-1 mb-4">
					<img
						className="h-20 rounded-full mx-auto text-center"
						src={props.agent.profile_pic}
						alt=""
					/>								
				</div>
				<p className="text-regular font-semibold">{props.agent.firstname} {props.agent.lastname}</p>
				<p className="text-sm">{props.agent.email}</p>
				<ul className="flex justify-center text-center">
					{props.agent.iam.map((role, index) => (
						<li className="p-1"  key={index}>
							<div className="items-center">
								<a className="group items-center p-1 bg-bluegray-100 rounded-xl text-sm">{role}</a>
							</div>
						</li>
					))}
				</ul>
				<p className="text-sm mt-1">{props.agent.today} tasks today</p>			
			</div>
		</div>
	)
}
