import { PanelAuth } from "@/services/axios";

export default {
  Request() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/twofactor/request`
    );
  },
  Activate(data) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/twofactor/activate`,
      data
    );
  },
  Disable(data) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/twofactor/disable`,
      data
    );
  },
  Status() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/twofactor/status`
    );
  },
};
