import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { AmlVerificationContextProvider } from '@/context/aml_verification'
import AmlVerification from '@/components/layout/AmlVerification'

function AmlSession() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<AmlVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<AmlVerification></AmlVerification>
					</div>
				</AmlVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default AmlSession
