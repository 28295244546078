import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import ManagersPage from '@/components/pages/ManagersPage'

function AgentsIndex() {
	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<ManagersPage></ManagersPage>
			</div>
		</AuthGuard>
	)
}

export default AgentsIndex
