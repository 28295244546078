export default function MyVerificationItem(props) {
	return (
		<>
			<div className={`h-full ${props.className ? props.className : ''}`}>
				<div className={'bg-bluegray-50 p-2 px-5 rounded-xl'}>
					<div className="flex mt-1">
						<div>
							<p className="text-sm">
								<span
									className={'uppercase font-semibold text-gray-700 text-xs'}>
									{props.label}
								</span>
							</p>
							<p className={'text-2xl font-bold text-primary-500'}>
								{props.amount}
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
