import { useContext, useEffect, useState } from 'react'
import Footer from '@/components/menu/Footer'
import SubNav from '@/components/menu/SubNav'
import IndividualSessionHeader from '@/components/sessions/individual/IndividualSessionHeader'
import IndividualSessionSidebar from '@/components/sessions/individual/IndividualSessionSidebar'
import IndividualService from '@/services/individual'
import IndividualCore from '@/components/sessions/individual/IndividualCore'
import { SessionContext } from '@/context/session'
import { useParams } from 'react-router-dom'


export default function IndividualSessionRessource() {
    const [session, sessionDispatch] = useContext(SessionContext)

    const [open] = useState(false)
    const [flowKey, setFlowKey] = useState(0)
    const { session_id } = useParams()


    useEffect(() => {
        GetIndividualResource()
        return () => { }
    }, [])

    async function GetIndividualResource() {
        await sessionDispatch({
            type: 'SET_READY',
            payload: false,
        })
        await IndividualService.GetIndividualResource(session_id).then(async (data) => {
            console.log('data')
            console.log(data)
            await sessionDispatch({
                type: 'SET_DATA',
                payload: data,
            })
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        if (!open) {
            setFlowKey(flowKey + 1)
        }
    }, [open])

    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container px-3'>
                        <IndividualSessionHeader onRefresh={() => GetIndividualResource()} />
                        <div className="flex mt-2">
                            <div className="w-3/12 pr-2">
                                <IndividualSessionSidebar />
                            </div>
                            <div className="w-9/12 pl-2">
                                {session.ready ? <IndividualCore onRefresh={() => GetIndividualResource()} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl mx-auto relative'>
                <Footer></Footer>
            </div>
        </div>
    )
}
