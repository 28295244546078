import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faArrowLeft,
  faArrowRight,
  faCabinetFiling,
  faIdCard,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import SmallButton from "@/components/ui/SmallButton";
import Alert from "@/components/ui/Alert";
import * as Notification from "@/components/ui/Notification";
import AnnotedImage from "@/components/ui/AnnotedImage";
import Cropper from "@/components/ui/Cropper";
import StepTitle from "@/components/ui/StepTitle";

export default function IdentityFeatures(props) {
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [documentReview, setDocumentReview] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeReview, setActiveReview] = useState(0);
  const [showAnnotation, setShowAnnotation] = useState(false);

  const keyMap = {
    GO_NEXT: ["right", "down"],
    GO_BACK: ["left", "up"],
    SELECT: ["enter"],
    DELETE: ["del", "backspace"],
  };

  function next(event) {
    event.preventDefault();
    setNext();
  }

  function back(event) {
    event.preventDefault();
    setPrev();
  }

  function select(event) {
    event.preventDefault();
    setDocumentInfo(identityVerification.features_checks[currentIndex].slug);
  }

  useEffect(() => {
    return () => {};
  });

  const handlers = {
    GO_NEXT: next,
    GO_BACK: back,
    SELECT: select,
    DELETE: removeCurrent,
  };

  function setNext() {
    if (!loading && documentReview[currentIndex]) {
      if (currentIndex + 1 < identityVerification.features_checks.length) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }

  function setPrev() {
    if (!loading) {
      if (currentIndex - 1 >= 0) {
        setShowAnnotation(true);
        setCurrentIndex(currentIndex - 1);
      }
    }
  }

  function buildExtractRequest() {
    let request = [];
    documentReview.map((doc, index) =>
      request.push({
        slug: identityVerification.features_checks[index].slug,
        position: doc.position,
        skipped: doc.skipped,
        reviewed: true,
        type: identityVerification.features_checks[index].type,
        side: identityVerification.features_checks[index].side,
      })
    );
    return {
      annotations: request,
    };
  }

  async function setDocumentInfo() {
    setLoading(true);
    const body = buildExtractRequest();
    await IdentityService.SetFeatures(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  function removeCurrent() {
    if (documentReview[currentIndex]) {
      setActiveReview(activeReview - 1);
    }
    let docReview = [...documentReview];
    docReview[currentIndex] = null;
    setDocumentReview(docReview);
  }

  function addNewReview(coordinates) {
    if (!documentReview[currentIndex]) {
      setActiveReview(activeReview + 1);
    }
    let docReview = [...documentReview];
    docReview[currentIndex] = {
      position: coordinates,
      name: identityVerification.features_checks[currentIndex].annotation.name,
      slug: identityVerification.features_checks[currentIndex].slug,
      side: identityVerification.features_checks[currentIndex].side,
      type: identityVerification.features_checks[currentIndex].type,
      skipped: false,
    };
    setDocumentReview(docReview);
  }

  function resetSkipped() {
    if (!documentReview[currentIndex]) {
      setActiveReview(activeReview + 1);
    }
    let docReview = [...documentReview];
    docReview[currentIndex] = {
      position: null,
      name: identityVerification.features_checks[currentIndex].annotation.name,
      side: identityVerification.features_checks[currentIndex].side,
      slug: identityVerification.features_checks[currentIndex].slug,
      type: identityVerification.features_checks[currentIndex].type,
      skipped: false,
    };
    setDocumentReview(docReview);
  }

  function skipAnnotation() {
    const coordinates = {
      top_left: {
        x: 0,
        y: 0,
      },
      top_right: {
        x: 0,
        y: 0,
      },
      bottom_left: {
        x: 0,
        y: 0,
      },
      bottom_right: {
        x: 0,
        y: 0,
      },
      width: 0,
      height: 0,
    };
    if (!documentReview[currentIndex]) {
      setActiveReview(activeReview + 1);
    }
    let docReview = [...documentReview];
    docReview[currentIndex] = {
      position: coordinates,
      name: identityVerification.features_checks[currentIndex].annotation.name,
      side: identityVerification.features_checks[currentIndex].side,
      slug: identityVerification.features_checks[currentIndex].slug,
      type: identityVerification.features_checks[currentIndex].type,
      skipped: true,
    };
    setDocumentReview(docReview);
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div id="template-selection" className="flex items-end flex-wrap">
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Document" icon={faIdCard}></StepTitle>
            </div>
            <div className="relative top-2 flex">
              {documentReview[currentIndex]?.skipped === true &&
              identityVerification.features_checks[currentIndex].required ===
                false ? (
                <span className="mr-2 text-sm relative top-1">Skipped</span>
              ) : null}
              {identityVerification.features_checks[currentIndex].required ===
              false ? (
                <div>
                  <SmallButton
                    type="pin"
                    label="Skip"
                    onClick={() => skipAnnotation()}
                  ></SmallButton>
                </div>
              ) : null}
              {identityVerification.features_checks[currentIndex].required ===
                false && documentReview[currentIndex]?.skipped === true ? (
                <div className="ml-2">
                  <SmallButton
                    type="pin"
                    label="Reset"
                    onClick={() => resetSkipped()}
                  ></SmallButton>
                </div>
              ) : null}

              {documentReview[currentIndex]?.position &&
              showAnnotation &&
              documentReview[currentIndex]?.skipped === false ? (
                <SmallButton
                  className="ml-1"
                  type="pin"
                  label={"Edit"}
                  onClick={() => setShowAnnotation(false)}
                ></SmallButton>
              ) : null}
            </div>
          </div>
          <div className="relative">
            {documentReview[currentIndex]?.position &&
            showAnnotation === true ? (
              <AnnotedImage
                key={currentIndex}
                single
                annotations={documentReview[currentIndex]}
                type="identity"
                id="identity-doc"
                className="object-contain h-80 w-full"
                src={
                  identityVerification.features_checks[currentIndex].side ===
                  "FRONT"
                    ? identityVerification.media.segmented.front
                    : identityVerification.media.segmented.back
                }
              ></AnnotedImage>
            ) : (
              <Cropper
                key={currentIndex}
                src={
                  identityVerification.features_checks[currentIndex].side ===
                  "FRONT"
                    ? identityVerification.media.segmented.front
                    : identityVerification.media.segmented.back
                }
                setPosition={(coordinates) => addNewReview(coordinates)}
              />
            )}
          </div>
        </div>
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle
                value="Registry Template"
                icon={faCabinetFiling}
              ></StepTitle>
            </div>
            <div>
              <span className="inline-flex items-center text-sm px-2 mt-2 py-0.5 rounded font-medium bg-gray-100 text-gray-800">
                {currentIndex + 1} /{" "}
                {identityVerification.features_checks.length}
              </span>
            </div>
          </div>
          {identityVerification.features_checks.length > 0 ? (
            <AnnotedImage
              single
              annotations={
                identityVerification.features_checks[currentIndex].annotation
              }
              type="registry"
              id="registry-doc"
              className="object-contain h-80 w-full"
              src={
                identityVerification.features_checks[currentIndex].side ===
                "FRONT"
                  ? identityVerification.media.template.front
                  : identityVerification.media.template.back
              }
            ></AnnotedImage>
          ) : null}
        </div>
        <div className="w-full">
          <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
            <div>
              {currentIndex - 1 >= 0 ? (
                <Button
                  onClick={() => setPrev()}
                  icon={faArrowLeft}
                  full
                  label="Back"
                ></Button>
              ) : null}
            </div>
            <div>
              <p className="mt-2.5 mb-2.5">
                {
                  identityVerification.features_checks[currentIndex].annotation
                    .name
                }{" "}
                {identityVerification.features_checks[currentIndex].required ===
                false ? (
                  <span>(optional)</span>
                ) : null}
              </p>
            </div>
            <div className="flex">
              {currentIndex + 1 < identityVerification.features_checks.length &&
              documentReview[currentIndex] ? (
                <Button
                  onClick={() => setNext()}
                  icon={faArrowRight}
                  full
                  label="Next"
                ></Button>
              ) : null}
              {activeReview === identityVerification.features_checks.length ? (
                <Button
                  className="ml-3"
                  loading={loading}
                  onClick={() => setDocumentInfo()}
                  icon={faCheck}
                  full
                  theme="success"
                  label="Confirm"
                ></Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
