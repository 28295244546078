import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/list?type=${type}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/task/${session_id}`
    );
  },
  CheckCorporateDocument(session_id, body, corporate_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/step/corporate_document_checks/${session_id}?corporate_id=${corporate_id}`,
      body
    );
  },
  Validate(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/task/${session_id}/validate`,
      {}
    );
  },
  Reject(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/task/${session_id}/reject`,
      {}
    );
  },
  ChangeRegistrationNumber(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/registration_number/${session_id}`,
      body
    );
  },
  ChangeCorporateCountry(session_id, document_country) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/corporate/task/${session_id}/change_country?country=${document_country}`,
      {}
    );
  },
  GetCorporateResource(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/corporate/session/${session_id}`
    );
  },
};
