import HeaderButton from "@/components/ui/HeaderButton";

import Card from "@/components/ui/Card";
import {
  faAddressCard,
  faAsterisk,
  faBadgeCheck,
  faBirthdayCake,
  faCar,
  faChevronDoubleRight,
  faClock,
  faGlobe,
  faIdCard,
  faPassport,
  faPen,
  faPlane,
  faStamp,
  faTrash,
  faUser,
  faUsers,
} from "@fortawesome/pro-duotone-svg-icons";
import RegistryHeaderItem from "./RegistryHeaderItem";
import { RegistryIdentityContext } from "@/context/registry_identity";
import { useContext, useState } from "react";
import RegistryHead from "./RegistryHead";
import RegistryService from "@/services/registry";
import * as Notification from "../ui/Notification";
import RegistryHeaderPersonalInfo from "./RegistryHeaderPersonalInfo";
import RegistryUploadDocument from "./RegistryUploadDocument";
import RegistryNewFeature from "./RegistryNewFeature";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnnotedImage from "../ui/AnnotedImage";
import { useHistory } from "react-router-dom";

export default function RegistryHeader(props) {
  const [newFeature, setNewFeature] = useState(null);
  const [deployLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [upload, setUpload] = useState();
  const router = useHistory();
  const [registryIdentity] = useContext(RegistryIdentityContext);

  async function SetDeploy(type) {
    await RegistryService.SetDeployment(registryIdentity.slug, type)
      .then(() => {
        if (type === "undeploy") {
          Notification.success("Document successfully removed from production");
        }
        if (type === "deploy") {
          Notification.success("Document successfully promoted to production");
        }
        props.onUpdate();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  async function DeleteDoc() {
    await RegistryService.DeleteDocument(registryIdentity.slug)
      .then(() => {
        Notification.success("Document successfully deleted");
        window.location.href = `/registry/${registryIdentity.country}`;
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  function setFeature(ft) {
    setNewFeature(ft);
    setOpen(true);
  }

  return (
    <>
      <div className="sm:flex sm:flex-wrap justify-between px-3 sm:px-0 pb-5 sm:pb-3">
        <div className="flex">
          <div className="mt-1">
            <h1 className="text-white">
              <span
                className="font-bold hover:underline cursor-pointer"
                onClick={() =>
                  router.push(`/registry/${registryIdentity.country}`)
                }
              >
                {registryIdentity.country}
              </span>{" "}
              / {registryIdentity.slug}
            </h1>
          </div>
        </div>
        <div className="flex flex-wrap mt-4 sm:mt-0">
          <div className="sm:ml-0 mr-3">
            <HeaderButton
              loading={deployLoading}
              onClick={() => DeleteDoc()}
              currentBg="primary-500"
              icon={faTrash}
              type="error"
              label="Delete"
            ></HeaderButton>
          </div>
          {registryIdentity.deployed ? (
            <div className="sm:ml-0">
              <HeaderButton
                loading={deployLoading}
                onClick={() => SetDeploy("undeploy")}
                currentBg="primary-500"
                icon={faTrash}
                type="error"
                label="Remove from production"
              ></HeaderButton>
            </div>
          ) : (
            <div className="sm:ml-0">
              <HeaderButton
                loading={deployLoading}
                onClick={() => SetDeploy("deploy")}
                currentBg="primary-500"
                icon={faChevronDoubleRight}
                type="success"
                label="Promote to production"
              ></HeaderButton>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <Card className="w-full px-3 sm:px-0">
          <div className="sm:flex p-4 justify-between">
            <div className="flex">
              <div className="sm:p-3 ml-2">
                <h3 className="text-2xl">
                  <div className="w-full sm:w-1/3">
                    {registryIdentity.document_type === "PASSPORT" ? (
                      <RegistryHead
                        slug={registryIdentity.slug}
                        value="Passport"
                        country={registryIdentity.country}
                        countryName={registryIdentity.country_name}
                        icon={faPassport}
                      ></RegistryHead>
                    ) : null}
                    {registryIdentity.document_type === "NATIONAL_ID" ? (
                      <RegistryHead
                        slug={registryIdentity.slug}
                        value="National ID"
                        country={registryIdentity.country}
                        countryName={registryIdentity.country_name}
                        icon={faIdCard}
                      ></RegistryHead>
                    ) : null}
                    {registryIdentity.document_type === "DRIVER_LICENSE" ? (
                      <RegistryHead
                        slug={registryIdentity.slug}
                        value="Driver License"
                        country={registryIdentity.country}
                        countryName={registryIdentity.country_name}
                        icon={faCar}
                      ></RegistryHead>
                    ) : null}
                    {registryIdentity.document_type === "RESIDENT_PERMIT" ? (
                      <RegistryHead
                        slug={registryIdentity.slug}
                        value="Resident Permit"
                        country={registryIdentity.country}
                        countryName={registryIdentity.country_name}
                        icon={faAddressCard}
                      ></RegistryHead>
                    ) : null}
                  </div>
                </h3>
              </div>
            </div>
            <div className="rounded-xl h-full md:w-4/6 w-full">
              <div className="flex flex-wrap">
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="Document Country"
                    value={registryIdentity.country}
                    icon={faGlobe}
                  ></RegistryHeaderItem>
                </div>
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="Issuing Year"
                    value={registryIdentity.issuing_year}
                    icon={faStamp}
                  ></RegistryHeaderItem>
                </div>
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="Validity"
                    value={`${registryIdentity.validity} years`}
                    icon={faClock}
                  ></RegistryHeaderItem>
                </div>
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="In Circulation"
                    value={`${
                      registryIdentity.out_of_circulation ? "No" : "Yes"
                    }`}
                    icon={faPlane}
                  ></RegistryHeaderItem>
                </div>
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="Production"
                    value={`${registryIdentity.deployed ? "Yes" : "No"}`}
                    icon={faBadgeCheck}
                  ></RegistryHeaderItem>
                </div>
                <div className="w-full sm:w-1/3">
                  <RegistryHeaderItem
                    label="Documents"
                    value={registryIdentity.verified_documents}
                    icon={faPassport}
                  ></RegistryHeaderItem>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-bluegray-200 rounded-b-xl bg-bluegray-100">
            <div className="flex flex-wrap">
              <div
                className={`${
                  registryIdentity.document_type === "PASSPORT"
                    ? "w-full"
                    : "w-1/2"
                }`}
              >
                <h1 className="font-bold px-6 py-4">
                  <span className="mr-2.5 text-bluegray-500">
                    <FontAwesomeIcon
                      icon={
                        registryIdentity.document_type === "PASSPORT"
                          ? faPassport
                          : faIdCard
                      }
                    ></FontAwesomeIcon>
                  </span>
                  <span className="text-primary-500">
                    {registryIdentity.document_type === "PASSPORT"
                      ? "Passport Page"
                      : "Front Document"}
                  </span>
                  {registryIdentity.deployed === false ? (
                    <span
                      onClick={() => setUpload("front")}
                      className="ml-2 relative top-1px inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-gray-200 text-gray-500 hover:text-gray-800 transition duration-100 ease-in cursor-pointer"
                    >
                      <FontAwesomeIcon
                        className="text-2xs relative top--1px mr-1"
                        icon={faPen}
                      ></FontAwesomeIcon>
                      Edit
                    </span>
                  ) : null}
                </h1>
                <div className="p-6 bg-bluegray-200 mb-3">
                  <AnnotedImage
                    annotations={registryIdentity.annotations.front}
                    id="front"
                    type="registry"
                    className="object-contain h-64"
                    src={registryIdentity.front}
                  ></AnnotedImage>
                </div>
              </div>
              {registryIdentity.document_type !== "PASSPORT" ? (
                <div className="w-1/2">
                  <h1 className="font-bold px-6 py-4">
                    <span className="mr-2.5 text-bluegray-500">
                      <FontAwesomeIcon icon={faAddressCard}></FontAwesomeIcon>
                    </span>
                    <span className="text-primary-500">Back Document</span>
                    {registryIdentity.deployed === false ? (
                      <span
                        onClick={() => setUpload("back")}
                        className="ml-2 relative top-1px inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium bg-gray-200 text-gray-500 hover:text-gray-800 transition duration-100 ease-in cursor-pointer"
                      >
                        <FontAwesomeIcon
                          className="text-2xs relative top--1px mr-1"
                          icon={faPen}
                        ></FontAwesomeIcon>
                        Edit
                      </span>
                    ) : null}
                  </h1>

                  <div className="p-6 bg-bluegray-200 mb-3">
                    <AnnotedImage
                      annotations={registryIdentity.annotations.back}
                      id="back"
                      type="registry"
                      className="object-contain h-64 w-full"
                      src={registryIdentity.back}
                    ></AnnotedImage>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="rounded-xl h-full w-full">
              <div className="flex flex-wrap p-3 pt-0">
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.firstname}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "firstname",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    value="Firstname"
                    icon={faUser}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.lastname}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "lastname",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    value="Lastname"
                    icon={faUsers}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.birth_date}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    value="Birth Date"
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "birth_date",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    icon={faBirthdayCake}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.document_number}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    value="Document Number"
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "document_number",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    icon={faAsterisk}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.document_expiration}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    value="Document Expiration"
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "document_expiration",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    icon={faClock}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.nationality}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    value="Nationality"
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "nationality",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    icon={faClock}
                  ></RegistryHeaderPersonalInfo>
                </div>
                <div className="w-full sm:w-1/4 p-3">
                  <RegistryHeaderPersonalInfo
                    data={registryIdentity.info.face}
                    document={registryIdentity.slug}
                    onDelete={() => props.onUpdate()}
                    value="Face"
                    onSet={() =>
                      setFeature({
                        side: "",
                        type: "info",
                        name: "face",
                        document_type: registryIdentity.document_type,
                      })
                    }
                    icon={faClock}
                  ></RegistryHeaderPersonalInfo>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      {newFeature ? (
        <RegistryNewFeature
          onSuccess={() => props.onUpdate()}
          onClose={() => setOpen(false)}
          open={open}
          type={newFeature.type}
          name={newFeature.name}
          side={newFeature.side}
        ></RegistryNewFeature>
      ) : null}
      {upload ? (
        <RegistryUploadDocument
          onSuccess={() => props.onUpdate()}
          onClose={() => setUpload(null)}
          open={upload !== ""}
          name={upload.toUpperCase()}
          slug={registryIdentity.slug}
          country={registryIdentity.country}
          countryName={registryIdentity.country_name}
          side={upload.toUpperCase()}
        ></RegistryUploadDocument>
      ) : null}
    </>
  );
}
