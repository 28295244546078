import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/duplicate/list?type=${type}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/duplicate/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/duplicate/task/${session_id}`
    );
  },
  RejectDuplicate(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/duplicate/task/${session_id}/reject`,
      {}
    );
  },
  ValidateDuplicate(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/duplicate/task/${session_id}/validate`,
      {}
    );
  },
};
