import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import ResidencyDocumentViewer from '../ResidencyDocumentViewer'
import StepTitle from '@/components/ui/StepTitle'
import {
	faCalendar,
	faCheckCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditDocumentTypeSidebar from '@/components/residency/EditDocumentTypeSidebar'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'
import ReactDatePicker from 'react-datepicker'

export default function ResidencyIssuingDate(props) {
	const [issuingDate, setIssuingDate] = useState(null)
	const [successLoading, setSuccessLoading] = useState(false)
	const [editDocumentOpen, setEditDocumentOpen] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)
	const keyMap = {
		SELECT: ['enter'],
	}

	const handlers = {
		SELECT: () => validateDate(),
	}

	async function validateDate() {
		setSuccessLoading(true)
		const issDate = moment(issuingDate).format('YYYY-MM-DD')
		await ResidencyService.SetIssuingDate(
			residencyVerification.task.session_id,
			issDate
		)
			.then(() => {
				setSuccessLoading(false)
				props.onUpdate()
			})
			.catch((err) => {
				Notification.error(err.message)
				setSuccessLoading(false)
			})
		return () => {}
	}

	function removeDays(dateObj, numDays) {
		dateObj.setDate(dateObj.getDate() - numDays)
		return dateObj
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}
			<div className="flex flex-wrap">
				<div className="w-3/5">
					<ResidencyDocumentViewer></ResidencyDocumentViewer>
				</div>
				<div className="w-2/5">
					<div>
						<StepTitle value="Issuing Date" icon={faCalendar}></StepTitle>
					</div>
					<div>
						<div className="text-center mb-6">
							<div className="mt-6 mb-6">
								<FontAwesomeIcon
									className="text-residency-500 text-5xl"
									icon={faCalendar}></FontAwesomeIcon>
							</div>
							<div>
								<ReactDatePicker
									className="border rounded-lg p-3 text-2xl font-bold"
									selected={issuingDate}
									onChange={(date) => setIssuingDate(date)}
									minDate={removeDays(new Date(), 90)}
									maxDate={new Date()}
									dateFormat="yyyy-MM-dd"
									placeholderText="Select issuing date"
								/>
							</div>
						</div>
						<hr />
						<div className="p-4 pb-0">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Instructions
							</p>
							<div>
								<p className="mt-2">
									<FontAwesomeIcon
										className="text-green-600 mr-2"
										icon={faCheckCircle}></FontAwesomeIcon>
									Less than 3 months
								</p>
							</div>
						</div>
						<div className="p-4">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Detected Keywords
							</p>
							{Object.entries(residencyVerification.suggestions).length ===
							0 ? (
									<p>None</p>
								) : null}
							{Object.entries(residencyVerification.suggestions).map(
								([key, value]) => {
									return (
										<div key={key} className="mb-3 mt-3">
											Page n°{key}
											<div>
												{value.map((item) => {
													return (
														<p
															key={item.paragraph_nb}
															className="mt-2 rounded-full text-sm font-medium text-gray-800">
															§{item.paragraph_nb} - {item.word}
														</p>
													)
												})}
											</div>
										</div>
									)
								}
							)}
						</div>
						<div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
							<div></div>
							<div className="p-2">
								<Button
									loading={successLoading}
									onClick={() => validateDate()}
									icon={faCheck}
									theme="success"
									full
									label="Submit"></Button>
							</div>
						</div>
					</div>
				</div>
				<EditDocumentTypeSidebar
					open={editDocumentOpen}
					setOpen={setEditDocumentOpen}></EditDocumentTypeSidebar>
			</div>
		</GlobalHotKeys>
	)
}
