import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { useHistory, useParams } from "react-router-dom";
import SubNav from "../menu/SubNav";
import Card from "../ui/Card";
import Footer from "../menu/Footer";
import IdentityVerificationHeader from "../verifications/identity/IdentityVerificationHeader";
import IdentitySegmentation from "../verifications/identity/IdentitySegmentation";
import IdentityService from "@/services/identity";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import * as Notification from "@/components/ui/Notification";
import IdentityTemplateSelection from "../verifications/identity/IdentityTemplateSelection";
import IdentityVisualCheck from "../verifications/identity/IdentityVisualCheck";
import IdentityAutoCrop from "../verifications/identity/IdentityAutoCrop";
import IdentityInfo from "../verifications/identity/IdentityInfo";
import IdentityInfoReview from "../verifications/identity/info/IdentityInfoReview";
import IdentityMrz from "../verifications/identity/mrz/IdentityMrz";
import IdentityInfoCrosscheck from "../verifications/identity/crosscheck/IdentityInfoCrosscheck";
import IdentityFeatures from "../verifications/identity/features/IdentityFeatures";
import IdentityFinalReview from "../verifications/identity/IdentityFinalReview";
import IdentityForgery from "../verifications/identity/IdentityForgery";
import useWebSocket from "react-use-websocket";
import AuthService from "@/services/auth";
import { AuthContext } from "@/context/auth";

export default function IdentityVerification() {
  const [identityVerification, identityVerificationDispatch] = useContext(
    IdentityVerificationContext
  );
  const [ready, setReady] = useState(false);
  const router = useHistory();
  const { session_id } = useParams();
  const [auth] = useContext(AuthContext);

  const socketUrl = `${process.env.REACT_APP_WS_HOST}/v3/websockets/online`;
  const { readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: async () => { },
    shouldReconnect: () => true,
  });

  useEffect(async () => {
    await getTask();
    return () => { };
  }, []);

  useEffect(async () => {
    if (readyState) {
      getWebSocket().send(
        JSON.stringify({
          auth: auth.auth.access_token,
          email: auth.user.email,
          firstname: auth.user.firstname,
          lastname: auth.user.lastname,
          profile_pic: auth.user.profile_pic,
          service: "IDENTITY",
          session_id: session_id,
        })
      );
    }
  }, [readyState]);

  async function getTask() {
    await IdentityService.GetTask(session_id)
      .then(async (data) => {
        if (data.task.service_session_id === "") {
          router.push("/verifications/identity");
          return;
        }
        console.log(data);
        await identityVerificationDispatch({
          type: "SET_SESSION",
          payload: data,
        });
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => { };
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <IdentityVerificationHeader
                onUpdate={() => getTask()}
              ></IdentityVerificationHeader>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div>
                  {ready ? (
                    <div>
                      <div className="p-6">
                        {identityVerification.verification.steps
                          .current_step === "auto_crop" ? (
                          <IdentityAutoCrop
                            onUpdate={() => getTask()}
                          ></IdentityAutoCrop>
                        ) : null}

                        {identityVerification.verification.steps
                          .current_step === "visual_check" ? (
                          <IdentityVisualCheck
                            onUpdate={() => getTask()}
                          ></IdentityVisualCheck>
                        ) : null}

                        {identityVerification.verification.steps
                          .current_step === "segmentation" ? (
                          <IdentitySegmentation
                            onUpdate={() => getTask()}
                          ></IdentitySegmentation>
                        ) : null}

                        {identityVerification.verification.steps
                          .current_step === "forgery" ? (
                          <IdentityForgery
                            onUpdate={() => getTask()}
                          ></IdentityForgery>
                        ) : null}

                        {identityVerification.verification.steps
                          .current_step === "template_selection" ? (
                          <IdentityTemplateSelection
                            onUpdate={() => getTask()}
                          ></IdentityTemplateSelection>
                        ) : null}
                        {identityVerification.verification.steps
                          .current_step === "info_extraction" ? (
                          <IdentityInfo
                            onUpdate={() => getTask()}
                          ></IdentityInfo>
                        ) : null}
                        {identityVerification.verification.steps
                          .current_step === "info_review" ? (
                          <IdentityInfoReview
                            onUpdate={() => getTask()}
                          ></IdentityInfoReview>
                        ) : null}
                        {identityVerification.verification.steps
                          .current_step === "mrz" ? (
                          <IdentityMrz onUpdate={() => getTask()}></IdentityMrz>
                        ) : null}
                        {identityVerification.verification.steps
                          .current_step === "crosscheck" ? (
                          <IdentityInfoCrosscheck
                            onUpdate={() => getTask()}
                          ></IdentityInfoCrosscheck>
                        ) : null}
                        {identityVerification.verification.steps
                          .current_step === "features" ? (
                          <IdentityFeatures
                            onUpdate={() => getTask()}
                          ></IdentityFeatures>
                        ) : null}

                        {identityVerification.verification.steps
                          .current_step === "final_review" ? (
                          <IdentityFinalReview
                            onUpdate={() => getTask()}
                          ></IdentityFinalReview>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
