import { Link } from 'react-router-dom'

export default function AppDashboardItem(props) {
	let path = '/'
	if (props.path) {
		path = props.path
	}

	return (
		<Link to={path}>
			<div className='h-full'>
				<div className='flex'>
					<div className='ml-3 sm:ml-0'>
						<img className='h-9 w-9 rounded-lg' src={props.logo_url}></img>
					</div>
					<div className='ml-2'>
						<p className='text-primary-500 ml-1 text-sm font-bold'>
							{props.name}
						</p>
						<h1 className='text-2xs text-primary-500 ml-1'>{props.type}</h1>
					</div>
				</div>
			</div>
		</Link>
	)
}
