import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { ResidencyVerificationContextProvider } from '@/context/residency_verification'
import ResidencyVerification from '@/components/layout/ResidencyVerification'

function ResidencySession() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<ResidencyVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<ResidencyVerification></ResidencyVerification>
					</div>
				</ResidencyVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default ResidencySession
