import {
	faFileInvoice,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'
import ResidencyTabs from './ResidencyTabs'

export default function ResidencyTasksHeader(props) {
	return (
		<div className="flex flex-wrap justify-between">
			<div>
				<div className="flex">
					<div className="mr-5 mt-0.5">
						<FontAwesomeIcon
							className="text-residency-500 text-6xl"
							icon={faFileInvoice}></FontAwesomeIcon>
					</div>
					<div>
						<h1 className="text-3xl">
							<span className="font-bold">Residency</span>
						</h1>
						<h3 className="text-lg  text-bluegray-600">
							Proof of Address checks
						</h3>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<div className="flex">
					<div className="flex -space-x-1 top-2.5 relative z-0 overflow-hidden">
						{props.synapsState && props.synapsState.online
							? Object.entries(props.synapsState.online).map(([, value]) => {
								return value.service === 'RESIDENCY' ? (
									<Tooltip auto={true} content={value.firstname}>
										<img
											className="relative z-30 inline-block h-7 w-7 rounded-full ring-2 ring-white"
											src={value.profile_pic}
											alt=""
										/>
									</Tooltip>
								) : null
							})
							: null}
					</div>
					<div className="flex flex-wrap ml-6 mr-2 bg-gray-100 rounded-xl p-2">
						<ResidencyTabs></ResidencyTabs>
					</div>
				</div>
			</div>
		</div>
	)
}
