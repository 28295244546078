import { useEffect, useState } from "react";
import Card from "../ui/Card";
import Footer from "../menu/Footer";
import ResidencyTasksHeader from "../residency/ResidencyTasksHeader";
import SubNav from "../menu/SubNav";
import useWebSocket from "react-use-websocket";
import ResidencyTasks from "../residency/ResidencyTasks";

export default function ResidencyTasksPage() {
  const [synapsState, setSynapsState] = useState(null);

  const socketUrl = `${process.env.REACT_APP_WS_HOST}/v3/websockets/online`;
  const { lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: async () => {},
    shouldReconnect: () => true,
  });

  useEffect(async () => {
    if (readyState) {
      setSynapsState(lastJsonMessage);
    }
  }, [readyState, lastJsonMessage]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container">
            <div className="flex">
              <Card className="w-full px-3 sm:px-0">
                <div className="bg-white p-6 border shadow rounded-xl w-full">
                  <ResidencyTasksHeader
                    synapsState={synapsState}
                  ></ResidencyTasksHeader>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div className="pt-0">
                  <ResidencyTasks synapsState={synapsState}></ResidencyTasks>
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
