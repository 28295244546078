import { useContext, useState } from "react";
import { DuplicateVerificationContext } from "@/context/duplicate_verification";
import { GlobalHotKeys } from "react-hotkeys";
import DuplicateService from "@/services/duplicate";
import { faStream, faUserFriends } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import DuplicateMatchItem from "@/components/duplicate/DuplicateMatchItem";
import { AuthContext } from "@/context/auth";
import HeaderButton from "@/components/ui/HeaderButton";
import ActivityDuplicateSidebar from "./ActivityDuplicateSidebar"

export default function DuplicateVisualCheck() {
  const [loading, setLoading] = useState(false);
  const [duplicateVerification] = useContext(DuplicateVerificationContext);
  const router = useHistory();
  const [auth] = useContext(AuthContext);
  const [activityOpen, setActivityOpen] = useState(false);

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function rejectDuplicate() {
    setLoading(true);
    await DuplicateService.RejectDuplicate(
      duplicateVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Duplicate rejected successfully");
        router.push("/verifications/duplicate");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function validateDuplicate() {
    setLoading(true);
    await DuplicateService.ValidateDuplicate(
      duplicateVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Duplicate validated successfully");
        router.push("/verifications/duplicate");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  const handlers = {};

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle
            value="Duplicate Visual Check"
            icon={faUserFriends}
          ></StepTitle>
        </div>
        <HeaderButton
          onClick={() => setActivityOpen(true)}
          currentBg="primary-500"
          icon={faStream}
          badge={duplicateVerification.task.activity.length}
          type="primary"
          label="Activity"
        ></HeaderButton>
      </div>
      <div>
        {duplicateVerification.verification.matches.map((match, i) => {
          return (
            <div key={i} className="flex flex-wrap">
              <div className="w-1/2">
                <div className="flex flex-wrap p-6">
                  <DuplicateMatchItem
                    key={duplicateVerification.task.session_id}
                    item={duplicateVerification.verification}
                    session={duplicateVerification.task}
                    bannable={false}
                    liveness={duplicateVerification.verification}
                  ></DuplicateMatchItem>
                </div>
              </div>
              {auth.iam.admin === true ? (
                <div className="w-1/2">
                  <div className="flex flex-wrap p-6">
                    <DuplicateMatchItem
                      key={match.session_id}
                      item={match}
                      bannable={true}
                      session={duplicateVerification.task}
                      liveness={duplicateVerification.verification}
                    ></DuplicateMatchItem>
                  </div>
                </div>
              ) : (
                <div className="w-1/2">
                  <div className="flex flex-wrap p-6">
                    <DuplicateMatchItem
                      key={match.session_id}
                      item={match}
                      bannable={false}
                      session={duplicateVerification.task}
                      liveness={duplicateVerification.verification}
                    ></DuplicateMatchItem>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div className="flex justify-center">
          <div className="mr-3">
            <Button
              loading={loading}
              onClick={() => rejectDuplicate()}
              icon={faTimes}
              full
              label="Reject"
              theme="error"
            ></Button>
          </div>
          <div className="ml-3">
            <Button
              loading={loading}
              onClick={() => validateDuplicate()}
              icon={faCheck}
              full
              label="Validate"
              theme="success"
            ></Button>
          </div>
        </div>
      </div>
      <ActivityDuplicateSidebar
        open={activityOpen}
        setOpen={setActivityOpen}
        verification={duplicateVerification}
      ></ActivityDuplicateSidebar>
    </GlobalHotKeys>
  );
}
