import Sleep from '@/lib/sleep'
import {
	faClone,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Alert from '../ui/Alert'
import CardTitle from '../ui/CardTitle'
import SupportService from '@/services/support'
import * as Notification from '@/components/ui/Notification'

export default function UpdateValue(props) {
	const [error, setError] = useState(null)
	const { register, handleSubmit, errors } = useForm()
	const [loading, setLoading] = useState(false)
	const [alias, setAlias] = useState('')
	const [session, setSession] = useState('')

	const formSchema = {
		app: {
			required: 'App required',
		},
		session: {
			required: 'Session required',
		},
	}

	const onSubmit = async (data) => {
		setLoading(true)
		let body = {
			alias: alias,
			session_id: data.session,
			app_key: data.app
		}
		await SupportService.DuplicateSession(body, data.session, data.app).then(async (data) => {
			Notification.success('Session duplicated')
			setLoading(false)
			setAlias('')
			setSession(data.session_id)
		}).catch((err) => {
			Notification.error(err.message)
			setLoading(false)
		})
	}

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									<div>
										<CardTitle
											value={'Duplicate session'}
											icon={faClone}></CardTitle>
									</div>
								</div>
								<form onSubmit={handleSubmit(onSubmit)}>
									{session === '' ? <>
										<div>
											<div className="py-2 px-3">
												{error ? (
													<Alert type="error" title={error}></Alert>
												) : null}
											</div>
											<div className="flex flex-wrap">
												<div className="p-3 w-full">
													<Input
														type="text"
														name="session"
														referrer={register(formSchema.session)}
														label={'Session to duplicate'}
														error={errors.session}
														message={errors.session?.message}
													/>
												</div>
												<div className="p-3 w-full">
													<Input
														name="app"
														referrer={register(formSchema.app)}
														label={'Target app key'}
														error={errors.app}
														message={errors.app?.message}
													/>
												</div>
												<div className="p-3 w-full">
													<Input
														value={alias}
														setValue={(value) => setAlias(value)}
														label={'Alias (optional)'}
													/>
												</div>
											</div>
										</div>
									</> :
										<div>
											<div className="py-2 px-3">
												{error ? (
													<Alert type="error" title={error}></Alert>
												) : null}
											</div>
											<div className="flex flex-wrap">
												<div className="p-3 w-full">
													<Input
														type="text"
														name="session"
														label={'New Session ID'}
														value={session}
														disabled
													/>
												</div>
											</div>
										</div>
									}
									<div>
										<div className="flex justify-between">
											<div className="p-3"></div>
											<div className="p-3">
												<div className="flex">
													<div className="mr-3">
														<Button
															theme="secondary"
															onClick={() => props.onClose()}
															label="Cancel"
														/>
													</div>
													{session !== '' ?
														<>
															<div className="mr-3">
																<Button
																	theme="secondary"
																	onClick={() => setSession('')}
																	label="Clear"
																/>
															</div>
														</> : null}
													<div>
														<Button
															type="submit"
															theme="success"
															loading={loading}
															onClick={() => handleSubmit(onSubmit)}
															label={'Duplicate '}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
