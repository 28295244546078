import { useContext, useEffect, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { faUserTie, faUniversity } from "@fortawesome/pro-duotone-svg-icons";
import StepTitle from "@/components/ui/StepTitle";
import { CorporateVerificationContext } from "@/context/corporate_verification";
import CorporateItem from "@/components/corporate/CorporateItem";
import CorporateOrganisation from "@/components/corporate/CorporateOrganisation";
import Textarea from "@/components/ui/Textarea";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import CorporateService from "@/services/corporate";
import * as Notification from "@/components/ui/Notification";

export default function CorporateBeneficials(props) {
  const [corporateVerification, corporateVerificationDispatch] = useContext(
    CorporateVerificationContext
  );
  const [loading, setLoading] = useState(false);
  const [corporateDocumentReview, setCorporateDocumentReview] = useState({});
  const [corporateDocumentNote, setCorporateDocumentNote] = useState({});

  useEffect(() => {
    if (corporateVerification.document.value) {
      let initCorporateDocumentNote = {};
      let initCorporateDocumentReview = {};

      initCorporateDocumentNote[corporateVerification.document.key] =
        corporateVerification.document.value.note;
      initCorporateDocumentReview[corporateVerification.document.key] =
        corporateVerification.document.value.reason;

      setCorporateDocumentNote(initCorporateDocumentNote);
      setCorporateDocumentReview(initCorporateDocumentReview);
    }
  }, [corporateVerification]);

  const keyMap = {};

  const handlers = {};

  async function SwitchReview() {
    await corporateVerificationDispatch({
      type: "SET_REVIEW_CLOSE",
      payload: {},
    });
  }

  async function verifyDocument(key, state, corporate_id) {
    setLoading(true);
    const body = {
      slug: key,
      state: state,
      review: corporateDocumentReview[key],
      internal_note: corporateDocumentNote[key],
    };
    await CorporateService.CheckCorporateDocument(
      corporateVerification.task.session_id,
      body,
      corporate_id
    )
      .then(() => {
        Notification.success("Success");
        props.onUpdate();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  async function updateProgress() {
    props.onUpdate();
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div>
        <div className="p-6 w-full">
          <div className="flex justify-between">
            <div>
              <StepTitle
                value="Corporate Beneficials"
                icon={faUserTie}
              ></StepTitle>
            </div>
            <Button
              loading={false}
              onClick={() => updateProgress()}
              full
              label="Update"
              theme="primary"
            ></Button>
          </div>
          {corporateVerification.document.review === false ? (
            <div>
              <div className="flex justify-center">
                <CorporateItem
                  label="Corporates"
                  name={corporateVerification.verification.corporate.name}
                  documents={corporateVerification.verification.document_steps}
                  icon={faUniversity}
                  corporate_id="1"
                ></CorporateItem>
              </div>
              <div className="tree">
                <ul>
                  <li>
                    <CorporateOrganisation
                      corporates={
                        corporateVerification.verification
                          .beneficial_owners_step
                      }
                    ></CorporateOrganisation>
                  </li>
                </ul>
              </div>
            </div>
          ) : null}
          {corporateVerification.document.review ? (
            <div className="w-full mt-3">
              <div className="flex">
                <Button
                  loading={false}
                  onClick={() => SwitchReview()}
                  full
                  label="Close"
                  theme="primary"
                ></Button>
                <p className="font-medium text-sm mt-4 ml-4 mb-2">
                  {corporateVerification.document.value.name}
                </p>
              </div>
              <div className="flex flex-wrap">
                <div className="w-3/5">
                  <div className="mt-4 mb-3">
                    {corporateVerification.document.value.is_pdf ? (
                      <iframe
                        className="w-full h-screen"
                        src={corporateVerification.document.value.filename}
                      ></iframe>
                    ) : (
                      <img
                        src={corporateVerification.document.value.filename}
                      />
                    )}
                  </div>
                </div>
                <div className="w-2/5 p-3">
                  <div>
                    <p className="text-gray-500 font-medium text-sm mb-2">
                      Document Review
                    </p>
                    <Textarea
                      value={
                        corporateDocumentReview[
                          corporateVerification.document.key
                        ]
                      }
                      setValue={(value) =>
                        setCorporateDocumentReview({
                          ...corporateDocumentReview,
                          [corporateVerification.document.key]: value,
                        })
                      }
                      placeholder="Write review"
                    ></Textarea>
                  </div>
                  <div>
                    <p className="text-gray-500 font-medium text-sm mb-2 mt-3">
                      Internal Note
                    </p>
                    <Textarea
                      value={
                        corporateDocumentNote[
                          corporateVerification.document.key
                        ]
                      }
                      setValue={(value) =>
                        setCorporateDocumentNote({
                          ...corporateDocumentNote,
                          [corporateVerification.document.key]: value,
                        })
                      }
                      placeholder="Write note"
                    ></Textarea>
                  </div>
                  <div className="flex flex-wrap">
                    <div className="w-1/3 mt-3">
                      {corporateDocumentReview[
                        corporateVerification.document.key
                      ] === "" ||
                      !corporateDocumentReview[
                        corporateVerification.document.key
                      ] ? (
                        <Button
                          loading={loading}
                          onClick={() =>
                            verifyDocument(
                              corporateVerification.document.key,
                              2,
                              corporateVerification.document.corporate_id
                            )
                          }
                          icon={faCheck}
                          label="Validate"
                          full
                          theme="success"
                        ></Button>
                      ) : (
                        <Button
                          loading={loading}
                          onClick={() =>
                            verifyDocument(
                              corporateVerification.document.key,
                              0,
                              corporateVerification.document.corporate_id
                            )
                          }
                          icon={faTimes}
                          label="Reject"
                          full
                          theme="error"
                        ></Button>
                      )}
                    </div>
                    <div className="w-1/3 mt-3 ml-3">
                      {corporateDocumentNote[
                        corporateVerification.document.key
                      ] !== "" ? (
                        <Button
                          loading={loading}
                          onClick={() =>
                            verifyDocument(
                              corporateVerification.document.key,
                              corporateVerification.document.value.state,
                              corporateVerification.document.corporate_id
                            )
                          }
                          icon={faCheck}
                          label="Update Note"
                          full
                          theme="pin"
                        ></Button>
                      ) : null}
                    </div>
                  </div>
                  {corporateVerification.document.value.history &&
                  corporateVerification.document.value.history.lengh > 0 ? (
                    <div className="mt-5">
                      <p className="text-gray-500 font-medium text-sm mb-2">
                        Document History
                      </p>
                      {corporateVerification.document.value.history.map(
                        ([key, history]) => {
                          return (
                            <div key={key} className="ml-4 mr-4">
                              <p className="text-gray-500 font-medium text-sm mb-2">
                                - {history}
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="p-6 w-1/3">
          <div className="flex justify-between">
            <div>
              <StepTitle
                value="Corporate Documents Notes"
                icon={faUserTie}
              ></StepTitle>
            </div>
          </div>
          {Object.entries(
            corporateVerification.verification.document_steps
          ).map(([key, value]) => {
            return (
              <div key={key}>
                <div className="mt-3 mb-3 w-full flex-wrap justify-between bg-bluegray-50 transition duration-100 ease-in border border-bluegray-100 p-3 rounded-lg">
                  <div>
                    <p className="font-medium">{value.name}</p>
                  </div>
                  <div>
                    <p className="font-light">{value.note}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </GlobalHotKeys>
  );
}
