import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import ResidencyDocumentViewer from '../ResidencyDocumentViewer'
import StepTitle from '@/components/ui/StepTitle'
import {
	faBurn,
	faCheckCircle,
	faFile,
	faPhone,
	faPlug,
	faTint,
	faUniversity,
	faWifi,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faPen } from '@fortawesome/pro-solid-svg-icons'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditDocumentTypeSidebar from '@/components/residency/EditDocumentTypeSidebar'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'

export default function ResidencyDocumentType(props) {
	const [successLoading, setSuccessLoading] = useState(false)
	const [editDocumentOpen, setEditDocumentOpen] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)
	const keyMap = {
		SELECT: ['enter'],
	}

	const handlers = {
		SELECT: () => validateStep('document_type_verification'),
	}


	async function validateStep(step) {
		setSuccessLoading(true)
		await ResidencyService.ValidateStep(
			residencyVerification.task.session_id,
			step
		)
			.then(() => {
				setSuccessLoading(false)
				props.onUpdate()
			})
			.catch((err) => {
				Notification.error(err.message)
				setSuccessLoading(false)
			})
		return () => {}
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}
			<div className="flex flex-wrap">
				<div className="w-3/5">
					<ResidencyDocumentViewer></ResidencyDocumentViewer>
				</div>
				<div className="w-2/5">
					<div>
						<StepTitle value="Document Type Review" icon={faFile}></StepTitle>
					</div>
					<div>
						<div>
							<p className="text-center">
								{residencyVerification.verification.details.document_type ===
								'ELECTRICITY_BILL' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faPlug}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Electricity Bill</p>
										</div>
									) : null}
								{residencyVerification.verification.details.document_type ===
								'INTERNET_BILL' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faWifi}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Internet Bill</p>
										</div>
									) : null}
								{residencyVerification.verification.details.document_type ===
								'LANDLINE_BILL' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faPhone}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Landline Bill</p>
										</div>
									) : null}
								{residencyVerification.verification.details.document_type ===
								'WATER_BILL' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faTint}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Water Bill</p>
										</div>
									) : null}
								{residencyVerification.verification.details.document_type ===
								'GAS_BILL' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faBurn}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Gas Bill</p>
										</div>
									) : null}
								{residencyVerification.verification.details.document_type ===
								'BANK_STATEMENT' ? (
										<div className="mt-6 mb-6">
											<FontAwesomeIcon
												className="text-residency-500 text-5xl"
												icon={faUniversity}></FontAwesomeIcon>
											<p className="font-bold text-2xl mt-5">Bank Statement</p>
										</div>
									) : null}
							</p>
						</div>
						<hr className="py-2" />
						<div className="p-4 pb-0">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Instructions
							</p>
							<div>
								<p className="mt-2">
									<FontAwesomeIcon
										className="text-green-600 mr-2"
										icon={faCheckCircle}></FontAwesomeIcon>
									Mobile phone bill not accepted
								</p>
							</div>
						</div>
						<div className="px-4">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Detected Keywords
							</p>
							{Object.entries(residencyVerification.suggestions).length ===
							0 ? (
									<p>None</p>
								) : null}
							{Object.entries(residencyVerification.suggestions).map(
								([key, value]) => {
									return (
										<p className="mb-3 mt-5" key={key}>
											Page n°{key}
											<p>
												{value.map((item, i) => {
													return (
														<p key={i} className="mt-2 rounded-full text-sm font-medium text-gray-800">
															§{item.paragraph_nb} - {item.word}
														</p>
													)
												})}
											</p>
										</p>
									)
								}
							)}
						</div>
						<div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
								<Button
									className="mr-3"
									onClick={() => setEditDocumentOpen(true)}
									icon={faPen}
									theme="pin"
									full
									label="Change document type"></Button>
								<Button
									loading={successLoading}
									onClick={() => validateStep('document_type_verification')}
									icon={faCheck}
									theme="success"
									full
									label="Validate"></Button>
						</div>
					</div>
				</div>
				<EditDocumentTypeSidebar
					open={editDocumentOpen}
					setOpen={setEditDocumentOpen}></EditDocumentTypeSidebar>
			</div>
		</GlobalHotKeys>
	)
}
