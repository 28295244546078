import { PanelAuth } from "@/services/axios";

export default {
  GetIndividualResource(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/individual/session/${session_id}`
    );
  },
  ResetStep(session_id, step_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/individual/session/${session_id}/reset?step_id=${step_id}`
    );
  },
  ResetTaskStep(session_id, step_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/individual/session/${session_id}/task/reset?step_id=${step_id}`
    );
  },
};
