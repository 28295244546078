import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loader from './Loader'

export default function SmallButton(props) {
	let themeType = props.theme
	let currentBg = 'white'
	let ringOffsetClass = ''

	if (currentBg === 'white') {
		ringOffsetClass = 'focus:ring-offset-white'
	}

	let theme = {
		primary: {
			normal: 'bg-primary-500',
			hover: 'hover:bg-primary-600',
			focus: 'focus:ring-primary-500',
			border: 'border-primary-500',
			borderhover: 'hover:border-primary-700',
			text: 'text-white',
			loader: 'text-white',
		},
		secondary: {
			normal: 'bg-white',
			hover: 'hover:bg-gray-50',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-gray-700',
			loader: 'gray-700',
		},
		success: {
			normal: 'bg-green-500',
			hover: 'hover:bg-green-600',
			focus: 'focus:ring-green-500',
			border: 'border-green-500',
			borderhover: 'hover:border-green-600',
			text: 'text-white',
			loader: 'white',
		},
		error: {
			normal: 'bg-red-500',
			hover: 'hover:bg-red-600',
			focus: 'focus:ring-red-500',
			border: 'border-red-500',
			borderhover: 'hover:border-red-600',
			text: 'text-white',
			loader: 'white',
		},
		pin: {
			normal: 'bg-orange-400',
			hover: 'hover:bg-orange-600',
			focus: 'focus:ring-orange-400',
			border: 'border-orange-200',
			borderhover: 'hover:border-orange-300',
			text: 'text-white',
			loader: 'white',
			badgeColor: 'bg-orange-200',
			badgeTextColor: 'bg-orange-600',
		},
		orange: {
			normal: 'bg-orange-500',
			hover: 'hover:bg-orange-600',
			focus: 'focus:ring-orange-500',
			border: 'border-orange-500',
			borderhover: 'hover:border-orange-600',
			text: 'text-white',
			loader: 'white',
			badgeColor: 'bg-orange-200',
			badgeTextColor: 'bg-orange-600',
		},
		emergency: {
			normal: 'bg-red-100',
			hover: 'hover:bg-red-600',
			focus: 'focus:ring-red-400',
			border: 'border-red-200',
			borderhover: 'hover:border-red-300',
			text: 'text-red-600',
			loader: 'white',
			badgeColor: 'bg-red-200',
			badgeTextColor: 'bg-red-600',
		},
	}

	if (!theme[themeType]) {
		themeType = 'primary'
	}

	const btn = theme[themeType]

	return (
		<>
			<div className={`relative ${props.className}`}>
				<button
					type={`${props.type ? props.type : 'button'}`}
					onClick={props.onClick}
					disabled={props.loading || props.disabled}
					className={`${props.className ? props.className : ''} px-3 ${props.full ? 'w-full' : ''
						} ${props.loading || props.disabled
							? `opacity-50 cursor-not-allowed ${btn.normal}`
							: `opacity-1 cursor-pointer ${btn.hover} hover:border ${btn.borderhover}`
						} ${btn.text} ${btn.normal
						} flex transition ease-in duration-150 justify-center py-1 border ${btn.border
						} rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2
					} ${ringOffsetClass} focus:ring-offset-2 ${btn.focus}`}>
					{props.loading ? (
						<Loader className="mr-2" color={btn.loader}></Loader>
					) : (
						''
					)}
					{props.icon && !props.loading ? (
						<div className="ml-0 mr-2">
							<FontAwesomeIcon className={`${btn.text}`} icon={props.icon} />
						</div>
					) : (
						''
					)}
					<span className={`${btn.text}`}>{props.label}</span>
					{props.badge ? (
						<span
							className={`inline-flex items-center px-2 py-0.5 ml-2 rounded text-xs font-medium bg-gray-100 ${btn.badgeColor} ${btn.badgeTextColor}`}>
							{props.badge}
						</span>
					) : null}
				</button>
			</div>
		</>
	)
}
