import MenuItem from '../menu/MenuItem'
import {
	faBrowser,
	faCabinetFiling,
	faFlask,
	faUsers,
	faUserTie,
	faHeadset,
	faChartNetwork,
	faUniversity
} from '@fortawesome/pro-duotone-svg-icons'
import { AuthContext } from '@/context/auth'
import { useContext } from 'react'

export default function Management() {
	const [auth] = useContext(AuthContext)

	return (
		<div className='flex flex-wrap px-3 pb-3'>
			{auth.iam.admin ? (
				<>
					<div className='w-1/3'>
						<MenuItem
							label='Apps'
							path='/apps'
							theme='primary'
							hover='gray'
							icon={faBrowser}></MenuItem>
					</div>

					<div className='w-1/3'>
						<MenuItem
							label='Agents'
							path='/agents'
							theme='primary'
							hover='gray'
							icon={faUsers}></MenuItem>
					</div>

					<div className='w-1/3'>
						<MenuItem
							label='Registry'
							path='/registry'
							theme='primary'
							hover='gray'
							icon={faCabinetFiling}></MenuItem>
					</div>

					<div className='w-1/3'>
						<MenuItem
							label='Managers'
							path='/managers'
							theme='primary'
							hover='gray'
							icon={faUserTie}></MenuItem>
					</div>

					<div className='w-1/3'>
						<MenuItem
							label='Labs'
							path='/labs'
							theme='primary'
							hover='gray'
							icon={faFlask}></MenuItem>
					</div>
				</>
			) : null}

			{auth.iam.admin || auth.iam.support_agent || auth.iam.support_manager ? (
				<div className='w-1/3'>
					<MenuItem
						label='Support'
						path='/support'
						theme='primary'
						hover='gray'
						icon={faHeadset}></MenuItem>
				</div>
			) : null}
			{auth.iam.admin ? (
				<div className='w-1/3'>
					<MenuItem
						label='Network'
						path='/network'
						theme='primary'
						hover='gray'
						icon={faChartNetwork}></MenuItem>
				</div>
			) : null}
			{auth.iam.admin ? (
				<div className='w-1/3'>
					<MenuItem
						label='Corporates'
						path='/corporates'
						theme='primary'
						hover='gray'
						icon={faUniversity}></MenuItem>
				</div>
			) : null}
		</div>
	)
}
