import IdentityStep from './IdentityStep'
import { useContext } from 'react'
import { IdentityVerificationContext } from '@/context/identity_verification'

export default function IdentitySteps() {
	const [identityVerification] = useContext(
		IdentityVerificationContext
	)
	return (
		<>
			<nav aria-label="Progress">
				<ol className="border-t border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0">
					<IdentityStep
						label="Auto Crop"
						number="1"
						current={
							identityVerification.verification.steps.current_step ===
							'auto_crop'
						}
						done={
							identityVerification.verification.steps.auto_crop
						}></IdentityStep>
					<IdentityStep
						label="Segmentation"
						number="2"
						current={
							identityVerification.verification.steps.current_step ===
							'segmentation'
						}
						done={
							identityVerification.verification.steps.segmentation
						}></IdentityStep>
					<IdentityStep
						label="Template"
						number="3"
						current={
							identityVerification.verification.steps.current_step ===
							'template_selection'
						}
						done={
							identityVerification.verification.steps.template_selection
						}></IdentityStep>
					<IdentityStep
						label="Info"
						number="4"
						current={
							identityVerification.verification.steps.current_step ===
							'info_extraction' ||
							identityVerification.verification.steps.current_step ===
							'info_review'
						}
						done={
							identityVerification.verification.steps.info_review
						}></IdentityStep>
					<IdentityStep
						label="MRZ"
						number="5"
						current={
							identityVerification.verification.steps.current_step === 'mrz'
						}
						done={identityVerification.verification.steps.mrz}></IdentityStep>
					<IdentityStep
						label="Crosscheck"
						number="6"
						current={
							identityVerification.verification.steps.current_step ===
							'crosscheck'
						}
						done={identityVerification.verification.steps.mrz}></IdentityStep>
					<IdentityStep
						label="Features"
						number="7"
						current={
							identityVerification.verification.steps.current_step ===
							'features'
						}
						done={identityVerification.verification.steps.features}
						last></IdentityStep>
				</ol>
			</nav>
		</>
	)
}
