import { faAsterisk, faLayerPlus } from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../ui/Button";
import Input from "../ui/Input";
import RegistryService from "@/services/registry";
import Cropper from "@/components/ui/Cropper";
import { RegistryIdentityContext } from "@/context/registry_identity";
import CardTitle from "../ui/CardTitle";
import TabSelector from "../ui/TabSelector";
import ListSelector from "../ui/ListSelector";
import Label from "../ui/Label";
import * as Notification from "@/components/ui/Notification";
import { Modal } from "react-responsive-modal";
import RegistrySuggestions from "./RegistrySuggestions";
import Switch from "@/components/ui/Switch";
import languagesList from "@/resources/languages.json";
import ContentSearchBar from "@/components/ui/ContentSearchBar";
import MiniContentSearchBar from "@/components/ui/MiniContentSearchBar";

export default function RegistryNewFeature(props) {
  const [, setError] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [featureName, setFeatureName] = useState("");
  const [featureLocation, setFeatureLocation] = useState(null);
  const [search, setSearch] = useState("");

  const [side, setSide] = useState();
  const [required, setRequired] = useState(true);
  const [dateFormat, setDateFormat] = useState(null);
  const [mrzFormat, setMrzFormat] = useState(null);
  const [source, setSource] = useState(null);
  const [lang, setLang] = useState("en");
  const [registryIdentity] = useContext(RegistryIdentityContext);

  const [mrzLinesCrosscheck, setMrzLinesCrosscheck] = useState(false);
  const [mrzBarcodeCrosscheck, setMrzBarcodeCrosscheck] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);

  const mrz_lines_choices = [
    { label: "National ID (3*30)", value: "TD1" },
    {
      label: "Passport (2*44)",
      value: "TD3",
    },

    { label: "National ID (2*36)", value: "TD2" },
  ];

  const mrz_barcode_choices = [
    {
      label: "Indian Aadhar",
      value: "AADHAR",
    },
    {
      label: "Indian Aadhar (IND_ID_B7)",
      value: "AADHAR_EXCEPTION",
    },
    {
      label: "American Driver license",
      value: "AAMVA",
    },
    {
      label: "Canadian BritishColumbia",
      value: "AAMVA_BRITISHCOLUMBIA",
    },
    // {
    // 	label: 'French National ID',
    // 	value: 'FRA_ID',
    // },
    {
      label: "Russian Driver license",
      value: "RUS_DL",
    },
    {
      label: "South African National ID",
      value: "ZAF_ID",
    },
    {
      label: "Moldova National ID",
      value: "MDA_ID",
    },
  ];

  useEffect(async () => {
    if (props.name) {
      setFeatureName(props.name);
    }

    if (props.type === "info") {
      setRequired(true);
    }

    if (props.side === "FRONT" || props.side === "BACK") {
      setSide(props.side);
    }

    if (props.side === "" || props.side === "") {
      setSide("FRONT");
    }

    await RegistryService.GetFeaturesSearchIndex().then((data) => {
      if (props.type === "fields") {
        setSuggestionsList(data.identity_fields);
      }
      if (props.type === "security") {
        setSuggestionsList(data.identity_security);
      }
    });
    return () => {};
  }, []);

  const formSchema = {
    name: {
      required: "Name required",
    },
  };

  const onSubmit = async () => {
    setError(null);

    const body = {
      document_slug: registryIdentity.slug,
      side: side,
      required: required,
      position: featureLocation,
    };

    if (props.type === "info") {
      body.slug = featureName;
      body.source = source;
      body.crosscheckable = false;
      if (mrzLinesCrosscheck || mrzBarcodeCrosscheck) {
        body.crosscheckable = true;
      }
      body.mrz_barcode_crosscheckable = mrzBarcodeCrosscheck;
      body.mrz_lines_crosscheckable = mrzLinesCrosscheck;
      body.date_format = dateFormat;

      if (props.name === "firstname" || props.name === "lastname") {
        body.lang = lang;
      }
    } else {
      body.name = featureName;
      body.feature_type = props.type.toUpperCase();
      body.required = required;
    }

    if (props.type === "mrz") {
      body.feature_type = props.name.toUpperCase();
      body.format = mrzFormat;
    }

    if (props.type === "info") {
      setLoading(true);
      await RegistryService.AddInfo(body)
        .then(async () => {
          props.onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Notification.error(err.message);
        });
    } else if (props.type === "mrz") {
      setLoading(true);
      await RegistryService.AddMrz(body)
        .then(async () => {
          props.onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Notification.error(err.message);
        });
    } else {
      setLoading(true);
      await RegistryService.AddFeature(body)
        .then(async () => {
          props.onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Notification.error(err.message);
        });
    }
    return () => {};
  };

  useEffect(() => {
    if (registryIdentity.document_type === "PASSPORT") {
      setMrzFormat("TD3");
    }
  }, []);

  return (
    <Modal
      open={props.open}
      showCloseIcon={true}
      closeIcon={true}
      onClose={() => props.onClose()}
      center
    >
      <div className="flex flex-wrap h-full">
        <div className="w-2/3 bg-bluegray-200 rounded-l-xl p-3">
          <div className="h-full flex flex-wrap content-center">
            <div>
              <Cropper
                src={
                  side === "FRONT"
                    ? registryIdentity.front
                    : registryIdentity.back
                }
                setPosition={(coordinates) => setFeatureLocation(coordinates)}
              />
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <form className="h-full w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col h-full justify-between">
              <header>
                <CardTitle
                  icon={faLayerPlus}
                  value={`New ${props.type} feature`}
                ></CardTitle>
              </header>
              <main className="mb-auto">
                <div className="px-4">
                  <div className="py-2">
                    {props.type === "info" || props.type === "mrz" ? (
                      <Input
                        type="text"
                        icon={faAsterisk}
                        referrer={register(formSchema.name)}
                        label="Name"
                        placeholder="Firstname"
                        value={props.name}
                        disabled={true}
                        name="name"
                        error={errors.name}
                        message={errors.name?.message}
                      />
                    ) : (
                      <Input
                        type="text"
                        suggestions={suggestionsList}
                        icon={faAsterisk}
                        referrer={register(formSchema.name)}
                        label="Name"
                        placeholder="Feature"
                        name="name"
                        setValue={(value) => setFeatureName(value)}
                        value={featureName}
                        error={errors.name}
                        message={errors.name?.message}
                      />
                    )}
                  </div>
                  <div className="py-2">
                    {props.type === "info" &&
                    (props.name === "firstname" ||
                      props.name === "lastname") ? (
                      <>
                        <div className="mb-1">
                          <Label value="Lang"></Label>
                        </div>
                        <ListSelector
                          onSelect={(selection) => setLang(selection)}
                          choices={languagesList}
                          selected={lang}
                        ></ListSelector>
                      </>
                    ) : null}
                  </div>
                  {featureName === "" ? (
                    <>
                      <div className="flex flex-wrap justify-between items-center">
                        <div>
                          <Label value="Suggestions"></Label>
                        </div>
                        <div>
                          <MiniContentSearchBar
                            onChange={(el) => setSearch(el.target.value)}
                          ></MiniContentSearchBar>
                        </div>
                      </div>
                      <div className="mt-1 h-96 overflow-auto">
                        <RegistrySuggestions
                          search={search}
                          onSet={(suggestion) => setFeatureName(suggestion)}
                          type={props.type}
                        ></RegistrySuggestions>
                      </div>
                    </>
                  ) : (
                    <>
                      {registryIdentity.document_type !== "PASSPORT" ? (
                        <>
                          <div className="mt-2">
                            <Label value="Side"></Label>
                          </div>
                          <div className="py-2">
                            <ListSelector
                              onSelect={(selection) => setSide(selection)}
                              choices={[
                                {
                                  label: "Front",
                                  value: "FRONT",
                                },
                                {
                                  label: "Back",
                                  value: "BACK",
                                },
                              ]}
                              selected={side}
                            ></ListSelector>
                          </div>
                        </>
                      ) : null}
                      {(props.type !== "info" && props.type !== "mrz") ||
                      props.name === "document_expiration" ? (
                        <div className="mt-2">
                          <div>
                            <Label value="Required"></Label>
                          </div>
                          <div className="py-2">
                            <TabSelector
                              onSelect={(selection) => setRequired(selection)}
                              choices={[
                                {
                                  label: "Yes",
                                  value: true,
                                },
                                { label: "No", value: false },
                              ]}
                              selected={required}
                            ></TabSelector>
                          </div>
                        </div>
                      ) : null}
                      {props.type === "info" &&
                      featureName !== "face" &&
                      (required === true ||
                        props.name === "document_expiration") ? (
                        <div className="mt-2">
                          <div>
                            <Label value="Cross Checkable"></Label>
                          </div>
                          <div className="py-2">
                            <p>
                              <Switch
                                checked={mrzLinesCrosscheck}
                                onChange={setMrzLinesCrosscheck}
                              ></Switch>
                              <span className="relative top--5px ml-3 text-sm">
                                MRZ Lines
                              </span>
                            </p>
                          </div>
                          <div className="py-2">
                            <p>
                              <Switch
                                checked={mrzBarcodeCrosscheck}
                                onChange={setMrzBarcodeCrosscheck}
                              ></Switch>
                              <span className="relative top--5px text-sm ml-3">
                                MRZ Barcode
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : null}
                      {props.type === "info" &&
                      (required === true ||
                        props.name === "document_expiration") &&
                      (mrzLinesCrosscheck === true ||
                        mrzBarcodeCrosscheck === true) ? (
                        <div className="mt-2">
                          <div>
                            <Label value="Info Source"></Label>
                          </div>
                          <div className="py-2">
                            {mrzBarcodeCrosscheck && mrzLinesCrosscheck ? (
                              <ListSelector
                                onSelect={(selection) => setSource(selection)}
                                choices={[
                                  {
                                    label: "MRZ_BARCODE",
                                    value: "MRZ_BARCODE",
                                  },
                                  {
                                    label: "MRZ_LINES",
                                    value: "MRZ_LINES",
                                  },
                                  { label: "OCR", value: "OCR" },
                                ]}
                                selected={source}
                              ></ListSelector>
                            ) : null}
                            {mrzBarcodeCrosscheck && !mrzLinesCrosscheck ? (
                              <ListSelector
                                onSelect={(selection) => setSource(selection)}
                                choices={[
                                  {
                                    label: "MRZ_BARCODE",
                                    value: "MRZ_BARCODE",
                                  },
                                  { label: "OCR", value: "OCR" },
                                ]}
                                selected={source}
                              ></ListSelector>
                            ) : null}
                            {!mrzBarcodeCrosscheck && mrzLinesCrosscheck ? (
                              <ListSelector
                                onSelect={(selection) => setSource(selection)}
                                choices={[
                                  {
                                    label: "MRZ_LINES",
                                    value: "MRZ_LINES",
                                  },
                                  { label: "OCR", value: "OCR" },
                                ]}
                                selected={source}
                              ></ListSelector>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {props.type === "info" &&
                      (required === true ||
                        props.name === "document_expiration") &&
                      (props.name === "document_expiration" ||
                        props.name === "birth_date") &&
                      (((mrzLinesCrosscheck === true ||
                        mrzBarcodeCrosscheck === true) &&
                        source) ||
                        (mrzLinesCrosscheck === false &&
                          mrzBarcodeCrosscheck === false)) ? (
                        <div className="mt-2">
                          <div>
                            <Label value="Date Format"></Label>
                          </div>
                          <div className="py-2">
                            <ListSelector
                              onSelect={(selection) => setDateFormat(selection)}
                              choices={[
                                {
                                  label: "18-06-2015",
                                  value: "DDMMYYYY",
                                },
                                { label: "2015-06-18", value: "YYYYMMDD" },
                                { label: "08-24-1998", value: "MMDDYYYY" },
                                { label: "18 JUN 2015", value: "DDJANYYYY" },
                                { label: "2015 JUN 18", value: "YYYYJANDD" },
                                { label: "JUN 18 2015", value: "JANDDYYYY" },
                                { label: "18 JUN 15", value: "DDJANYY" },
                                { label: "18/06/74", value: "DDMMYY" },
                                { label: "74/06/18", value: "YYMMDD" },
                              ]}
                              selected={dateFormat}
                            ></ListSelector>
                          </div>
                        </div>
                      ) : null}

                      {props.type === "mrz" ? (
                        <div className="mt-2">
                          <div>
                            <Label value="MRZ Format"></Label>
                          </div>
                          <div className="py-2">
                            <ListSelector
                              onSelect={(selection) => setMrzFormat(selection)}
                              choices={
                                props.name === "lines"
                                  ? mrz_lines_choices
                                  : mrz_barcode_choices
                              }
                              selected={mrzFormat}
                            ></ListSelector>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </main>
              <footer>
                <div className="p-4">
                  <Button
                    type="submit"
                    disabled={featureLocation === null ? true : false}
                    full
                    icon={faPlus}
                    loading={loading}
                    label="Add"
                  />
                </div>
              </footer>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
