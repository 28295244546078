import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import { faStream, faUndo } from '@fortawesome/pro-duotone-svg-icons'
import { faCheck, faThumbtack, faTimes } from '@fortawesome/pro-solid-svg-icons'
import Moment from 'react-moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ActivityFacematchSidebar({ open, setOpen, verification }) {
	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className="h-full w-full rounded-xl">
					<form className="h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl">
						<div className="flex-1 h-0 overflow-y-auto">
							<div className="py-6 px-4 bg-primary-500 sm:px-6 rounded-t-xl">
								<div className="flex items-center justify-between">
									<h2
										className="text-lg font-medium text-white"
										id="slide-over-title">
										Verification Activity
									</h2>
									<div className="ml-3 h-7 flex items-center">
										<button
											onClick={() => setOpen(false)}
											type="button"
											className="bg-primary-400 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
											<span className="sr-only">Close panel</span>
											<svg
												className="h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className="mt-1">
									<p className="text-sm text-white">
										Overview of the current verification activity
									</p>
								</div>
							</div>
							<div className="flex-1 flex flex-col justify-between">
								<div className="flow-root p-8">
									<ul className="-mb-8">
										{verification.task.activity.map((item, i) => {
											return (
												<li key={item.date}>
													<div className="relative pb-8">
														{i <
															verification.task.activity.length - 1 ? (
															<span
																className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
																aria-hidden="true"></span>
														) : null}
														<div className="relative flex space-x-3">
															<div>
																<span
																	className={`h-8 w-8 rounded-full  ${item.action === 'PIN'
																		? 'bg-orange-100'
																		: null
																		}  ${item.action === 'VALIDATE'
																			? 'bg-green-100'
																			: null
																		}

																	${item.action === 'REJECT' ? 'bg-red-100' : null}
																	
																	${item.action === 'FEED' ? 'bg-gray-200' : null} ${item.action === 'UNDO'
																			? 'bg-bluegray-300'
																			: null
																		}  flex items-center justify-center ring-8 ring-white`}>
																	{item.action === 'FEED' ? (
																		<FontAwesomeIcon
																			className="text-primary-500 text-xs"
																			icon={faStream}></FontAwesomeIcon>
																	) : null}
																	{item.action === 'PIN' ? (
																		<FontAwesomeIcon
																			className="text-orange-500 text-xs"
																			icon={faThumbtack}></FontAwesomeIcon>
																	) : null}
																	{item.action === 'UNDO' ? (
																		<FontAwesomeIcon
																			className="text-primary-500 text-xs"
																			icon={faUndo}></FontAwesomeIcon>
																	) : null}
																	{item.action === 'REJECT' ? (
																		<FontAwesomeIcon
																			className="text-primary-500 text-xs"
																			icon={faTimes}></FontAwesomeIcon>
																	) : null}
																	{item.action === 'VALIDATE' ? (
																		<FontAwesomeIcon
																			className="text-primary-500 text-xs"
																			icon={faCheck}></FontAwesomeIcon>
																	) : null}
																</span>
															</div>
															<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
																<div>
																	<p className="text-sm text-gray-800">
																		{item.description}
																	</p>
																</div>
																<div className="text-right text-sm whitespace-nowrap text-gray-500">
																	<time dateTime="2020-09-20">
																		{' '}
																		<Moment unix fromNow>
																			{item.date / 1000}
																		</Moment>
																	</time>
																</div>
															</div>
														</div>
													</div>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</div>
						<div className="flex-shrink-0 px-4 py-4 flex justify-end">
							<Button
								onClick={() => setOpen(false)}
								theme="secondary"
								label="Close"></Button>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
