import { useState, useEffect } from 'react'
import Tooltip from './Tooltip'

export default function AnnotedImage(props) {
	const [imgWidth, setImgWidth] = useState(0)
	const [imgHeight, setImgHeight] = useState(0)
	const [loaded, setLoaded] = useState(false)

	function initSize() {
		if (document.getElementById(props.id)?.clientWidth) {
			setImgWidth(document.getElementById(props.id).clientWidth)
		}

		if (document.getElementById(props.id)?.clientHeight) {
			setImgHeight(document.getElementById(props.id).clientHeight)
		}
	}

	function initLoad() {
		initSize()
		setLoaded(true)
		window.addEventListener('resize', initSize)
	}

	useEffect(() => {
		console.log(props.annotations)
	}, [])

	function SingleAnnotation({ annotation, sameColor, type, noStyle }) {
		let position = {}
		if (type === 'registry') {
			if (annotation.registry_position) {
				position = annotation.registry_position
			}
		} else {
			if (annotation.position) {
				position = annotation.position
			}
		}

		if (sameColor) {
			annotation.color = 'FF0000'
		}

		let initialStyle = {
			backgroundColor: `#${annotation.color}50`,
			borderColor: `#${annotation.color}90`,
		}
		let hoverStyle = {
			backgroundColor: `#${annotation.color}75`,
			borderColor: `#${annotation.color}`,
		}

		if (noStyle) {
			initialStyle = {
				borderColor: `#${annotation.color}`,
			}
		}

		let locationMargin = 0
		let sizeMargin = 0
		if (noStyle) {
			sizeMargin = 10
			locationMargin = 5
		}

		const imgRef = document.getElementById(props.id)

		const [elementStyle, setElementStyle] = useState(initialStyle)
		let width = parseInt(position.width * imgRef.width + sizeMargin)
		let height = parseInt(position.height * imgRef.height + sizeMargin)
		let left = parseInt(position.top_left.x * imgRef.width - locationMargin)
		let top = parseInt(position.top_left.y * imgRef.height - locationMargin)

		// if (type === 'registry') {
		// 	width = parseInt(position.width * imgWidth + sizeMargin)
		// 	height = parseInt(position.height * imgHeight + sizeMargin)
		// 	left = parseInt(position.top_left.x * imgWidth - locationMargin)
		// 	top = parseInt(position.top_left.y * imgHeight - locationMargin)
		// }
		const sizeStyle = {
			width: `${width}px`,
			height: `${height}px`,
			top: `${top}px`,
			left: `${left}px`,
		}
		let customClasses = 'absolute border-2'
		return (
			<div
				onMouseOver={() => setElementStyle({ ...hoverStyle })}
				onMouseOut={() => setElementStyle(initialStyle)}
				key={annotation.slug}>
				<Tooltip auto={true} content={annotation.name}>
					<div
						style={{ ...elementStyle, ...sizeStyle }}
						className={customClasses}></div>
				</Tooltip>
			</div>
		)
	}

	function DrawAnnotations(props) {
		if (props.single) {
			return (
				<SingleAnnotation
					sameColor
					noStyle={props.noStyle}
					type={props.type}
					annotation={props.annotations}></SingleAnnotation>
			)
		} else {
			return props.annotations.map((annotation, i) => {
				return (
					<SingleAnnotation
						key={i}
						type={props.type}
						annotation={annotation}></SingleAnnotation>
				)
			})
		}
	}

	return (
		<div className="relative">
			<img
				id={props.id}
				draggable="false"
				src={props.src}
				className={`${props.className} shadow rounded-md h-auto`}
				onLoad={initLoad}></img>
			{loaded ?
				<DrawAnnotations
					noStyle={props.noStyle}
					type={props.type}
					single={props.single ? true : false}
					annotations={props.annotations}></DrawAnnotations>
				: null
			}
		</div>
	)
}
