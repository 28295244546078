import { useContext, useEffect, useState } from 'react'
import { IdentityVerificationContext } from '@/context/identity_verification'
import { GlobalHotKeys } from 'react-hotkeys'
import IdentityMrzBarcodeExtract from '@/components/verifications/identity/mrz/IdentityMrzBarcodeExtract'

export default function IdentityMrzBarcode(props) {
	const [, setAnnotation] = useState(null)
	const [identityVerification] = useContext(
		IdentityVerificationContext
	)

	const keyMap = {
		ENTER: ['enter'],
	}

	useEffect(() => {
		setAnnotation({
			slug: 'mrz_lines',
			name: 'MRZ',
			registry_position:
				identityVerification.verification.details.mrz.lines.registry_position,
		})
		return () => {}
	}, [])

	const handlers = {
		ENTER: () => {},
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
		<IdentityMrzBarcodeExtract
					onUpdate={() => props.onUpdate()}></IdentityMrzBarcodeExtract>
		</GlobalHotKeys>
	)
}
