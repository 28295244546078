import Sleep from '@/lib/sleep'
import {
	faAsterisk,
	faClock,
	faStamp,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../ui/Button'
import CountryFlag from '../ui/CountryFlag'
import Input from '../ui/Input'
import Upload from '../ui/Upload'
import RegistryService from '@/services/registry'
import Alert from '../ui/Alert'
import { useHistory } from 'react-router-dom'

export default function RegistryNewDocument(props) {
	const [error, setError] = useState(null)
	const { register, handleSubmit, errors } = useForm()
	const [loading, setLoading] = useState(false)
	const [front, setFront] = useState(null)
	const [back, setBack] = useState(null)
	const router = useHistory()

	const formSchema = {
		issuing_year: {
			required: 'Issuing Year required',
			pattern: {
				value: /^[0-9]*$/i,
				message: 'Invalid issuing year',
			},
		},
		validity: {
			required: 'Validity required',
			pattern: {
				value: /^[0-9]*$/i,
				message: 'Invalid validity',
			},
		},
		reference: {
			required: 'Reference required',
		},
	}

	const onSubmit = async (data) => {
		setError(null)

		if (!front) {
			setError('Front document required')
			return () => {}
		}

		if (props.slug !== 'PASSPORT' && !back) {
			setError('Back document required')
			return () => {}
		}

		setLoading(true)

		await Sleep(2000)

		let bodyFormData = new FormData()
		bodyFormData.append('country', props.country)
		bodyFormData.append('front_document', front)
		bodyFormData.append('document_type', props.slug)
		bodyFormData.append('validity', data.validity)
		bodyFormData.append('issuing_year', data.issuing_year)
		bodyFormData.append('reference', data.reference)

		if (props.slug !== 'PASSPORT') {
			bodyFormData.append('back_document', back)
		}

		await RegistryService.AddRegistryDoc(bodyFormData)
			.then((data) => {
				router.push(`/registry/${props.country}/${data.slug}`)
			})
			.catch((err) => {
				setLoading(false)
				setError(err.message)
			})
		return () => {}
	}

	return (
		<Transition
			show={props.open}
			enter='ease-out duration-200'
			enterFrom='opacity-0'
			enterTo='opacity-100'
			leave='ease-in duration-100'
			leaveFrom='opacity-100'
			leaveTo='opacity-0'>
			<div className='fixed z-10 inset-0 overflow-y-auto'>
				<div className='flex items-end justify-center min-h-screen text-center sm:block sm:p-0'>
					<div
						className='fixed inset-0 transition-opacity'
						aria-hidden='true'
						onClick={() => props.onClose()}>
						<div className='absolute inset-0 bg-gray-800 opacity-75'></div>
					</div>

					<span
						className='hidden sm:inline-block sm:align-middle sm:h-screen'
						aria-hidden='true'>
						&#8203;
					</span>

					<div
						className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'
						role='dialog'
						aria-modal='true'
						aria-labelledby='modal-headline'>
						<div className='p-4'>
							<div>
								<div className='p-3 pb-0'>
									<h3
										className='text-lg leading-6 font-medium text-gray-900'
										id='modal-headline'>
										<div className='flex text-sm'>
											<CountryFlag country={props.country}></CountryFlag>
											<span className='ml-2 relative top--1px'>
												{props.countryName}
											</span>
										</div>
									</h3>
									<h1 className='text-2xl font-bold mt-1'>New {props.label}</h1>
								</div>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div>
										<div className='py-2 px-3'>
											{error ? (
												<Alert type='error' title={error}></Alert>
											) : null}
										</div>
										<div className='flex flex-wrap'>
											<div className='p-3 w-full'>
												<Input
													type='text'
													icon={faAsterisk}
													referrer={register(formSchema.reference)}
													label='Reference'
													placeholder='B1'
													name='reference'
													error={errors.reference}
													message={errors.reference?.message}
												/>
											</div>
											<div className='p-3 w-full sm:w-1/2'>
												<Input
													icon={faStamp}
													referrer={register(formSchema.issuing_year)}
													label='Issuing Year'
													placeholder='1994'
													name='issuing_year'
													error={errors.issuing_year}
													message={errors.issuing_year?.message}
												/>
											</div>
											<div className='p-3 w-full sm:w-1/2'>
												<Input
													icon={faClock}
													referrer={register(formSchema.validity)}
													label='Validity (in years)'
													placeholder='9'
													name='validity'
													error={errors.validity}
													message={errors.validity?.message}
												/>
											</div>
											<div className='p-3 w-full'>
												{props.slug === 'PASSPORT' ? (
													<label
														htmlFor={props.name}
														className='block text-sm font-medium text-gray-700 mb-2'>
														Passport Page
													</label>
												) : (
													<label
														htmlFor={props.name}
														className='block text-sm font-medium text-gray-700 mb-2'>
														Front Document
													</label>
												)}
												<Upload
													loading={loading}
													acceptedFileTypes={[
														'image/jpeg',
														'image/png',
														'image/jpg',
													]}
													setFile={setFront}></Upload>
											</div>
											{props.slug !== 'PASSPORT' ? (
												<>
													<div className='p-3 pt-0 w-full'>
														<label
															htmlFor={props.name}
															className='block text-sm font-medium text-gray-700 mb-2'>
															Back Document
														</label>
														<Upload
															loading={loading}
															acceptedFileTypes={[
																'image/jpeg',
																'image/png',
																'image/jpg',
															]}
															setFile={setBack}></Upload>
													</div>
												</>
											) : null}
										</div>
									</div>

									<div>
										<div className='flex justify-between'>
											<div className='p-3'></div>
											<div className='p-3'>
												<div className='flex'>
													<div className='mr-3'>
														<Button
															theme='secondary'
															onClick={() => props.onClose()}
															label='Cancel'
														/>
													</div>
													<div>
														<Button
															type='submit'
															theme='success'
															loading={loading}
															onClick={() => handleSubmit(onSubmit)}
															label='Add document'
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
