import CountryFlag from '../ui/CountryFlag'

export default function RegistryListItem(props) {
	return (
		<div className="p-6">
			<div className="text-center">
				<div className="mx-auto text-center my-3">
					<CountryFlag
						className={'w-12 rounded-md'}
						country={props.registry.alpha3}></CountryFlag>
				</div>
				<p className="text-regular font-semibold">{props.registry.name}</p>
				<p className="text-sm">{props.registry.alpha3}</p>
			</div>
		</div>
	)
}
