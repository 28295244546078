import { useEffect, useState } from "react";

export default function DuplicateMatchItem(props) {
  const [, setOpen] = useState(false);

  useEffect(() => {
    if (props.user) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.user]);

  return (
    <div className="w-full list-item">
      {props.liveness.liveness_image !== "" ? (
        <div>
          <div className="flex justify-between">
            <div className="mx-auto">
              <p className="ml-3 mb-3">Liveness Face</p>
            </div>
          </div>
          <img
            draggable={false}
            className={"object-contain h-128 w-full"}
            src={props.item.liveness_image}
          />
        </div>
      ) : null}
      <div className="mx-auto mt-2">
        <div className="flex justify-between">
          <div className="mx-auto">
            <p className="ml-3">Id Face</p>
          </div>
        </div>
        <img
          draggable={false}
          className={"object-contain h-128 w-full"}
          src={props.item.id_image_front}
        />
      </div>
    </div>
  );
}
