import {
	faAsterisk,
	faBarcodeScan,
	faCalendarAlt,
	faCodeBranch,
	faFilter,
	faFlag,
	faFontCase,
	faLayerPlus,
	faTrash,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'

export default function RegistryFeatureInfoSpecs(props) {
	return (
		<div className="flex">
			{props.data.source === 'MRZ_LINES' ||
			props.data.source === 'MRZ_BARCODE' ? (
					<div className="ml-2">
						<Tooltip auto={true} content={`Source ${props.data.source}`}>
							<span>
								<FontAwesomeIcon
									className="text-green-500 text-sm"
									icon={faBarcodeScan}></FontAwesomeIcon>
							</span>
						</Tooltip>
					</div>
				) : null}

			{props.data.source === 'OCR' ? (
				<div className="ml-2">
					<Tooltip auto={true} content="Source OCR">
						<span>
							<FontAwesomeIcon
								className="text-green-500  text-sm"
								icon={faFontCase}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{props.data.lang &&
			props.data.lang !== '' &&
			(props.data.slug === 'firstname' || props.data.slug === 'lastname') &&
			props.data.active ? (
					<div className="ml-2">
						<Tooltip auto={true} content={`Lang (${props.data.lang})`}>
							<span>
								<FontAwesomeIcon
									className="text-green-500  text-sm"
									icon={faFlag}></FontAwesomeIcon>
							</span>
						</Tooltip>
					</div>
				) : null}

			{props.data.cross_checkable ? (
				<div className="ml-2">
					<Tooltip auto={true} content="Crosscheckable">
						<span>
							<FontAwesomeIcon
								className="text-green-500  text-sm"
								icon={faCodeBranch}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{props.data.date_format ? (
				<div className="ml-2">
					<Tooltip auto={true} content={props.data.date_format}>
						<span>
							<FontAwesomeIcon
								className="text-green-500 text-sm"
								icon={faCalendarAlt}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{props.data.active && props.data.required === true ? (
				<div className="ml-2">
					<Tooltip auto={true} content="Required">
						<span>
							<FontAwesomeIcon
								className="text-red-600  text-sm"
								icon={faAsterisk}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{props.data.active && props.data.required === false ? (
				<div className="ml-2">
					<Tooltip auto={true} content="Optional">
						<span>
							<FontAwesomeIcon
								className="text-orange-500  text-sm"
								icon={faFilter}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{props.data.active ? (
				<div className="ml-2 cursor-pointer" onClick={() => props.onDelete()}>
					<Tooltip auto={true} content="Delete">
						<span>
							<FontAwesomeIcon
								className="text-gray-400  text-sm"
								icon={faTrash}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}

			{!props.data.active ? (
				<div className="ml-2 cursor-pointer" onClick={() => props.onSet()}>
					<Tooltip auto={true} content="Register">
						<span>
							<FontAwesomeIcon
								className="text-gray-400  text-sm"
								icon={faLayerPlus}></FontAwesomeIcon>
						</span>
					</Tooltip>
				</div>
			) : null}
		</div>
	)
}
