import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import AppsListPage from '@/components/pages/AppsListPage'

function Apps() {
	return (
		<AuthGuard>
			<IamGuard page='admin'>
				<div className='relative min-h-screen flex flex-col'>
					<Navbar></Navbar>
					<AppsListPage></AppsListPage>
				</div>
			</IamGuard>
		</AuthGuard>
	)
}

export default Apps
