import {
	faAddressCard,
	faBug,
	faCodeBranch,
	faComments,
	faCropAlt,
	faFingerprint,
	faQrcode,
	faUser,
	faUserSecret,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import Moment from 'react-moment'
import CountryFlag from '../ui/CountryFlag'
import Tooltip from '../ui/Tooltip'
import HeaderButton from '@/components/ui/HeaderButton'
import IdentityService from '@/services/identity'

export default function IdentityTaskItem(props) {
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (props.user) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [props.user])

	async function UnpinTask(identity) {
		await IdentityService.UnpinTask(identity.task.session_id)
			.then(async () => {
				props.update()
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => { }
	}

	return (
		<div className="w-full flex">
			<div className="w-full list-item">
				<a
					href={`/verifications/identity/${props.item.task.session_id}`}
					className="flex-shrink-0 group block">
					<div className="flex justify-between items-center">
						<div className="ml-1">
							<div className="flex">
								<div>
									<Tooltip auto={true} content={props.item.app.name}>
										<span className="inline-block relative">
											<img
												className="h-10 w-10 rounded-lg relative top-1 border border-gray-100"
												src={props.item.app.logo_url}
												alt=""
											/>
										</span>
									</Tooltip>
								</div>
								<div className="mt-1">
									<p className="ml-4 text-sm font-medium text-gray-700 group-hover:text-gray-900">
										<span>{props.item.task.session_id}</span>
									</p>
									<div className="ml-4 text-xs font-light flex">
										<span className="relative top--1px mr-1">
											<CountryFlag
												className="h-4 w-4 rounded"
												country={props.item.verification.document.country}
											/>
										</span>{' '}
										{props.item.verification.document.type === 'PASSPORT' ? (
											<span>Passport</span>
										) : null}
										{props.item.verification.document.type === 'NATIONAL_ID' ? (
											<span>National ID</span>
										) : null}
										{props.item.verification.document.type ===
											'RESIDENT_PERMIT' ? (
											<span>Resident Permit</span>
										) : null}
										{props.item.verification.document.type ===
											'DRIVER_LICENSE' ? (
											<span>Driver License</span>
										) : null}
										{props.item.task.pin_details.note && props.item.task.pin ? (
											<div>
												{'  '} - {'  '}
												<span className="font-bold text-orange-500">
													{props.item.task.pin_details.note}
												</span>
											</div>
										) : null}
									</div>
								</div>
							</div>
						</div>
						<div className="text-right">
							<div className="flex items-center">
								<Tooltip auto={true} content={props.user?.firstname}>
									<span>
										<img
											className={`z-0 h-5 w-5 rounded-full relative cursor-default animate__animated ${open ? 'animate__flipInX' : 'animate__flipOutX'
												}`}
											src={
												props.user
													? props.user?.profile_pic
													: '/img/default-user.svg'
											}
											alt=""
										/>
									</span>
								</Tooltip>
								<div>
									<div className="ml-3 mr-2">
										<p className="text-xs font-light cursor-default text-gray-700">
											<Moment unix fromNow>
												{props.item.task.creation_date / 1000}
											</Moment>
										</p>
									</div>
								</div>
								{props.item.task.pin === true ? (
									<div className="ml-3 mr-2">
										{props.item.task.pin_details.action ===
											'SEGMENTATION_FAIL' ? (
											<Tooltip auto={true} content="Segmentation issue">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faCropAlt}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'TEMPLATE_UNCERTAINTY' ? (
											<Tooltip auto={true} content="Segmentation issue">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faAddressCard}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'INFO_REVIEW_UNCERTAINTY' ? (
											<Tooltip auto={true} content="Info Review uncertainty">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faUser}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'INFO_EXTRACT_UNCERTAINTY' ? (
											<Tooltip auto={true} content="Info Extract uncertainty">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faCropAlt}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action === 'MRZ_INVALID' ? (
											<Tooltip auto={true} content="MRZ Invalid">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faQrcode}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'CROSSCHECK_UNCERTAINTY' ? (
											<Tooltip auto={true} content="Cross check uncertainty">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faCodeBranch}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'FEATURES_UNCERTAINTY' ? (
											<Tooltip auto={true} content="Features uncertainty">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faFingerprint}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action ===
											'FORGERY_DOCUMENT_LOOK_FORGED' ? (
											<Tooltip auto={true} content="Document look forged">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faUserSecret}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action === 'INTERNAL_ISSUE' ? (
											<Tooltip auto={true} content="Internal Issue">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faBug}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
										{props.item.task.pin_details.action === 'INTERNAL_MESSAGE' ? (
											<Tooltip auto={true} content="Internal Message">
												<span>
													<FontAwesomeIcon
														className="text-orange-500"
														icon={faComments}></FontAwesomeIcon>
												</span>
											</Tooltip>
										) : null}
									</div>
								) : null}
							</div>
						</div>
					</div>
				</a>
			</div>
			{props.item.task.pin === true ? (
				<div className="ml-2 mr-2 mt-4">
					<HeaderButton
						onClick={() => UnpinTask(props.item)}
						currentBg="white"
						type="orange"
						label={'Unpin'}></HeaderButton>
				</div>
			) : null}
		</div>
	)
}
