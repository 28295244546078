import { useContext, useEffect, useState } from 'react'
import ResidencyService from '@/services/residency'
import Empty from '../misc/Empty'
import TasksPlaceholder from '../placeholder/TasksPlaceholder'
import Sleep from '@/lib/sleep'
import * as Notification from '@/components/ui/Notification'
import PinCard from '../ui/PinCard'
import {
	faAddressCard,
	faBug,
	faCodeBranch,
	faComments,
	faCrop,
	faCropAlt,
	faFilter,
	faFingerprint,
	faPassport,
	faQrcode,
	faThumbtack,
	faUser,
	faUserSecret,
} from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderButton from '@/components/ui/HeaderButton'
import { ResidencyContext } from '@/context/residency'
import ResidencyTaskItem from './ResidencyTaskItem'

export default function ResidencyTasks(props) {
	const [pinType, setPinType] = useState('')
	const [residency, residencyDispatch] = useContext(ResidencyContext)
	const [ready, setReady] = useState(false)
	const [showFilter, setShowFilter] = useState(false)

	function updatePin(newPin) {
		if (pinType === newPin) {
			setPinType('')
		} else {
			setPinType(newPin)
		}
	}

	useEffect(async () => {
		setReady(false)
		await ResidencyService.GetTasks(residency.selectedTab.key, pinType)
			.then(async (data) => {
				residencyDispatch({
					type: 'SET_TASKS',
					payload: {
						tasks: data,
					},
				})
				await Sleep(500)
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => { }
	}, [residency.selectedTab, pinType])

	return (
		<>
			<div className="flex justify-between">
				<div>
					<CardTitle
						value={residency.selectedTab.title}
						icon={residency.selectedTab.icon}></CardTitle>
				</div>
				<div className="mt-3 mr-6 flex">
					{pinType !== '' ? (
						<p className="mt-1 mr-4">
							<FontAwesomeIcon
								className="text-orange-400 mr-2"
								icon={faThumbtack}></FontAwesomeIcon>
							{pinType}
						</p>
					) : null}
					{residency.selectedTab.key === 'pinned' ? (
						<HeaderButton
							onClick={() => setShowFilter(!showFilter)}
							currentBg="white"
							icon={faFilter}
							type="orange"
							label={showFilter ? 'Hide filter' : 'Show filter'}></HeaderButton>
					) : null}
				</div>
			</div>
			{residency.selectedTab.key === 'pinned' && showFilter ? (
				<div className="flex flex-wrap p-3 pb-0 pt-0 bg-bluegray-50 mt-3 mb-3">
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="SEGMENTATION_FAIL"
							title="Segmentation issue"
							description="Error during segmentation process"
							icon={faCropAlt}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="TEMPLATE_UNCERTAINTY"
							title="Template Uncertainty"
							description="Not sure about if the template is the real one"
							icon={faAddressCard}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="TEMPLATE_REGISTRY_REQUEST_TEMPLATE"
							title="Missing Model"
							description="Missing model template"
							icon={faPassport}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="INFO_REVIEW_UNCERTAINTY"
							title="Info Review Uncertainty"
							description="Uncertainty about info values"
							icon={faUser}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="INFO_EXTRACT_UNCERTAINTY"
							title="Info Extract Uncertainty"
							description="Uncertainty about info extraction"
							icon={faCrop}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="MRZ_INVALID"
							title="Invalid MRZ"
							description="Machine-Readable Zone (MRZ) is invalid"
							icon={faQrcode}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="CROSSCHECK_UNCERTAINTY"
							title="Crosscheck Uncertainty"
							description="Uncertainty about crosscheck values"
							icon={faCodeBranch}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="FEATURES_UNCERTAINTY"
							title="Features Uncertainty"
							description="Uncertainty about features"
							icon={faFingerprint}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="FORGERY_DOCUMENT_LOOK_FORGED"
							title="Document seems forged"
							description="The current document seems forged"
							icon={faUserSecret}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="INTERNAL_ISSUE"
							title="Bug"
							description="Internal Synaps Panel issue"
							icon={faBug}></PinCard>
					</div>
					<div className="w-1/3 p-2">
						<PinCard
							selected={pinType}
							setPin={(slug) => updatePin(slug)}
							slug="INTERNAL_MESSAGE"
							title="Message"
							description="Internal team message"
							icon={faComments}></PinCard>
					</div>
				</div>
			) : null}

			{ready ? (
				<div className="flex flex-wrap p-6 pt-3">
					{residency.tasks.map((task) => {
						return (
							<>
								<ResidencyTaskItem
									update={(slug) => updatePin(slug)}
									user={props.synapsState?.residency[task.task.session_id]}
									key={task.task.session_id}
									item={task}></ResidencyTaskItem>
							</>
						)
					})}
					{residency.tasks.length === 0 ? (
						<Empty value="No tasks"></Empty>
					) : null}
				</div>
			) : (
				<div className="p-4">
					<TasksPlaceholder></TasksPlaceholder>
				</div>
			)}
		</>
	)
}
