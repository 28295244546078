import Searchbar from '../ui/SearchBar'
import MiniProfile from '../user/MiniProfile'
import NavMenu from '../menu/NavMenu'

export default function Navbar() {
	return (
		<nav className='flex-shrink-0 bg-primary-500'>
			<div className='max-w-7xl mx-auto py-3'>
				<div className='flex px-3 sm:p-0 justify-between'>
					<div className='flex'>
						<a href='/'>
							<div className='cursor-pointer relative top-1px'>
								<img
									className='w-9 h-9'
									src='/img/logo/synaps-logo-s.svg'></img>
							</div>
						</a>
						<div className='ml-3'>
							<Searchbar></Searchbar>
						</div>
					</div>
					<div className='flex'>
						<div className='z-5'>
							<NavMenu></NavMenu>
						</div>
						<div className='z-5 ml-2'>
							<MiniProfile></MiniProfile>
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}
