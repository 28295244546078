import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import ResidencyDocumentViewer from '../ResidencyDocumentViewer'
import StepTitle from '@/components/ui/StepTitle'
import {
	faCheckCircle,
	faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditDocumentTypeSidebar from '@/components/residency/EditDocumentTypeSidebar'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'
import Tooltip from '@/components/ui/Tooltip'

export default function ResidencyDocumentName(props) {
	const [successLoading, setSuccessLoading] = useState(false)
	const [editDocumentOpen, setEditDocumentOpen] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)

	const keyMap = {
		SELECT: ['enter'],
	}

	const handlers = {
		SELECT: () => validateStep('name_verification'),
	}

	async function validateStep(step) {
		setSuccessLoading(true)
		await ResidencyService.ValidateStep(
			residencyVerification.task.session_id,
			step
		)
			.then(() => {
				setSuccessLoading(false)
				props.onUpdate()
			})
			.catch((err) => {
				Notification.error(err.message)
				setSuccessLoading(false)
			})
		return () => {}
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}
			<div className="flex flex-wrap">
				<div className="w-3/5">
					<ResidencyDocumentViewer></ResidencyDocumentViewer>
				</div>
				<div className="w-2/5">
					<div>
						<StepTitle value="Name verification" icon={faUser}></StepTitle>
					</div>
					<div>
						<div className="text-center">
							<div className="mt-6 mb-6">
								<FontAwesomeIcon
									className="text-residency-500 text-5xl"
									icon={faUser}></FontAwesomeIcon>
								<h3 className="text-2xl mt-6">
									<Tooltip auto={true} content="Firstname">
										<span className="font-light">
											{residencyVerification.verification.is_accomodation
												? residencyVerification.verification.accomodation
													.firstname
												: residencyVerification.verification.details.firstname}
										</span>
									</Tooltip>
									<Tooltip auto={true} content="Lastname">
										<span className="font-bold">
											{' '}
											{residencyVerification.verification.is_accomodation
												? residencyVerification.verification.accomodation
													.lastname
												: residencyVerification.verification.details.lastname}
										</span>
									</Tooltip>
								</h3>
							</div>
						</div>
						<hr />
						<div className="p-4 pb-0">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Instructions
							</p>
							<div>
								<p className="mt-2">
									<FontAwesomeIcon
										className="text-green-600 mr-2"
										icon={faCheckCircle}></FontAwesomeIcon>
									Firstname should be written
								</p>
							</div>
							<div>
								<p className="mt-2">
									<FontAwesomeIcon
										className="text-green-600 mr-2"
										icon={faCheckCircle}></FontAwesomeIcon>
									Lastname should be written
								</p>
							</div>
						</div>
						<div className="p-4">
							<p className="uppercase text-sm font-bold text-gray-500 mt-4">
								Detected Keywords
							</p>
							{Object.entries(residencyVerification.suggestions).length ===
							0 ? (
									<p>None</p>
								) : null}
							{Object.entries(residencyVerification.suggestions).map(
								([key, value]) => {
									return (
										<p className="mb-3 mt-3" key={key}>
											Page n°{key}
											<p>
												{value.map((item, i) => {
													return (
														<p key={i} className="mt-2 rounded-full text-sm font-medium text-gray-800">
															§{item.paragraph_nb} - {item.word}
														</p>
													)
												})}
											</p>
										</p>
									)
								}
							)}
						</div>
						<div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
							<div></div>
							<div className="p-2">
								<Button
									loading={successLoading}
									onClick={() => validateStep('name_verification')}
									icon={faCheck}
									theme="success"
									full
									label="Validate"></Button>
							</div>
						</div>
					</div>
				</div>
				<EditDocumentTypeSidebar
					open={editDocumentOpen}
					setOpen={setEditDocumentOpen}></EditDocumentTypeSidebar>
			</div>
		</GlobalHotKeys>
	)
}
