import { useReducer, createContext } from 'react'

export const RegistryIdentityContext = createContext()

const initialState = {}

function setResponse(state, payload) {
	return payload
}

const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_RESPONSE':
		return setResponse(state, action.payload)
	default:
		return state
	}
}

export const RegistryIdentityContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<RegistryIdentityContext.Provider value={[state, dispatch]}>
			{props.children}
		</RegistryIdentityContext.Provider>
	)
}
