import { LoginContext } from "@/context/login";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/context/auth";
import Loader from "../ui/Loader";
import Sleep from "@/lib/sleep";
import { b64Encode } from "@/lib/b64";
import { useHistory } from "react-router-dom";
import Cookie from "js-cookie";
import LogRocket from "logrocket";

export default function AuthProcess() {
  const [loginState] = useContext(LoginContext);
  const [, authDispatch] = useContext(AuthContext);
  const router = useHistory();

  useEffect(async () => {
    const query = new URLSearchParams(router.location.search);

    const s_auth = {
      auth: {
        access_token: loginState.auth.auth.access_token,
        refresh_token: loginState.auth.auth.refresh_token,
      },
      user: {
        email: loginState.auth.email,
        firstname: loginState.auth.firstname,
        lastname: loginState.auth.lastname,
        profile_pic: loginState.auth.profile_pic,
      },
      iam: {
        admin: loginState.auth.iam.includes("ADMIN"),
        residency: loginState.auth.iam.includes("RESIDENCY"),
        identity: loginState.auth.iam.includes("IDENTITY"),
        aml: loginState.auth.iam.includes("AML"),
        facematch: loginState.auth.iam.includes("FACEMATCH"),
        corporate: loginState.auth.iam.includes("CORPORATE"),
        duplicate: loginState.auth.iam.includes("DUPLICATE"),
        support_agent: loginState.auth.iam.includes("SUPPORT_AGENT"),
        support_manager: loginState.auth.iam.includes("SUPPORT_MANAGER"),
      },
      authenticated: true,
    };

    authDispatch({
      type: "SET_AUTH",
      payload: s_auth,
    });

    Cookie.set(
      "s_auth",
      Buffer.from(JSON.stringify(s_auth)).toString("base64")
    );

    LogRocket.identify(loginState.auth.email, {
      firstname: loginState.auth.firstname,
      lastname: loginState.auth.lastname,
    });

    Cookie.set(
      "s_profile",
      Buffer.from(
        JSON.stringify({
          user: {
            email: loginState.auth.email,
            firstname: loginState.auth.firstname,
            lastname: loginState.auth.lastname,
            profile_pic: loginState.auth.profile_pic,
          },
        })
      ).toString("base64")
    );

    await Sleep(1500);

    const redirect_uri = query.get("redirect_uri");
    if (redirect_uri) {
      const pathName = decodeURIComponent(redirect_uri);
      if (pathName !== "") {
        router.push(pathName);
      } else {
        router.push("/");
      }
    } else {
      router.push("/");
    }
    return () => {};
  }, []);

  return (
    <>
      <div className="mx-auto text-center mt-5">
        <Loader color="primary-500" size="maxi"></Loader>
        <p className="text-primary-500 mt-4 mb-5">Authentication</p>
      </div>
    </>
  );
}
