import axios from "axios";
import Cookies from "js-cookie";
import { b64Decode } from "@/lib/b64";

let isRefreshing = false;
let nbTries = 0;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const PanelAuth = axios.create();
PanelAuth.interceptors.request.use((request) => {
  let s_auth = Cookies.get("s_auth");
  s_auth = JSON.parse(b64Decode(s_auth));
  const access_token = s_auth.auth.access_token;
  request.headers["Authorization"] = access_token;
  return request;
});

PanelAuth.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      Cookies.remove("s_auth");
      if (window.location.pathname !== "/") {
        const pathName = encodeURIComponent(window.location.pathname);
        window.location.href = `/login?redirect_uri=${pathName}`;
      } else {
        window.location.href = `/login`;
      }
      return Promise.reject({
        api_code: "AUTH_SESSION_EXPIRED",
        message: "Session expired",
      });
    }
    if (error.response === undefined) {
      return Promise.reject({
        api_code: "NETWORK_ERROR",
        message: "Network Error",
      });
    }

    return Promise.reject(error.response.data);
  }
);

const PanelPublic = axios.create();

PanelPublic.interceptors.request.use((request) => {
  return request;
});

PanelPublic.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    console.log(error);
    if (error.response === undefined) {
      return Promise.reject({
        api_code: "NETWORK_ERROR",
        message: "Network Error",
      });
    }
    return Promise.reject(error.response.data);
  }
);

export { PanelAuth, PanelPublic };
