import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import AgentPage from '@/components/pages/AgentPage'

function AgentsIndex() {
	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<AgentPage></AgentPage>
			</div>
		</AuthGuard>
	)
}

export default AgentsIndex
