import RegistryListHeaderItem from "@/components/registry/RegistryListHeaderItem";
import {
  faBrowser,
  faShield,
  faStream,
  faTerminal,
  faUniversity,
  faWindowRestore,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AppsListHeader(props) {
  return (
    <div className="flex flex-wrap justify-between">
      <div className="w-full sm:w-3/12 px-3 pb-6 sm:pb-0 sm:px-0">
        <div className="flex">
          <div className="mr-5 mt-0.5">
            <FontAwesomeIcon
              className="text-gray-400 text-6xl"
              icon={faBrowser}
            ></FontAwesomeIcon>
          </div>
          <div>
            <h1 className="text-3xl">
              <span className="font-bold">Apps</span>
            </h1>
            <h3 className="text-lg  text-bluegray-600">Synaps customer apps</h3>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-9/12">
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/5">
            <RegistryListHeaderItem
              label="Workflow"
              value={props.data.workflow}
              icon={faStream}
            ></RegistryListHeaderItem>
          </div>
          <div className="w-full sm:w-1/5">
            <RegistryListHeaderItem
              label="Corporate"
              value={props.data.corporate}
              icon={faUniversity}
            ></RegistryListHeaderItem>
          </div>
          <div className="w-full sm:w-1/5">
            <RegistryListHeaderItem
              label="Production"
              value={props.data.production}
              icon={faShield}
            ></RegistryListHeaderItem>
          </div>
          <div className="w-full sm:w-1/5">
            <RegistryListHeaderItem
              label="Sandbox"
              value={props.data.sandbox}
              icon={faTerminal}
            ></RegistryListHeaderItem>
          </div>
          <div className="w-full sm:w-1/5">
            <RegistryListHeaderItem
              label="Total"
              value={props.data.total}
              icon={faWindowRestore}
            ></RegistryListHeaderItem>
          </div>
        </div>
      </div>
    </div>
  );
}
