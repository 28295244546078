import { useContext, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faGlass,
  faPassport,
} from "@fortawesome/pro-duotone-svg-icons";
import Button from "@/components/ui/Button";

import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import { faArrowAltSquareRight } from "@fortawesome/pro-solid-svg-icons";

export default function IdentityAutoCrop(props) {
  const [completed, setCompleted] = useState(false);
  const [toIncomplete, setToIncomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [frontRotation, setFrontRotation] = useState(0);
  const [backRotation, setBackRotation] = useState(0);
  const router = useHistory();

  async function processAutoChecks() {
    setLoading(true);
    await IdentityService.ProcessAutoChecks(identityVerification.task.session_id)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function swapFace() {
    setLoading(true);
    await IdentityService.SwapDocumentFace(identityVerification.task.session_id)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }



  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle
            value="Document Visual Check"
            icon={faPassport}
          ></StepTitle>
        </div>
      </div>
      <div className="flex flex-wrap mx-3">
        <div className="w-1/2">
          <div className="mx-auto">
            <div className="flex justify-between">
              <div>
                <p className="ml-8">Front</p>
              </div>
            </div>
            <img
              draggable={false}
              className={`object-contain h-128 w-full rotate-${frontRotation}`}
              src={identityVerification.media.thumbnail.front}
            />
          </div>
        </div>
        {identityVerification.verification.document.type !== "PASSPORT" ? (
          <div className="w-1/2">
            <div>
              <div className="flex justify-between">
                <div className="flex">
                  <p className="ml-8">Back</p>
                </div>
              </div>
              <img
                draggable={false}
                className={`object-contain h-128 w-full rotate-${backRotation}`}
                src={identityVerification.media.thumbnail.back}
              />
            </div>
          </div>
        ) : null}{" "}
      </div>

      <div className="mt-5 w-full flex justify-center">
        <div className="mr-3">
          <Button
            onClick={() => swapFace()}
            theme="pin"
            icon={faArrowAltSquareRight}
            loading={loading}
            label="Swap document faces"
          ></Button>
        </div>
        <div className="ml-3">
          <Button
            onClick={() => processAutoChecks()}
            theme="primary"
            loading={loading}
            label="Process Auto Checks"
          ></Button>
        </div>
      </div>


      <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">

      </div>
    </GlobalHotKeys>
  );
}
