import { CrosscheckContext } from '@/context/crosscheck'
import CrosscheckService from '@/services/crosscheck'
import { useContext, useEffect } from 'react'
import Tab from '../ui/Tab'

export default function CrosscheckTabs() {
	const [crosscheck, crosscheckDispatch] = useContext(CrosscheckContext)

	useEffect(async () => {
		await CrosscheckService.GetStats()
			.then((data) => {
				crosscheckDispatch({
					type: 'SET_STATS',
					payload: {
						stats: data,
					},
				})
			})
			.catch(() => {})
		return () => {}
	}, [])

	function setCurrentTab(currentTab) {
		crosscheckDispatch({
			type: 'SET_CURRENT_TAB',
			payload: {
				tab: currentTab,
			},
		})
	}

	return crosscheck.tabs.map((tab) => {
		return (
			<Tab
				badge={crosscheck.stats[tab.key]}
				selectedKey={crosscheck.selectedTab.key}
				key={tab.key}
				item={tab}
				onClick={() => setCurrentTab(tab)}></Tab>
		)
	})
}
