import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { FacematchVerificationContextProvider } from '@/context/facematch_verification'
import FacematchVerification from '@/components/layout/FacematchVerification'

function FacematchSession() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<FacematchVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<FacematchVerification></FacematchVerification>
					</div>
				</FacematchVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default FacematchSession
