import { AmlContext } from "@/context/aml";
import AmlService from "@/services/aml";
import { useContext, useEffect } from "react";
import Tab from "../ui/Tab";

export default function AmlTabs() {
  const [aml, amlDispatch] = useContext(AmlContext);

  useEffect(async () => {
    await AmlService.GetStats()
      .then((data) => {
        amlDispatch({
          type: "SET_STATS",
          payload: {
            stats: data,
          },
        });
      })
      .catch(() => {});
    return () => {};
  }, []);

  function setCurrentTab(currentTab) {
    amlDispatch({
      type: "SET_CURRENT_TAB",
      payload: {
        tab: currentTab,
      },
    });
  }

  return aml.tabs.map((tab) => {
    return (
      <Tab
        badge={aml.stats[tab.key]}
        selectedKey={aml.selectedTab.key}
        key={tab.key}
        item={tab}
        onClick={() => setCurrentTab(tab)}
      ></Tab>
    );
  });
}
