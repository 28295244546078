import { useContext, useState } from "react";
import SlidingPane from "react-sliding-pane";
import Button from "@/components/ui/Button";
import {
  faCalendar,
  faIdCard,
  faPastafarianism,
  faWrench,
  faUserSecret,
  faGavel,
  faPalette,
  faPassport,
  faClipboard,
  faLightbulb,
  faVectorSquare,
} from "@fortawesome/pro-duotone-svg-icons";
import { IdentityVerificationContext } from "@/context/identity_verification";
import * as Notification from "@/components/ui/Notification";
import IdentityService from "@/services/identity";
import { useHistory } from "react-router-dom";
import RejectCard from "../ui/RejectCard";

export default function RejectIdentitySidebar({ open, setOpen }) {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const [identityVerification] = useContext(IdentityVerificationContext);

  function updatePin(newPin) {
    if (pin === newPin) {
      setPin("");
    } else {
      setPin(newPin);
    }
  }

  async function rejectIdentity() {
    setLoading(true);
    await IdentityService.Reject(identityVerification.task.session_id, pin)
      .then(() => {
        setLoading(false);
        Notification.success("Identity rejected successfully");
        router.push("/verifications/identity");
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
    return () => {};
  }

  return (
    <>
      <SlidingPane
        className={"rounded-xl"}
        hideHeader={true}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <div className="h-full w-full rounded-xl">
          <form className="h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl">
            <div className="flex-1 h-0 overflow-y-auto">
              <div className="py-6 px-4 bg-red-500 sm:px-6 rounded-t-xl">
                <div className="flex items-center justify-between">
                  <h2
                    className="text-lg font-medium text-white"
                    id="slide-over-title"
                  >
                    Reject verification
                  </h2>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      onClick={() => setOpen(false)}
                      type="button"
                      className="bg-red-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="sr-only">Close panel</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-white">
                    Select the reason of rejection
                  </p>
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-between">
                <div className="px-4 py-2">
                  {identityVerification.verification.steps.current_step ===
                  "template_selection" ? (
                    <>
                      <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                        Template
                      </p>
                      <RejectCard
                        selected={pin}
                        setPin={(slug) => updatePin(slug)}
                        slug="INVALID_DOCUMENT_TYPE"
                        title="Invalid document type"
                        description="Document type is invalid"
                        icon={faIdCard}
                      ></RejectCard>
                      <RejectCard
                        selected={pin}
                        setPin={(slug) => updatePin(slug)}
                        slug="DOCUMENT_INVALID_FRONT_SIDE"
                        title="Invalid front document"
                        description="Front side document is invalid"
                        icon={faIdCard}
                      ></RejectCard>
                      <RejectCard
                        selected={pin}
                        setPin={(slug) => updatePin(slug)}
                        slug="DOCUMENT_INVALID_BACK_SIDE"
                        title="Invalid back document"
                        description="Back side document is invalid"
                        icon={faIdCard}
                      ></RejectCard>
                    </>
                  ) : null}

                  {identityVerification.verification.steps.current_step ===
                  "info_review" ? (
                    <>
                      {" "}
                      <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                        Info Review
                      </p>
                      <RejectCard
                        selected={pin}
                        setPin={(slug) => updatePin(slug)}
                        slug="RESTRICTED_NATIONALITY_TYPE"
                        title="Nationality not accepted"
                        description="Nationality not accepted by the application"
                        icon={faCalendar}
                      ></RejectCard>
                    </>
                  ) : null}

                  {identityVerification.verification.steps.current_step ===
                  "info_extraction" ? (
                    <> </>
                  ) : null}

                  {identityVerification.verification.steps.current_step ===
                  "mrz" ? (
                    <>
                      {" "}
                      <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                        Machine-Readable Zone (MRZ)
                      </p>
                      <RejectCard
                        selected={pin}
                        setPin={(slug) => updatePin(slug)}
                        slug="DOCUMENT_INCONSISTENT"
                        title="Inconsistent document"
                        description="Document contains inconsistencies"
                        icon={faWrench}
                      ></RejectCard>
                    </>
                  ) : null}

                  <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                    General
                  </p>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_COMPLIANCE"
                    title="Compliance"
                    description="Compliance requirement not meet"
                    icon={faGavel}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="INVALID_DOCUMENT_TYPE"
                    title="Invalid document type"
                    description="Document type is invalid"
                    icon={faIdCard}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_INVALID_FRONT_SIDE"
                    title="Invalid front document"
                    description="Front side document is invalid"
                    icon={faIdCard}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_INVALID_BACK_SIDE"
                    title="Invalid back document"
                    description="Back side document is invalid"
                    icon={faIdCard}
                  ></RejectCard>

                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="INVALID_DOCUMENT_TYPE"
                    title="Invalid document type"
                    description="Document type is invalid"
                    icon={faIdCard}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_INVALID_FRONT_SIDE"
                    title="Invalid front document"
                    description="Front side document is invalid"
                    icon={faIdCard}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_INVALID_BACK_SIDE"
                    title="Invalid back document"
                    description="Back side document is invalid"
                    icon={faIdCard}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="BLACK_WHITE_PICTURE"
                    title="Color"
                    description="Black & white picture are forbidden"
                    icon={faPalette}
                  ></RejectCard>

                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="INVALID_DOCUMENT"
                    title="Document"
                    description="The document is not a valid proof of identity"
                    icon={faPassport}
                  ></RejectCard>

                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="BAD_QUALITY"
                    title="Quality"
                    description="All the fields and features are not clearly visible"
                    icon={faClipboard}
                  ></RejectCard>

                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="DOCUMENT_HIDDEN"
                    title="Document"
                    description="Document not visible or part of the document is cropped"
                    icon={faVectorSquare}
                  ></RejectCard>

                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="BAD_ENVIRONMENT"
                    title="Environment"
                    description="Reflect or bad lightning on the document"
                    icon={faLightbulb}
                  ></RejectCard>

                  <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                    Final Rejection
                  </p>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="SPAM_REJECTION"
                    title="Spam"
                    description="Reject when the user sends too much wrong verification"
                    icon={faPastafarianism}
                  ></RejectCard>
                  <RejectCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="FORGED_REJECTION"
                    title="Forged"
                    description="Reject when the user sends a forged document"
                    icon={faUserSecret}
                  ></RejectCard>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0 px-4 py-4 flex justify-end">
              <Button
                onClick={() => setOpen(false)}
                theme="secondary"
                label="Cancel"
              ></Button>
              {pin !== "" ? (
                <Button
                  loading={loading}
                  className="ml-2"
                  onClick={() => rejectIdentity()}
                  theme="error"
                  label="Reject"
                ></Button>
              ) : null}
            </div>
          </form>
        </div>
      </SlidingPane>
    </>
  );
}
