import { useEffect, useState } from 'react'
import Moment from 'react-moment'
import Tooltip from '../ui/Tooltip'

export default function CrosscheckTaskItem(props) {
	const [, setOpen] = useState(false)

	useEffect(() => {
		if (props.user) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [props.user])

	return (
		<div className="w-full list-item">
			<a
				href={`/verifications/crosscheck/${props.item.task.session_id}`}
				className="flex-shrink-0 group block">
				<div className="flex justify-between items-center">
					<div className="ml-1">
						<div className="flex">
							<div>
								<Tooltip auto={true} content={props.item.app.name}>
									<span className="inline-block relative">
										<img
											className="h-10 w-10 rounded-lg relative top-1 border border-gray-100"
											src={props.item.app.logo_url}
											alt=""
										/>
									</span>
								</Tooltip>
							</div>
							<div className="mt-4">
								<p className="ml-4 text-sm font-medium text-gray-700 group-hover:text-gray-900">
									<span>{props.item.task.session_id}</span>
								</p>
							</div>
						</div>
					</div>
					<div className="text-right">
						<div className="flex items-center">
							<div>
								<div className="ml-3 mr-2">
									<p className="text-xs font-light cursor-default text-gray-700">
										<Moment unix fromNow>
											{props.item.task.creation_date / 1000}
										</Moment>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a>
		</div>
	)
}
