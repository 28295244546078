import { useReducer, createContext } from 'react'

export const FacematchVerificationContext = createContext()

function setSession(state, payload) {
	return {
		...payload,
	}
}

const reducer = (state, action) => {
	switch (action.type) {
	case 'SET_SESSION':
		return setSession(state, action.payload)
	default:
		return state
	}
}

export const FacematchVerificationContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, {})

	return (
		<FacematchVerificationContext.Provider value={[state, dispatch]}>
			{props.children}
		</FacematchVerificationContext.Provider>
	)
}
