import { useContext } from "react";
import { AuthContext } from "@/context/auth";
import {
  faCodeBranch,
  faFileInvoice,
  faHeadSide,
  faPassport,
  faStream,
  faUniversity,
  faUserFriends,
} from "@fortawesome/pro-duotone-svg-icons";
import DashboardItem from "./DashboardItem";
import StatsService from "@/services/stats";
import { useEffect, useState } from "react";

export default function DashboardTasks() {
  const [auth] = useContext(AuthContext);
  const [tasks, setStats] = useState([]);

  useEffect(() => {
    StatsService.RetreiveGlobalTasksStats()
      .then((data) => {
        setStats(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="flex flex-wrap items-stretch">
        {auth.iam.admin || auth.iam.identity ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Identity"
              path="/verifications/identity"
              theme="identity"
              amount={tasks.identity}
              icon={faPassport}
            ></DashboardItem>
          </div>
        ) : null}

        {auth.iam.admin || auth.iam.residency ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Residency"
              path="/verifications/residency"
              theme="residency"
              amount={tasks.residency}
              icon={faFileInvoice}
            ></DashboardItem>
          </div>
        ) : null}

        {auth.iam.admin || auth.iam.aml ? (
          <div className="dashboard-task">
            <DashboardItem
              label="AML"
              path="/verifications/aml"
              theme="aml"
              amount={tasks.aml}
              icon={faStream}
            ></DashboardItem>
          </div>
        ) : null}
        {auth.iam.admin || auth.iam.corporate ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Corporate"
              path="/verifications/corporate"
              theme="corporate"
              amount={tasks.corporate}
              icon={faUniversity}
            ></DashboardItem>
          </div>
        ) : null}
        {auth.iam.admin || auth.iam.facematch ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Facematch"
              path="/verifications/facematch"
              theme="facematch"
              amount={tasks.facematch}
              icon={faHeadSide}
            ></DashboardItem>
          </div>
        ) : null}
        {auth.iam.admin || auth.iam.duplicate ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Dupplicate"
              path="/verifications/duplicate"
              theme="dupplicate"
              amount={tasks.duplicate}
              icon={faUserFriends}
            ></DashboardItem>
          </div>
        ) : null}
        {auth.iam.admin || auth.iam.crosscheck ? (
          <div className="dashboard-task">
            <DashboardItem
              label="Crosscheck"
              path="/verifications/crosscheck"
              theme="crosscheck"
              amount={tasks.crosscheck}
              icon={faCodeBranch}
            ></DashboardItem>
          </div>
        ) : null}
      </div>
    </div>
  );
}
