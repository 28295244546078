import { PanelAuth } from "@/services/axios";

export default {
  GetLastApps() {
    return PanelAuth.get(`${process.env.REACT_APP_PANEL_HOST}/v3/apps/last`);
  },
  GetApps(page, search) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/list?page=${page}&search=${search}`
    );
  },
  UpdateAppStatus(body) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/status`,
      body
    );
  },
  GetAppDetails(appKey) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/details/${appKey}`
    );
  },
  GetAppExport(appKey) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/export/${appKey}`
    );
  },
  EnableAnima(appKey, id, name, description) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/did/${appKey}?protocol=ANIMA&id=${id}&name=${name}&description=${description}`,
      {}
    );
  },
  UpdateApp(body, appKey) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/apps/update/${appKey}`,
      body
    );
  },
};
