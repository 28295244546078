import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "@/context/auth";

export default function AuthGuard({ children }) {
  const [auth] = useContext(AuthContext);

  let redirectURI = "/login";

  if (window.location.pathname !== "/") {
    const pathName = encodeURIComponent(window.location.pathname);
    redirectURI = `/login?redirect_uri=${pathName}`;
  }

  return auth.authenticated ? children : <Redirect to={redirectURI} />;
}
