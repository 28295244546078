import { faLink, faAt, faArrowLeft, faQuestionCircle, faPen } from '@fortawesome/pro-solid-svg-icons'
import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@/components/ui/Tooltip'
import MiniButton from '@/components/ui/MiniButton'
import { SessionContext } from '@/context/session'
import { useHistory } from 'react-router-dom'
import AppSidebar from '@/components/sessions/IndividualAppSidebar'
import { AuthContext } from '@/context/auth'
import UpdateValue from '@/components/sessions/UpdateValue'
import * as Notification from '@/components/ui/Notification'
import SupportService from '@/services/support'

export default function IdentityVerificationHeader(props) {
    const [individual] = useContext(SessionContext)
    const { session_id } = useParams()
    const router = useHistory()
    const [appOpen, setAppOpen] = useState(false)
    const [auth] = useContext(AuthContext)
    const [manageable, setManageable] = useState(false)

    async function UpdateAlias(value) {
        let body = {
            value: value,
            type: 'ALIAS',
            session_id: session_id
        }
        await SupportService.UpdateValue(body).then(async (data) => {
            Notification.success('Alias updated')
            props.onRefresh()
            setManageable(false)
        }).catch((err) => {
            Notification.error(err.message)
            setManageable(false)
        })
    }

    return (
        <>
            {individual.ready ? <div className="pb-3">
                <div className="flex justify-between">
                    <div className="flex">
                        <MiniButton className="mr-1.5" onClick={() => router.push('/support')} currentBg="primary" theme="secondary" icon={faArrowLeft}
                            label="Back"></MiniButton>
                        <div className="ml-3 sm:ml-0">
                            <img
                                className="h-12 rounded-lg"
                                src={individual.app.logo_url}></img>
                        </div>
                        <div className="ml-3 relative">
                            <p className="text-white text-sm font-bold">
                                {individual.app.name}
                            </p>
                            <h1 className="text-sm text-white flex">
                                <Tooltip auto={true} content={'Session ID'}>
                                    <span>
                                        <FontAwesomeIcon className="text-bluegray-500" icon={faLink} /> {session_id}
                                    </span>
                                </Tooltip>
                                {individual.alias ? <> <Tooltip auto={true} content={'Alias'}>
                                    <>
                                        <span>
                                            <FontAwesomeIcon className="ml-2 text-bluegray-500" icon={faAt} /> {individual.alias}
                                            {auth.iam.admin === true || auth.iam.support_manager === true ?
                                                <FontAwesomeIcon onClick={() => setManageable(true)} className="text-regular font-bold text-sm text-gray-500 cursor-pointer hover:text-white transition duration-100 ease-in ml-2 text-bluegray-500" icon={faPen}></FontAwesomeIcon>
                                                : null
                                            }
                                        </span>
                                    </>
                                </Tooltip>

                                </>
                                    : null}
                            </h1>
                        </div>
                    </div>
                    <div className="mt-4 sm:mt-0">
                        <MiniButton className="mr-1.5" onClick={() => setAppOpen(true)} currentBg="primary" theme="pin" icon={faQuestionCircle}
                            label="App Info"></MiniButton>
                    </div>
                </div>

                <AppSidebar
                    open={appOpen}
                    setOpen={setAppOpen}></AppSidebar>
                <UpdateValue open={manageable}
                    onClose={() => setManageable(false)}
                    onUpdate={(value) => UpdateAlias(value)}
                    value="Alias"
                    old={individual.alias}
                    icon={faAt}>
                </UpdateValue>
            </div> : null}
        </>
    )
}
