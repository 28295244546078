import React, { useState, useCallback, useRef, useEffect, createRef } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import Upload from '@/components/ui/Upload'

export default function UploadLogo(props) {
	const [upImg, setUpImg] = useState()
	const [firstRender, setFirstRender] = useState(true);
	const imgRef = useRef(null)
	const divRef = useRef(null)
	const previewCanvasRef = useRef(null)
	const [crop, setCrop] = useState({ height: 100, width: 100, aspect: 1 })
	const [completedCrop, setCompletedCrop] = useState(null)
	const [file, setFile] = useState(null)

	function onSelectFile(file) {
		const reader = new FileReader()
		setFirstRender(true);
		reader.addEventListener('load', () => setUpImg(reader.result))
		reader.readAsDataURL(file)
		setFile(true)
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img;
	}, []);

	useEffect(() => {
		if (!imgRef.current) return;

		const x = ((divRef.current?.offsetWidth ?? 0) - imgRef.current.width) / 2;

		setCrop({ x, y: 0, width: imgRef.current.width, height: imgRef.current.height, aspect: 1 });
	}, [imgRef.current, divRef, setCrop]);

	useEffect(() => {
		if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
			return
		}

		setFirstRender(false);

		const image = imgRef.current
		const canvas = previewCanvasRef.current
		const crop = completedCrop

		const leftBorder = ((divRef.current?.offsetWidth ?? 0) - imgRef.current.width) / 2;

		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		const ctx = canvas.getContext('2d')
		const pixelRatio = window.devicePixelRatio

		canvas.width = crop.width * pixelRatio
		canvas.height = crop.height * pixelRatio

		ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
		ctx.imageSmoothingQuality = 'high'

		ctx.drawImage(
			image,
			crop.x * scaleX - (firstRender ? 0 : leftBorder),
			crop.y * scaleY,
			(firstRender ? image.width : crop.width) * scaleX,
			(firstRender ? image.height : crop.height) * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)


		canvas.toBlob(
			(blob) => {
				props.setLogo(blob)
				// props.setLogoSize(blob.size)
			},
			'image/png',
			1
		)
	}, [completedCrop])

	function removeFile() {
		setFile(false)
		props.setLogo(null)
	}

	return (
		<div className="App">
			<div>
				{!file ? <Upload acceptedFileTypes={[
					'image/jpeg',
					'image/png',
					'image/jpg',
				]}
					setFile={onSelectFile} /> : null}
			</div>

			{file ?
				<>
					<div className="flex flex-wrap">
						<div className="w-2/3">
							<div className="flex justify-between">
								<div>
									<label
										className="block text-sm font-medium text-primary-700">
										Square Logo
									</label>
								</div>
								<div>
									<label onClick={() => removeFile()} className="block text-sm font-medium cursor-pointer text-red-600">
										Remove
									</label>
								</div>
							</div>
							<div className="mt-2" ref={divRef}>
								<ReactCrop
									crop={crop}
									onChange={(c) => {
										console.log("crop");
										setCrop(c);
									}}
									onComplete={(c) => {
										console.log("complete")
										setCompletedCrop(c)
									}}
									src={upImg}
									onImageLoaded={onLoad}
								/>
							</div>
						</div>
						<div className="w-1/3 pl-3">
							<label
								className="block text-sm font-medium text-primary-700">
								Preview
							</label>
							<div className="mt-2">
								<canvas className="border border-gray-400 rounded-xl"
									ref={previewCanvasRef}
									style={{
										width: 150,
										height: 150
									}}
								/>
							</div>
						</div>
					</div>

				</> : null}
		</div>
	)
}
