import { useContext, useEffect, useState } from 'react'
import Footer from '@/components/menu/Footer'
import SubNav from '@/components/menu/SubNav'
import CorporateVerificationHeader from '@/components/sessions/corporate/CorporateVerificationHeader'
import CorporateSessionSidebar from '@/components/sessions/corporate/CorporateSessionSidebar'
import CorporateService from '@/services/corporate'
import CorporateCore from '@/components/sessions/corporate/CorporateCore'
import { SessionContext } from '@/context/session'
import { useParams } from 'react-router-dom'


export default function CorporateSessionRessource() {
    const [session, sessionDispatch] = useContext(SessionContext)
    const { session_id } = useParams()

    const [open] = useState(false)
    const [flowKey, setFlowKey] = useState(0)


    useEffect(() => {
        GetCorporateResource()
        return () => { }
    }, [])

    async function GetCorporateResource() {
        await sessionDispatch({
            type: 'SET_READY',
            payload: false,
        })
        await CorporateService.GetCorporateResource(session_id).then(async (data) => {
            console.log('data')
            console.log(data)
            await sessionDispatch({
                type: 'SET_DATA',
                payload: data,
            })
        }).catch((err) => {
            console.log(err.message)
        })
    }

    useEffect(() => {
        if (!open) {
            setFlowKey(flowKey + 1)
        }
    }, [open])

    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className='max-w-7xl mx-auto relative'>
                    <div className='dashboard-container px-3'>
                        <CorporateVerificationHeader onRefresh={() => GetCorporateResource()} />
                        <div className="flex mt-2">
                            <div className="w-3/12 pr-2">
                                <CorporateSessionSidebar />
                            </div>
                            <div className="w-9/12 pl-2">
                                {session.ready ? <CorporateCore onRefresh={() => GetCorporateResource()} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-7xl mx-auto relative'>
                <Footer></Footer>
            </div>
        </div>
    )
}
