import { useContext, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faClipboardCheck,
  faLightbulbOn,
  faPalette,
  faPassport,
  faRedoAlt,
  faUndoAlt,
  faVectorSquare,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import Tooltip from "@/components/ui/Tooltip";
import VisualCheck from "./visual-check/VisualCheck";
import VisualCheckStep from "./visual-check/VisualCheckStep";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";

export default function IdentityVisualCheck(props) {
  const [completed, setCompleted] = useState(false);
  const [toIncomplete, setToIncomplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [frontRotation, setFrontRotation] = useState(0);
  const [backRotation, setBackRotation] = useState(0);
  const [currentItem, setCurrentItem] = useState("COLOR_PICTURE");
  const router = useHistory();
  const [checkList, setCheckList] = useState({
    COLOR_PICTURE: {
      slug: "COLOR_PICTURE",
      title: "Color Picture",
      content:
        "Check that current document is colored. Black & white picture are forbidden.",
      icon: faPalette,
      reviewed: false,
      state: false,
    },
    INVALID_DOCUMENT: {
      slug: "INVALID_DOCUMENT",
      title: "Valid Document",
      content: "Make sure the document is a valid proof of identity",
      icon: faPassport,
      reviewed: false,
      state: false,
    },
    GOOD_QUALITY: {
      slug: "GOOD_QUALITY",
      title: "Good Quality",
      content:
        "Make sure that all the fields and features are clearly visible.",
      icon: faClipboardCheck,
      reviewed: false,
      state: false,
    },
    GOOD_ENVIRONMENT: {
      slug: "GOOD_ENVIRONMENT",
      title: "Good Environment",
      content:
        "Check that there is no reflect or bad lightning on the document.",
      icon: faLightbulbOn,
      reviewed: false,
      state: false,
    },
    FULL_DOCUMENT_VISIBLE: {
      slug: "FULL_DOCUMENT_VISIBLE",
      title: "4 corners visible",
      content:
        "Make sure that all the document is visible and no part of the document is cropped",
      icon: faVectorSquare,
      reviewed: false,
      state: false,
    },
  });

  function pushCurrentItem() {
    let selectedItem = null;
    Object.keys(checkList).map((item) => {
      if (checkList[item].reviewed === false) {
        if (selectedItem === null) {
          selectedItem = item;
        }
      }
    });
    if (selectedItem === null) {
      setCompleted(true);
      setCurrentItem(selectedItem);
    } else {
      setCurrentItem(selectedItem);
    }
  }

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function setVisualCheck() {
    const body = {
      color_picture: checkList["COLOR_PICTURE"].state,
      good_quality: checkList["GOOD_QUALITY"].state,
      good_environment: checkList["GOOD_ENVIRONMENT"].state,
      full_document_visible: checkList["FULL_DOCUMENT_VISIBLE"].state,
      invalid_document: checkList["INVALID_DOCUMENT"].state,
    };
    setLoading(true);
    await IdentityService.SetVisualCheck(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function setRejectVisualCheck() {
    const body = {
      color_picture: checkList["COLOR_PICTURE"].state,
      good_quality: checkList["GOOD_QUALITY"].state,
      good_environment: checkList["GOOD_ENVIRONMENT"].state,
      full_document_visible: checkList["FULL_DOCUMENT_VISIBLE"].state,
      invalid_document: checkList["INVALID_DOCUMENT"].state,
    };
    setLoading(true);
    await IdentityService.SetVisualCheck(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        Notification.success("Identity rejected successfully");
        router.push("/verifications/identity");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  function setToInvalid(event) {
    event.preventDefault();
    putItem(currentItem, false);
  }

  function setToValid(event) {
    event.preventDefault();
    if (completed) {
      setVisualCheck();
    } else {
      putItem(currentItem, true);
    }
  }

  function putItem(item, result) {
    checkList[item].reviewed = true;
    checkList[item].state = result;
    if (result === false) {
      setToIncomplete(true);
    }
    setCheckList(checkList);
    pushCurrentItem();
  }

  function rotateRightFront() {
    if (frontRotation + 90 === 360) {
      setFrontRotation(0);
    } else {
      setFrontRotation(frontRotation + 90);
    }
  }

  function rotateLeftFront() {
    if (frontRotation - 90 === -90) {
      setFrontRotation(270);
    } else {
      setFrontRotation(frontRotation - 90);
    }
  }

  function rotateRightBack() {
    if (backRotation + 90 === 360) {
      setBackRotation(0);
    } else {
      setBackRotation(backRotation + 90);
    }
  }

  function rotateLeftBack() {
    if (backRotation - 90 === -90) {
      setBackRotation(270);
    } else {
      setBackRotation(backRotation - 90);
    }
  }

  async function swapFace() {
    setLoading(true);
    await IdentityService.SwapDocumentFace(identityVerification.task.session_id)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  const handlers = {
    DEL: setToInvalid,
    SELECT: setToValid,
  };

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle
            value="Document Visual Check"
            icon={faPassport}
          ></StepTitle>
        </div>
      </div>
      <div className="flex flex-wrap mx-3">
        <div className="w-1/2">
          <div className="mx-auto">
            <div className="flex justify-between">
              <div>
                <p className="ml-8">Front</p>
              </div>
              <div className="mr-8">
                <Tooltip auto={true} content="Rotate Left">
                  <button
                    onClick={() => rotateLeftFront()}
                    className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faUndoAlt}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
                <Tooltip auto={true} content="Rotate Right">
                  <button
                    onClick={() => rotateRightFront()}
                    className="mb-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                  >
                    <FontAwesomeIcon
                      className="outline-none"
                      icon={faRedoAlt}
                    ></FontAwesomeIcon>
                  </button>
                </Tooltip>
              </div>
            </div>
            <img
              draggable={false}
              className={`object-contain h-128 w-full rotate-${frontRotation}`}
              src={identityVerification.media.thumbnail.front}
            />
          </div>
        </div>
        {identityVerification.verification.document.type !== "PASSPORT" ? (
          <div className="w-1/2">
            <div>
              <div className="flex justify-between">
                <div className="flex">
                  <p className="ml-8">Back</p>
                </div>
                <div className="mr-8">
                  <Tooltip auto={true} content="Rotate Left">
                    <button
                      onClick={() => rotateLeftBack()}
                      className="mb-3 mr-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                    >
                      <FontAwesomeIcon
                        className="outline-none"
                        icon={faUndoAlt}
                      ></FontAwesomeIcon>
                    </button>
                  </Tooltip>
                  <Tooltip auto={true} content="Rotate Right">
                    <button
                      onClick={() => rotateRightBack()}
                      className="mb-3 cursor-pointer text-primary-500 focus:outline-none hover:opacity-75 transition duration-100 ease-in"
                    >
                      <FontAwesomeIcon
                        className="outline-none"
                        icon={faRedoAlt}
                      ></FontAwesomeIcon>
                    </button>
                  </Tooltip>
                </div>
              </div>
              <img
                draggable={false}
                className={`object-contain h-128 w-full rotate-${backRotation}`}
                src={identityVerification.media.thumbnail.back}
              />
            </div>
          </div>
        ) : null}{" "}
      </div>

      {identityVerification.verification.document.type !== "PASSPORT" ? (
        <div className="mt-3 w-full flex justify-center">
          <Button
            onClick={() => swapFace()}
            theme="primary"
            label="Swap Face"
          ></Button>
        </div>
      ) : null}

      <div className="flex">
        <div className="w-full px-12 mt-8">
          {Object.keys(checkList).map((item) => {
            return (
              <VisualCheck
                onSet={(result) => putItem(item, result)}
                key={item}
                active={currentItem === checkList[item].slug}
                valid={checkList[item].state}
                reviewed={checkList[item].reviewed}
                title={checkList[item].title}
                content={checkList[item].content}
              ></VisualCheck>
            );
          })}
        </div>
      </div>

      <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div>
          {Object.keys(checkList).map((item) => {
            return (
              <VisualCheckStep
                onSet={(result) => putItem(item, result)}
                key={item}
                active={currentItem === checkList[item].slug}
                valid={checkList[item].state}
                reviewed={checkList[item].reviewed}
                title={checkList[item].title}
                icon={checkList[item].icon}
                content={checkList[item].content}
              ></VisualCheckStep>
            );
          })}
        </div>
        <div>
          {toIncomplete ? (
            <Button
              loading={loading}
              onClick={() => setRejectVisualCheck()}
              disabled={!completed}
              icon={faTimes}
              full
              label="Reject"
              theme="error"
            ></Button>
          ) : (
            <Button
              loading={loading}
              onClick={() => setVisualCheck()}
              disabled={!completed}
              icon={faCheck}
              full
              label="Next"
              theme="success"
            ></Button>
          )}
        </div>
      </div>
    </GlobalHotKeys>
  );
}
