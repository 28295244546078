import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import FacematchTasksPage from '@/components/pages/FacematchTasksPage'
import { FacematchContextProvider } from '@/context/facematch'

function Facematch() {
	return (
		<AuthGuard>
			<IamGuard page='facematch'>
				<FacematchContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<FacematchTasksPage></FacematchTasksPage>
					</div>
				</FacematchContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default Facematch
