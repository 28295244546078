import LoginLayout from "../components/layout/LoginLayout";
import { LoginContextProvider } from "@/context/login";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import PublicGuard from "@/guard/public";
import { useEffect } from "react";

export default function LoginPage(props) {
  return (
    <LoginContextProvider>
      <PublicGuard>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        >
          <LoginLayout>{props.children}</LoginLayout>
        </GoogleReCaptchaProvider>
      </PublicGuard>
    </LoginContextProvider>
  );
}
