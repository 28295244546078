import Label from '../ui/Label'
import RegistryFeatureInfoSpecs from './RegistryFeatureInfoSpecs'
import RegistryService from '@/services/registry'
import * as Notification from '@/components/ui/Notification'

export default function RegistryHeaderPersonalInfo(props) {
	async function deleteInfo() {
		const body = {
			slug: props.data.slug,
			document_slug: props.document,
		}

		await RegistryService.DeleteInfo(body)
			.then(async () => {
				Notification.success(`${props.value} successfully deleted`)
				props.onDelete()
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => {}
	}

	return (
		<div className={'h-full'}>
			<div className={'bg-white p-3 rounded-xl  border border-gray-200'}>
				<div className="flex justify-between">
					<div>
						<div
							className="h-2 rounded-xl w-8 mt-2 mb-2"
							style={{
								backgroundColor: `#${props.data.color}`,
							}}></div>
					</div>
					<div className="relative top--2px">
						<div>
							<RegistryFeatureInfoSpecs
								onDelete={() => deleteInfo()}
								onSet={() => props.onSet()}
								data={props.data}></RegistryFeatureInfoSpecs>
						</div>
					</div>
				</div>
				<div className="flex justify-between">
					<div>
						<Label value={props.value}></Label>
					</div>
				</div>
			</div>
		</div>
	)
}
