export default function TasksPlaceholder() {
	return (
		<>
			<div className="animate-pulse">
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="list-item p-3">
					<div className="flex">
						<div>
							<div className="h-8 w-8 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-48 mt-1 h-2 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 mt-1.5 h-2 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
