import { useCallback, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'

export default function Cropper(props) {
	const imgRef = useRef(null)
	const [loaded, setLoaded] = useState(false)
	const [crop, setCrop] = useState()
	const [croppedImageUrl, setCroppedImageUrl] = useState(null)

	async function setCoordinates(c) {
		const coordinates = {
			top_left: {
				x: (c.x) / imgRef.current.width,
				y: (c.y) / imgRef.current.height,
			},
			top_right: {
				x: (c.x + c.width) / imgRef.current.width,
				y: (c.y) / imgRef.current.height,
			},
			bottom_left: {
				x: (c.x) / imgRef.current.width,
				y: (c.y + c.height) / imgRef.current.height,
			},
			bottom_right: {
				x: (c.x + c.width) / imgRef.current.width,
				y: (c.y + c.height) / imgRef.current.height,
			},
			width: c.width / imgRef.current.width,
			height: c.height / imgRef.current.height,
		}

		console.log('---> Coordinates')
		console.log(coordinates)

		if (loaded && coordinates.width > 0.005 && coordinates.height > 0.005) {
			props.setPosition(coordinates)
		}
	}

	const onLoad = useCallback((img) => {
		imgRef.current = img
		setLoaded(true)
		return () => { }
	}, [])

	const onCropComplete = crop => {
		makeClientCrop(crop)
	}

	async function makeClientCrop(crop) {
		const croppedImageUrl = await getCroppedImg(
			imgRef.current,
			crop,
			'newFile.jpeg'
		)
		setCroppedImageUrl(croppedImageUrl)
	}

	function getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / image.width
		const scaleY = image.naturalHeight / image.height
		canvas.width = crop.width
		canvas.height = crop.height
		const ctx = canvas.getContext('2d')

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		)

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'))
					console.error('Canvas is empty')
					return
				}
				blob.name = fileName
				let fileUrl = ''
				window.URL.revokeObjectURL(fileUrl)
				fileUrl = window.URL.createObjectURL(blob)
				resolve(fileUrl)
			}, 'image/jpeg')
		})
	}

	return (
		<div className='App'>
			<ReactCrop
				locked={false}
				src={props.src}
				onImageLoaded={onLoad}
				crop={crop}
				className="synaps-crop"
				onChange={(_, percentCrop) => setCrop(percentCrop)}
				onComplete={(percentCrop) => setCoordinates(percentCrop)}
			/>
			{croppedImageUrl && (
				<img alt="Crop" src={croppedImageUrl} />
			)}
		</div>

	)
}
