import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "@/pages/login";
import NotFoundPage from "@/pages/not-found";
import Dashboard from "@/pages/dashboard";
import Registry from "@/pages/registry";
import RegistryCountry from "@/pages/registry/country";
import RegistryCountrySlug from "@/pages/registry/country/slug";
import Apps from "@/pages/apps";
import Settings from "@/pages/settings";
import ManagersIndex from "@/pages/managers";
import AgentsIndex from "@/pages/agents/index";
import SupportIndex from "@/pages/support/index";
import AppIndex from "@/pages/apps/index";
import AgentsKey from "@/pages/agents/key";
import CorporatesIndex from "@/pages/corporates";
import NetworkIndex from "@/pages/network/index";
import NetworkProjectIndex from "@/pages/network/project/index";
import VerificationResidency from "@/pages/verifications/residency";
import VerificationIdentity from "@/pages/verifications/identity";
import VerificationFacematch from "@/pages/verifications/facematch";
import VerificationDuplicate from "@/pages/verifications/duplicate";
import VerificationCrosscheck from "@/pages/verifications/crosscheck";
import VerificationCorporate from "@/pages/verifications/corporate";
import VerificationAml from "@/pages/verifications/aml";
import VerificationResidencySession from "@/pages/verifications/residency/session";
import VerificationIdentitySession from "@/pages/verifications/identity/session";
import VerificationFacematchSession from "@/pages/verifications/facematch/session";
import VerificationDuplicateSession from "@/pages/verifications/duplicate/session";
import VerificationCrosscheckSession from "@/pages/verifications/crosscheck/session";
import VerificationCorporateSession from "@/pages/verifications/corporate/session";
import VerificationAmlSession from "@/pages/verifications/aml/session";
import { ToastContainer } from "react-toastify";
import { AuthContext, AuthContextProvider } from "@/context/auth";
import { useEffect, useState, useContext } from "react";
import AppIndividualSession from "@/pages/sessions/individual_session";
import AppCorporateSession from "@/pages/sessions/corporate_session";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import { b64Decode } from "@/lib/b64";
import LogRocket from "logrocket";

function AuthCore(props) {
  const [, authDispatch] = useContext(AuthContext);
  const [ready, setReady] = useState(false);
  const router = useHistory();

  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID || "", {
    dom: {
      inputSanitizer: true,
    },
  });

  useEffect(() => {
    const s_auth = Cookie.get("s_auth");
    if (s_auth == "" || s_auth == null) {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login"
      ) {
        const pathName = encodeURIComponent(window.location.pathname);
        router.push(`/login?redirect_uri=${pathName}`);
      } else {
        router.push("/login");
      }
      setReady(true);
      return;
    }
    setReady(true);

    const sAuth = JSON.parse(b64Decode(s_auth));

    authDispatch({
      type: "SET_AUTH",
      payload: sAuth,
    });
    setReady(true);
  }, []);

  return ready ? props.children : null;
}

function App() {
  return (
    <AuthContextProvider>
      <Router>
        <AuthCore>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/settings" component={Settings} />

            {/* Registry */}
            <Route exact path="/registry" component={Registry} />
            <Route
              exact
              path="/registry/:country"
              component={RegistryCountry}
            />
            <Route
              exact
              path="/registry/:country/:slug"
              component={RegistryCountrySlug}
            />

            {/* Apps */}
            <Route exact path="/apps" component={Apps} />
            <Route exact path="/apps/:app" component={AppIndex} />

            {/* Managers */}
            <Route exact path="/support" component={SupportIndex} />

            {/* Managers */}
            <Route exact path="/network" component={NetworkIndex} />
            <Route
              exact
              path="/network/:project"
              component={NetworkProjectIndex}
            />

            {/* Managers */}
            <Route exact path="/managers" component={ManagersIndex} />

            {/* Corporates */}
            <Route exact path="/corporates" component={CorporatesIndex} />

            {/* Agents */}
            <Route exact path="/agents" component={AgentsIndex} />
            <Route exact path="/agents/:key" component={AgentsKey} />
            {/* Verifications */}
            <Route
              exact
              path="/verifications/residency"
              component={VerificationResidency}
            />
            <Route
              exact
              path="/verifications/residency/:session_id"
              component={VerificationResidencySession}
            />
            <Route
              exact
              path="/verifications/identity"
              component={VerificationIdentity}
            />
            <Route
              exact
              path="/verifications/identity/:session_id"
              component={VerificationIdentitySession}
            />
            <Route
              exact
              path="/verifications/facematch"
              component={VerificationFacematch}
            />
            <Route
              exact
              path="/verifications/facematch/:session_id"
              component={VerificationFacematchSession}
            />
            <Route
              exact
              path="/verifications/duplicate"
              component={VerificationDuplicate}
            />
            <Route
              exact
              path="/verifications/duplicate/:session_id"
              component={VerificationDuplicateSession}
            />
            <Route
              exact
              path="/verifications/crosscheck"
              component={VerificationCrosscheck}
            />
            <Route
              exact
              path="/verifications/crosscheck/:session_id"
              component={VerificationCrosscheckSession}
            />
            <Route
              exact
              path="/verifications/corporate"
              component={VerificationCorporate}
            />
            <Route
              exact
              path="/verifications/corporate/:session_id"
              component={VerificationCorporateSession}
            />
            <Route
              exact
              path="/verifications/aml"
              component={VerificationAml}
            />
            <Route
              exact
              path="/verifications/aml/:session_id"
              component={VerificationAmlSession}
            />

            <Route
              exact
              path="/individual/:session_id"
              component={AppIndividualSession}
            />
            <Route
              exact
              path="/corporate/:session_id"
              component={AppCorporateSession}
            />

            <Route path="*" component={NotFoundPage} />
          </Switch>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </AuthCore>
      </Router>
    </AuthContextProvider>
  );
}

export default App;
