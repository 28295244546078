import HeaderButton from "@/components/ui/HeaderButton";
import Card from "@/components/ui/Card";
import {
  faAddressCard,
  faAsterisk,
  faBirthdayCake,
  faCabinetFiling,
  faCar,
  faClock,
  faFlag,
  faHistory,
  faIdCard,
  faPassport,
  faStream,
  faUndo,
  faThumbtack,
  faGlobeEurope,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@/components/ui/Tooltip";
import CountryFlag from "@/components/ui/CountryFlag";
import IdentitySteps from "./IdentitySteps";
import { useContext, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import IdentityHeaderItem from "@/components/identity/IdentityHeaderItem";
import PinIdentitySidebar from "@/components/identity/PinIdentitySidebar";
import AlertPin from "@/components/ui/AlertPin";
import IdentityService from "@/services/identity";
import * as Notification from "@/components/ui/Notification";
import EditDocumentTypeSidebar from "@/components/identity/EditDocumentTypeSidebar";
import EditDocumentCountry from "@/components/identity/EditDocumentCountry";
import ActivityIdentitySidebar from "@/components/identity/ActivityIdentitySidebar";
import RejectIdentitySidebar from "@/components/identity/RejectIdentitySidebar";
import IdentityHistory from "@/components/identity/IdentityHistory";

export default function IdentityVerificationHeader() {
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [pinOpen, setPinOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [undoLoading, setUndoLoading] = useState(false);
  const [editDocumentOpen, setEditDocumentOpen] = useState(false);
  const [editCountryOpen, setEditCountryOpen] = useState(false);
  const [activityOpen, setActivityOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);

  async function UndoStep() {
    setUndoLoading(true);
    await IdentityService.Undo(identityVerification.task.session_id)
      .then(() => {
        setUndoLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setUndoLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  async function UnpinTask() {
    await IdentityService.UnpinTask(identityVerification.task.session_id)
      .then(async () => {
        window.location.reload();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <>
      <div className="flex flex-wrap justify-between pb-3">
        <div className="flex">
          <div className="ml-3 sm:ml-0">
            <img
              className="h-8 rounded-lg"
              src={identityVerification.app.logo_url}
            ></img>
          </div>
          <div className="ml-2">
            <p className="text-white text-2xs uppercase font-bold">
              {identityVerification.app.name}
            </p>
            <h1 className="text-2xs text-white">
              {identityVerification.task.session_id}
            </h1>
          </div>
        </div>
        <div className="flex flex-wrap mt-4 sm:mt-0">
          <HeaderButton
            loading={undoLoading}
            onClick={() => UndoStep()}
            currentBg="primary-500"
            icon={faUndo}
            type="primary"
            label="Undo"
          ></HeaderButton>
          <div className="ml-2">
            <HeaderButton
              onClick={() => setActivityOpen(true)}
              currentBg="primary-500"
              icon={faStream}
              badge={identityVerification.task.activity.length}
              type="primary"
              label="Activity"
            ></HeaderButton>
          </div>
          <div className="ml-3">
            <HeaderButton
              onClick={() => setHistoryOpen(true)}
              currentBg="primary-500"
              icon={faHistory}
              badge={
                identityVerification.history
                  ? identityVerification.history.length
                  : 0
              }
              type="secondary"
              label={"History"}
            ></HeaderButton>
          </div>
          <div className="ml-3">
            {identityVerification.task.pin === false ? (
              <HeaderButton
                onClick={() => setPinOpen(true)}
                currentBg="primary-500"
                icon={faThumbtack}
                type="pin"
                label="Pin"
              ></HeaderButton>
            ) : (
              <HeaderButton
                currentBg="primary-500"
                onClick={() => UnpinTask()}
                icon={faThumbtack}
                type="pin"
                label="Unpin"
              ></HeaderButton>
            )}
          </div>
          <div className="ml-3">
            <HeaderButton
              currentBg="primary-500"
              icon={faTimes}
              type="error"
              onClick={() => setRejectOpen(true)}
              label="Reject"
            ></HeaderButton>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap">
        <Card className="w-full px-3 sm:px-0">
          <div className="flex p-4 justify-between">
            <div className="flex">
              <div className="p-3 ml-2">
                <h3 className="text-2xl">
                  <Tooltip auto={true} content="Firstname">
                    <span className="font-light">
                      {identityVerification.verification.details.info.firstname
                        .value
                        ? identityVerification.verification.details.info
                            .firstname.value
                        : "-"}{" "}
                    </span>
                  </Tooltip>
                  <Tooltip auto={true} content="Lastname">
                    <span className="font-bold">
                      {identityVerification.verification.details.info.lastname
                        .value
                        ? identityVerification.verification.details.info
                            .lastname.value
                        : "-"}
                    </span>
                  </Tooltip>
                </h3>
                <div className="flex">
                  <div>
                    <div className="mt-2">
                      <Tooltip auto={true} content="Birth Date">
                        <div>
                          <FontAwesomeIcon
                            className="mr-2 text-gray-400"
                            icon={faBirthdayCake}
                          ></FontAwesomeIcon>
                          {identityVerification.verification.details.info
                            .birth_date.value
                            ? identityVerification.verification.details.info
                                .birth_date.value
                            : "-"}
                        </div>
                      </Tooltip>
                    </div>
                    {identityVerification.verification.document.type !==
                    "DRIVER_LICENSE" ? (
                      <div className="mt-2">
                        <Tooltip auto={true} content="Nationality">
                          <span className="relative top-2px mr-1">
                            {identityVerification.verification.details.info
                              .nationality.value !== "" ? (
                              <CountryFlag
                                className="h-4 w-4 mr-2 relative top--2px rounded"
                                country={
                                  identityVerification.verification.details.info
                                    .nationality.value
                                }
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="mr-2 text-gray-400"
                                icon={faFlag}
                              ></FontAwesomeIcon>
                            )}
                            {identityVerification.verification.details.info
                              .nationality.value
                              ? identityVerification.verification.details.info
                                  .nationality.value
                              : "-"}
                          </span>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-xl h-full md:w-4/6 w-full">
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/3">
                  {identityVerification.verification.document.type ===
                  "PASSPORT" ? (
                    <IdentityHeaderItem
                      label="Document Type"
                      value="Passport"
                      editable
                      onEdit={() => setEditDocumentOpen(true)}
                      icon={faPassport}
                    ></IdentityHeaderItem>
                  ) : null}
                  {identityVerification.verification.document.type ===
                  "NATIONAL_ID" ? (
                    <IdentityHeaderItem
                      label="Document Type"
                      value="National ID"
                      editable
                      onEdit={() => setEditDocumentOpen(true)}
                      icon={faIdCard}
                    ></IdentityHeaderItem>
                  ) : null}
                  {identityVerification.verification.document.type ===
                  "DRIVER_LICENSE" ? (
                    <IdentityHeaderItem
                      label="Document Type"
                      value="Driver License"
                      editable
                      onEdit={() => setEditDocumentOpen(true)}
                      icon={faCar}
                    ></IdentityHeaderItem>
                  ) : null}
                  {identityVerification.verification.document.type ===
                  "RESIDENT_PERMIT" ? (
                    <IdentityHeaderItem
                      label="Document Type"
                      value="Resident Permit"
                      editable
                      onEdit={() => setEditDocumentOpen(true)}
                      icon={faAddressCard}
                    ></IdentityHeaderItem>
                  ) : null}
                </div>
                <div className="w-full md:w-1/3">
                  <IdentityHeaderItem
                    label="Document Country"
                    editable
                    value={identityVerification.verification.document.country}
                    onEdit={() => setEditCountryOpen(true)}
                    icon={faGlobeEurope}
                  ></IdentityHeaderItem>
                </div>
                <div className="w-full md:w-1/3">
                  <IdentityHeaderItem
                    label="Document Number"
                    value={
                      identityVerification.verification.details.info
                        .document_number.value
                    }
                    icon={faAsterisk}
                  ></IdentityHeaderItem>
                </div>
                <div className="w-full md:w-1/3">
                  <IdentityHeaderItem
                    label="Document Expiration"
                    value={
                      identityVerification.verification.details.info
                        .document_expiration.value
                    }
                    icon={faClock}
                  ></IdentityHeaderItem>
                </div>
                <div className="w-full md:w-1/3">
                  <IdentityHeaderItem
                    label="Document Template"
                    value={identityVerification.verification.document.model}
                    icon={faCabinetFiling}
                  ></IdentityHeaderItem>
                </div>
              </div>
            </div>
          </div>
          <div>
            <IdentitySteps></IdentitySteps>
          </div>
        </Card>
        <div className="w-full">
          {identityVerification.task.pin ? (
            <div className="mt-8">
              <AlertPin
                type="pin"
                message={identityVerification.task.pin_details.action}
                details={`${identityVerification.task.pin_details.note}`}
              ></AlertPin>
            </div>
          ) : null}
        </div>
      </div>
      <PinIdentitySidebar
        open={pinOpen}
        setOpen={setPinOpen}
      ></PinIdentitySidebar>
      <RejectIdentitySidebar
        open={rejectOpen}
        setOpen={setRejectOpen}
      ></RejectIdentitySidebar>
      <ActivityIdentitySidebar
        open={activityOpen}
        setOpen={setActivityOpen}
      ></ActivityIdentitySidebar>
      <EditDocumentTypeSidebar
        open={editDocumentOpen}
        setOpen={setEditDocumentOpen}
      ></EditDocumentTypeSidebar>
      <EditDocumentCountry
        open={editCountryOpen}
        setOpen={setEditCountryOpen}
      ></EditDocumentCountry>
      <IdentityHistory
        open={historyOpen}
        setOpen={setHistoryOpen}
      ></IdentityHistory>
    </>
  );
}
