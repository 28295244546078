import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExclamationCircle,
	faCheckCircle,
	faMailbox,
	faShieldAlt,
	faThumbtack,
} from '@fortawesome/pro-duotone-svg-icons'

export default function AlertPin(props) {
	let type = props.type

	let theme = {
		success: {
			bg: 'green-100',
			icon: faCheckCircle,
			message: 'green-800',
			iconColor: 'green-500',
		},
		error: {
			bg: 'red-100',
			icon: faExclamationCircle,
			message: 'red-800',
			iconColor: 'red-500',
		},
		email: {
			bg: 'gray-200',
			icon: faMailbox,
			message: 'gray-800',
			iconColor: 'primary-500',
		},
		pin: {
			bg: 'orange-100',
			icon: faThumbtack,
			message: 'orange-500',
			iconColor: 'orange-500',
		},
		'2FA': {
			bg: 'gray-200',
			icon: faShieldAlt,
			message: 'gray-800',
			iconColor: 'primary-500',
		},
	}

	if (!theme[type]) {
		type = 'success'
	}

	const alert = theme[type]

	return (
		<>
			<div className={`rounded-xl bg-${alert.bg} p-3 mb-3 mx-3 sm:mx-0`}>
				<div className="flex items-center">
					<div>
						<p>
							<FontAwesomeIcon
								className={`text-md mx-2 mr-4 ${alert.iconColor}`}
								icon={alert.icon}
							/>
						</p>
					</div>
					<div>
						<h3 className={`text-sm font-medium ${alert.message}`}>Pinned</h3>
						<h3 className={`text-sm font-regular ${alert.message}`}>
							{props.message}
						</h3>
						<h3 className={`text-sm font-regular ${alert.message}`}>
							{props.details}
						</h3>
					</div>
				</div>
			</div>
		</>
	)
}
