import AuthGuard from "@/guard/auth";
import Navbar from "@/components/menu/Navbar";
import IamGuard from "@/guard/iam";
import RegistryListPage from "@/components/pages/RegistryListPage";

function Registry() {
  return (
    <AuthGuard>
      <IamGuard page="admin">
        <div className="relative min-h-screen flex flex-col">
          <Navbar></Navbar>
          <RegistryListPage></RegistryListPage>
        </div>
      </IamGuard>
    </AuthGuard>
  );
}

export default Registry;
