import { useContext, useState, useEffect } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faAddressCard,
  faCabinetFiling,
  faChevronDown,
  faChevronUp,
  faCross,
  faEye,
  faFingerprint,
  faMinus,
  faPen,
  faPlus,
  faQrcode,
  faSearch,
  faStream,
  faTimes,
  faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import ButtonReview from "@/components/ui/ButtonReview";

import StepTitle from "@/components/ui/StepTitle";
import { useHistory } from "react-router-dom";
import * as Notification from "@/components/ui/Notification";
import Input from '@/components/ui/Input'

import AnnotedImage from "@/components/ui/AnnotedImage";
import FinalReviewConfirm from "@/components/verifications/identity/FinalReviewConfirm";
import IdentityTemplateElementReview from "@/components/identity/IdentityTemplateElementReview";

export default function IdentityFinalReview(props) {
  const [loading, setLoading] = useState(false);
  const [updateFirstname, setUpdateFirstname] = useState(false);
  const [updateLastname, setUpdateLastname] = useState(false);
  const [updateBirthDate, setUpdateBirthDate] = useState(false);
  const [updateDocumentNumber, setUpdateDocumentNumber] = useState(false);
  const [updateDocumentExpiration, setUpdateDocumentExpiration] = useState(false);

  const [newFirstname, setNewFirstname] = useState("");
  const [newLastname, setNewLastname] = useState("");
  const [newBirthDate, setNewBirthDate] = useState("");
  const [newDocumentNumber, setNewDocumentNumber] = useState("");
  const [newDocumentExpiration, setNewDocumentExpiration] = useState("");

  const [templateList, setTemplateList] = useState([]);
  const [error, setError] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [registryList, setRegistryList] = useState([]);


  const [confirmModal, setConfirmModal] = useState(false);
  const router = useHistory();
  const [identityVerification] = useContext(IdentityVerificationContext);

  const keyMap = {};

  useEffect(() => {
    setNewFirstname(identityVerification.verification.details.info.firstname.value)
    setNewLastname(identityVerification.verification.details.info.lastname.value)
    setNewBirthDate(identityVerification.verification.details.info.birth_date.value)
    setNewDocumentNumber(identityVerification.verification.details.info.document_number.value)
    setNewDocumentExpiration(identityVerification.verification.details.info.document_expiration.value)
  }, [])

  useEffect(() => {
    IdentityService.GetRegistryTemplates(
      identityVerification.verification.document.country,
      identityVerification.verification.document.type
    )
      .then((data) => {
        console.log("data")
        console.log(data)
        setTemplateList(data);
        if (data.length > 0) {
          setCurrentIndex(0);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
    return () => { };
  }, []);

  useEffect(() => {
    const newTemplates = [];

    templateList
      .filter((template) => template.slug.includes(searchTerm.toUpperCase()))
      .map((template) => {
        newTemplates.push(template);
      });
    setRegistryList(newTemplates);
    setCurrentIndex(0);
  }, [templateList, searchTerm]);

  async function validateIdentity() {
    setLoading(true);
    setConfirmModal(false)
    await IdentityService.Validate(identityVerification.task.session_id)
      .then(() => {
        Notification.success("Identity verified successfully!");
        setLoading(false);
        router.push("/verifications/identity");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  const handlers = {};

  async function updateInfo(slug, value) {
    setLoading(true);
    await IdentityService.SetInfoReview(
      identityVerification.task.session_id,
      slug,
      value
    )
      .then(() => {
        props.onUpdate()
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  function setPrev() {
    if (!loading) {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  }

  function setNext() {
    if (!loading) {
      if (currentIndex + 1 < registryList.length) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }


  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle value="Final Review" icon={faEye}></StepTitle>
        </div>
      </div>
      <div className="flex flex-wrap items-end">
        <div className="w-1/2 mb-6">
          <div className="mb-6">
            <div className="p-3">
              <div className="mx-auto">
                <div className="flex justify-between">
                  <div className="mb-2">
                    <p className="uppercase mb-1 text-gray-500 font-bold text-sm">
                      Front
                    </p>
                  </div>
                </div>
                <AnnotedImage
                  annotations={identityVerification.final_review.front_annotation}
                  id="front"
                  className="object-contain h-64"
                  src={identityVerification.media.segmented.front}
                ></AnnotedImage>
              </div>
            </div>
            {identityVerification.verification.document.type !== "PASSPORT" ? (
              <div className="p-3 mb-6">
                <div>
                  <div className="flex justify-between">
                    <div className="mb-2">
                      <p className="uppercase mb-1 text-gray-500 font-bold text-sm">
                        Back
                      </p>
                    </div>
                  </div>

                  <AnnotedImage
                    annotations={identityVerification.final_review.back_annotation}
                    id="back"
                    className="object-contain h-64"
                    src={identityVerification.media.segmented.back}
                  ></AnnotedImage>
                </div>
              </div>
            ) : null}{" "}
          </div>
        </div>
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Template" icon={faCabinetFiling}></StepTitle>
            </div>
            <div>
              <div className="flex flex-wrap">
                <Input
                  type="text"
                  icon={faSearch}
                  placeholder="Search"
                  setValue={(value) => setSearchTerm(value)}
                />
                <span className="inline-flex items-center text-sm px-2 ml-2 rounded-lg font-medium bg-gray-100 text-gray-800">
                  {currentIndex + 1} / {registryList.length}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${currentIndex === 0
              ? "cursor-default opacity-75"
              : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
              } w-full p-2 rounded-t-xl text-center transition duration-100 ease-in`}
            onClick={() => setPrev()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={currentIndex === 0 ? faMinus : faChevronUp}
            ></FontAwesomeIcon>
          </div>
          {registryList.length > 0 ? (
            <IdentityTemplateElementReview
              onSet={(slug) => { }}
              element={registryList[currentIndex]}
            ></IdentityTemplateElementReview>
          ) : null}
          <div
            className={`${currentIndex === registryList.length - 1
              ? "cursor-default opacity-75"
              : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
              } w-full p-2 rounded-t-xl text-center transition duration-100 ease-in`}
            onClick={() => setNext()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={
                currentIndex === registryList.length - 1
                  ? faMinus
                  : faChevronDown
              }
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <StepTitle value="Info" icon={faUser}></StepTitle>
      </div>

      <div className="flex flex-wrap px-3">
        {identityVerification.verification.details.info.firstname.active ? (
          <div className="w-1/3 my-3">
            <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
              Firstname
              <FontAwesomeIcon
                className="ml-2 text-corporate-500 cursor-pointer"
                onClick={() => setUpdateFirstname(!updateFirstname)}
                icon={faPen}></FontAwesomeIcon>
            </p>
            {!updateFirstname ?
              <p>
                {identityVerification.verification.details.info.firstname.value}
              </p>
              :
              <div className="flex">
                <div className="w-1/2">
                  <Input
                    type='text'
                    placeholder='Firstname'
                    value={newFirstname}
                    setValue={(value) => setNewFirstname(value)}
                  />
                </div>
                <div className="ml-2 mr-2 mt-2">
                  <ButtonReview
                    onClick={() => updateInfo("firstname", newFirstname) & setUpdateFirstname(false)}
                    theme="success"
                    icon={faCheck}
                  ></ButtonReview>
                </div>
                <div className="mr-2 mt-2">
                  <ButtonReview
                    className="mr-0"
                    onClick={() => setUpdateFirstname(false) & setNewFirstname(identityVerification.verification.details.info.firstname.value)}
                    theme="error"
                    icon={faTimes}
                  ></ButtonReview>
                </div>
              </div>}
          </div>
        ) : null}
        {identityVerification.verification.details.info.lastname.active ? (
          <div className="w-1/3 my-3">
            <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
              Lastname
              <FontAwesomeIcon
                className="ml-2 text-corporate-500 cursor-pointer"
                onClick={() => setUpdateLastname(!updateLastname)}
                icon={faPen}></FontAwesomeIcon>
            </p>
            {!updateLastname ?
              <p>
                {identityVerification.verification.details.info.lastname.value}
              </p>
              :
              <div className="flex">
                <div className="w-1/2">
                  <Input
                    type='text'
                    placeholder='Lastname'
                    value={newLastname}
                    setValue={(value) => setNewLastname(value)}
                  />
                </div>
                <div className="ml-2 mr-2 mt-2">
                  <ButtonReview
                    onClick={() => updateInfo("lastname", newLastname) & setUpdateLastname(false)}
                    theme="success"
                    icon={faCheck}
                  ></ButtonReview>
                </div>
                <div className="mr-2 mt-2">
                  <ButtonReview
                    className="mr-0"
                    onClick={() => setUpdateLastname(false) & setNewLastname(identityVerification.verification.details.info.lastname.value)}
                    theme="error"
                    icon={faTimes}
                  ></ButtonReview>
                </div>
              </div>}
          </div>
        ) : null}
        {identityVerification.verification.details.info.birth_date.active ? (
          <div className="w-1/3 my-3">
            <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
              Birth Date
              <FontAwesomeIcon
                className="ml-2 text-corporate-500 cursor-pointer"
                onClick={() => setUpdateBirthDate(!updateBirthDate)}
                icon={faPen}></FontAwesomeIcon>
            </p>
            {!updateBirthDate ?
              <p>
                {identityVerification.verification.details.info.birth_date.value}
              </p>
              :
              <div className="flex">
                <div className="w-1/2">
                  <Input
                    type='text'
                    placeholder='Birth Date'
                    value={newBirthDate}
                    setValue={(value) => setNewBirthDate(value)}
                  />
                </div>
                <div className="ml-2 mr-2 mt-2">
                  <ButtonReview
                    onClick={() => updateInfo("birth_date", newBirthDate) & setUpdateBirthDate(false)}
                    theme="success"
                    icon={faCheck}
                  ></ButtonReview>
                </div>
                <div className="mr-2 mt-2">
                  <ButtonReview
                    className="mr-0"
                    onClick={() => setUpdateBirthDate(false) & setNewBirthDate(identityVerification.verification.details.info.birth_date.value)}
                    theme="error"
                    icon={faTimes}
                  ></ButtonReview>
                </div>
              </div>}
          </div>
        ) : null}
        {identityVerification.verification.details.info.document_number
          .active ? (
          <div className="w-1/3 my-3">
            <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
              Document Number
              <FontAwesomeIcon
                className="ml-2 text-corporate-500 cursor-pointer"
                onClick={() => setUpdateDocumentNumber(!updateDocumentNumber)}
                icon={faPen}></FontAwesomeIcon>
            </p>
            {!updateDocumentNumber ?
              <p>
                {identityVerification.verification.details.info.document_number.value}
              </p>
              :
              <div className="flex">
                <div className="w-1/2">
                  <Input
                    type='text'
                    placeholder='Document Number'
                    value={newDocumentNumber}
                    setValue={(value) => setNewDocumentNumber(value)}
                  />
                </div>
                <div className="ml-2 mr-2 mt-2">
                  <ButtonReview
                    onClick={() => updateInfo("document_number", newDocumentNumber) & setUpdateDocumentNumber(false)}
                    theme="success"
                    icon={faCheck}
                  ></ButtonReview>
                </div>
                <div className="mr-2 mt-2">
                  <ButtonReview
                    className="mr-0"
                    onClick={() => setUpdateDocumentNumber(false) & setNewDocumentNumber(identityVerification.verification.details.info.document_number.value)}
                    theme="error"
                    icon={faTimes}
                  ></ButtonReview>
                </div>
              </div>}
          </div>
        ) : null}
        {identityVerification.verification.details.info.document_expiration
          .active ? (
          <div className="w-1/3 my-3">
            <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
              Document Expiration
              <FontAwesomeIcon
                className="ml-2 text-corporate-500 cursor-pointer"
                onClick={() => setUpdateDocumentExpiration(!updateDocumentExpiration)}
                icon={faPen}></FontAwesomeIcon>
            </p>
            {!updateDocumentExpiration ?
              <p>
                {identityVerification.verification.details.info.document_expiration.value}
              </p>
              :
              <div className="flex">
                <div className="w-1/2">
                  <Input
                    type='text'
                    placeholder='Birth Date'
                    value={newDocumentExpiration}
                    setValue={(value) => setNewDocumentExpiration(value)}
                  />
                </div>
                <div className="ml-2 mr-2 mt-2">
                  <ButtonReview
                    onClick={() => updateInfo("document_expiration", newDocumentExpiration) & setUpdateDocumentExpiration(false)}
                    theme="success"
                    icon={faCheck}
                  ></ButtonReview>
                </div>
                <div className="mr-2 mt-2">
                  <ButtonReview
                    className="mr-0"
                    onClick={() => setUpdateDocumentExpiration(false) & setNewDocumentExpiration(identityVerification.verification.details.info.document_expiration.value)}
                    theme="error"
                    icon={faTimes}
                  ></ButtonReview>
                </div>
              </div>}
          </div>
        ) : null}
      </div>

      <div className="mt-8">
        <StepTitle value="Document" icon={faAddressCard}></StepTitle>
      </div>

      <div className="flex flex-wrap px-3">
        <div className="w-1/3 my-3">
          <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
            Document country
          </p>
          <p>{identityVerification.verification.document.country}</p>
        </div>
        <div className="w-1/3 my-3">
          <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
            Document type
          </p>
          <p>{identityVerification.verification.document.type}</p>
        </div>
        <div className="w-1/3 my-3">
          <p className="uppercase mb-1 text-gray-500 font-bold text-xs">
            Document template
          </p>
          <p>{identityVerification.verification.document.model}</p>
        </div>
      </div>


      <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div></div>
        {identityVerification.app._key === "246382731" ?
          <>
            <div>
              <Button
                loading={loading}
                onClick={() => setConfirmModal(true)}
                icon={faCheck}
                full
                label="Validate"
                theme="success"
              ></Button>
            </div>
          </>
          : <div>
            <Button
              loading={loading}
              onClick={() => validateIdentity()}
              icon={faCheck}
              full
              label="Validate"
              theme="success"
            ></Button>
          </div>}
      </div>
      <FinalReviewConfirm
        open={confirmModal}
        verification={identityVerification}
        onValidate={() => validateIdentity()}
        onClose={() => setConfirmModal(false)}></FinalReviewConfirm>
    </GlobalHotKeys>
  );
}
