import { useEffect, useState } from "react";
import Moment from "react-moment";
import Tooltip from "../ui/Tooltip";
import HeaderButton from "@/components/ui/HeaderButton";
import IdentityService from "@/services/identity";
import * as Notification from "@/components/ui/Notification";
import { faBug, faComments } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function DuplicateTaskItem(props) {
  const [, setOpen] = useState(false);

  useEffect(() => {
    if (props.user) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.user]);

  async function UnpinTask(identity) {
    await IdentityService.UnpinTask(identity.task.session_id)
      .then(async () => {
        props.update();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <div className="w-full flex">
      <div className="w-full list-item">
        <a
          href={`/verifications/duplicate/${props.item.task.session_id}`}
          className="flex-shrink-0 group block"
        >
          <div className="flex justify-between items-center">
            <div className="ml-1">
              <div className="flex">
                <div>
                  <Tooltip auto={true} content={props.item.app.name}>
                    <span className="inline-block relative">
                      <img
                        className="h-10 w-10 rounded-lg relative top-1 border border-gray-100"
                        src={props.item.app.logo_url}
                        alt=""
                      />
                    </span>
                  </Tooltip>
                </div>
                <div className="mt-4">
                  <p className="ml-4 text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    <span>{props.item.task.session_id}</span>
                  </p>
                </div>
                {props.item.task.pin_details.note && props.item.task.pin ? (
                  <div className="ml-2 mt-3">
                    {"  "} - {"  "}
                    <span className="font-bold text-orange-500">
                      {props.item.task.pin_details.note}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-right flex">
              <div className="flex items-center">
                <div>
                  <div className="ml-3 mr-2">
                    <p className="text-xs font-light cursor-default text-gray-700">
                      <Moment unix fromNow>
                        {props.item.task.creation_date / 1000}
                      </Moment>
                    </p>
                  </div>
                </div>
              </div>
              {props.item.task.pin === true ? (
                <div className="ml-3 mr-2">
                  {props.item.task.pin_details.action === "INTERNAL_ISSUE" ? (
                    <Tooltip auto={true} content="Internal Issue">
                      <span>
                        <FontAwesomeIcon
                          className="text-orange-500"
                          icon={faBug}
                        ></FontAwesomeIcon>
                      </span>
                    </Tooltip>
                  ) : null}
                  {props.item.task.pin_details.action === "INTERNAL_MESSAGE" ? (
                    <Tooltip auto={true} content="Internal Message">
                      <span>
                        <FontAwesomeIcon
                          className="text-orange-500"
                          icon={faComments}
                        ></FontAwesomeIcon>
                      </span>
                    </Tooltip>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </a>
      </div>
      {props.item.task.pin === true ? (
        <div className="ml-2 mr-2 mt-4">
          <HeaderButton
            onClick={() => UnpinTask(props.item)}
            currentBg="white"
            type="orange"
            label={"Unpin"}
          ></HeaderButton>
        </div>
      ) : null}
    </div>
  );
}
