import { useEffect, useState } from 'react'
import SubNav from '../menu/SubNav'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import StatService from '@/services/stats'
import Card from '../ui/Card'
import { faUniversity, faUserTie } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import VerificationStepPlaceholder from '../placeholder/VerificationStepPlaceholder'
import Footer from '../menu/Footer'
import { useHistory } from 'react-router-dom'
import ContentSearchBar from '../ui/ContentSearchBar'
import Empty from '../misc/Empty'
import Alert from '@/components/ui/Alert'

export default function CorporateListPage() {
	const [ready, setReady] = useState(false)
	const [error, setError] = useState(null)
	const [corporates, setCorporates] = useState(null)
	const [data, setData] = useState(null)
	const router = useHistory()

	useEffect(async () => {
		await StatService.RetreiveCorporatesList()
			.then((data) => {
				console.log('data')
				console.log(data)
				setData(data)
				setCorporates(data)
				setReady(true)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}, [])

	function setSearch(term) {
		if (term === term.toUpperCase() && term.length === 3) {
			let filteredManager = data.filter((reg) =>
				reg.country.toLowerCase().includes(term.toLowerCase())
			)
			setCorporates(filteredManager)
			return
		}

		let filteredManager = data.filter((reg) =>
			reg.corporate.toLowerCase().includes(term.toLowerCase())
		)
		setCorporates(filteredManager)

		if (filteredManager.length === 0) {
			filteredManager = data.filter((reg) =>
			reg.alias.toLowerCase().includes(term.toLowerCase())
		)
			setCorporates(filteredManager)
		}
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mx-auto relative">
					<div className="verification-container">
						{ready ? (
							<Card className="w-full px-3 sm:px-0">
								<div>
									<div className="p-3">
										<div className="flex justify-between">
											<div>
												<CardTitle
													value="Corporates"
													icon={faUniversity}></CardTitle>
											</div>
											<div className="flex justify-center">
												<ContentSearchBar
													onChange={(el) =>
														setSearch(el.target.value)
													}></ContentSearchBar>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}

										{ready ? (
											<>
												{' '}
												<div className="flex flex-wrap items-stretch">
													{corporates.map((corporate) => {
														return (
															<div onClick={() => router.push('/corporate/' + corporate.session_id)}
																className="w-full sm:w-1/4 p-4 self-auto"
																key={corporate.session_id}>
																<div className="flex p-2 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
																	<CardTitle
																		value={corporate.corporate}
																		icon={faUniversity}></CardTitle>
																	<div className="mt-3">
																		<img className="relative h-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${corporate.country}.svg`}></img>
																	</div>
																</div>
															</div>
														)
													})}
												</div>
												{corporates.length === 0 ? (
													<div className="text-center">
														<Empty value="No Corporates"></Empty>
													</div>
												) : null}
											</>
										) : (
											<VerificationStepPlaceholder></VerificationStepPlaceholder>
										)}
									</div>
								</div>
							</Card>
						) : (
							<VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
						)}
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
