import { Menu, Transition } from '@headlessui/react'
import {
	faBrowser,
	faCabinetFiling,
	faFileInvoice,
	faHeadSide,
	faPassport,
	faStream,
	faUniversity,
	faUserFriends,
	faUsers,
} from '@fortawesome/pro-duotone-svg-icons'
import { useContext } from 'react'
import { AuthContext } from '@/context/auth'
import MenuItem from './MenuItem'
import MenuExtraItem from './MenuExtraItem'

export default function MiniProfile() {
	const [auth] = useContext(AuthContext)
	return (
		<div className='flex'>
			<div className='relative inline-block text-left'>
				<Menu>
					{({ open }) => (
						<>
							<Menu.Button className="outline-none-i">
								<img className="w-8 p-2 mr-1 relative top-3px rounded-md hover:bg-primary-900 transition duration-100 ease-in" src="/icons/ui/famenu.svg" />
							</Menu.Button>

							<Transition
								show={open}
								enter='transition ease-in duration-100'
								enterFrom='transform opacity-0'
								enterTo='transform opacity-100'
								leave='transition ease-in duration-100'
								leaveFrom='transform opacity-100'
								leaveTo='transform opacity-0'>
								<Menu.Items
									static
									className='absolute right--40px sm:right-0 sm:zoom-none navmenu mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-xl shadow outline-none'>
									<div className='flex flex-wrap items-stretch'>
										<div className='w-full'>
											<div className='px-3 pt-4'>
												<h3 className='ml-3 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900'>
													Services
												</h3>
											</div>
										</div>

										{auth.iam.admin || auth.iam.identity ? (
											<div className='w-1/3'>
												<MenuItem
													label='Identity'
													path='/verifications/identity'
													theme='identity'
													icon={faPassport}></MenuItem>
											</div>
										) : null}

										{auth.iam.admin || auth.iam.residency ? (
											<div className='w-1/3'>
												<MenuItem
													label='Residency'
													path='/verifications/residency'
													theme='residency'
													icon={faFileInvoice}></MenuItem>
											</div>
										) : null}

										{auth.iam.admin || auth.iam.aml ? (
											<div className='w-1/3'>
												<MenuItem
													label='AML'
													path='/verifications/aml'
													theme='aml'
													icon={faStream}></MenuItem>
											</div>
										) : null}
										{auth.iam.admin || auth.iam.corporate ? (
											<div className='w-1/3'>
												<MenuItem
													label='Corporate'
													path='/verifications/corporate'
													theme='corporate'
													icon={faUniversity}></MenuItem>
											</div>
										) : null}
										{auth.iam.admin || auth.iam.facematch ? (
											<div className='w-1/3'>
												<MenuItem
													label='Facematch'
													path='/verifications/facematch'
													theme='facematch'
													icon={faHeadSide}></MenuItem>
											</div>
										) : null}
										{auth.iam.admin || auth.iam.dupplicate ? (
											<div className='w-1/3'>
												<MenuItem
													label='Dupplicate'
													path='/verifications/dupplicate'
													theme='dupplicate'
													icon={faUserFriends}></MenuItem>
											</div>
										) : null}
									</div>
									{auth.iam.admin ? (
										<div className='px-3 py-3'>
											<div>
												<h3 className='ml-3 mb-2 uppercase tracking-wide font-semibold text-sm lg:text-xs text-gray-900'>
													Management
												</h3>
											</div>
											<div className='flex flex-wrap'>
												<div className='w-1/2'>
													<MenuExtraItem
														label='Apps'
														path='/apps'
														theme='primary'
														icon={faBrowser}></MenuExtraItem>
												</div>
												<div className='w-1/2'>
													<MenuExtraItem
														label='Agents'
														path='/Agents'
														theme='primary'
														icon={faUsers}></MenuExtraItem>
												</div>
												<div className='w-1/2'>
													<MenuExtraItem
														label='Registry'
														path='/registry'
														theme='primary'
														icon={faCabinetFiling}></MenuExtraItem>
												</div>
											</div>
										</div>
									) : null}
								</Menu.Items>
							</Transition>
						</>
					)}
				</Menu>
			</div>
		</div>
	)
}
