import { useContext, useState } from "react";
import SlidingPane from "react-sliding-pane";
import Button from "@/components/ui/Button";
import PinCard from "@/components/ui/PinCard";
import {
  faAddressCard,
  faBug,
  faCodeBranch,
  faComment,
  faComments,
  faCrop,
  faCropAlt,
  faFingerprint,
  faQrcode,
  faUser,
  faUserSecret,
} from "@fortawesome/pro-duotone-svg-icons";
import { DuplicateVerificationContext } from "@/context/duplicate_verification";
import Textarea from "@/components/ui/Textarea";
import * as Notification from "@/components/ui/Notification";
import IdentityService from "@/services/identity";
import { useHistory } from "react-router-dom";

export default function PinDuplicateSidebar({ open, setOpen }) {
  const [pin, setPin] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  const [duplicateVerification] = useContext(DuplicateVerificationContext);

  function updatePin(newPin) {
    if (pin === newPin) {
      setPin("");
    } else {
      setPin(newPin);
    }
  }

  async function pinIdentityTask() {
    const body = {
      action: pin,
      note: note,
    };

    setLoading(true);
    await IdentityService.PinIdentityTask(
      duplicateVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        Notification.success("Task pinned successfully");
        router.push("/verifications/duplicate");
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
    return () => {};
  }

  return (
    <>
      <SlidingPane
        className={"rounded-xl"}
        hideHeader={true}
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <div className="h-full w-full rounded-xl">
          <form className="h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl">
            <div className="flex-1 h-0 overflow-y-auto">
              <div className="py-6 px-4 bg-orange-400 sm:px-6 rounded-t-xl">
                <div className="flex items-center justify-between">
                  <h2
                    className="text-lg font-medium text-white"
                    id="slide-over-title"
                  >
                    Pin Duplicate
                  </h2>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      onClick={() => setOpen(false)}
                      type="button"
                      className="bg-orange-400 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    >
                      <span className="sr-only">Close panel</span>
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="mt-1">
                  <p className="text-sm text-white">
                    Describe the issue encountered during the duplicate
                    verification process
                  </p>
                </div>
              </div>
              <div className="flex-1 flex flex-col justify-between">
                <div className="px-4 py-2">
                  <p className="pt-2 uppercase text-gray-500 font-bold text-xs">
                    Panel Platform
                  </p>
                  <PinCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="INTERNAL_ISSUE"
                    title="Bug"
                    description="Internal Synaps Panel issue"
                    icon={faBug}
                  ></PinCard>
                  <PinCard
                    selected={pin}
                    setPin={(slug) => updatePin(slug)}
                    slug="INTERNAL_MESSAGE"
                    title="Message"
                    description="Internal Team Message"
                    icon={faComments}
                  ></PinCard>

                  <p className="pt-2 mb-4 uppercase text-gray-500 font-bold text-xs">
                    Additional Information
                  </p>
                  <Textarea
                    placeholder="Write additional details"
                    setValue={(value) => setNote(value)}
                  ></Textarea>
                </div>
              </div>
            </div>
            <div className="flex-shrink-0 px-4 py-4 flex justify-end">
              <Button
                onClick={() => setOpen(false)}
                theme="secondary"
                label="Cancel"
              ></Button>
              {pin !== "" ? (
                <Button
                  loading={loading}
                  className="ml-2"
                  onClick={() => pinIdentityTask()}
                  theme="pin"
                  label="Pin"
                ></Button>
              ) : null}
            </div>
          </form>
        </div>
      </SlidingPane>
    </>
  );
}
