import { useHistory } from "react-router-dom";

export default function RegistryCountrytItem(props) {
  const router = useHistory();

  return props.docs.map((doc) => {
    return (
      <div
        onClick={() => router.push(`/registry/${props.country}/${doc.slug}`)}
        className="w-full sm:w-1/3 p-4 self-auto"
        key={doc.slug}
      >
        <div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 transition duration-200 ease-in">
          <div className="flex justify-between">
            <div></div>
            <div>
              <p className="mt-2 mr-2">
                {doc.deployed ? (
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Deployed
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-orange-100 text-orange-800">
                    <svg
                      className="-ml-0.5 mr-1.5 h-2 w-2 text-orange-400"
                      fill="currentColor"
                      viewBox="0 0 8 8"
                    >
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Draft
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap p-4">
            <div className={`${props.noBack ? "w-1/2 mx-auto" : "w-1/2"}`}>
              <img src={doc.front} className="object-contain h-48 w-full" />
            </div>
            {!props.noBack ? (
              <div className="w-1/2">
                <img src={doc.back} className="object-contain h-48 w-full" />
              </div>
            ) : null}
          </div>
          <div className="w-full">
            <p className="p-4 text-center text-regular uppercase font-regular">
              <span>{doc.slug}</span>
            </p>
          </div>
        </div>
      </div>
    );
  });
}
