
import { useState } from 'react'
import Button from '../ui/Button'
import CountryFlag from '../ui/CountryFlag'
import Upload from '../ui/Upload'
import RegistryService from '@/services/registry'
import { Modal } from 'react-responsive-modal'
import * as Notification from '@/components/ui/Notification'

export default function RegistryNewDocument(props) {
	const [, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const [document, setDocument] = useState(null)
	async function submit() {
		setError(null)

		if (!document) {
			Notification.error('Document required')
			return () => {}
		}

		setLoading(true)

		let bodyFormData = new FormData()
		bodyFormData.append('document', document)

		await RegistryService.Upload(bodyFormData, props.side, props.slug)
			.then(() => {
				Notification.success('Document successfully edited')
				props.onSuccess()
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => {}
	}

	return (
		<Modal
			open={props.open}
			showCloseIcon={true}
			closeIcon={true}
			onClose={() => props.onClose()}
			center>
			<div className="p-6">
				<div className="flex flex-wrap">
					<div className="p-3 pb-0">
						<h3
							className="text-lg leading-6 font-medium text-gray-900"
							id="modal-headline">
							<div className="flex text-sm">
								<CountryFlag country={props.country}></CountryFlag>
								<span className="ml-2 relative top--1px">
									{props.countryName}
								</span>
							</div>
						</h3>
						<h1 className="text-2xl font-bold mt-1">
							{props.slug} / <span className="font-thin">{props.side}</span>
						</h1>
					</div>
					<div className="w-full pt-6">
						<div>
							<Upload
								loading={loading}
								acceptedFileTypes={['image/jpeg', 'image/png', 'image/jpg']}
								setFile={setDocument}></Upload>
						</div>
					</div>
				</div>
				<div className="pt-3">
					<div className="flex justify-between">
						<div></div>
						<div>
							<div className="flex">
								<div className="mr-3">
									<Button
										theme="secondary"
										onClick={() => props.onClose()}
										label="Cancel"
									/>
								</div>
								<div>
									<Button
										theme="success"
										loading={loading}
										onClick={() => submit()}
										label="Edit document"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
