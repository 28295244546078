import { useEffect, useState } from 'react'
import SubNav from '../menu/SubNav'
import { useHistory } from 'react-router-dom'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import AgentsService from '@/services/agents'
import Card from '../ui/Card'
import { faUsers } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import VerificationStepPlaceholder from '../placeholder/VerificationStepPlaceholder'
import Footer from '../menu/Footer'
import UserListItem from '../user/UserListItem'
import ContentSearchBar from '../ui/ContentSearchBar'
import Empty from '../misc/Empty'
import Alert from '@/components/ui/Alert'
import Button from '../ui/Button'
import NewAgent from '../user/NewAgent'

export default function RegistryListPage(props) {
	const [ready, setReady] = useState(false)
	const [error, setError] = useState(null)
	const [agents, setAgents] = useState(null)
	const [data, setData] = useState(null)
	const [open, setOpen] = useState(false)
	const router = useHistory()

	function openModal() {
		setOpen(true)
	}

	async function close() {
		await AgentsService.RetreiveAgentsList()
			.then((data) => {
				setData(data)
				setAgents(data)
				setReady(true)
				setOpen(false)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => {}
	}

	useEffect(async () => {
		await AgentsService.RetreiveAgentsList()
			.then((data) => {
				setData(data)
				setAgents(data)
				setReady(true)
				console.log(data)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => {}
	}, [])

	function setSearch(term) {
		let filteredAgent = data.filter((reg) =>
			reg.email.toLowerCase().includes(term.toLowerCase())
		)
		setAgents(filteredAgent)
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mx-auto relative">
					<div className="verification-container">
						{ready ? (
							<Card className="w-full px-3 sm:px-0">
								<div>
									<div className="p-3">
										<div className="flex justify-between">
											<div>
												<CardTitle
													value="Agents"
													icon={faUsers}></CardTitle>
											</div>
											<div className="flex justify-center">
												<div className="mr-3 w-full mt-1">
													<Button
														type="submit"
														theme="success"
														onClick={() => openModal()}
														label="Add agent"
													/>
												</div>
												<ContentSearchBar
													onChange={(el) =>
														setSearch(el.target.value)
													}></ContentSearchBar>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}

										{ready ? (
											<>
												{' '}
												<div className="flex flex-wrap items-stretch">
													{agents.map((agent) => {
														return (
															<div
															onClick={() =>
																router.push(`/agents/${agent.key}`)
															}
																className="w-full sm:w-1/4 p-4 self-auto"
																key={agent.email}>
																<div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
																	<UserListItem
																		agent={agent}></UserListItem>
																</div>
															</div>
														)
													})}
												</div>
												{agents.length === 0 ? (
													<div className="text-center">
														<Empty value="No Agents"></Empty>
													</div>
												) : null}
											</>
										) : (
											<VerificationStepPlaceholder></VerificationStepPlaceholder>
										)}
									</div>
								</div>
							</Card>
						) : (
							<VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
						)}
					</div>
					<Footer></Footer>
				</div>
			</div>
			<NewAgent
				open={open}
				label={props.label}
				countryName={props.countryName}
				slug={props.slug}
				country={props.country}
				onClose={() => close()}></NewAgent>
		</div>
	)
}
