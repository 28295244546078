import { useHistory } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "@/context/auth";

export default function IamGuard(props) {
  const [auth] = useContext(AuthContext);
  const [layout, setLayout] = useState(null);
  const router = useHistory();

  useEffect(() => {
    console.log(auth);
    if (auth.iam.admin === true) {
      setLayout(props.children);
      return () => {};
    }

    // Identity
    if (props.page === "identity" && auth.iam.identity === true) {
      setLayout(props.children);
      return () => {};
    }

    // Residency
    if (props.page === "residency" && auth.iam.residency === true) {
      setLayout(props.children);
      return () => {};
    }

    // AML
    if (props.page === "aml" && auth.iam.aml === true) {
      setLayout(props.children);
      return () => {};
    }

    // Corproate
    if (props.page === "corporate" && auth.iam.corporate === true) {
      setLayout(props.children);
      return () => {};
    }

    // Facematch
    if (props.page === "facematch" && auth.iam.facematch === true) {
      setLayout(props.children);
      return () => {};
    }

    // Dupplicate
    if (props.page === "duplicate" && auth.iam.duplicate === true) {
      setLayout(props.children);
      return () => {};
    }

    // Support
    if (
      props.page === "support" &&
      (auth.iam.support_agent === true || auth.iam.support_manager === true)
    ) {
      setLayout(props.children);
      return () => {};
    }

    router.push("/");
    return () => {};
  }, []);

  return layout;
}
