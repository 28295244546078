import { useContext, useEffect, useState } from "react";
import Empty from "../misc/Empty";
import TasksPlaceholder from "../placeholder/TasksPlaceholder";
import Sleep from "@/lib/sleep";
import * as Notification from "@/components/ui/Notification";
import CardTitle from "../ui/CardTitle";
import { DuplicateContext } from "@/context/duplicate";
import DuplicateService from "@/services/duplicate";
import DuplicateTaskItem from "./DuplicateTaskItem";
import { faBug, faComments } from "@fortawesome/pro-duotone-svg-icons";
import PinCard from "../ui/PinCard";

export default function DuplicateTasks() {
  const [duplicate, duplicateDispatch] = useContext(DuplicateContext);
  const [ready, setReady] = useState(false);
  const [pinType, setPinType] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  function updatePin(newPin) {
    if (pinType === newPin) {
      setPinType("");
    } else {
      setPinType(newPin);
    }
  }

  useEffect(async () => {
    setReady(false);
    await DuplicateService.GetTasks(duplicate.selectedTab.key, pinType)
      .then(async (data) => {
        duplicateDispatch({
          type: "SET_TASKS",
          payload: {
            tasks: data,
          },
        });
        await Sleep(500);
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }, [duplicate.selectedTab, pinType]);

  return (
    <>
      <div className="flex justify-between">
        <div>
          <CardTitle
            value={duplicate.selectedTab.title}
            icon={duplicate.selectedTab.icon}
          ></CardTitle>
        </div>
      </div>
      {duplicate.selectedTab.key === "pinned" && showFilter ? (
        <div className="flex flex-wrap p-4 pb-0 pt-0 bg-bluegray-50 mt-3 mb-3">
          <div className="w-1/3 p-2">
            <PinCard
              selected={pinType}
              setPin={(slug) => updatePin(slug)}
              slug="INTERNAL_ISSUE"
              title="Bug"
              description="Internal Synaps Panel issue"
              icon={faBug}
            ></PinCard>
          </div>
          <div className="w-1/3 p-2">
            <PinCard
              selected={pinType}
              setPin={(slug) => updatePin(slug)}
              slug="INTERNAL_MESSAGE"
              title="Message"
              description="Internal team message"
              icon={faComments}
            ></PinCard>
          </div>
        </div>
      ) : null}

      {ready ? (
        <div className="flex flex-wrap p-6 pt-3">
          {duplicate.tasks.map((task) => {
            return (
              <DuplicateTaskItem
                update={(slug) => updatePin(slug)}
                key={task.task.session_id}
                item={task}
              ></DuplicateTaskItem>
            );
          })}
          {duplicate.tasks.length === 0 ? (
            <Empty value="No tasks"></Empty>
          ) : null}
        </div>
      ) : (
        <div className="p-4">
          <TasksPlaceholder></TasksPlaceholder>
        </div>
      )}
    </>
  );
}
