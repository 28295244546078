import { useContext, useEffect } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import { faClipboardList } from '@fortawesome/pro-duotone-svg-icons'
import StepTitle from '@/components/ui/StepTitle'
import { CorporateVerificationContext } from '@/context/corporate_verification'
import Badge from '@/components/ui/Badge'

export default function CorporateAdditionalChecks() {
	const [corporateVerification] = useContext(CorporateVerificationContext)

	useEffect(() => {}, [corporateVerification])

	const keyMap = {}

	const handlers = {}

	return (
		<GlobalHotKeys
			tabIndex='1'
			className='outline-none'
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			<div className='p-6'>
				{corporateVerification.additional_task.length !== 0 ? (
					<div className='flex justify-between'>
						<div>
							<StepTitle
								value='Additional Checks'
								icon={faClipboardList}></StepTitle>
						</div>
					</div>
				) : null}

				<div>
					{Object.entries(corporateVerification.additional_task).map(
						([key, value]) => {
							return (
								<div
									key={key}
									onClick={() =>
										window.open(
											`/verifications/${value.service.toLowerCase()}/${
												value.session_id
											}`,
											'_blank'
										)
									}
									className={
										'mt-3 flex w-full cursor-pointer flex-wrap justify-between bg-bluegray-50 hover:bg-bluegray-100 transition duration-100 ease-in border border-bluegray-100 p-3 rounded-lg'
									}>
									<div>
										{/* {value} */}
										<p className='font-medium'>{value.service}</p>
									</div>
									<div>
										{' '}
										<p>
											{value.state === 2 ? (
												<Badge label='Validated' small type='green'></Badge>
											) : null}
											{value.state === 1 ? (
												<Badge label='Pending' small type='orange'></Badge>
											) : null}
											{value.state === 0 ? (
												<Badge label='Rejected' small type='red'></Badge>
											) : null}
										</p>
									</div>
								</div>
							)
						}
					)}
				</div>
			</div>
		</GlobalHotKeys>
	)
}
