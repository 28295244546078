import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function RegistryHeaderItem(props) {
	return (
		<div className={'h-full mb-4 px-2'}>
			<div
				className={'bg-bluegray-50 p-3 rounded-xl  border border-bluegray-100'}>
				<div className="flex items-center">
					<div className="text-3xl mr-4">
						<FontAwesomeIcon
							className="text-identity-500"
							icon={props.icon}></FontAwesomeIcon>
					</div>
					<div>
						<div className="flex">
							<div>
								<p className="text-xs">
									<span
										className={'uppercase font-semibold text-bluegray-700 text-xs'}>
										{props.label}
									</span>
								</p>
							</div>
						</div>
						<div className="flex">
							<p className={'text-regular font-bold text-primary-500'}>
								{props.value}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
