import { useState } from 'react'
import Tooltip from './Tooltip'

export default function AnnotedResidency(props) {
	const [imgWidth, setImgWidth] = useState(0)
	const [imgHeight, setImgHeight] = useState(0)

	function initSize() {
		if (document.getElementById(props.id)?.clientWidth) {
			setImgWidth(document.getElementById(props.id).clientWidth)
		}

		if (document.getElementById(props.id)?.clientHeight) {
			setImgHeight(document.getElementById(props.id).clientHeight)
		}
	}

	function initLoad() {
		initSize()
		window.addEventListener('resize', initSize)
	}

	function isSuggested(suggestions, id) {
		let found = false
		suggestions.map((item) => {
			if (item.paragraph_nb == id) {
				found = true
			}
		})
		return found
	}

	function SingleAnnotation({
		annotation,
		noStyle,
		suggestions,
		id,
	}) {
		let position = {}
		position = annotation
		position.color = 'ADD8E6'

		if (isSuggested(suggestions, id) == true) {
			position.color = '008000'
		}

		let initialStyle = {
			backgroundColor: `#${position.color}50`,
			borderColor: `#${position.color}90`,
		}
		let hoverStyle = {
			backgroundColor: `#${position.color}75`,
			borderColor: `#${position.color}`,
		}

		if (noStyle) {
			initialStyle = {
				borderColor: `#${position.color}`,
			}
		}

		let locationMargin = 0
		let sizeMargin = 0
		if (noStyle) {
			sizeMargin = 10
			locationMargin = 5
		}

		const [elementStyle, setElementStyle] = useState(initialStyle)
		const width = parseInt(position.width * imgWidth + sizeMargin)
		const height = parseInt(position.height * imgHeight + sizeMargin)
		const left = parseInt(position.top_left.x * imgWidth - locationMargin)
		const top = parseInt(position.top_left.y * imgHeight - locationMargin)
		const sizeStyle = {
			width: `${width}px`,
			height: `${height}px`,
			top: `${top}px`,
			left: `${left}px`,
		}
		let customClasses = 'absolute border-2'
		return (
			<div
				onMouseOver={() => setElementStyle({ ...hoverStyle })}
				onMouseOut={() => setElementStyle(initialStyle)}
				key={left}>
				<Tooltip auto={true} content={`(${id}) - ${position.translated_text}`}>
					<div
						key={left}
						style={{ ...elementStyle, ...sizeStyle }}
						className={customClasses}></div>
				</Tooltip>
			</div>
		)
	}

	function DrawAnnotations(props) {
		return props.annotations.map((annotation, i) => {
			return (
				<SingleAnnotation
					key={i}
					suggestions={props.suggestions}
					id={i}
					type={props.type}
					annotation={annotation}></SingleAnnotation>
			)
		})
	}

	return (
		<div className="relative">
			<img
				id={props.id}
				draggable="false"
				src={props.src}
				className={`${props.className} shadow rounded-md h-auto`}
				onLoad={initLoad}></img>
			<DrawAnnotations
				noStyle={props.noStyle}
				type={props.type}
				suggestions={props.suggestions}
				annotations={props.annotations}></DrawAnnotations>
		</div>
	)
}
