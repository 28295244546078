import { PanelAuth } from "@/services/axios";

export default {
  RetreiveAgentsList() {
    return PanelAuth.get(`${process.env.REACT_APP_PANEL_HOST}/v3/users/list`);
  },
  AddAgent(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/create`,
      body
    );
  },
  RetreiveAgent(key) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/details?key=${key}`
    );
  },
  AddPermission(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/attach/iam`,
      body
    );
  },
  DeletePermission(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/users/delete/iam`,
      body
    );
  },
};
