import {
	faSirenOn,
	faTasks,
	faThumbtack,
} from '@fortawesome/pro-duotone-svg-icons'
import { useReducer, createContext } from 'react'

export const ResidencyContext = createContext()

const initialState = {
	stats: {
		pinned: 0,
		tasks: 0,
		urgent: 0,
	},
	tasks: [],
	selectedTab: {
		title: 'Tasks',
		key: 'tasks',
		slug: 'tasks',
		icon: faTasks,
	},
	tabs: [
		{
			key: 'tasks',
			label: 'Tasks',
			icon: faTasks,
			bgNormal: '',
			bgHover: 'hover:bg-primary-500',
			textActive: 'text-primary-500',
			textNormal: 'text-gray-500',
			textHover: 'hover:text-white',
			badge: 0,
			bgBadge: 'bg-primary-500',
			bgBadgeHover: 'hover:bg-primary-900',
			textBadge: 'text-white',
			group: 'group-hover:text-white group-hover:bg-primary-900',
		},
		{
			key: 'pinned',
			label: 'Pinned',
			icon: faThumbtack,
			bgActive: 'bg-orange-500',
			bgNormal: '',
			bgHover: 'hover:bg-orange-500',
			textActive: 'text-orange-500',
			textNormal: 'text-red-500',
			textHover: 'hover:text-white',
			badge: 0,
			bgBadge: 'bg-orange-500',
			bgBadgeHover: 'hover:bg-orange-700',
			textBadge: 'text-white',
			group: 'group-hover:text-white group-hover:bg-orange-900',
		},
		{
			key: 'urgent',
			label: 'Urgent',
			icon: faSirenOn,
			bgActive: 'bg-orange-500',
			bgNormal: '',
			bgHover: 'hover:bg-red-500',
			textActive: 'text-red-500',
			textNormal: 'text-red-500',
			textHover: 'hover:text-white',
			badge: 0,
			bgBadge: 'bg-red-500',
			bgBadgeHover: 'hover:bg-red-700',
			textBadge: 'text-white',
			group: 'group-hover:text-white group-hover:bg-red-900',
		},
	],
}

function setCurrentTab(state, payload) {
	return {
		...state,
		selectedTab: {
			title: payload.tab.label,
			icon: payload.tab.icon,
			key: payload.tab.key,
		},
	}
}

function setStats(state, payload) {
	return {
		...state,
		stats: payload.stats,
	}
}

function setTasks(state, payload) {
	return {
		...state,
		tasks: payload.tasks,
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_CURRENT_TAB':
			return setCurrentTab(state, action.payload)
		case 'SET_STATS':
			return setStats(state, action.payload)
		case 'SET_TASKS':
			return setTasks(state, action.payload)
		default:
			return state
	}
}

export const ResidencyContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<ResidencyContext.Provider value={[state, dispatch]}>
			{props.children}
		</ResidencyContext.Provider>
	)
}
