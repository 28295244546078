import { useContext, useState, useEffect } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SessionContext } from '@/context/session'
import { useHistory } from 'react-router-dom'
import Tooltip from '@/components/ui/Tooltip'
import NetworkService from '@/services/network'
import {
	faDiscord,
	faTelegram,
	faTwitter,
	faSlack,
} from '@fortawesome/free-brands-svg-icons'
import { faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { faEnvelope, faEnvelopeOpen, faMailbox } from '@fortawesome/pro-solid-svg-icons'

export default function AppSidebar({ open, setOpen }) {
	const [pin, setPin] = useState('')
	const [note, setNote] = useState('')
	const [loading, setLoading] = useState(false)
	const [projectNetwork, setProjectNetwork] = useState(null)
	const router = useHistory()

	const [session] = useContext(SessionContext)

	useEffect(() => {
		NetworkService.GetAppNetwork(session.app.id).then((data) => {
			console.log("data")
			console.log(data)
			setProjectNetwork(data)
		}).catch((err) => {
			setLoading(false)
		})
		return () => { }
	}, [])

	let supportsIcons = {
		"discord": faDiscord,
		"telegram": faTelegram,
		"twitter": faTwitter,
		"slack": faSlack,
		"other": faPlus,
	}


	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-orange-400 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										App Info
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-orange-400 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
							</div>
							<div>
								{session.ready ? <>
									{Object.keys(session.app_steps).map(function (key) {
										return (
											<>
												<div className="z-max w-full h-full flex items-center justify-center">
													<div className="flex flex-col w-full h-full rounded">
														<header className="p-5 pb-1">
															<div className="flex justify-between items-center">
																{session.app_steps[key].step === 'IDENTITY' ? <div className="flex items-center">
																	<div>
																		<img className="h-9 relative mr-2" src="/img/icons/ui/passport.svg" />
																	</div>
																	<div>
																		<h2 className="text-lg text-primary-500  font-medium">ID Verification</h2>
																	</div>
																</div> : null}
																{session.app_steps[key].step === 'RESIDENCY' ? <div className="flex items-center">
																	<div>
																		<img className="h-6 relative top-0.5 mr-2" src="/img/icons/ui/icon-utility-bill.svg" />
																	</div>
																	<div>
																		<h2 className="text-xl text-primary-500  font-medium"> Proof of residency
																		</h2>
																	</div>
																</div> : null}
																{session.app_steps[key].step === 'PHONE' ? <div className="flex items-center">
																	<div>
																		<img className="h-9 relative mr-2" src="/img/icons/ui/icon-call.svg" />
																	</div>
																	<div>
																		<h2 className="text-lg text-primary-500 font-medium"> Phone verification
																		</h2>
																	</div>
																</div> : null}
																{session.app_steps[key].step === 'LIVENESS' ? <div className="flex items-center">
																	<div>
																		<img className="h-9 relative mr-2" src="/img/icons/ui/icon-call.svg" />
																	</div>
																	<div>
																		<h2 className="text-lg text-primary-500 font-medium"> Liveness verification
																		</h2>
																	</div>
																</div> : null}
															</div>
														</header>

														<main
															className="flex-1 overflow-y-auto p-5 py-0 flow-body"
															id="individual-main">
															<div className="flex flex-wrap">
																<div className="w-full">
																	<div>
																		{session.app_steps[key].step === 'IDENTITY' || session.app_steps[key].step === 'RESIDENCY' ?
																			<>
																				<div className="flex justify-between mt-4">
																					<div>
																						<p className="text-sm font-bold text-primary-700">Restricted documents</p>
																					</div>
																				</div>
																				<div>
																					<div className="flex">
																						<div className="relative z-0">
																							<div className="flex mt-2">
																								<div>
																									<div className="flex">
																										{session.app_steps[key].step === 'IDENTITY' ?
																											<>
																												<div>
																													<Tooltip auto={true} content={`Passport ${session.app_steps[key].setting.documents_restrictions.includes('PASSPORT') ? ': forbidden' : ': allowed'}`}>
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('PASSPORT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-passport.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content={`National ID ${session.app_steps[key].setting.documents_restrictions.includes('NATIONAL_ID') ? ': forbidden' : ': allowed'}`}>
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('NATIONAL_ID') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-national-id.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content={`Driver License ${session.app_steps[key].setting.documents_restrictions.includes('DRIVER_LICENSE') ? ': forbidden' : ': allowed'}`}>
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('DRIVER_LICENSE') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-driver-license.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content={`Resident Permit ${session.app_steps[key].setting.documents_restrictions.includes('RESIDENT_PERMIT') ? ': forbidden' : ': allowed'}`}>
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('RESIDENT_PERMIT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-residence-permit.svg" />
																													</Tooltip>
																												</div>
																											</> : null}
																										{session.app_steps[key].step === 'RESIDENCY' ?
																											<>
																												<div>
																													<Tooltip auto={true} content="Gas Bill">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content="Water Bill">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content="Internet Bill">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content="Electricity Bill">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content="Landline Bill">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
																													</Tooltip>
																												</div>
																												<div>
																													<Tooltip auto={true} content="Bank Statement">
																														<img className={`h-8 mr-3 ${session.app_steps[key].setting.documents_restrictions.includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
																													</Tooltip>
																												</div>
																											</> : null}
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</>
																			: null}
																		<div className="flex justify-between mt-4">
																			<div>
																				<p className="text-sm font-bold text-primary-700">Restricted countries</p>
																			</div>
																		</div>
																		<div>
																			{session.app_steps[key].setting.restrictions.length === 0 ? <p className="text-center mt-3 text-gray-400 text-sm font-medium">No restricted countries</p> : null}
																			{Object.entries(session.app_steps[key].setting.restrictions).map(([country, t], k) => {
																				return <div key={country} className={session.app_steps[key].step === 'PHONE' ? 'mt-4' : 'cursor-pointer w-full mt-4 transition-25 transition duration-200 ease-in'}>
																					<div className="flex flex-wrap justify-between border hover:border-primary-200 ease-in transition-25 duration-200 p-2 rounded-xl">
																						{session.app_steps[key].step === 'PHONE' ?
																							<div className={'flex flex-1'}>
																								<div className="flex items-center ml-2">
																									<div>
																										<img className="relative h-4 top-[-1px] rounded-sm mr-2" src={`/img/icons/flags/${country}.svg`}></img>
																									</div>
																									<div>
																										<p key={country}>{country}</p>
																									</div>
																								</div>
																							</div>
																							: null}
																						<div className="flex mr-2">
																							{session.app_steps[key].step === 'IDENTITY' ?
																								<div>
																									<div className="flex mr-5">
																										<div>
																											<img className="relative h-4 mt-2 rounded-sm mr-2" src={`/img/icons/flags/${country}.svg`}></img>
																										</div>
																										<div className="relative mt-1 rounded-sm mr-5">
																											<p key={country}>{country}</p>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Passport">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('PASSPORT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-passport.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="National ID">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('NATIONAL_ID') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-national-id.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Driver License">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('DRIVER_LICENSE') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-driver-license.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Resident Permit">
																												<img className={`h-8 ${t[1] != undefined && t[1].includes('RESIDENT_PERMIT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-residence-permit.svg" />
																											</Tooltip>
																										</div>
																									</div>
																								</div>
																								: null}
																							{session.app_steps[key].step === 'RESIDENCY' ?
																								<div>
																									<div className="flex mr-5">
																										<div>
																											<img className="relative h-4 mt-2 rounded-sm mr-2" src={`/img/icons/flags/${country}.svg`}></img>
																										</div>
																										<div className="relative mt-1 rounded-sm mr-5">
																											<p key={country}>{country}</p>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Gas Bill">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('GAS_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-gas-bill.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Water Bill">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('WATER_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-water-bill.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Internet Bill">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('INTERNET_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-internet-bill.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Electricity Bill">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('ELECTRICITY_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-electricity-bill.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Landline Bill">
																												<img className={`h-8 mr-3 ${t[1] != undefined && t[1].includes('LANDLINE_BILL') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-document-landline-bill.svg" />
																											</Tooltip>
																										</div>
																										<div>
																											<Tooltip auto={true} content="Bank Statement">
																												<img className={`h-8 ${t[1] != undefined && t[1].includes('BANK_STATEMENT') ? 'opacity-25' : ''}`} src="/img/icons/ui/icon-bank-statement.svg" />
																											</Tooltip>
																										</div>
																									</div>
																								</div>
																								: null}
																						</div>
																					</div>
																				</div>
																			})}
																		</div>
																	</div>
																</div>

															</div>
															<hr className="my-3" />
														</main>
													</div>
												</div>
											</>
										)
									})}

									{projectNetwork !== null && projectNetwork.fields !== null ?
										<>
											<div>
												<div className='w-full h-full flex items-center p-5 pb-1'>
													<div>
														<img className="h-9 relative mr-2" src="/img/icons/ui/icon-corporate-register-standalone.svg" />
													</div>
													<div>
														<h2 className="text-lg text-primary-500  font-medium">Support contact</h2>
													</div>
												</div>
												<div className='flex'>
													<div className="flex  p-5 pb-1" >
														<div className="mb-3 flex" >
															<div className='bg-bluegray-100 p-5 text-center rounded-xl'>
																<FontAwesomeIcon className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faEnvelope}></FontAwesomeIcon>
															</div>
															<div className='ml-3 mt-2'>
																<p className="text-md text-primary-500 uppercase font-medium">support mail</p>
																<p className="text-sm text-primary-500">{projectNetwork.mail}</p>
															</div>
														</div>
													</div>
													<div className='flex  p-5 pb-1'>
														{projectNetwork.fields.map((field, i) => {
															return (
																<div key={i} className="mb-3 flex  mr-6" >
																	<div className='bg-bluegray-100 p-5 text-center rounded-xl'>
																		<FontAwesomeIcon className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={supportsIcons[field.type]}></FontAwesomeIcon>
																	</div>
																	<div className='ml-3 mt-2'>
																		<p className="text-md text-primary-500 uppercase font-medium">{field.type}</p>
																		<p className="text-sm text-primary-500">{field.value}</p>
																	</div>

																</div>
															)
														})}
													</div>
												</div>
											</div>
										</> : null}

								</> : null}
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-end'>
							<Button
								onClick={() => setOpen(false)}
								theme='secondary'
								label='Close'></Button>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
