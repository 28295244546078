import { useContext, useState } from "react";
import { FacematchVerificationContext } from "@/context/facematch_verification";
import { GlobalHotKeys } from "react-hotkeys";
import FacematchService from "@/services/facematch";
import { faCheck, faTimes, faHeadSide } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import HeaderButton from "@/components/ui/HeaderButton";
import ActivityFacematchSidebar from "./ActivityFacematchSidebar"
import { faStream } from "@fortawesome/pro-duotone-svg-icons";

export default function FacematchVisualCheck() {
  const [loading, setLoading] = useState(false);
  const [facematchVerification] = useContext(FacematchVerificationContext);
  const router = useHistory();
  const [activityOpen, setActivityOpen] = useState(false);

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function rejectFacematch() {
    setLoading(true);
    await FacematchService.RejectFacematch(
      facematchVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Facematch rejected successfully");
        router.push("/verifications/facematch");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function validateFacematch() {
    setLoading(true);
    await FacematchService.ValidateFacematch(
      facematchVerification.task.session_id
    )
      .then(() => {
        setLoading(false);
        Notification.success("Facematch validated successfully");
        router.push("/verifications/facematch");
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  const handlers = {};

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle
            value="Facematch Visual Check"
            icon={faHeadSide}
          ></StepTitle>
        </div>
        <HeaderButton
          onClick={() => setActivityOpen(true)}
          currentBg="primary-500"
          icon={faStream}
          badge={facematchVerification.task.activity.length}
          type="primary"
          label="Activity"
        ></HeaderButton>
      </div>
      <div className="flex flex-wrap mx-3">
        <div className="w-1/2">
          <div className="mx-auto">
            <div className="flex justify-between">
              <div className="mx-auto">
                <p className="ml-3 mb-3">Id Face</p>
              </div>
            </div>
            <img
              draggable={false}
              className={"object-contain h-128 w-full"}
              src={facematchVerification.verification.id_image}
            />
          </div>
        </div>
        <div className="w-1/2">
          <div>
            <div className="flex justify-between">
              <div className="mx-auto">
                <p className="ml-3 mb-3">Liveness Face</p>
              </div>
            </div>
            <img
              draggable={false}
              className={"object-contain h-128 w-full"}
              src={facematchVerification.verification.liveness_image}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap mx-3 mt-5">
        <div className="w-1/2">
          <div className="mx-auto">
            <img
              draggable={false}
              className={"object-contain h-128 w-full"}
              src={
                facematchVerification.identity.verification.document.front
                  .segmented
              }
            />
          </div>
        </div>
        {facematchVerification.identity.verification.document.back.segmented !==
          "" ? (
          <div className="w-1/2">
            <div>
              <img
                draggable={false}
                className={"object-contain h-128 w-full"}
                src={
                  facematchVerification.identity.verification.document.back
                    .segmented
                }
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="bg-bluegray-50 mt-8 p-3 rounded-xl">
        <div className="flex justify-center">
          <div className="mr-3">
            <Button
              loading={loading}
              onClick={() => rejectFacematch()}
              icon={faTimes}
              full
              label="Reject"
              theme="error"
            ></Button>
          </div>
          <div className="ml-3">
            <Button
              loading={loading}
              onClick={() => validateFacematch()}
              icon={faCheck}
              full
              label="Match"
              theme="success"
            ></Button>
          </div>
        </div>
      </div>
      <ActivityFacematchSidebar
        open={activityOpen}
        setOpen={setActivityOpen}
        verification={facematchVerification}
      ></ActivityFacematchSidebar>
    </GlobalHotKeys>
  );
}
