import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import { faEye, faIdCard, faPen } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import Alert from "@/components/ui/Alert";
import * as Notification from "@/components/ui/Notification";
import AnnotedImage from "@/components/ui/AnnotedImage";
import StepTitle from "@/components/ui/StepTitle";
import Input from "@/components/ui/Input";
import IdentityReviewInfoStepper from "@/components/identity/IdentityReviewInfoStepper";
import CountrySelector from "@/components/ui/CountrySelector";
import MiniButton from "@/components/ui/MiniButton";
import { useHistory } from "react-router-dom";

export default function IdentityInfo(props) {
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [featureValue, setFeatureValue] = useState("");
  const [featureUpdated, setFeatureUpdated] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [reject, setReject] = useState("");
  const router = useHistory();

  function updateFeatureValue(value) {
    setFeatureValue(value);
    if (value === identityVerification.info_checks[currentIndex].value) {
      setFeatureUpdated(false);
    } else {
      setFeatureUpdated(true);
    }
  }

  function updateCustomDateFeatureValue(value) {
    let newValue = featureValue + value;
    setFeatureValue(newValue);
    if (newValue === identityVerification.info_checks[currentIndex].value) {
      setFeatureUpdated(false);
    } else {
      setFeatureUpdated(true);
    }
  }

  const keyMap = {
    ENTER: ["enter"],
  };

  useEffect(() => {
    if (identityVerification.info_checks.length > 0) {
      setFeatureValue(identityVerification.info_checks[currentIndex].value);
      setFeatureUpdated(false);
    }
    return () => {};
  }, [currentIndex]);

  const handlers = {
    ENTER: setDocumentInfo,
  };

  function setNext() {
    if (currentIndex + 1 < identityVerification.info_checks.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      props.onUpdate();
    }
  }

  async function setDocumentInfo() {
    setLoading(true);
    await IdentityService.SetInfoReview(
      identityVerification.task.session_id,
      identityVerification.info_checks[currentIndex].slug,
      featureValue
    )
      .then(() => {
        setLoading(false);
        setNext();
      })
      .catch((err) => {
        if (
          err.api_code === "IDENTITY_DOCUMENT_DOB_DATE_MINOR" ||
          err.api_code === "IDENTITY_DOCUMENT_EXPIRED"
        ) {
          setReject(err.api_code);
        }
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  async function rejectIdentity() {
    setLoading(true);
    await IdentityService.Reject(identityVerification.task.session_id, reject)
      .then(() => {
        setLoading(false);
        Notification.success("Identity rejected successfully");
        router.push("/verifications/identity");
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
    return () => {};
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      {identityVerification.info_checks.length > 0 ? (
        <div id="template-selection" className="flex flex-wrap">
          <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
            <div className="flex justify-between">
              <div>
                <StepTitle value="Document" icon={faIdCard}></StepTitle>
              </div>
            </div>
            <div className="rounded-xl flex bg-bluegray-50 h-full items-center">
              <div className="w-full">
                <AnnotedImage
                  src={
                    identityVerification.info_checks[currentIndex].side ===
                    "FRONT"
                      ? identityVerification.media.segmented.front
                      : identityVerification.media.segmented.back
                  }
                  single
                  id="user-document"
                  sameColor
                  noStyle
                  margin={20}
                  className="object-contain h-80 w-full"
                  annotations={
                    identityVerification.info_checks[currentIndex].annotation
                  }
                />
              </div>
            </div>
          </div>
          <div className={"w-full mb-3 sm:w-1/2 pr-0 mt-16 sm:mt-0"}>
            <div className="flex justify-between">
              <div>
                <StepTitle value="Review" icon={faEye}></StepTitle>
              </div>
            </div>
            <div className={"bg-bluegray-50 rounded-xl flex h-full"}>
              <div className="w-full">
                <div className="flex flex-col h-full">
                  <header className="py-5 bg-bluegray-200 text-primary-500 font-regular rounded-t-xl">
                    <div className="flex justify-between">
                      <div>
                        <span className="inline-flex items-center border border-gray-300 px-2.5 py-0.5 rounded-md ml-5 text-sm font-medium bg-gray-100 text-gray-800">
                          {identityVerification.info_checks[currentIndex].slug}
                        </span>
                      </div>
                    </div>
                  </header>
                  <main className="flex-1 overflow-y-auto mx-6">
                    <p className="text-xs uppercase mt-6 text-gray-500 font-bold">
                      Value
                    </p>
                    {identityVerification.info_checks[currentIndex].slug ===
                    "nationality" ? (
                      <CountrySelector
                        placeholder="Search"
                        setValue={(value) => updateFeatureValue(value.alpha3)}
                        value={featureValue}
                      ></CountrySelector>
                    ) : (
                      <Input
                        className="mt-2"
                        setValue={(value) => updateFeatureValue(value)}
                        value={featureValue}
                      ></Input>
                    )}

                    {identityVerification.info_checks[currentIndex].slug ===
                      "birth_date" ||
                    identityVerification.info_checks[currentIndex].slug ===
                      "document_expiration" ? (
                      <div>
                        <p className="text-xs uppercase mt-4 text-gray-500 font-bold">
                          Arabic Number (européen)
                        </p>
                        <div className="flex justify-between mt-2">
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("0")}
                            theme="secondary"
                            label="0"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("1")}
                            theme="secondary"
                            label="1"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("2")}
                            theme="secondary"
                            label="2"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("3")}
                            theme="secondary"
                            label="3"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("4")}
                            theme="secondary"
                            label="4"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("5")}
                            theme="secondary"
                            label="5"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("6")}
                            theme="secondary"
                            label="6"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("7")}
                            theme="secondary"
                            label="7"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("8")}
                            theme="secondary"
                            label="8"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("9")}
                            theme="secondary"
                            label="9"
                          ></MiniButton>
                        </div>

                        <p className="text-xs uppercase mt-3 text-gray-500 font-bold">
                          Hindous Number (arabe)
                        </p>
                        <div className="flex justify-between mt-2">
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٠")}
                            theme="secondary"
                            label="٠"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("١")}
                            theme="secondary"
                            label="١"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٢")}
                            theme="secondary"
                            label="٢"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٣")}
                            theme="secondary"
                            label="٣"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٤")}
                            theme="secondary"
                            label="٤"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٥")}
                            theme="secondary"
                            label="٥"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٦")}
                            theme="secondary"
                            label="٦"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٧")}
                            theme="secondary"
                            label="٧"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٨")}
                            theme="secondary"
                            label="٨"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٩")}
                            theme="secondary"
                            label="٩"
                          ></MiniButton>
                        </div>

                        <p className="text-xs uppercase mt-3 text-gray-500 font-bold">
                          Persans Number (persan)
                        </p>
                        <div className="flex justify-between mt-2">
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٠")}
                            theme="secondary"
                            label="٠"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("١")}
                            theme="secondary"
                            label="١"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٢")}
                            theme="secondary"
                            label="٢"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٣")}
                            theme="secondary"
                            label="٣"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("۴")}
                            theme="secondary"
                            label="۴"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("۵")}
                            theme="secondary"
                            label="۵"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("۶")}
                            theme="secondary"
                            label="۶"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٧")}
                            theme="secondary"
                            label="٧"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٨")}
                            theme="secondary"
                            label="٨"
                          ></MiniButton>
                          <MiniButton
                            onClick={() => updateCustomDateFeatureValue("٩")}
                            theme="secondary"
                            label="٩"
                          ></MiniButton>
                        </div>
                      </div>
                    ) : null}
                    <div></div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between bg-bluegray-50 mt-16 p-3 rounded-xl">
              <div>
                <div className="relative top-4">
                  <IdentityReviewInfoStepper
                    current={currentIndex}
                    total={identityVerification.info_checks.length}
                  ></IdentityReviewInfoStepper>
                </div>
              </div>
              <div>
                {reject !== "" ? (
                  <Button
                    loading={loading}
                    className="mx-auto"
                    onClick={() => rejectIdentity()}
                    theme="error"
                    label="Reject"
                  ></Button>
                ) : (
                  <div>
                    {featureUpdated === false ? (
                      <Button
                        className="mx-auto"
                        onClick={() => setDocumentInfo()}
                        icon={faCheck}
                        theme="success"
                        loading={loading}
                        label="Validate"
                      />
                    ) : (
                      <Button
                        className="mx-auto"
                        onClick={() => setDocumentInfo()}
                        icon={faPen}
                        theme="orange"
                        loading={loading}
                        label="Edit"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </GlobalHotKeys>
  );
}
