import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import RegistryCountryDocListPage from '@/components/pages/RegistryCountryDocListPage'

function RegistryCountryDocList() {
	return (
		<AuthGuard>
			<IamGuard page='admin'>
				<div className='relative min-h-screen flex flex-col'>
					<Navbar></Navbar>
					<RegistryCountryDocListPage></RegistryCountryDocListPage>
				</div>
			</IamGuard>
		</AuthGuard>
	)
}

export default RegistryCountryDocList
