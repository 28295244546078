import { useReducer, createContext } from 'react'

export const CorporateVerificationContext = createContext()

function setSession(state, payload) {
	return {
		...payload,
		selectedStep: {
			current_step: payload.current_step,
			steps: ['owner', 'documents', 'beneficial'],
		},
		document: {
			review: false,
			value: {
				note: '',
			},
		},
	}
}

function setReviewOpen(state, payload) {
	return {
		...state,
		selectedStep: {
			current_step: 2,
			steps: ['owner', 'documents', 'beneficial'],
		},
		document: {
			review: true,
			key: payload.key,
			value: payload.value,
			corporate_id: payload.corporate_id,
		},
	}
}

function setReviewClose(state) {
	return {
		...state,
		selectedStep: {
			current_step: 2,
			steps: ['owner', 'documents', 'beneficial'],
		},
		document: {
			review: false,
			value: {
				note: '',
			},
		},
	}
}

function setStep(state, payload) {
	return {
		...state,
		selectedStep: {
			current_step: payload,
			steps: ['owner', 'documents', 'beneficial'],
		},
		document: {
			review: false,
			value: {
				note: '',
			},
		},
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_SESSION':
			return setSession(state, action.payload)
		case 'SET_STEP':
			return setStep(state, action.payload)
		case 'SET_REVIEW_OPEN':
			return setReviewOpen(state, action.payload)
		case 'SET_REVIEW_CLOSE':
			return setReviewClose(state, action.payload)
		default:
			return state
	}
}

export const CorporateVerificationContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, {})

	return (
		<CorporateVerificationContext.Provider value={[state, dispatch]}>
			{props.children}
		</CorporateVerificationContext.Provider>
	)
}
