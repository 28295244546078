import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import Alert from "@/components/ui/Alert";
import { faIdCard } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import StepTitle from "@/components/ui/StepTitle";
import Button from "@/components/ui/Button";
import InputMrzTD1 from "@/components/ui/InputMrzTD1";
import InputMrzTD2 from "@/components/ui/InputMrzTD2";
import InputMrzTD3 from "@/components/ui/InputMrzTD3";
import IdentityService from "@/services/identity";
import * as Notification from "@/components/ui/Notification";

export default function IdentityMrzLinesType(props) {
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [, setAnnotation] = useState(null);
  const [mrzInput, setMrzInput] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [requiredLength, setRequiredLength] = useState(0);

  const keyMap = {
    ENTER: ["enter"],
  };

  useEffect(() => {
    setAnnotation({
      slug: "mrz_lines",
      name: "MRZ",
      registry_position:
        identityVerification.verification.details.mrz.lines.position,
    });

    if (identityVerification.verification.details.mrz.lines.format === "TD1") {
      setRequiredLength(90);
    }
    if (identityVerification.verification.details.mrz.lines.format === "TD2") {
      setRequiredLength(72);
    }
    if (identityVerification.verification.details.mrz.lines.format === "TD3") {
      setRequiredLength(88);
    }
    return () => {};
  }, []);

  const handlers = {
    ENTER: () => extractMRZ(),
  };

  async function extractMRZ() {
    const body = {
      mrz_value: mrzInput,
    };

    setLoading(true);
    await IdentityService.ExtractMRZ(identityVerification.task.session_id, body)
      .then(() => {
        setLoading(false);
        Notification.success("MRZ Success");
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }
  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div id="template-selection" className="flex flex-wrap">
        <div className={"w-full mb-3 mx-auto pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Document" icon={faIdCard}></StepTitle>
            </div>
          </div>
          <div className="relative">
            <img
              className="object-contain w-full"
              src={
                identityVerification.verification.details.mrz.lines
                  .document_side === "FRONT"
                  ? identityVerification.media.segmented.front
                  : identityVerification.media.segmented.back
              }
            ></img>{" "}
          </div>
        </div>
        <div className="w-full">
          <div>
            {identityVerification.verification.details.mrz.lines.format ===
            "TD1" ? (
              <InputMrzTD1 value={[mrzInput, setMrzInput]} />
            ) : null}
            {identityVerification.verification.details.mrz.lines.format ===
            "TD2" ? (
              <InputMrzTD2 value={[mrzInput, setMrzInput]} />
            ) : null}
            {identityVerification.verification.details.mrz.lines.format ===
            "TD3" ? (
              <InputMrzTD3 value={[mrzInput, setMrzInput]} />
            ) : null}
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-between bg-bluegray-50 p-3 rounded-xl">
            <div></div>
            <div className="flex">
              <Button
                className="ml-3"
                loading={loading}
                disabled={mrzInput.trim().length !== requiredLength}
                onClick={() => extractMRZ()}
                icon={faCheck}
                full
                theme="success"
                label="Verify"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
