import { DuplicateContext } from '@/context/duplicate'
import DuplicateService from '@/services/duplicate'
import { useContext, useEffect } from 'react'
import Tab from '../ui/Tab'

export default function DuplicateTabs() {
	const [duplicate, duplicateDispatch] = useContext(DuplicateContext)

	useEffect(async () => {
		await DuplicateService.GetStats()
			.then((data) => {
				duplicateDispatch({
					type: 'SET_STATS',
					payload: {
						stats: data,
					},
				})
			})
			.catch(() => {})
		return () => {}
	}, [])

	function setCurrentTab(currentTab) {
		duplicateDispatch({
			type: 'SET_CURRENT_TAB',
			payload: {
				tab: currentTab,
			},
		})
	}

	return duplicate.tabs.map((tab) => {
		return (
			<Tab
				badge={duplicate.stats[tab.key]}
				selectedKey={duplicate.selectedTab.key}
				key={tab.key}
				item={tab}
				onClick={() => setCurrentTab(tab)}></Tab>
		)
	})
}
