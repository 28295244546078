import { useContext, useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import PinCard from '@/components/ui/PinCard'
import {
	faAddressCard,
	faBug,
	faCodeBranch,
	faComment,
	faComments,
	faCrop,
	faCropAlt,
	faFingerprint,
	faQrcode,
	faUser,
	faUserSecret,
} from '@fortawesome/pro-duotone-svg-icons'
import { IdentityVerificationContext } from '@/context/identity_verification'
import Textarea from '@/components/ui/Textarea'
import * as Notification from '@/components/ui/Notification'
import IdentityService from '@/services/identity'
import { useHistory } from 'react-router-dom'

export default function PinIdentitySidebar({ open, setOpen }) {
	const [pin, setPin] = useState('')
	const [note, setNote] = useState('')
	const [loading, setLoading] = useState(false)
	const router = useHistory()

	const [identityVerification] = useContext(IdentityVerificationContext)

	function updatePin(newPin) {
		if (pin === newPin) {
			setPin('')
		} else {
			setPin(newPin)
		}
	}

	async function pinIdentityTask() {
		const body = {
			action: pin,
			note: note,
		}

		setLoading(true)
		await IdentityService.PinIdentityTask(
			identityVerification.task.session_id,
			body
		)
			.then(() => {
				setLoading(false)
				Notification.success('Task pinned successfully')
				router.push('/verifications/identity')
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => {}
	}

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-orange-400 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										Pin Identity
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-orange-400 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className='mt-1'>
									<p className='text-sm text-white'>
										Describe the issue encountered during the identity
										verification process
									</p>
								</div>
							</div>
							<div className='flex-1 flex flex-col justify-between'>
								<div className='px-4 py-2'>
									{identityVerification.verification.steps.current_step ===
									'segmentation' ? (
										<>
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Segmentation
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='SEGMENTATION_FAIL'
												title='Segmentation issue'
												description='Error during segmentation process'
												icon={faCropAlt}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'template_selection' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Template Selection
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='TEMPLATE_UNCERTAINTY'
												title='Template Uncertainty'
												description='Not sure about if the template is the real one'
												icon={faAddressCard}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'info_review' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Info Review
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='INFO_REVIEW_UNCERTAINTY'
												title='Info Review Uncertainty'
												description='Uncertainty about info values'
												icon={faUser}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'info_extraction' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Info Extract
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='INFO_EXTRACT_UNCERTAINTY'
												title='Info Extract Uncertainty'
												description='Uncertainty about info extraction'
												icon={faCrop}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'mrz' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Machine-Readable Zone (MRZ)
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='MRZ_INVALID'
												title='Invalid MRZ'
												description='Machine-Readable Zone (MRZ) is invalid'
												icon={faQrcode}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'crosscheck' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Crosscheck
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='CROSSCHECK_UNCERTAINTY'
												title='Crosscheck Uncertainty'
												description='Uncertainty about crosscheck values'
												icon={faCodeBranch}></PinCard>
										</>
									) : null}

									{identityVerification.verification.steps.current_step ===
									'features' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Features
											</p>
											<PinCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='FEATURES_UNCERTAINTY'
												title='Features Uncertainty'
												description='Uncertainty about features'
												icon={faFingerprint}></PinCard>
										</>
									) : null}

									<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
										Forgery
									</p>
									<PinCard
										selected={pin}
										setPin={(slug) => updatePin(slug)}
										slug='FORGERY_DOCUMENT_LOOK_FORGED'
										title='Document seems forged'
										description='The current document seems forged'
										icon={faUserSecret}></PinCard>

									<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
										Panel Platform
									</p>
									<PinCard
										selected={pin}
										setPin={(slug) => updatePin(slug)}
										slug='INTERNAL_ISSUE'
										title='Bug'
										description='Internal Synaps Panel issue'
										icon={faBug}></PinCard>
									<PinCard
										selected={pin}
										setPin={(slug) => updatePin(slug)}
										slug='INTERNAL_MESSAGE'
										title='Message'
										description='Internal Team Message'
										icon={faComments}></PinCard>

									<p className='pt-2 mb-4 uppercase text-gray-500 font-bold text-xs'>
										Additional Information
									</p>
									<Textarea
										placeholder='Write additional details'
										setValue={(value) => setNote(value)}></Textarea>
								</div>
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-end'>
							<Button
								onClick={() => setOpen(false)}
								theme='secondary'
								label='Cancel'></Button>
							{pin !== '' ? (
								<Button
									loading={loading}
									className='ml-2'
									onClick={() => pinIdentityTask()}
									theme='pin'
									label='Pin'></Button>
							) : null}
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
