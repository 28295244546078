import {
	faUsers,
	faPassport,
	faFileInvoice,
	faStream,
	faUniversity,
	faHeadSide,
	faGlobe,
	faCodeBranch,
	faUserFriends,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import Alert from '../ui/Alert'
import CardTitle from '../ui/CardTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import AgentsService from '@/services/agents'

export default function AgentPermission(props) {
	const [error, setError] = useState(null)

	async function AddPermission(permission) {
		let body = {
			'email': props.agent.agent.email,
			'role': permission,
		}
		await AgentsService.AddPermission(body)
			.then(() => {
				props.onUpdate()
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}

	async function DeletePermission(permission) {
		let body = {
			'email': props.agent.agent.email,
			'role': permission,
		}
		await AgentsService.DeletePermission(body)
			.then(() => {
				props.onUpdate()
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									<div>
										<CardTitle
											value="Agent Permission"
											icon={faUsers}></CardTitle>
									</div>
								</div>
								<form>
									<div>
										<div className="py-2 px-3">
											{error ? (
												<Alert type="error" title={error}></Alert>
											) : null}
										</div>
										{props.agent && props.agent.agent.iam.includes('IDENTITY') ? (
											<div onClick={() => DeletePermission('IDENTITY')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faPassport}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Identity</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('IDENTITY')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faPassport}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Identity</p>
													</div>
												</div>
											</div>}
										{props.agent && props.agent.agent.iam.includes('RESIDENCY') ? (
											<div onClick={() => DeletePermission('RESIDENCY')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faFileInvoice}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Residency</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('RESIDENCY')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faFileInvoice}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Residency</p>
													</div>
												</div>
											</div>
										}
										{props.agent && props.agent.agent.iam.includes('CROSSCHECK') ? (
											<div onClick={() => DeletePermission('CROSSCHECK')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faCodeBranch}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Crosscheck</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('CROSSCHECK')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faCodeBranch}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Crosscheck</p>
													</div>
												</div>
											</div>
										}
										{props.agent && props.agent.agent.iam.includes('FACEMATCH') ? (
											<div onClick={() => DeletePermission('FACEMATCH')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faHeadSide}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Facematch</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('FACEMATCH')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faHeadSide}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Facematch</p>
													</div>
												</div>
											</div>
										}
										{props.agent && props.agent.agent.iam.includes('DUPLICATE') ? (
											<div onClick={() => DeletePermission('DUPLICATE')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faUserFriends}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Duplicate</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('DUPLICATE')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faUserFriends}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Duplicate</p>
													</div>
												</div>
											</div>
										}
										{props.agent && props.agent.agent.iam.includes('AML') ? (
											<div onClick={() => DeletePermission('AML')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faStream}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Aml</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('AML')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faStream}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Aml</p>
													</div>
												</div>
											</div>}
										{props.agent && props.agent.agent.iam.includes('CORPORATE') ? (
											<div onClick={() => DeletePermission('CORPORATE')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md bg-green-300 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faUniversity}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Corporate</p>
													</div>
												</div>
											</div>
										) :
											<div onClick={() => AddPermission('CORPORATE')} className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faUniversity}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Corporate</p>
													</div>
												</div>
											</div>}
										{props.agent && props.agent.agent.iam.includes('ADMIN') ? (
											<div className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 bg-green-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faGlobe}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Admin</p>
													</div>
												</div>
											</div>
										) :
											<div className="flex justify-center mb-4 border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
												<div className="w-1/2 text-center">
													<FontAwesomeIcon
														className={'text-left ml-2 text-3xl mt-0.5 mr-2'}
														icon={faGlobe}></FontAwesomeIcon>
												</div>										<div className="p-6 w-1/2">
													<div className="text-center">
														<p className="text-sm">Admin</p>
													</div>
												</div>
											</div>}
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
