import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'
import { faFile } from '@fortawesome/pro-duotone-svg-icons'
import { useContext } from 'react'
import { CorporateVerificationContext } from '@/context/corporate_verification'

export default function CorporateItem(props) {
	const [, corporateVerificationDispatch] = useContext(
		CorporateVerificationContext
	)

	async function SwitchReview(key, value) {
		await corporateVerificationDispatch({
			type: 'SET_REVIEW_OPEN',
			payload: {
				key: key,
				value: value,
				corporate_id: props.corporate_id,
			},
		})
	}

	return (
		<>
			<div className={'p-3  rounded-xl border border-bluegray-100'}>
				<div className="flex justify-center">
					<div className="text-3xl">
						<FontAwesomeIcon
							className="text-corporate-500"
							icon={props.icon}></FontAwesomeIcon>
					</div>
				</div>

				<div className="flex justify-center">
					<div>
						<p className="text-xs">
							<span
								className={'uppercase font-semibold text-bluegray-700 text-xs'}>
								{props.name}
							</span>
						</p>
					</div>
				</div>
				<div className="flex justify-center mt-3">
					{Object.entries(props.documents).map(([key, value]) => {
						return (
							<Tooltip auto={true} content={value.name} key={key}>
								<div className="transform hover:scale-110 motion-reduce:transform-none cursor-pointer text-2xl mr-2 ml-2">
									{value.state === 2 ? (
										<FontAwesomeIcon
											className="text-green-500"
											icon={faFile}
											onClick={() =>
												SwitchReview(key, value)
											}></FontAwesomeIcon>
									) : null}
									{value.state === 1 ? (
										<FontAwesomeIcon
											className="text-orange-500"
											icon={faFile}
											onClick={() =>
												SwitchReview(key, value)
											}></FontAwesomeIcon>
									) : null}
									{value.state === 0 ? (
										<FontAwesomeIcon
											className="text-corporate-500"
											onClick={() => SwitchReview(key, value)}
											icon={faFile}></FontAwesomeIcon>
									) : null}
								</div>
							</Tooltip>
						)
					})}
				</div>
			</div>
		</>
	)
}
