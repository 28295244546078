import { useParams } from "react-router-dom";
import {
  faClock,
  faExclamationCircle,
  faDoNotEnter,
  faCheckCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import * as Notification from "@/components/ui/Notification";
import IndividualService from "@/services/individual";
import { AuthContext } from "@/context/auth";

export default function IndividualLiveness(props) {
  const { app_id, session_id } = useParams();
  const [auth] = useContext(AuthContext);

  async function ResetIndividualResource() {
    await IndividualService.ResetStep(session_id, props.step_id)
      .then(async (data) => {
        Notification.success("Onbaording updated");
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  function LivenessTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              Liveness Verification
            </p>
            {props.sub_session_id !== "" && props.sub_session_id !== undefined ? (
              <p className="font-medium text-gray-400">
                #{props.sub_session_id}
              </p>
            ) : null}
          </div>
          <div>
            <div className="flex">
              {props.reset !== 0 && props.reset !== undefined ? (
                <>
                  {props.reset === 1 ? (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1 mr-4">
                      {props.reset} reset
                    </p>
                  ) :
                    <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1 mr-4">
                      {props.reset} resets
                    </p>
                  }
                </>
              ) : null}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <>
                  <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                    <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} />{" "}
                    Not started
                  </p>
                </>
              ) : null}
              {state === "FINAL_REJECTED" || state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDoNotEnter} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheckCircle} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state !== "NOT_STARTED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function LivenessFooter({ state }) {
    return (
      <div>
        <hr className="my-4 border-bluegray-200" />
        <div className="flex justify-between items-center">
          <div>
          </div>
          <div>
            <div className="flex">
              {state !== "NOT_STARTED" &&
                props.source !== "CORPORATE" && props.reset !== 2 &&
                (auth.iam.admin === true || auth.iam.support_manager === true || auth.iam.support_agent === true) ? (
                <p
                  onClick={() => ResetIndividualResource()}
                  className="text-white cursor-pointer text-sm bg-primary-500 rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>
              ) :
                <p
                  className="text-gray-400 text-sm bg-gray-100 cursor-not-allowed rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${props.capitalize ? "capitalize" : null
                }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function InfoFeature(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs capitalize">
              {props.title}
            </p>
            {props.included ? (
              <p className="text-green-500 text-sm">{props.label}</p>
            ) : (
              <p className="text-red-500 text-sm">Not {props.label}</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  function LivenessDetails({ step, step_id }) {
    return (
      <div>
        <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
          Challenge
        </p>
        <div className="flex flex-wrap mt-3">
          <div className="w-1/4 pr-3">
            <div
              className="bg-bluegray-100 w-full h-[250px] px-2 rounded-md mt-3"
              style={{
                backgroundImage: `url(${step.face})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
              }}
            ></div>
          </div>
          <div className="w-3/4 pl-3">
            <div>
              <InfoFeature
                title="Challenge"
                icon={"icon-valid"}
                included={true}
                label="Passed"
              />
            </div>
            <div>
              <InfoItem
                title="Attempts"
                icon={"look-in-camera"}
                included={true}
                value={step.attempts}
              />
            </div>
            <div>
              <InfoItem
                title="Enrolled at"
                icon={"icon-timeout"}
                included={true}
                value={step.enrollment_date ? step.enrollment_date : "-"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <LivenessTitle title={props.title} state={props.step.state} />
      {props.step.state === "VALIDATED" || props.step.state === "REJECTED" ? (
        <LivenessDetails step_id={props.step_id} step={props.step} />
      ) : null}
      <LivenessFooter title={props.title} state={props.step.state} />
    </div>
  );
}
