import CorporateStep from './CorporateStep'
import { useContext } from 'react'
import { CorporateVerificationContext } from '@/context/corporate_verification'

export default function CorporateSteps() {
	const [corporateVerification] = useContext(CorporateVerificationContext)
	return (
		<>
			<nav aria-label='Progress'>
				<ol className='border-t border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0'>
					<CorporateStep
						label='Corporate Officer'
						number='1'
						step='0'
						current={
							corporateVerification.selectedStep.steps[
								corporateVerification.selectedStep.current_step
							] === 'owner'
						}
						done={
							corporateVerification.verification.steps.owner_verification
						}></CorporateStep>
					<CorporateStep
						label='Corporate Documents'
						number='2'
						step='1'
						current={
							corporateVerification.selectedStep.steps[
								corporateVerification.selectedStep.current_step
							] === 'documents'
						}
						done={
							corporateVerification.verification.steps.corporate_documents
						}></CorporateStep>
					<CorporateStep
						label='Beneficial Owners'
						number='3'
						step='2'
						current={
							corporateVerification.selectedStep.steps[
								corporateVerification.selectedStep.current_step
							] === 'beneficial'
						}
						last
						done={
							corporateVerification.verification.steps.beneficial_owners
						}></CorporateStep>
				</ol>
			</nav>
		</>
	)
}
