import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import Alert from "@/components/ui/Alert";
import {
  faCabinetFiling,
  faIdCard,
  faQrcode,
} from "@fortawesome/pro-duotone-svg-icons";
import StepTitle from "@/components/ui/StepTitle";
import Cropper from "@/components/ui/Cropper";
import AnnotedImage from "@/components/ui/AnnotedImage";
import Button from "@/components/ui/Button";
import * as Notification from "@/components/ui/Notification";
import IdentityService from "@/services/identity";

export default function IdentityMrzLinesExtract(props) {
  const [loading, setLoading] = useState(false);
  const [error] = useState("");
  const [annotation, setAnnotation] = useState(null);
  const [featureAnnotation, setFetureAnnotation] = useState(null);
  const [identityVerification] = useContext(IdentityVerificationContext);

  const keyMap = {
    ENTER: ["enter"],
  };

  useEffect(() => {
    setAnnotation({
      slug: "mrz_lines",
      name: "MRZ",
      registry_position:
        identityVerification.verification.details.mrz.lines.registry_position,
    });
    return () => {};
  }, []);

  const handlers = {
    ENTER: () => extractMRZ(),
  };

  async function extractMRZ() {
    const body = {
      position: featureAnnotation.position,
    };

    setLoading(true);
    await IdentityService.ExtractMRZ(identityVerification.task.session_id, body)
      .then(() => {
        setLoading(false);
        Notification.success("MRZ Success");
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        props.onUpdate();
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div id="template-selection" className="flex flex-wrap">
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Document" icon={faIdCard}></StepTitle>
            </div>
          </div>
          <div className="relative">
            <Cropper
              src={
                identityVerification.verification.details.mrz.lines
                  .document_side === "FRONT"
                  ? identityVerification.media.segmented.front
                  : identityVerification.media.segmented.back
              }
              setPosition={(coordinates) =>
                setFetureAnnotation({
                  name: "MRZ",
                  slug: "mrz_lines",
                  position: coordinates,
                })
              }
            />
          </div>
        </div>
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle
                value="Registry Template"
                icon={faCabinetFiling}
              ></StepTitle>
            </div>
          </div>
          {annotation ? (
            <AnnotedImage
              single
              annotations={annotation}
              type="registry"
              id="registry-doc"
              className="object-contain h-80 w-full"
              src={
                identityVerification.verification.details.mrz.lines
                  .document_side === "FRONT"
                  ? identityVerification.media.template.front
                  : identityVerification.media.template.back
              }
            ></AnnotedImage>
          ) : null}
        </div>

        <div className="w-full">
          <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
            <div></div>
            <div className="flex">
              <Button
                disabled={!featureAnnotation}
                className="mr2p3"
                loading={loading}
                onClick={() => extractMRZ()}
                icon={faQrcode}
                full
                theme="success"
                label="Extract"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
