import { LoginContext } from '@/context/login'
import { useContext } from 'react'

export default function Login() {
	const [loginState] = useContext(LoginContext)

	return (
		<>
			<div className='min-h-screen px-8 bg-primary-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
				<div className='mx-auto'>
					<img
						className='w-64'
						src='/img/logo/synaps-panel-logo-white.svg'></img>
				</div>
				<div>
					<div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-4 shadow'>
						{loginState.currentComponent}
					</div>
				</div>
			</div>
		</>
	)
}
