import { useParams } from "react-router-dom";
import {
  faClock,
  faPen,
  faDoNotEnter,
  faExclamationCircle,
  faFileDownload,
  faFileUpload,
  faFileCheck,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/pro-solid-svg-icons";
import { faCheckCircle, faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countries from "@/resources/countries_label.json";
import { Modal } from "react-responsive-modal";
import { useContext, useState, useEffect } from 'react'
import IndividualService from "@/services/individual";
import * as Notification from "@/components/ui/Notification";
import { AuthContext } from "@/context/auth";
import SupportService from "@/services/support";
import UpdateValue from "@/components/sessions/UpdateValue";
import { Collapse } from 'react-collapse';

export default function IndividualResidency(props) {
  const { app_id, session_id } = useParams();
  const [open, setOpen] = useState(false);
  const [auth] = useContext(AuthContext);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [manageable, setManageable] = useState(false);

  const [historyCollapse, setHistoryCollapse] = useState([]);
  const [documentHistory, setDocumentHistory] = useState([]);

  const [titleHistory, setTitleHistory] = useState(null);
  const [titleCollapse, setTitleCollapse] = useState(false);

  async function ResetIndividualResource() {
    await IndividualService.ResetStep(session_id, props.step_id)
      .then(async (data) => {
        Notification.success("Onbaording updated");
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  async function ResetTaskIndividualResource() {
    await IndividualService.ResetTaskStep(session_id, props.step_id)
      .then(async (data) => {
        Notification.success("Onbaording updated");
      })
      .catch((err) => {
        Notification.error(err.message);
      });
  }

  useEffect(() => {
    let collapseHistory = []
    let newDocumentHistory = []
    let index = 0
    props.step.history.reverse().forEach(element => {
      if (index === 0) {
        if (props.step.history.length <= 1) {
          setTitleCollapse(true)
        }
        setTitleHistory(element)
      } else {
        newDocumentHistory.push(element)
        collapseHistory.push(false)
      }
      index = index + 1
    });
    setHistoryCollapse(collapseHistory)
    setDocumentHistory(newDocumentHistory)
    return () => { }
  }, [])

  function CollapseHistory(index) {
    let collapseHistory = []
    let i = 0
    historyCollapse.forEach(element => {
      if (i === index) {
        collapseHistory.push(!element)
      } else {
        collapseHistory.push(element)
      }
      i = i + 1
    });
    setHistoryCollapse(collapseHistory)
  }

  function ResidencyTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "Proof of Residency"}
            </p>
            {props.sub_session_id !== "" && props.sub_session_id !== undefined ? (
              <p className="font-medium text-gray-400">
                #{props.sub_session_id}
              </p>
            ) : null}
          </div>
          <div>
            <div className="flex">
              {props.reset !== 0 && props.reset !== undefined ? (
                <>
                  {props.reset === 1 ? (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1 mr-4">
                      {props.reset} reset
                    </p>
                  ) :
                    <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1 mr-4">
                      {props.reset} resets
                    </p>
                  }
                </>
              ) : null}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <>
                  {props.step.history.length === 0 ? (
                    <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                      <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} />{" "}
                      Not started
                    </p>
                  ) : (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                      <FontAwesomeIcon
                        className="mr-0.5"
                        icon={faExclamationCircle}
                      />{" "}
                      Rejected
                    </p>
                  )}
                </>
              ) : null}
              {state === "FINAL_REJECTED" || state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDoNotEnter} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheckCircle} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state !== "NOT_STARTED" ||
          (state === "NOT_STARTED" && props.step.history.length > 0) ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function ResidencyFooter({ state }) {
    return (
      <div>
        <hr className="my-4 border-bluegray-200" />
        <div className="flex justify-between items-center">
          <div>
            {props.step.history.length > 0 ? (
              <p
                onClick={() => setOpen(true)}
                className="text-blue-500  cursor-pointer text-sm px-5 p-3 mr-3"
              >
                Show History
              </p>
            ) : null}
          </div>
          <div>
            <div className="flex">
              {state === "NOT_STARTED" &&
                props.source !== "CORPORATE" &&
                props.step.history.length > 0 &&
                (auth.iam.admin === true || auth.iam.support_manager === true) ? (
                <p
                  onClick={() => ResetTaskIndividualResource()}
                  className="text-black hover:bg-gray-100 ease-in duration-200 transition cursor-pointer text-sm border border-gray-200 rounded-md px-5 p-3 mr-3"
                >
                  Back to task
                </p>
              ) : null}
              {state !== "VALIDATED" && state !== "NOT_STARTED" && props.reset !== 2 &&
                props.source !== "CORPORATE" &&
                (auth.iam.admin === true || auth.iam.support_manager === true || auth.iam.support_agent === true) ? (
                <p
                  onClick={() => ResetIndividualResource()}
                  className="text-white cursor-pointer text-sm bg-primary-500 rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>
              ) :
                <p
                  className="text-gray-400 text-sm bg-gray-100 cursor-not-allowed rounded-md px-5 p-3 mr-3"
                >
                  Reset
                </p>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function EditInfo(title, value) {
    setValue(value);
    setTitle(title);
    setManageable(true);
  }

  async function UpdateIndividualValue(value) {
    let body = {
      value: value,
      type: title.toUpperCase(),
      session_id: session_id,
      step_id: props.step_id,
    };
    await SupportService.UpdateValue(body)
      .then(async (data) => {
        Notification.success("Value updated");
        props.onRefresh();
        setManageable(false);
      })
      .catch((err) => {
        Notification.error(err.message);
        setManageable(false);
      });
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${props.capitalize ? "capitalize" : null
                }`}
            >
              {props.value}
            </p>
          </div>
          <div className="group">
            {(auth.iam.admin === true || auth.iam.support_manager === true) &&
              props.source !== "CORPORATE" ? (
              <FontAwesomeIcon
                onClick={() => EditInfo(props.title, props.value)}
                className="mt-3 ml-4 text-regular font-bold text-sm text-gray-500 cursor-pointer group-hover:text-red-500  transition duration-100 ease-in ml-2"
                icon={faPen}
              ></FontAwesomeIcon>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  function InfoHistoryItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${props.capitalize ? "capitalize" : null
                }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function CountryItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 flex-shrink-0">
            <div
              className="h-9 w-9"
              style={{
                backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${"/img/icons/ui/icon-round.svg"})`,
                backgroundSize: "60%, contain",
                backgroundRepeat: "no-repeat, no-repeat",
                backgroundPosition: "center center, center center",
              }}
            ></div>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p className="text-gray-600 text-sm">{props.value}</p>
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDocument({ step_id, info, member_id, document }) {
    return (
      <div>
        <div className="mt-3">
          <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
            Document Details
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3">
              <iframe
                className="w-full h-[728px]"
                src={document.document.file}
              ></iframe>{" "}
            </div>
            <div className="w-2/6 pl-3 mt-4">
              <div>
                <InfoItem
                  icon={"icon-firstname"}
                  title="Firstname"
                  source={props.source}
                  value={info.firstname ? info.firstname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-lastname"}
                  title="Lastname"
                  source={props.source}
                  value={info.lastname ? info.lastname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-address"}
                  title="Address"
                  source={props.source}
                  value={info.address ? info.address : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-city"}
                  title="City"
                  source={props.source}
                  value={info.city ? info.city : "-"}
                />
              </div>
              <div>
                <InfoItem
                  icon={"icon-zipcode"}
                  title="Zip Code"
                  source={props.source}
                  value={info.zipcode ? info.zipcode : "-"}
                />
              </div>
              <div>
                <CountryItem
                  title="Country"
                  source={props.source}
                  value={info.country ? countries[info.country] : "-"}
                  country={info.country}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDocumentAccomodation({
    step_id,
    info,
    member_id,
    document,
  }) {
    return (
      <div>
        <div className="mt-6">
          <p className="mt-6 mb-3 uppercase tracking-wide font-medium text-sm text-primary-400">
            Proof of accomodation
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3">
              <iframe
                className="w-full h-[728px]"
                src={document.accomodation_proof}
              ></iframe>
            </div>
            <div className="w-2/6 pl-3 mt-4">
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-firstname"}
                  title="Firstname"
                  value={info.firstname ? info.firstname.toLowerCase() : "-"}
                />
              </div>
              <div>
                <InfoItem
                  capitalize
                  icon={"icon-lastname"}
                  title="Lastname"
                  value={info.lastname ? info.lastname.toLowerCase() : "-"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ResidencyDetails({ step, step_id, member_id }) {
    return (
      <div>
        <ResidencyDocument
          member_id={member_id}
          step_id={step_id}
          document={step.document}
          info={step.document.info}
        />
        {step.document.is_accomodation ? (
          <ResidencyDocumentAccomodation
            member_id={member_id}
            step_id={step_id}
            document={step.document}
            info={step.document.info}
          />
        ) : null}
      </div>
    );
  }

  function HistoryTitle() {
    return (
      <div className="history-title">
        <div className="flex justify-between items-center pb-2 px-6 pt-6">
          <div>
            <p className="font-medium text-primary-400">
              Proof of residency
            </p>
          </div>
          <div>
            <div className="flex">
              {props.step.state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {props.step.state === "NOT_STARTED" ? (
                <>
                  {props.step.history.length === 0 ? (
                    <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                      <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} />{" "}
                      Not started
                    </p>
                  ) : (
                    <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                      <FontAwesomeIcon
                        className="mr-0.5"
                        icon={faExclamationCircle}
                      />{" "}
                      Rejected
                    </p>
                  )}
                </>
              ) : null}
              {props.step.state === "FINAL_REJECTED" || props.step.state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDoNotEnter} /> Final
                  Rejected
                </p>
              ) : null}
              {props.step.state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheckCircle} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {props.step.history.length > 0 ?
          <>
            <div onClick={() => setTitleCollapse(!titleCollapse)} className="mt-4 px-5 pb-2 flex justify-between cursor-pointer">
              <div>
                {titleHistory.state !== "VALIDATED" ?
                  <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                    <div className="top-[2px]">
                      <p className="text-sm font-bold mb-2">CUSTOMER REASON</p>
                      <p className='text-sm'>
                        {titleHistory.customer_reason}
                      </p>
                    </div>
                  </div> : null}
              </div>
              {titleCollapse ? (
                <div><FontAwesomeIcon className="mr-1" icon={faCaretUp} /></div>
              ) : (<div><FontAwesomeIcon className="mr-1" icon={faCaretDown} /></div>)}
            </div>
            <Collapse isOpened={titleCollapse}>
              <div className="px-4">
                <div className="flex px-2">
                  <div className="bg-bluegray-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-gray-500" icon={faFileDownload} /></div></div>
                  <div className="mt-3 ml-4">
                    <p className="text-sm font-bold mb-2">SUBMITED AT</p>
                    <p className='text-sm'>
                      {titleHistory.submitted_at}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-8">
                <div className="vl pl-4"></div>
              </div>
              <div className="px-4 mb-3">
                <div className="flex px-2">
                  <div className="bg-bluegray-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-gray-500" icon={faFileCheck} /></div></div>
                  <div className="mt-3 ml-4">
                    <p className="text-sm font-bold mb-2">VERIFIED AT</p>
                    <p className='text-sm'>
                      {titleHistory.verified_at}
                    </p>
                  </div>
                </div>
              </div>
            </Collapse>
            <hr className="border-bluegray-200" />
          </>
          : null}
      </div >
    );
  }

  function HistoryElement({ element, index }) {
    return (
      <div className="history-title">
        <div onClick={() => CollapseHistory(index)} className="mt-4 px-5 pb-2 flex justify-between cursor-pointer">
          <div>
            {element.state === "REJECTED" ?
              <div className="mb-3">
                <div className="flex px-1">
                  <div>
                    <div className="bg-orange-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-orange-500" icon={faFileUpload} /></div></div>
                  </div>
                  <div className="mt-3 ml-4">
                    <p className="text-sm font-bold mb-2">RESUBMISSION REQUEST</p>
                    <p className='text-sm'>
                      {element.customer_reason}
                    </p>
                  </div>
                </div>
              </div> : null}
            {element.state === "FINALE_REJECTED" ?
              <div className="mb-3">
                <div className="flex px-1">
                  <div>
                    <div className="bg-red-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-red-500" icon={faDoNotEnter} /></div></div>
                  </div>
                  <div className="mt-3 ml-4">
                    <p className="text-sm font-bold mb-2">FINAL REJECTED</p>
                    <p className='text-sm'>
                      {element.customer_reason}
                    </p>
                  </div>
                </div>
              </div> : null}
            {element.state === "VALIDATED" ?
              <div className="mb-3">
                <div className="flex px-1">
                  <div>
                    <div className="bg-green-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-green-500" icon={faFileCheck} /></div></div>
                  </div>
                  <div className="mt-3 ml-4">
                    <p className="text-sm font-bold mb-2">VALIDATED</p>
                    <p className='text-sm'>
                      {element.customer_reason}
                    </p>
                  </div>
                </div>
              </div> : null}
          </div>
          {historyCollapse[index] ? (
            <div><FontAwesomeIcon className="mr-1" icon={faCaretUp} /></div>
          ) : (<div><FontAwesomeIcon className="mr-1" icon={faCaretDown} /></div>)}
        </div>
        <Collapse isOpened={historyCollapse[index]}>
          <div className="px-4">
            <div className="flex px-2">
              <div className="bg-bluegray-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-gray-500" icon={faFileDownload} /></div></div>
              <div className="mt-3 ml-4">
                <p className="text-sm font-bold mb-2">SUBMITED AT</p>
                <p className='text-sm'>
                  {element.submitted_at}
                </p>
              </div>
            </div>
          </div>
          <div className="ml-8">
            <div className="vl pl-4"></div>
          </div>
          <div className="px-4 mb-3">
            <div className="flex px-2">
              <div className="bg-bluegray-100 p-2 rounded-full"><div className="m-2"><FontAwesomeIcon className="text-regular mx-2 mt-1 mb-1 font-bold text-xl text-gray-500" icon={faFileCheck} /></div></div>
              <div className="mt-3 ml-4">
                <p className="text-sm font-bold mb-2">VERIFIED AT</p>
                <p className='text-sm'>
                  {element.verified_at}
                </p>
              </div>
            </div>
          </div>
        </Collapse>
      </div >
    );
  }

  function ResidencyBaseDetails({ step, step_id, member_id }) {
    return (
      <div>
        {step.state === "REJECTED" ? (
          <div className="flex flex-wrap">
            <div className="w-1/2">
              <InfoItem
                icon={"icon-firstname"}
                title="Customer Reason"
                value={step.document.rejection.customer_reason}
              />
            </div>
          </div>
        ) : null}
        <div className="mt-5">
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3">
              <iframe
                className="w-full h-[728px]"
                src={step.document.document.file}
              ></iframe>
            </div>
          </div>
        </div>

        {step.document.is_accomodation && step.state === "VALIDATED" ? (
          <div className="mt-5">
            <div className="flex flex-wrap">
              <div
                className={`${step.document.accomodation.document.type === "PASSPORT"
                  ? "w-full"
                  : "w-1/2 pr-3"
                  }`}
              >
                <p className="uppercase tracking-wide font-medium text-sm text-primary-400">
                  {step.document.accomodation.document.type === "PASSPORT"
                    ? "Host Passport page"
                    : "Host Front Document"}
                </p>
                <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                  <img
                    src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                    className="w-full h-96"
                    style={{
                      backgroundImage: `url('${step.document.accomodation.document.front_file}')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                    }}
                  />
                </div>
              </div>
              {step.document.accomodation.document.type !== "PASSPORT" ? (
                <div className="w-1/2 pl-3">
                  <p className="uppercase tracking-wide font-medium text-sm text-primary-400">
                    Host Back Document
                  </p>
                  <div className="bg-bluegray-100 w-full h-96 px-2 rounded-md mt-3">
                    <img
                      src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                      className="w-full h-96"
                      style={{
                        backgroundImage: `url('${step.document.accomodation.document.back_file}')`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  return (
    <div>
      <ResidencyTitle title={props.title} state={props.step.state} />
      {props.step.state === "VALIDATED" ? (
        <ResidencyDetails
          member_id={props.member_id}
          step_id={props.step_id}
          step={props.step}
        />
      ) : null}
      {props.step.state === "PENDING" ||
        props.step.state === "REJECTED" ||
        (props.step.state === "NOT_STARTED" && props.step.history.length > 0) ? (
        <ResidencyBaseDetails
          member_id={props.member_id}
          step_id={props.step_id}
          step={props.step}
        />
      ) : null}
      <ResidencyFooter title={props.title} state={props.step.state} />

      <Modal
        open={open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() => setOpen(false)}
        center
      >
        <div className="core-view-history history-view">
          <HistoryTitle />
          {documentHistory.length > 0 ?
            <div className="px-1 mt-4">
              <p className="px-6 font-medium text-primary-400">
                HISTORY
              </p>
            </div>
            : null}
          {documentHistory.map((history, index) => {
            return (
              <HistoryElement key={index} element={history} index={index}></HistoryElement>
            );
          })}
        </div>
      </Modal>
      <UpdateValue
        open={manageable}
        onClose={() => setManageable(false)}
        onUpdate={(value) => UpdateIndividualValue(value)}
        value={title}
        old={value}
      ></UpdateValue>
    </div>
  );
}
