import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import AgentDetails from '@/components/pages/AgentDetails'

function AgentsKey() {
	return (
		<AuthGuard>
			<IamGuard page='admin'>
                <Navbar></Navbar>
                <AgentDetails></AgentDetails>
			</IamGuard>
		</AuthGuard>
	)
}

export default AgentsKey
