import {
	faAddressCard,
	faCabinetFiling,
	faCar,
	faIdCard,
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RegistryListHeaderItem from './RegistryListHeaderItem'
import CountryFlag from '../ui/CountryFlag'

export default function RegistryCountrytHeader(props) {
	return (
		<div className="flex flex-wrap justify-between">
			<div className="w-full sm:w-4/12 px-3 pb-6 sm:pb-0 sm:px-0">
				<div className="flex">
					<div className="mr-5 mt-0.5">
						<FontAwesomeIcon
							className="text-gray-400 text-6xl"
							icon={faCabinetFiling}></FontAwesomeIcon>
					</div>
					<div>
						<h1 className="text-3xl">
							<span className="font-bold">Registry</span>
						</h1>
						<div className="flex mt-1">
							<div>
								<CountryFlag
									className="rounded-sm"
									country={props.data.country}></CountryFlag>
							</div>
							<div className="ml-2">{props.data.country_name}</div>
						</div>
					</div>
				</div>
			</div>
			<div className="w-full sm:w-8/12">
				<div className="flex flex-wrap">
					<div className="w-full sm:w-1/4">
						<RegistryListHeaderItem
							label="Passport"
							value={props.data.stats.passport}
							icon={faPassport}></RegistryListHeaderItem>
					</div>
					<div className="w-full sm:w-1/4">
						<RegistryListHeaderItem
							label="National ID"
							value={props.data.stats.national_id}
							icon={faIdCard}></RegistryListHeaderItem>
					</div>
					<div className="w-full sm:w-1/4">
						<RegistryListHeaderItem
							label="Driver License"
							value={props.data.stats.driver_license}
							icon={faCar}></RegistryListHeaderItem>
					</div>
					<div className="w-full sm:w-1/4">
						<RegistryListHeaderItem
							label="Res. Permit"
							value={props.data.stats.resident_permit}
							icon={faAddressCard}></RegistryListHeaderItem>
					</div>
				</div>
			</div>
		</div>
	)
}
