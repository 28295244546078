import { useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import { useEffect } from 'react'
import NetworkService from '@/services/network'
import SwitchGreen from '@/components/ui/SwitchGreen'
import Empty from '../misc/Empty'
import MiniButton from '@/components/ui/MiniButton'
import { faFileDownload, faFileUpload, faTimes, faMinusCircle, faPlus } from '@fortawesome/pro-duotone-svg-icons'
import { useFileReader } from '@/hooks/filereader'
import * as Notification from '@/components/ui/Notification'
import NewComponent from './NewComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faDiscord,
    faTelegram,
    faTwitter,
    faSlack,
} from '@fortawesome/free-brands-svg-icons'
import UploadLogo from '@/components/ui/UploadLogo'

export default function ProjectItem(props) {
    const [error, setError] = useState(null)
    const [network, setNetwork] = useState(null)
    const [closed, setClosed] = useState(null)
    const [corporate, setCorporate] = useState(null)
    const [workflow, setWorkflow] = useState(null)
    const [scorechain, setScorechain] = useState(null)
    const [color, setColor] = useState(null)
    const [logoSize, setLogoSize] = useState(null)
    const [loading, setLoading] = useState(false)
    const [whitelist1, setWhitelist1] = useState([])
    const [whitelist2, setWhitelist2] = useState([])
    const [whitelist3, setWhitelist3] = useState([])
    const [uploadKey, setUploadKey] = useState(0)
    const [addKey, setAddKey] = useState(0)
    const [adding, setAdding] = useState(false)
    const [jwt, setJwt] = useState(false)
    const [jwt_key, setJwtKey] = useState(null)
    const [authComponents, setAuthComponents] = useState([])
    const [webhookStatus, setWebhookStatus] = useState(false)
    const [webhookURL, setWebhookURL] = useState(null)
    const [webhookSecret, setWebhookSecret] = useState(null)
    const [webhookStart, setWebhookStart] = useState(false)
    const [supportEmail, setSupportEmail] = useState(null)
    const [supportFields, setSupportFields] = useState([])
    const [logo, setLogo] = useState(null)


    const [authComponentLoading, setAuthComponentLoading] = useState(false)
    const [addAuthComponent, setAddAuthComponent] = useState(false)
    const [{ result }, setResult, setFile] = useFileReader({
        method: 'readAsText',
    })

    let supportsIcons = {
        "discord": faDiscord,
        "telegram": faTelegram,
        "twitter": faTwitter,
        "slack": faSlack,
        "other": faPlus,
    }


    useEffect(() => {
        setError(null)
        setCorporate(props.project.settings.corporate)
        setWorkflow(props.project.settings.workflow)
        setClosed(props.project.closed)
        setScorechain(props.project.settings.scorechain)
        setNetwork(props.project.network)
        setColor(props.project.settings.color)
        setLogoSize(props.project.settings.auth.logo_size)
        setAuthComponents(props.project.settings.auth.components)
        setJwt(props.project.settings.jwt)
        setJwtKey(props.project.settings.jwt_key)
        setWebhookStatus(props.project.settings.webhook.active)
        setWebhookURL(props.project.settings.webhook.url)
        setWebhookSecret(props.project.settings.webhook.secret)
        setWebhookStart(props.project.settings.webhook.start)
        setSupportEmail(props.project.mail)
        setSupportFields(props.project.fields)

        if (props.project.whitelist.whitelist.length > 0) {
            let perChunk = Math.round(props.project.whitelist.whitelist.length / 3)

            var result = props.project.whitelist.whitelist.reduce((resultArray, item, index) => {
                const chunkIndex = Math.floor(index / perChunk)

                if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []
                }

                resultArray[chunkIndex].push(item)

                return resultArray
            }, [])

            if (result.length > 0) {
                setWhitelist1(result[0])
            }
            if (result.length > 1) {
                setWhitelist2(result[1])
            }
            if (result.length > 2) {
                setWhitelist3(result[2])
            }
            if (result.length > 3) {
                setWhitelist3(result[2].concat(result[3]))
            }
        }
    }, [])

    useEffect(() => {
        if (result) {
            ImportWhitelist(result)
            setResult(null)
            setFile(null)
            setUploadKey(uploadKey + 1)
        }
    }, [result])

    function ImportWhitelist(newWhitelist) {
        let arrayWhitelist = newWhitelist.split('\n')

        if (adding === true) {
            arrayWhitelist = arrayWhitelist.concat(whitelist1)
            arrayWhitelist = arrayWhitelist.concat(whitelist2)
            arrayWhitelist = arrayWhitelist.concat(whitelist3)
        }

        let perChunk = Math.round(arrayWhitelist.length / 3)

        var result = arrayWhitelist.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk)

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []
            }

            resultArray[chunkIndex].push(item.toLowerCase().replace(/\s/g, ''))

            return resultArray
        }, [])

        if (result.length > 0) {
            setWhitelist1(result[0])
        }
        if (result.length > 1) {
            setWhitelist2(result[1])
        }
        if (result.length > 2) {
            setWhitelist3(result[2])
        }
        if (result.length > 3) {
            setWhitelist3(result[2].concat(result[3]))
        }

        setAdding(false)
    }

    function ClearWhitelist() {
        setWhitelist1([])
        setWhitelist2([])
        setWhitelist3([])
    }

    function Submit() {
        setLoading(true)

        let body = {
            'network': network,
            'color': color,
            'logo_size': logoSize,
            'whitelist': whitelist1.concat(whitelist2).concat(whitelist3),
            'closed': closed,
            'auth_components': authComponents,
            'corporate': corporate,
            'workflow': workflow,
            'scorechain': scorechain,
            'jwt': jwt,
            'jwt_key': jwt_key,
            'mail': supportEmail,
            'fields': supportFields,
            'webhook': {
                'active': webhookStatus,
                'url': webhookURL,
                'start': webhookStart,
            }
        }

        if (logo !== null) {
            let bodyFormData = new FormData()
            bodyFormData.append('project_logo', logo)

            NetworkService.UploadProjectNetworkLogo(bodyFormData).then((data) => {
                body.logo = data.logo

                NetworkService.UpdateProjectNetwork(body, props.project.name).then((data) => {
                    setLoading(false)
                    Notification.success('Project updated')
                }).catch((err) => {
                    Notification.error(err.message)
                    setLoading(false)
                })
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
            return
        }

        NetworkService.UpdateProjectNetwork(body, props.project.name).then((data) => {
            setLoading(false)
            Notification.success('Project updated')
        }).catch((err) => {
            Notification.error(err.message)
            setLoading(false)
        })
    }

    function getFile() {
        document.getElementById('import-file').click()
    }

    function getAddFile() {
        setAdding(true)
        document.getElementById('import-file').click()
    }

    const onInputFile = e => {
        setFile(e.target.files[0])
    }

    function Export(exportType) {
        NetworkService.GetProjectExport(props.project.name, exportType).then((data) => {
            console.log(data)
            let csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n')
            let encodedUri = encodeURI(csvContent)
            var link = document.createElement('a')
            link.setAttribute('href', encodedUri)
            link.setAttribute('download', 'export_' + exportType + '_' + props.project.name + '.csv')
            document.body.appendChild(link)
            link.click()
        }).catch((err) => {
            Notification.error(err.message)
            setLoading(false)
        })
    }

    async function removeComponent(index) {
        setAuthComponentLoading(true)
        if (index > -1) {
            await authComponents.splice(index, 1)
            setAuthComponentLoading(false)
        }
        setAuthComponentLoading(false)
    }

    async function updateField(value, index) {
        const newFields = [...supportFields];
        newFields[index].value = value;
        setSupportFields(newFields);
    }

    return (
        <div
            className="w-full p-4 self-auto">
            <div className="border h-full border-gray-200 shadow-sm items-center rounded-xl">
                <div className="p-6">
                    <div className="text-center">
                        <div className="mx-auto text-center mt-1 mb-4">
                            <img
                                className="h-20 rounded-full mx-auto text-center"
                                src={props.project.settings.auth.logo}
                                alt=""
                            />
                        </div>
                        <p className="text-regular font-semibold">{props.project.name}</p>

                        <div className="mt-4">
                            <div className="flex justify-between">
                                <div className="w-full mt-3 mr-6">
                                    <label
                                        className="flex block text-sm mb-3 font-medium text-primary-700">
                                        NETWORK (OPTIONNAL)
                                    </label>
                                    <Input setValue={(value) => setNetwork(value)} value={network} placeholder="synaps" />

                                    <label
                                        className="flex block text-sm mb-3 mt-4 font-medium text-primary-700">
                                        SUPPORT CONTACT MAIL
                                    </label>
                                    <Input setValue={(value) => setSupportEmail(value)} value={supportEmail} placeholder="synaps" />


                                    {supportFields !== null ?
                                        <>
                                            <label
                                                className="flex block text-sm mb-3 mt-4 font-medium text-primary-700">
                                                SUPPORT CONTACT LINK (OPTIONNAL)
                                            </label>
                                            {supportFields.map((field, i) => {
                                                return (
                                                    <div key={i} className="flex justify-between w-full mb-3" >
                                                        <div className='w-full'>
                                                            <Input icon={supportsIcons[field.type]} value={supportFields[i].value} placeholder={field.type} setValue={(value) => updateField(value, i)} />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </> : null}
                                    <label
                                        className="flex block text-sm mb-3 mt-4 font-medium text-primary-700">
                                        COLOR
                                    </label>
                                    <Input setValue={(value) => setColor(value)} value={color} placeholder="000000" />

                                    {jwt === true ?
                                        <>
                                            <label
                                                className="flex block text-sm mb-3 mt-4 font-medium text-primary-700">
                                                JWT Key
                                            </label>
                                            <Input setValue={(value) => setJwtKey(value)} value={jwt_key} placeholder="" />
                                        </>
                                        : null}
                                    <label
                                        className="flex block text-sm mb-3 mt-4 font-medium text-primary-700">
                                        LOGO SIZE
                                    </label>
                                    <Input setValue={(value) => setLogoSize(value)} value={logoSize} placeholder="h-200px" />
                                    <div className="mt-4">
                                        <UploadLogo setLogo={(blob) => setLogo(blob)} />
                                    </div>
                                </div>
                                <div className="w-full mt-3 ml-6">
                                    <div className="w-full flex justify-between">
                                        <div>
                                            <div className="w-full mt-2">
                                                <label
                                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                                    Status
                                                </label>
                                                <div className="my-3 mt-2">
                                                    <SwitchGreen checked={!closed}
                                                        onChange={() => setClosed(!closed)} />
                                                </div>
                                            </div>
                                            <div className="mt-6">
                                                <label
                                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                                    JWT
                                                </label>
                                                <SwitchGreen checked={jwt}
                                                    onChange={() => setJwt(!jwt)} />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="w-full mt-2">
                                                <label
                                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                                    ScoreCHain
                                                </label>
                                                <div className="my-3 mt-2">
                                                    <SwitchGreen checked={scorechain}
                                                        onChange={() => setScorechain(!scorechain)} />
                                                </div>
                                            </div>
                                            <div className="w-full text-center">
                                                <p
                                                    className="flex block text-center text-sm mb-3 font-medium text-primary-700">
                                                    KYB
                                                </p>
                                                <SwitchGreen checked={corporate}
                                                    onChange={() => setCorporate(!corporate)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                {authComponentLoading === false && authComponents !== null ?
                                    <div>
                                        {authComponents.map((component, index) => {
                                            return (
                                                <div key={index} className="flex mb-3">
                                                    <div className="p-3 w-full border border-gray-200 shadow-sm items-center rounded-xl">
                                                        {component.type} | {component.instruction} | {component.slug}
                                                    </div>
                                                    <h2 className="text-xl mt-3 ml-2"><span><FontAwesomeIcon onClick={() => removeComponent(index)} className="cursor-pointer text-red-400 hover:text-red-700 transition duration-100 ease-in" icon={faMinusCircle}></FontAwesomeIcon></span></h2>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    : null}
                                <div className="mt-4 flex justify-between">
                                    <div></div>
                                    <Button full theme="primary" label="Add component" onClick={() => setAddAuthComponent(true)}></Button>
                                    <div></div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <label
                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                    Webhooks
                                </label>
                                <div className="w-full flex justify-between">
                                    <div className="w-full flex justify-between">
                                        <div className="w-full">
                                            <label
                                                className="flex block text-sm mb-3 font-medium text-primary-700">
                                                Status
                                            </label>
                                            <div className="flex">
                                                <div className="mt-2">{webhookStatus === true ?
                                                    <a className="mt-2 group items-center p-1 bg-identity-100 rounded-xl text-sm">ACTIVE</a> :
                                                    <a className="mt-2 group items-center p-1 bg-dupplicate-100 rounded-xl text-sm">DISABLED</a>}</div>
                                                <div className="my-3 mx-4 mt-2">
                                                    <SwitchGreen checked={webhookStatus}
                                                        onChange={() => setWebhookStatus(!webhookStatus)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full">
                                            {webhookStatus === true ? <>
                                                <label
                                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                                    Start
                                                </label>
                                                <div className="flex">
                                                    <div className="mt-2">{webhookStart === true ?
                                                        <a className="mt-2 group items-center p-1 bg-identity-100 rounded-xl text-sm">ACTIVE</a> :
                                                        <a className="mt-2 group items-center p-1 bg-dupplicate-100 rounded-xl text-sm">DISABLED</a>}</div>
                                                    <div className="my-3 mx-4 mt-2">
                                                        <SwitchGreen checked={webhookStart}
                                                            onChange={() => setWebhookStart(!webhookStart)} />
                                                    </div>
                                                </div>
                                            </>
                                                : null}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        {webhookStatus === true ? <>
                                            <label
                                                className="flex block text-sm mb-3 font-medium text-primary-700">
                                                URL
                                            </label>
                                            <Input setValue={(value) => setWebhookURL(value)} value={webhookURL} placeholder="synaps" />
                                        </>
                                            : null}
                                    </div>
                                </div>
                                <div className="w-full flex justify-between">
                                    {webhookStatus === true ? <>
                                        <div className="w-full">
                                            <label
                                                className="flex block text-sm mb-3 font-medium text-primary-700">
                                                Secret
                                            </label>
                                            <Input disabled value={webhookSecret} placeholder="" />
                                        </div>
                                    </>
                                        : null}
                                </div>
                            </div>
                            <div className="mt-4">
                                <label
                                    className="flex block text-sm mb-3 font-medium text-primary-700">
                                    Whitelist
                                </label>
                                <div className="flex justify-between">
                                    <div className="flex">
                                        <label className="custom-file-upload">
                                            <input key={uploadKey} className="hidden" type="file" id="import-file" onChange={onInputFile} />
                                            <MiniButton icon={faFileDownload} onClick={() => getFile()} theme="secondary" label="Import"></MiniButton>
                                        </label>
                                        <div className="ml-2">
                                            <input key={addKey} className="hidden" type="file" id="add-file" onChange={onInputFile} />
                                            <MiniButton icon={faFileDownload} onClick={() => getAddFile()} theme="secondary" label="Add"></MiniButton>
                                        </div>
                                        <div className="ml-2">
                                            <MiniButton onClick={() => Export('WORKFLOW')} icon={faFileUpload} theme="secondary" label="Export KYC"></MiniButton>
                                        </div>
                                        <div className="ml-2">
                                            <MiniButton onClick={() => Export('APPROVED_KYC')} icon={faFileUpload} theme="secondary" label="Export APPROVED KYC"></MiniButton>
                                        </div>
                                        <div className="ml-2">
                                            <MiniButton onClick={() => Export('CORPORATE')} icon={faFileUpload} theme="secondary" label="Export KYB"></MiniButton>
                                        </div>
                                        <div className="ml-2">
                                            <MiniButton onClick={() => Export('APPROVED_KYB')} icon={faFileUpload} theme="secondary" label="Export APPROVED KYB"></MiniButton>
                                        </div>
                                        <div className="ml-2">
                                            <MiniButton onClick={() => ClearWhitelist()} icon={faTimes} theme="secondary" label="Clear"></MiniButton>
                                        </div>
                                    </div>
                                    <p className="text-regular font-semibold">{whitelist1.length + whitelist2.length + whitelist3.length} users</p>
                                </div>

                                <div className="flex justify-between mt-4">
                                    <div>
                                        {whitelist1.map((user, index) => {
                                            return (
                                                <div key={index} className="text-left">
                                                    <p className="text-regular font-semibold">{user}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {whitelist2.map((user, index) => {
                                            return (
                                                <div key={index} className="text-left">
                                                    <p className="text-regular font-semibold">{user}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        {whitelist3.map((user, index) => {
                                            return (
                                                <div key={index} className="text-left">
                                                    <p className="text-regular font-semibold">{user}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                {whitelist1.length === 0 ? (
                                    <div className="text-center">
                                        <Empty value="No whitelist"></Empty>
                                    </div>
                                ) : null}
                            </div>
                            <div className="mt-5 w-full flex justify-between">
                                <div></div>
                                <Button loading={loading} full theme="success" label="Update" onClick={() => Submit()}></Button>
                            </div>
                            <NewComponent
                                open={addAuthComponent}
                                onUpdate={(component) => authComponents.push(component)}
                                onClose={() => setAddAuthComponent(false)}></NewComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
