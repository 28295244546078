import { useEffect, useState } from 'react'
import SubNav from '../menu/SubNav'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import AppsService from '@/services/apps'
import Card from '../ui/Card'
import {
	faWindow,
} from '@fortawesome/pro-duotone-svg-icons'
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import CardTitle from '../ui/CardTitle'
import Footer from '../menu/Footer'
import ContentSearchBar from '../ui/ContentSearchBar'
import Empty from '../misc/Empty'
import Alert from '@/components/ui/Alert'
import AppsListHeader from './apps/AppsListHeader'
import TasksPlaceholder from '../placeholder/TasksPlaceholder'
import Tooltip from '../ui/Tooltip'
import Moment from 'react-moment'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@/components/ui/Button'

export default function AppsListPage() {
	const [currentPage, setCurrentPage] = useState(1)
	const [initiated, setInitiated] = useState(false)
	const [loading, setLoading] = useState(false)

	const [search, setSearch] = useState('')
	const [ready, setReady] = useState(false)
	const [error, setError] = useState(null)
	const [data, setData] = useState(null)

	async function GetAppList() {
		await AppsService.GetApps(currentPage - 1, search)
			.then((data) => {
				setData(data)
				setReady(true)
				setInitiated(true)
			})
			.catch((err) => {
				setError(err.message)
			})
	}

	useEffect(async () => {
		if (initiated == false) {
			setReady(false)
		}
		await AppsService.GetApps(currentPage - 1, search)
			.then((data) => {
				setData(data)
				console.log(data)
				setReady(true)
				setInitiated(true)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}, [search, currentPage])

	async function UpdateAppStatus(sandbox, app) {
		setLoading(true)
		let body = {
			app_key: app._key,
			sandbox: sandbox
		}
		await AppsService.UpdateAppStatus(body)
			.then(() => {
				setLoading(false)
				GetAppList()
			})
			.catch((err) => {
				setError(err.message)
			})
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mx-auto relative">
					<div className="verification-container">
						{ready ? (
							<div className="max-w-7xl mx-auto relative">
								<div className="dashboard-container">
									<div className="flex">
										<Card className="w-full px-3 sm:px-0">
											<div className="bg-white p-6 border shadow rounded-xl w-full">
												<AppsListHeader
													data={{
														production: data.production,
														sandbox: data.sandbox,
														workflow: data.workflow,
														corporate: data.corporate,
														total: data.total,
													}}></AppsListHeader>
											</div>
										</Card>
									</div>
								</div>
							</div>
						) : (
							<VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
						)}
					</div>
				</div>

				<div className="max-w-7xl mx-auto relative">
					<div className="flex flex-wrap mt-6">
						<Card className="w-full px-3 sm:px-0">
							<div>
								{ready && data.production_requested !== null && data.production_requested.length > 0 ? (
									<div className="p-3">
										<div className="flex justify-between">
											<div>
												<CardTitle value="Production Request" icon={faWindow}></CardTitle>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}

										{ready ? (
											<div className="flex flex-wrap p-2 mt-3 pt-3">
												{data.production_requested.map((app) => {
													return (
														<div className="w-full list-item" key={app._key}>
															<div className="flex justify-between items-center">
																<div className="ml-1">
																	<div className="flex">
																		<div>
																			<Tooltip auto={true} content={app.name}>
																				<span className="inline-block relative">
																					<img
																						className="h-10 w-10 rounded-lg relative top-1 border border-gray-100"
																						src={app.logo_url}
																						alt=""
																					/>
																				</span>
																			</Tooltip>
																		</div>
																		<div className="mt-1">
																			<p className="ml-4 text-sm font-medium text-gray-700 group-hover:text-gray-900">
																				<span>{app.name}</span>
																			</p>
																			<div className="ml-4 text-xs font-light flex">
																				{app.type}
																			</div>
																		</div>
																		<div className="ml-4 mt-3">
																			{app.sandbox ? (
																				<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
																					Sandbox
																				</span>
																			) :
																				<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
																					Production
																				</span>
																			}
																		</div>
																	</div>
																</div>
																<div className="flex items-center">
																	<div className='flex-shrink-0 px-4 py-4 flex justify-end'>
																		<Button
																			loading={loading}
																			onClick={() => UpdateAppStatus(false, app)}
																			theme='success'
																			label='Promote'></Button>
																		<Button
																			loading={loading}
																			className='ml-2'
																			onClick={() => UpdateAppStatus(true, app)}
																			theme='error'
																			label='Reject'></Button>
																	</div>
																	<div className="text-right">
																		<div className="flex items-center">
																			<div>
																				<div className="ml-3 mr-2">
																					<p className="text-xs font-light cursor-default text-gray-700">
																						<Moment unix fromNow>
																							{app.created_at / 1000}
																						</Moment>
																					</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													)
												})}
												{data.apps.length === 0 ? (
													<Empty value="No apps"></Empty>
												) : null}
											</div>
										) : (
											<div className="p-4">
												<TasksPlaceholder></TasksPlaceholder>
											</div>
										)}
									</div>)
									: null}
								<div className="p-3">
									<div className="flex justify-between">
										<div>
											<CardTitle value="Apps" icon={faWindow}></CardTitle>
										</div>
										<div>
											<ContentSearchBar
												onChange={(el) =>
													setSearch(el.target.value)
												}></ContentSearchBar>
										</div>
									</div>
									{error ? (
										<div className="p-3">
											<Alert type="error" title={error}></Alert>
										</div>
									) : null}

									{ready ? (
										<div className="flex flex-wrap p-2 mt-3 pt-3">
											{data.apps.map((app) => {
												return (
													<div className="w-full list-item" key={app._key}>
														<a
															href={`/apps/${app._key}`}
															className="flex-shrink-0 group block">
															<div className="flex justify-between items-center">
																<div className="ml-1">
																	<div className="flex">
																		<div>
																			<Tooltip auto={true} content={app.name}>
																				<span className="inline-block relative">
																					<img
																						className="h-10 w-10 rounded-lg relative top-1 border border-gray-100"
																						src={app.logo_url}
																						alt=""
																					/>
																				</span>
																			</Tooltip>
																		</div>
																		<div className="mt-1">
																			<p className="ml-4 text-sm font-medium text-gray-700 group-hover:text-gray-900">
																				<span>{app.name}</span>
																			</p>
																			<div className="ml-4 text-xs font-light flex">
																				{app.type}
																			</div>
																		</div>
																		<div className="ml-4 mt-3">
																			{app.sandbox ? (
																				<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
																					Sandbox
																				</span>
																			) :
																				<span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
																					Production
																				</span>
																			}
																		</div>
																	</div>
																</div>
																<div className="text-right">
																	<div className="flex items-center">
																		<div>
																			<div className="ml-3 mr-2">
																				<p className="text-xs font-light cursor-default text-gray-700">
																					<Moment unix fromNow>
																						{app.created_at / 1000}
																					</Moment>
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</a>
													</div>
												)
											})}
											{data.apps.length === 0 ? (
												<Empty value="No apps"></Empty>
											) : null}
										</div>
									) : (
										<div className="p-4">
											<TasksPlaceholder></TasksPlaceholder>
										</div>
									)}
								</div>
							</div>
						</Card>
					</div>
					<div className="flex justify-between">
						<div className="mt-5">
							{ready ? (
								<p className="font-light">
									Showing{' '}
									<span className="font-medium">
										{(currentPage - 1) * 20 + 1}
									</span>{' '}
									to{' '}
									<span className="font-medium">
										{currentPage * 20 < data.total
											? currentPage * 20
											: data.total}
									</span>{' '}
									of <span className="font-medium">{data.total} results</span>
								</p>
							) : null}
						</div>
						<div className="mt-3 px-3 sm:px-0">
							{ready ? (
								<Pagination
									hideFirstLastPages={true}
									prevPageText={
										<FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
									}
									nextPageText={
										<FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
									}
									innerClass="relative z-0 inline-flex rounded-md shadow-sm -space-x-px cursor-pointer"
									activePage={currentPage}
									itemsCountPerPage={20}
									totalItemsCount={data.total}
									pageRangeDisplayed={5}
									activeClass={'font-bold'}
									itemClassPrev="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50"
									itemClassNext="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-200 bg-white text-sm font-regular text-gray-500 hover:bg-gray-50"
									itemClass="relative inline-flex items-center px-4 py-2 border border-gray-200 bg-white text-sm font-light text-gray-700 hover:bg-gray-50"
									onChange={(e) => setCurrentPage(e)}
								/>
							) : null}
						</div>
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
