import { CorporateContext } from '@/context/corporate'
import CorporateService from '@/services/corporate'
import { useContext, useEffect } from 'react'
import Tab from '../ui/Tab'

export default function CorporateTabs() {
	const [corporate, corporateDispatch] = useContext(CorporateContext)

	useEffect(async () => {
		await CorporateService.GetStats()
			.then((data) => {
				corporateDispatch({
					type: 'SET_STATS',
					payload: {
						stats: data,
					},
				})
			})
			.catch(() => {})
		return () => {}
	}, [])

	function setCurrentTab(currentTab) {
		corporateDispatch({
			type: 'SET_CURRENT_TAB',
			payload: {
				tab: currentTab,
			},
		})
	}

	return corporate.tabs.map((tab) => {
		return (
			<Tab
				badge={corporate.stats[tab.key]}
				selectedKey={corporate.selectedTab.key}
				key={tab.key}
				item={tab}
				onClick={() => setCurrentTab(tab)}></Tab>
		)
	})
}
