import useDigitInput from '@/lib/digits'
import { forwardRef } from 'react'

const DigitInputElement = forwardRef(({ ...props }, ref) => {
	return (
		<label className="mrz-label">
			<div className="uppercase mrz">{props.value}</div>
			<hr className="mrz-hr" />
			<input className="uppercase mrz-input" {...props} ref={ref} />
		</label>
	)
})

export default function InputMrzTD2(props) {
	const [value, onChange] = props.value
	const digits = useDigitInput({
		acceptedCharacters: /^[0-9A-Za-z<]$/,
		length: 72,
		value,
		onChange,
	})

	return (
		<>
			<div className="mrz-group mt-6">
				{[...Array(36)].map((el, i) => {
					return <DigitInputElement key={i} autofocus={i === 0} {...digits[i]} />
				})}
			</div>
			<div className="mrz-group">
				{[...Array(36)].map((el, i) => {
					return <DigitInputElement key={i} {...digits[i + 36]} />
				})}
			</div>
		</>
	)
}

DigitInputElement.displayName = 'DigitInputElement'