import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'
import {
	faPassport,
	faFileInvoice,
} from '@fortawesome/pro-duotone-svg-icons'

export default function CorporateMemberItem(props) {
	return (
		<>
			<div className={'p-3 rounded-xl border border-bluegray-100'}>
				<div className="flex justify-center">
					<div className="text-3xl">
						<FontAwesomeIcon
							className="text-corporate-500"
							icon={props.icon}></FontAwesomeIcon>
					</div>
				</div>

				<div className="flex justify-center">
					<div>
						<p className="text-xs">
							<span
								className={'uppercase font-semibold text-bluegray-700 text-xs'}>
								{props.name}
							</span>
						</p>
					</div>
				</div>
				<div className="flex justify-center mt-3">
					<Tooltip auto={true} content="Identity">
						<div
							onClick={() =>
								window.open(
									`/verifications/identity/${props.identity.session_id}`,
									'_blank'
								)
							}
							className="transform hover:scale-110 motion-reduce:transform-none cursor-pointer text-2xl mr-2 ml-2">
							{props.identity.state === 2 ? (
								<FontAwesomeIcon
									className="text-green-500"
									icon={faPassport}></FontAwesomeIcon>
							) : null}
							{props.identity.state === 1 ? (
								<FontAwesomeIcon
									className="text-orange-500"
									icon={faPassport}></FontAwesomeIcon>
							) : null}
							{props.identity.state === 0 ? (
								<FontAwesomeIcon
									className="text-corporate-500"
									icon={faPassport}></FontAwesomeIcon>
							) : null}
						</div>
					</Tooltip>
					<Tooltip auto={true} content="Residency">
						<div
							onClick={() =>
								window.open(
									`/verifications/residency/${props.residency.session_id}`,
									'_blank'
								)
							}
							className="transform hover:scale-110 motion-reduce:transform-none cursor-pointer  text-2xl mr-2 ml-2">
							{props.residency.state === 2 ? (
								<FontAwesomeIcon
									className="text-green-500"
									icon={faFileInvoice}></FontAwesomeIcon>
							) : null}
							{props.residency.state === 1 ? (
								<FontAwesomeIcon
									className="text-orange-500"
									icon={faFileInvoice}></FontAwesomeIcon>
							) : null}
							{props.residency.state === 0 ? (
								<FontAwesomeIcon
									className="text-corporate-500"
									icon={faFileInvoice}></FontAwesomeIcon>
							) : null}
						</div>
					</Tooltip>
				</div>
			</div>
		</>
	)
}
