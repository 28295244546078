import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/crosscheck/list?type=${type}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/crosscheck/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/crosscheck/task/${session_id}`
    );
  },
  RejectCrosscheck(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/crosscheck/task/${session_id}/reject`,
      {}
    );
  },
  ValidateCrosscheck(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/crosscheck/task/${session_id}/validate`,
      {}
    );
  },
};
