import {
  faSirenOn,
  faTasks,
  faThumbtack,
} from "@fortawesome/pro-duotone-svg-icons";
import { useReducer, createContext } from "react";

export const FacematchContext = createContext();

const initialState = {
  stats: {
    pinned: 0,
    tasks: 0,
    urgent: 0,
  },
  tasks: [],
  selectedTab: {
    title: "Tasks",
    key: "tasks",
    slug: "tasks",
    icon: faTasks,
  },
  tabs: [
    {
      key: "tasks",
      label: "Tasks",
      icon: faTasks,
      bgNormal: "",
      bgHover: "primary-500",
      textActive: "primary-500",
      textNormal: "gray-500",
      textHover: "white",
      badge: 0,
      bgBadge: "primary-500",
      bgBadgeHover: "primary-900",
      textBadge: "gray-100",
    },
    {
      key: "urgent",
      label: "Urgent",
      icon: faSirenOn,
      bgActive: "orange-500",
      bgNormal: "",
      bgHover: "red-500",
      textActive: "red-500",
      textNormal: "red-500",
      textHover: "white",
      badge: 0,
      bgBadge: "red-500",
      bgBadgeHover: "red-700",
      textBadge: "gray-100",
    },
    {
      key: "pinned",
      label: "Pinned",
      icon: faThumbtack,
      bgActive: "bg-orange-500",
      bgNormal: "",
      bgHover: "hover:bg-orange-500",
      textActive: "text-orange-500",
      textNormal: "text-red-500",
      textHover: "hover:text-white",
      badge: 0,
      bgBadge: "bg-orange-500",
      bgBadgeHover: "hover:bg-orange-700",
      textBadge: "text-white",
      group: "group-hover:text-white group-hover:bg-orange-900",
    },
  ],
};

function setCurrentTab(state, payload) {
  return {
    ...state,
    selectedTab: {
      title: payload.tab.label,
      icon: payload.tab.icon,
      key: payload.tab.key,
    },
  };
}

function setStats(state, payload) {
  return {
    ...state,
    stats: payload.stats,
  };
}

function setTasks(state, payload) {
  return {
    ...state,
    tasks: payload.tasks,
  };
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_TAB":
      return setCurrentTab(state, action.payload);
    case "SET_STATS":
      return setStats(state, action.payload);
    case "SET_TASKS":
      return setTasks(state, action.payload);
    default:
      return state;
  }
};

export const FacematchContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <FacematchContext.Provider value={[state, dispatch]}>
      {props.children}
    </FacematchContext.Provider>
  );
};
