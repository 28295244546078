import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SubNav from '../menu/SubNav'
import RegistryHeader from '../registry/RegistryHeader'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import RegistryService from '@/services/registry'
import { RegistryIdentityContext } from '@/context/registry_identity'
import * as Notification from '@/components/ui/Notification'
import RegistryDocument from '../registry/RegistryDocument'
import ContentPlaceholder from '../placeholder/ContentPlaceholder'
import Footer from '../menu/Footer'

export default function RegistryIdentity() {
	const [ready, setReady] = useState(false)
	const { slug } = useParams()
	const [, registryIdentityDispatch] = useContext(RegistryIdentityContext)

	useEffect(async () => {
		await GetIdentityDocument()
		return () => {}
	}, [])

	async function GetIdentityDocument() {
		setReady(false)
		await RegistryService.GetIdentityDocument(slug)
			.then(async (data) => {
				registryIdentityDispatch({
					type: 'SET_RESPONSE',
					payload: data,
				})
				setReady(true)
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => {}
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='verification-container'>
						{ready ? (
							<RegistryHeader
								onUpdate={() => GetIdentityDocument()}></RegistryHeader>
						) : (
							<VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
						)}
					</div>
				</div>

				<div className='max-w-7xl mx-auto relative mt-8'>
					{ready ? (
						<RegistryDocument
							onUpdate={() => GetIdentityDocument()}></RegistryDocument>
					) : (
						<ContentPlaceholder></ContentPlaceholder>
					)}
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
