import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SubNav from "../menu/SubNav";
import Card from "../ui/Card";
import Footer from "../menu/Footer";
import CorporateService from "@/services/corporate";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import * as Notification from "@/components/ui/Notification";
import useWebSocket from "react-use-websocket";
import Button from "../ui/Button";
import AuthService from "@/services/auth";
import { CorporateVerificationContext } from "@/context/corporate_verification";
import CorporateVerificationHeader from "../verifications/corporate/CorporateVerificationHeader";
import CorporateDocumentsCheck from "../verifications/corporate/corporate-documents/CorporateDocumentsCheck";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import CorporateOfficer from "../verifications/corporate/corporate-officer/CorporateOfficer";
import CorporateBeneficials from "../verifications/corporate/corporate-beneficials/CorporateBeneficials";
import CorporateAdditionalChecks from "../verifications/corporate/corporate-additional/CorporateAdditionalChecks";
import { AuthContext } from "@/context/auth";

export default function CorporateVerification() {
  const [corporateVerification, corporateVerificationDispatch] = useContext(
    CorporateVerificationContext
  );
  const [ready, setReady] = useState(false);
  const router = useHistory();
  const { session_id } = useParams();
  const [loadingVerif, setloadingVerif] = useState(false);
  const [auth] = useContext(AuthContext);

  const socketUrl = `${process.env.REACT_APP_WS_HOST}/v3/websockets/online`;
  const { readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: async () => {},
    shouldReconnect: () => true,
  });

  useEffect(async () => {
    await getTask();
    return () => {};
  }, []);

  useEffect(async () => {
    if (readyState) {
      getWebSocket().send(
        JSON.stringify({
          auth: auth.auth.access_token,
          email: auth.user.email,
          firstname: auth.user.firstname,
          lastname: auth.user.lastname,
          profile_pic: auth.user.profile_pic,
          service: "CORPORATE",
          session_id: session_id,
        })
      );
    }
  }, [readyState]);

  async function getTask() {
    await CorporateService.GetTask(session_id)
      .then(async (data) => {
        if (data.task.service_session_id === "") {
          router.push("/verifications/corporate");
          return;
        }
        if (corporateVerification.selectedStep) {
          data.current_step = corporateVerification.selectedStep.current_step;
        } else {
          data.current_step = "0";
        }
        await corporateVerificationDispatch({
          type: "SET_SESSION",
          payload: data,
        });
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  async function validateTask() {
    setloadingVerif(true);
    await CorporateService.Validate(session_id)
      .then(async () => {
        Notification.success("Corporate verified successfully!");
        setloadingVerif(false);
        router.push("/verifications/corporate");
      })
      .catch((err) => {
        Notification.error(err.message);
        setloadingVerif(false);
      });
    return () => {};
  }

  async function rejectTask() {
    setloadingVerif(true);
    await CorporateService.Reject(session_id)
      .then(async () => {
        Notification.success("Corporate rejected successfully!");
        setloadingVerif(false);
        router.push("/verifications/corporate");
      })
      .catch((err) => {
        Notification.error(err.message);
        setloadingVerif(false);
      });
    return () => {};
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <CorporateVerificationHeader
                onUpdate={() => getTask()}
              ></CorporateVerificationHeader>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div>
                  {ready ? (
                    <>
                      {corporateVerification.selectedStep.steps[
                        corporateVerification.selectedStep.current_step
                      ] === "documents" ? (
                        <CorporateDocumentsCheck
                          onUpdate={() => getTask()}
                        ></CorporateDocumentsCheck>
                      ) : null}
                      {corporateVerification.selectedStep.steps[
                        corporateVerification.selectedStep.current_step
                      ] === "owner" ? (
                        <CorporateOfficer
                          onUpdate={() => getTask()}
                        ></CorporateOfficer>
                      ) : null}
                      {corporateVerification.selectedStep.steps[
                        corporateVerification.selectedStep.current_step
                      ] === "beneficial" ? (
                        <CorporateBeneficials
                          onUpdate={() => getTask()}
                        ></CorporateBeneficials>
                      ) : null}
                      <CorporateAdditionalChecks
                        onUpdate={() => getTask()}
                      ></CorporateAdditionalChecks>
                    </>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
              {ready &&
              corporateVerification.selectedStep.steps[
                corporateVerification.selectedStep.current_step
              ] === "beneficial" ? (
                <div className="p-6">
                  <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
                    <div>
                      <Button
                        loading={loadingVerif}
                        onClick={() => rejectTask()}
                        icon={faTimes}
                        full
                        label="Reject"
                        theme="error"
                      ></Button>
                    </div>
                    <div>
                      <Button
                        loading={loadingVerif}
                        onClick={() => validateTask()}
                        icon={faCheck}
                        full
                        label="Validate"
                        theme="success"
                      ></Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
