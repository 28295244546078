export default function MiniContentSearchBar(props) {
	return (
		<div className="w-full mt-2 mr-2">
			<div className="relative">
				<input
					onChange={(el) => props.onChange(el)}
					id="search"
					name="search"
					className="transition w-64 duration-200 ease-in-out block pl-2 pr-3 py-1 border border-bluegray-200 rounded-md leading-5 bg-bluegray-300 bg-opacity-25 text-primary-100 placeholder-primary-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
					placeholder="Search"
					type="search"
				/>
			</div>
		</div>
	)
}
