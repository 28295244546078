import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/aml/list?type=${type}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/aml/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/aml/task/${session_id}`
    );
  },
  RejectAml(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/aml/task/${session_id}/reject`,
      body
    );
  },
  ValidateAml(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/aml/task/${session_id}/validate`,
      body
    );
  },
};
