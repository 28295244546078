import {
    faMinusCircle,
    faPlusCircle,
    faTimes,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Alert from '../ui/Alert'
import ListSelector from '../ui/ListSelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'

export default function NewProject(props) {
    const [error, setError] = useState(null)
    const [instruction, setInstruction] = useState(null)
    const [slug, setSlug] = useState(null)
    const [type, setType] = useState(null)
    const [placeholder, setPlaceholder] = useState(null)
    const [choices, setChoices] = useState([])
    const [newChoice, setNewChoice] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError(null)
        setInstruction(null)
        setPlaceholder(null)
        setType(null)
        setChoices([])
        setSlug(null)
    }, [])

    function Submit() {
        let component = {
            'instruction': instruction,
            'type': type,
            'slug': slug,
            'choices': choices,
            'placeholder': placeholder,
        }

        props.onUpdate(component)
        setError(null)
        setInstruction(null)
        setPlaceholder(null)
        setType(null)
        setChoices([])
        setSlug(null)
        props.onClose()
    }

    function addChoice() {
        choices.push(newChoice)
        setNewChoice('')
    }

    async function removeChoice(element) {
        setLoading(true)
        const index = choices.indexOf(element)
        if (index > -1) {
            await choices.splice(index, 1)
            setChoices(choices)
            setType('DROPDOWN')
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <Transition
            show={props.open}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                        onClick={() => props.onClose()}>
                        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
                    </div>

                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>

                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline">

                        <div className='p-4 createapp-view'>
                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-xl">New component</h2>
                                </div>
                                <div>
                                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div>
                                {error ? (
                                    <Alert type="error" title={error}></Alert>
                                ) : null}
                            </div>
                            <div className="mt-4">
                                <div className="mt-3">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        Instruction (field displayed at the top of the input)
                                    </label>
                                    <Input setValue={(value) => setInstruction(value)} value={instruction} placeholder="Wallet Address" />
                                </div>
                                <div className="mt-3">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        Input type
                                    </label>
                                    <ListSelector
                                        onSelect={(selection) => setType(selection)}
                                        selected={type}
                                        choices={[
                                            {
                                                label: 'INPUT',
                                                value: 'INPUT',
                                            },
                                            {
                                                label: 'DROPDOWN',
                                                value: 'DROPDOWN',
                                            },
                                            {
                                                label: 'COUNTRY',
                                                value: 'COUNTRY',
                                            },
                                            {
                                                label: 'TEXTAREA',
                                                value: 'TEXTAREA',
                                            },
                                            {
                                                label: 'UPLOAD',
                                                value: 'UPLOAD',
                                            },
                                            {
                                                label: 'CHECKBOX',
                                                value: 'CHECKBOX',
                                            },
                                            {
                                                label: 'LIST',
                                                value: 'LIST',
                                            }
                                        ]}></ListSelector>
                                </div>
                                {type === 'DROPDOWN' ?
                                    <div className="mt-3">
                                        <label
                                            className="flex block text-sm mb-3 font-medium text-primary-700">
                                            DropDown choices
                                        </label>
                                        {loading === false ?
                                            <>
                                                {choices.map((choice, index) => {
                                                    return (
                                                        <div key={index} className="flex mb-2 w-full">
                                                            <div className="w-full"><Input disabled value={choice} /></div>

                                                            <h2 className="text-xl mt-2 ml-2"><span><FontAwesomeIcon onClick={() => removeChoice(choice)} className="cursor-pointer text-red-400 hover:text-red-700 transition duration-100 ease-in" icon={faMinusCircle}></FontAwesomeIcon></span></h2>
                                                        </div>
                                                    )
                                                })}</>
                                            : null}
                                        <div className="flex mt-4 w-full">
                                            <div className="w-full"><Input setValue={(value) => setNewChoice(value)} value={newChoice} placeholder="new choice" /></div>
                                            <h2 className="text-xl mt-2 ml-2"><span><FontAwesomeIcon onClick={() => addChoice()} className="cursor-pointer text-green-400 hover:text-green-700 transition duration-100 ease-in" icon={faPlusCircle}></FontAwesomeIcon></span></h2>
                                        </div>
                                    </div>
                                    : null}
                                <div className="mt-3">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        Placeholder
                                    </label>
                                    <Input setValue={(value) => setPlaceholder(value)} value={placeholder} placeholder="Your wallet address" />
                                </div>
                                <div className="mt-3">
                                    <label
                                        className="flex block text-sm mb-3 font-medium text-primary-700">
                                        Slug (field displayed in db)
                                    </label>
                                    <Input setValue={(value) => setSlug(value)} value={slug} placeholder="address" />
                                </div>

                                <div className="mt-5">
                                    <Button full disabled={!instruction || !slug || !type} theme="success" label="Create" onClick={() => Submit()}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    )
}
