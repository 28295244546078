import { ResidencyContext } from '@/context/residency'
import ResidencyService from '@/services/residency'
import { useContext, useEffect } from 'react'
import Tab from '../ui/Tab'

export default function ResidencyTabs() {
	const [residency, residencyDispatch] = useContext(ResidencyContext)

	useEffect(async () => {
		await ResidencyService.GetStats()
			.then((data) => {
				residencyDispatch({
					type: 'SET_STATS',
					payload: {
						stats: data,
					},
				})
			})
			.catch(() => {})
		return () => {}
	}, [])

	function setCurrentTab(currentTab) {
		residencyDispatch({
			type: 'SET_CURRENT_TAB',
			payload: {
				tab: currentTab,
			},
		})
	}

	return residency.tabs.map((tab) => {
		return (
			<Tab
				badge={residency.stats[tab.key]}
				selectedKey={residency.selectedTab.key}
				key={tab.key}
				item={tab}
				onClick={() => setCurrentTab(tab)}></Tab>
		)
	})
}
