import { useContext, useEffect, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import Input from "../../ui/Input";
import {
  faCabinetFiling,
  faIdCard,
  faSearch,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faMinus,
} from "@fortawesome/pro-solid-svg-icons";
import Alert from "@/components/ui/Alert";
import IdentityTemplateElement from "@/components/identity/IdentityTemplateElement";
import * as Notification from "@/components/ui/Notification";
import StepTitle from "@/components/ui/StepTitle";
import Button from "@/components/ui/Button";

export default function IdentityTemplateSelection(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [templateList, setTemplateList] = useState([]);
  const [registryList, setRegistryList] = useState([]);

  const keyMap = {
    GO_NEXT: ["right", "down"],
    GO_BACK: ["left", "up"],
    SELECT: ["enter"],
  };

  function next(event) {
    event.preventDefault();
    setNext();
  }

  function back(event) {
    event.preventDefault();
    setPrev();
  }

  function select(event) {
    event.preventDefault();
    setDocumentTemplate(registryList[currentIndex].slug);
  }

  const handlers = {
    GO_NEXT: next,
    GO_BACK: back,
    SELECT: select,
  };

  useEffect(() => {
    IdentityService.GetRegistryTemplates(
      identityVerification.verification.document.country,
      identityVerification.verification.document.type
    )
      .then((data) => {
        setTemplateList(data);
        if (data.length > 0) {
          setCurrentIndex(0);
        }
      })
      .catch((err) => {
        setError(err.message);
      });
    return () => { };
  }, []);

  useEffect(() => {
    const newTemplates = [];

    templateList
      .filter((template) => template.slug.includes(searchTerm.toUpperCase()))
      .map((template) => {
        newTemplates.push(template);
      });
    newTemplates.push({
      slug: "new_template",
    });
    setRegistryList(newTemplates);
    setCurrentIndex(0);
  }, [templateList, searchTerm]);

  function setNext() {
    if (!loading) {
      if (currentIndex + 1 < registryList.length) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }

  function setPrev() {
    if (!loading) {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  }

  async function swapFace() {
    setLoading(true);
    await IdentityService.SwapDocumentFace(identityVerification.task.session_id)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  async function setDocumentTemplate(slug) {
    setLoading(true);
    await IdentityService.SetTemplate(
      identityVerification.task.session_id,
      slug
    )
      .then(() => {
        setLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => { };
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div id="template-selection" className="flex flex-wrap">
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Document" icon={faIdCard}></StepTitle>
            </div>
          </div>

          {identityVerification.verification.document.type !== "PASSPORT" ? (
            <div className="mt-3 w-full flex justify-center">
              <Button
                onClick={() => swapFace()}
                theme="primary"
                label="Swap Face"
              ></Button>
            </div>
          ) : null}

          <div className="mx-auto mt-12">
            <img
              key="front-image"
              className="object-contain h-80 w-full"
              src={identityVerification.media.segmented.front}
            />
          </div>
          {identityVerification.verification.document.type !== "PASSPORT" ? (
            <div>
              <img
                key="back-image"
                className="object-contain h-80 w-full"
                src={identityVerification.media.segmented.back}
              />
            </div>
          ) : null}
        </div>
        <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle value="Template" icon={faCabinetFiling}></StepTitle>
            </div>
            <div>
              <div className="flex flex-wrap">
                <Input
                  type="text"
                  icon={faSearch}
                  placeholder="Search"
                  setValue={(value) => setSearchTerm(value)}
                />
                <span className="inline-flex items-center text-sm px-2 ml-2 rounded-lg font-medium bg-gray-100 text-gray-800">
                  {currentIndex + 1} / {registryList.length}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`${currentIndex === 0
                ? "cursor-default opacity-75"
                : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
              } w-full p-2 rounded-t-xl text-center transition duration-100 ease-in`}
            onClick={() => setPrev()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={currentIndex === 0 ? faMinus : faChevronUp}
            ></FontAwesomeIcon>
          </div>
          {registryList.length > 0 ? (
            <IdentityTemplateElement
              onSet={(slug) => setDocumentTemplate(slug)}
              element={registryList[currentIndex]}
            ></IdentityTemplateElement>
          ) : null}
          <div
            className={`${currentIndex === registryList.length - 1
                ? "cursor-default opacity-75"
                : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
              } w-full p-2 rounded-t-xl text-center transition duration-100 ease-in`}
            onClick={() => setNext()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={
                currentIndex === registryList.length - 1
                  ? faMinus
                  : faChevronDown
              }
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
