import { useState, useEffect } from 'react'
import SubNav from '../menu/SubNav'
import Card from '../ui/Card'
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import Footer from '../menu/Footer'
import Alert from '@/components/ui/Alert'
import NetworkService from '@/services/network'
import VerificationStepPlaceholder from '../placeholder/VerificationStepPlaceholder'
import Button from '../ui/Button'
import ProjectItem from '../project/ProjectItem'
import { useParams, useHistory } from 'react-router-dom'
import Sleep from '@/lib/sleep'

export default function NetworkProjectPage() {
	const [error, setError] = useState(null)
	const [ready, setReady] = useState(false)
	const [selectedProject, setSelectedProject] = useState(null)
	const { project } = useParams()
	const router = useHistory()

	useEffect(async () => {
		await NetworkService.GetProjectNetwork()
			.then(async (data) => {
				data.list.forEach(async (item, index) => {
					if (item.name === project) {
						setSelectedProject(item)
						setReady(true)
					}
				  })
			})
			.catch((err) => {
				console.log(err)
			})
		return () => { }
	}, [])

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mt-8 mx-auto relative">
					<div className="verification-container">
						<Card className="w-full px-3 mt-5 sm:px-0">
							{ready === true ?
								<div>
									<div className="p-3">
										<div className="flex w-full justify-between">
											<div className="mr-3 mt-1">
												<CardTitle
													value="Project"
													icon={faChartNetwork}></CardTitle>
											</div>
											<div className="mr-3 mt-2">
												<Button
													type="submit"
													theme="primary"
													onClick={() => router.push('/network')}
													label="Back"
												/>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}
										{ready ? (
											<>
												{' '}
												<div className="flex mt-5 flex-wrap items-stretch">
													<ProjectItem project={selectedProject}></ProjectItem>
												</div>
											</>
										) : (
											<VerificationStepPlaceholder></VerificationStepPlaceholder>
										)}
									</div>
								</div>
								: null}
						</Card>
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
