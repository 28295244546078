import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type, pin_type, project) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/list?type=${type}&pin_type=${pin_type}&project=${project}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}`
    );
  },
  SegmentDocument(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/segmentation?session_id=${session_id}`,
      body
    );
  },
  GetRegistryTemplates(country, document_type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/template/${country}/${document_type}`
    );
  },
  SetVisualCheck(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/visual_check?session_id=${session_id}`,
      body
    );
  },
  SetForgeryCheck(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/forgery?session_id=${session_id}`,
      body
    );
  },
  SetTemplate(session_id, slug) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/template?session_id=${session_id}&slug=${slug}`
    );
  },
  SetInfoExtract(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/info_extract?session_id=${session_id}`,
      body
    );
  },
  SetFeatures(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/features?session_id=${session_id}`,
      body
    );
  },
  SetInfoReview(session_id, slug, value) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/info_review?session_id=${session_id}&slug=${slug}&value=${value}`,
      {}
    );
  },
  SetInfoCrosscheck(session_id, slug, valid) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/info_crosscheck?session_id=${session_id}&slug=${slug}&valid=${valid}`,
      {}
    );
  },
  ExtractMRZ(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/mrz_lines?session_id=${session_id}`,
      body
    );
  },
  ExtractMRZBarcode(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/mrz_barcode?session_id=${session_id}`,
      body
    );
  },
  PinIdentityTask(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/pin`,
      body
    );
  },
  UnpinTask(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/unpin`,
      null
    );
  },
  Undo(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/step/undo?session_id=${session_id}`,
      {}
    );
  },
  Reject(session_id, rejection_code) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/reject?rejection_id=${rejection_code}`,
      {}
    );
  },
  ChangeDocumentType(session_id, document_type) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/change_document_type?document_type=${document_type}`,
      {}
    );
  },
  ChangeDocumentCountry(session_id, document_country) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/change_document_country?country=${document_country}`,
      {}
    );
  },
  Validate(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/validate`,
      {}
    );
  },
  SwapDocumentFace(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/swap`,
      {}
    );
  },
  ProcessAutoChecks(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/identity/task/${session_id}/auto`,
      {}
    );
  },
};
