import { useContext, useEffect, useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import {
	faExclamationTriangle,
	faPen,
	faSearch,
} from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { CorporateVerificationContext } from '@/context/corporate_verification'
import * as Notification from '@/components/ui/Notification'
import { useHistory } from 'react-router-dom'
import CountryCard from '../ui/CountryCard'
import countriesList from '@/resources/countries.json'
import Input from '../ui/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CorporateService from '@/services/corporate'

export default function EditCountry({ open, setOpen }) {
	const [pin, setPin] = useState('')
	const [subMode, setSubMode] = useState('validation')
	const [loading, setLoading] = useState(false)
	const router = useHistory()
	const [localValue, setLocalValue] = useState('')

	const [corporateVerification] = useContext(
		CorporateVerificationContext
	)
	useEffect(() => {
		if (
			pin !== '' && corporateVerification.app.setting.restricted_corporate_countries.length > 0 &&
			JSON.stringify(corporateVerification.app.setting.restricted_corporate_countries).includes(pin)
		) {
			setSubMode('rejection')
		} else {
			setSubMode('validation')
		}
	}, [pin])

	function updatePin(newPin) {
		if (pin === newPin) {
			setPin('')
		} else {
			setPin(newPin)
		}
	}

	async function rejectCorporate(rejection) {
		setLoading(true)
		await CorporateService.Reject(
			corporateVerification.task.session_id,
			rejection
		)
			.then(() => {
				setLoading(false)
				Notification.success('Corporate rejected successfully')
				router.push('/verifications/corporate')
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => { }
	}

	async function changeCorporateCountry() {
		setLoading(true)
		await CorporateService.ChangeCorporateCountry(
			corporateVerification.task.session_id,
			pin
		)
			.then(() => {
				setLoading(false)
				Notification.success('Corporate country changed successfully')
				window.location.reload()
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => { }
	}

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-primary-500 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										Edit Country
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-primary-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className='mt-1'>
									<p className='text-sm text-white'>
										Select the current document country among all the available
										countries
									</p>
								</div>
							</div>
							<div className='flex-1 flex flex-col justify-between'>
								<div className='px-4 py-2'>
									<>
										<div className='mt-2 mb-2'>
											<Input
												type='text'
												icon={faSearch}
												placeholder='Search'
												setValue={(value) => setLocalValue(value)}
											/>
										</div>
										<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
											Countries
										</p>
										{countriesList
											.filter((c) =>
												c.name.toLowerCase().includes(localValue.toLowerCase())
											)
											.map((country) => {
												return (
													<div key={country.alpha3}>
														<CountryCard
															selected={pin}
															setPin={() => updatePin(country.alpha3)}
															slug={country.alpha3}
															title={country.name}></CountryCard>
													</div>
												)
											})}
									</>
								</div>
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-between'>
							<div className='mt-3 ml-2'>
								{pin !== '' && subMode === 'rejection' ? (
									<p className='text-red-500'>
										<FontAwesomeIcon
											className='mr-2'
											icon={faExclamationTriangle}></FontAwesomeIcon>
										Restricted country
									</p>
								) : null}
							</div>
							<div className='flex'>
								<Button
									onClick={() => setOpen(false)}
									theme='secondary'
									label='Cancel'></Button>
								{pin !== '' && subMode === 'validation' ? (
									<Button
										loading={loading}
										className='ml-2'
										onClick={() => changeCorporateCountry()}
										icon={faPen}
										theme='success'
										label='Change document'></Button>
								) : null}
								{pin !== '' && subMode === 'rejection' ? (
									<Button
										loading={loading}
										className='ml-2'
										onClick={() => rejectCorporate('RESTRICTED_COUNTRY_TYPE')}
										icon={faTimes}
										theme='error'
										label='Reject'></Button>
								) : null}
							</div>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
