import {
	faAsterisk,
	faBan,
	faBirthdayCake,
	faCalendar,
	faCar,
	faCut,
	faEye,
	faFeather,
	faFemale,
	faFlag,
	faHandPointRight,
	faHouse,
	faIdCard,
	faLineHeight,
	faLungs,
	faMale,
	faMap,
	faMapMarked,
	faMapMarker,
	faMarker,
	faPassport,
	faRestroom,
	faSearch,
	faSignature,
	faSortNumericDown,
	faStamp,
	faSteeringWheel,
	faSuitcase,
	faTilde,
	faTint,
	faUser,
	faUsers,
	faVenusMars,
	faWeight,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function RegistrySuggestions(props) {
	const fieldsSuggestions = [
		{
			title: 'Personal',
			icon: faUser,
			values: [
				{
					icon: faBirthdayCake,
					en: 'Birth Place',
					fr: 'Lieu de naissance'
				},
				{
					icon: faVenusMars,
					en: 'Gender',
					fr: 'Sexe'
				},
				{
					icon: faSortNumericDown,
					en: 'Personal Number',
					fr: 'Numéro personnel'
				},
				{
					icon: faEye,
					en: 'Eyes',
					fr: 'Yeux'
				},
				{
					icon: faCut,
					en: 'Hair',
					fr: 'Cheveux'
				},
				{
					icon: faLineHeight,
					en: 'Height',
					fr: 'Taille'
				},
				{
					icon: faWeight,
					en: 'Weight',
					fr: 'Poids'
				},
				{
					icon: faMapMarker,
					en: 'Postal Address',
					fr: 'Adresse postale'
				},
				{
					icon: faSuitcase,
					en: 'Occupation',
					fr: 'Travail / Occupation'
				},
				{
					icon: faHouse,
					en: 'Residence',
					fr: 'Résidence'
				},
				{
					icon: faTint,
					en: 'Blood Group',
					fr: 'Group Sanguin'
				},
				{
					icon: faLungs,
					en: 'Organ Donor',
					fr: 'Donneur d\'organe'
				},
			],
		},
		{
			title: 'Document',
			icon: faIdCard,
			values: [
				{
					icon: faFlag,
					en: 'Nationality',
					fr: 'Nationalité'
				},
				{
					icon: faSignature,
					en: 'Signature',
					fr: 'Signature'
				},
				{
					icon: faPassport,
					en: 'Document Type',
					fr: 'Type du document'
				},
			],
		},
		{
			title: 'Issuance',
			icon: faSignature,
			values: [
				{
					icon: faStamp,
					en: 'Authority',
					fr: 'Autorité'
				},
				{
					icon: faSignature,
					en: 'Authority Signature',
					fr: 'Signature de l\'autorité'
				},
				{
					icon: faCalendar,
					en: 'Issuing Date',
					fr: 'Date d\'émission'
				},
				{
					icon: faFlag,
					en: 'Issuing Country',
					fr: 'Pays émetteur'
				},
				{
					icon: faMap,
					en: 'Issuing State',
					fr: 'Etat émetteur'
				},
				{
					icon: faMapMarker,
					en: 'Issuing Place',
					fr: 'Lieu d\'émission'
				},
			],
		},
		{
			title: 'Family',
			icon: faUsers,
			values: [
				{
					icon: faRestroom,
					en: 'Parents',
					fr: 'Parents'
				},
				{
					icon: faFemale,
					en: 'Mother name',
					fr: 'Nom de la mère'
				},
				{
					icon: faMale,
					en: 'Father name',
					fr: 'Nom du père'
				},
			],
		},
		{
			title: 'Driving',
			icon: faCar,
			values: [
				{
					icon: faTilde,
					en: 'Driver Class',
					fr: 'Type de conducteur'
				},
				{
					icon: faBan,
					en: 'Driver Restriction',
					fr: 'Restriction du conducteur'
				},
				{
					icon: faUser,
					en: 'Driver Endorsement',
					fr: 'Approbation du conducteur'
				},
				{
					icon: faSteeringWheel,
					en: 'Permit Type',
					fr: 'Type de permis'
				},
			],
		},
	]

	const securitySuggestions = [
		{
			title: 'Security Features',
			icon: faSearch,
			values: [
				'Optically Variable Ink',
				'Photo Repetition',
				'Multiple Laser Image',
				'Changeable Laser Image',
				'Kinegram',
				'Hologram',
				'Authority Symbol',
				'Country Flag',
				'Watermark',
				'Diffractive Identification Device',
				'Document Notice',
				'Iridescent Ink',
				'Laminate',
				'Magnetic Stripe',
				'Microchip',
				'Microprint',
				'Nanoprint',
				'Offset Printing',
				'QR Code',
				'Real ID Star',
				'See-through Register',
				'Taurus Symbol',
				'Thermochromic Ink',
				'Vehicle List',
				'Squeezed Information',
			],
		},
	]

	return (
		<>
			{props.type === 'fields'
				? fieldsSuggestions.map((el) => {
					return (
						<div key={el.title} className="mb-5">
							<p className="text-gray-700 text-sm">
								<FontAwesomeIcon
									className="text-xs mr-1"
									icon={el.icon}></FontAwesomeIcon>{' '}
								{el.title}
							</p>
							{el.values.filter((c) =>
								c.en.toLowerCase().includes(props.search)
							).map((suggestion) => {
								return (
									<div onClick={() => props.onSet(suggestion.en)} className="items-center flex flex-wrap mt-2 hover:bg-gray-200 p-3 bg-gray-50 border border-gray-200 hover:border-gray-400 my-3 rounded-md text-primary-700 text-sm cursor-pointer transition duration-200 ease-in" key={suggestion.en}>
										<div className="W-24">
											<FontAwesomeIcon className="text-xl mr-3 ml-1 text-gray-500" icon={suggestion.icon} />
										</div>
										<div>
											<p>
												{suggestion.en}
												<span className="block text-xs text-gray-500">{suggestion.fr}</span>
											</p>
										</div>
									</div>
								)
							})}
						</div>
					)
				})
				: null}
			{props.type === 'security'
				? securitySuggestions.map((el) => {
					return (

						<div key={el.title} className="mt-5">
							<p className="text-gray-700 text-sm">
								<FontAwesomeIcon
									className="text-xs mr-1"
									icon={el.icon}></FontAwesomeIcon>{' '}
								{el.title}
							</p>
							{el.values.filter((c) =>
								c.toLowerCase().includes(props.search)
							).map((suggestion) => {
								return (

									<div onClick={() => props.onSet(suggestion)} className="items-center flex flex-wrap mt-2 hover:bg-gray-200 p-3 bg-gray-50 border border-gray-200 hover:border-gray-400 my-3 rounded-md text-primary-700 text-sm cursor-pointer transition duration-200 ease-in" key={suggestion.en}>
										<div className="W-24">
											<FontAwesomeIcon className="text-xl mr-3 ml-1 text-gray-500" icon={faAsterisk} />
										</div>
										<div>
											<p>
												{suggestion}
												<span className="block text-xs text-gray-500">{suggestion}</span>
											</p>
										</div>
									</div>


								)
							})}
						</div>
					)
				})
				: null}
		</>
	)
}
