import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import AmlTasksPage from '@/components/pages/AmlTasksPage'
import { AmlContextProvider } from '@/context/aml'

function Aml() {
	return (
		<AuthGuard>
			<IamGuard page='aml'>
				<AmlContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<AmlTasksPage></AmlTasksPage>
					</div>
				</AmlContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default Aml
