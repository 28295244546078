import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SubNav from "../menu/SubNav";
import Card from "../ui/Card";
import Footer from "../menu/Footer";
import DuplicateVerificationHeader from "../verifications/duplicate/DuplicateVerificationHeader";
import DuplicateService from "@/services/duplicate";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import * as Notification from "@/components/ui/Notification";
import useWebSocket from "react-use-websocket";
import AuthService from "@/services/auth";
import { DuplicateVerificationContext } from "@/context/duplicate_verification";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import DuplicateVisualCheck from "../verifications/duplicate/DuplicateVisualCheck";
import { AuthContext } from "@/context/auth";

export default function DuplicateVerification() {
  const [, duplicateVerificationDispatch] = useContext(
    DuplicateVerificationContext
  );
  const [ready, setReady] = useState(false);
  const router = useHistory();
  const { session_id } = useParams();
  const [auth] = useContext(AuthContext);

  const socketUrl = `${process.env.REACT_APP_WS_HOST}/v3/websockets/online`;
  const { readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: async () => {},
    shouldReconnect: () => true,
  });

  useEffect(async () => {
    await getTask();
    return () => {};
  }, []);

  useEffect(async () => {
    if (readyState) {
      getWebSocket().send(
        JSON.stringify({
          auth: auth.auth.access_token,
          email: auth.user.email,
          firstname: auth.user.firstname,
          lastname: auth.user.lastname,
          profile_pic: auth.user.profile_pic,
          service: "DUPLICATE",
          session_id: session_id,
        })
      );
    }
  }, [readyState]);

  async function getTask() {
    await DuplicateService.GetTask(session_id)
      .then(async (data) => {
        if (data.task.service_session_id === "") {
          router.push("/verifications/duplicate");
          return;
        }
        await duplicateVerificationDispatch({
          type: "SET_SESSION",
          payload: data,
        });
        console.log("data");
        console.log(data);
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <DuplicateVerificationHeader
                onUpdate={() => getTask()}
              ></DuplicateVerificationHeader>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>
        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div>
                  {ready ? (
                    <div>
                      <div className="p-6">
                        <DuplicateVisualCheck
                          onUpdate={() => getTask()}
                        ></DuplicateVisualCheck>
                      </div>
                    </div>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
