import ResidencyStep from './ResidencyStep'
import { useContext } from 'react'
import { ResidencyVerificationContext } from '@/context/residency_verification'

export default function ResidencySteps() {
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)
	return (
		<>
			<nav aria-label="Progress">
				<ol className="border-t border-gray-200 divide-y divide-gray-300 md:flex md:divide-y-0">
					{residencyVerification.verification.is_accomodation ? (
						<>
							<ResidencyStep
								label="Host ID"
								number="1"
								current={
									residencyVerification.verification.steps.current_step ===
									'host_id_verification'
								}
								done={
									residencyVerification.verification.steps.host_id_verification
								}></ResidencyStep>

							<ResidencyStep
								label="Accomodation"
								number="2"
								current={
									residencyVerification.verification.steps.current_step ===
									'accomodation_proof_verification'
								}
								done={
									residencyVerification.verification.steps
										.accomodation_proof_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Document Type"
								number="3"
								current={
									residencyVerification.verification.steps.current_step ===
									'document_type_verification'
								}
								done={
									residencyVerification.verification.steps
										.document_type_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Name"
								number="4"
								current={
									residencyVerification.verification.steps.current_step ===
									'name_verification'
								}
								done={
									residencyVerification.verification.steps.name_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Issuing Date"
								number="5"
								current={
									residencyVerification.verification.steps.current_step ===
									'issuing_date_verification'
								}
								done={
									residencyVerification.verification.steps
										.issuing_date_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Postal Address"
								number="6"
								current={
									residencyVerification.verification.steps.current_step ===
									'address_verification'
								}
								last
								done={
									residencyVerification.verification.steps.address_verification
								}></ResidencyStep>
						</>
					) : (
						<>
							<ResidencyStep
								label="Document Type"
								number="1"
								current={
									residencyVerification.verification.steps.current_step ===
									'document_type_verification'
								}
								done={
									residencyVerification.verification.steps
										.document_type_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Name"
								number="2"
								current={
									residencyVerification.verification.steps.current_step ===
									'name_verification'
								}
								done={
									residencyVerification.verification.steps.name_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Issuing Date"
								number="3"
								current={
									residencyVerification.verification.steps.current_step ===
									'issuing_date_verification'
								}
								done={
									residencyVerification.verification.steps
										.issuing_date_verification
								}></ResidencyStep>
							<ResidencyStep
								label="Postal Address"
								number="4"
								current={
									residencyVerification.verification.steps.current_step ===
									'address_verification'
								}
								last
								done={
									residencyVerification.verification.steps.address_verification
								}></ResidencyStep>
						</>
					)}
				</ol>
			</nav>
		</>
	)
}
