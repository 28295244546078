import { useContext } from "react";
import { AuthContext } from "@/context/auth";
import Card from "../ui/Card";
import CardTitle from "../ui/CardTitle";
import {
  faBrowser,
  faChartPie,
  faHeadset,
  faLayerGroup,
  faQuestionCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTasks } from "@fortawesome/pro-duotone-svg-icons";
import DashboardTasks from "../dashboard/DasbhoardTasks";
import MyVerificationStats from "../dashboard/MyVerificationStats";
import ReviewerHelp from "../dashboard/ReviewerHelp";
import Button from "../ui/Button";
import Footer from "../menu/Footer";
import Management from "../dashboard/Management";
import Apps from "../dashboard/Apps";
import SubNav from "../menu/SubNav";

export default function DashboardPage() {
  const [auth] = useContext(AuthContext);

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="dashboard-container">
            <div className="flex justify-between mt-3">
              <div>
                <h1 className="text-3xl text-white">
                  <span className="font-bold">Hello,</span>
                  <span className="font-light"> {auth.user.firstname}</span>
                </h1>
                <h3 className="text-lg mt-2 text-bluegray-300">
                  Welcome to your verification platform
                </h3>
              </div>
              <div className="mt-2.5 mr-3 sm:mr-0">
                <a href="slack://open?team=TMG0VNSAC">
                  <Button
                    currentBg="primary"
                    theme="secondary"
                    icon={faHeadset}
                    label="Contact Support"
                  ></Button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0 sm:pr-1.5 sm:w-2/3">
              <div>
                <CardTitle value="Tasks" icon={faTasks}></CardTitle>
                <div className="px-3 pb-3">
                  <DashboardTasks></DashboardTasks>
                </div>
              </div>
            </Card>
            <Card className="w-full px-3 mt-8 sm:mt-0 sm:pt-0 sm:px-0 sm:pl-3 sm:w-1/3">
              <CardTitle value="My Verifications" icon={faChartPie}></CardTitle>
              <MyVerificationStats service="identity"></MyVerificationStats>
            </Card>
          </div>

          <div className="flex flex-wrap items-stretch mt-8 sm:0">
            <Card className="w-full px-3 sm:pr-3 sm:px-0 sm:w-2/3">
              <div className="flex justify-between">
                <div>
                  <CardTitle value="Apps" icon={faBrowser}></CardTitle>
                </div>
                <div>
                  {/* <Link href="/apps">
											<p className="p-4 pb-0 mr-1 cursor-pointer hover:underline">
												View all
											</p>
										</Link> */}
                </div>
              </div>
              <Apps></Apps>
            </Card>
            <Card className="w-full px-3 sm:pl-3 sm:px-0 sm:w-1/3 mt-8 sm:mt-0">
              <CardTitle value="Management" icon={faLayerGroup}></CardTitle>
              <Management></Management>
            </Card>
          </div>
          <div className="flex flex-wrap mt-8">
            <Card className="w-full px-3 sm:px-0">
              <div className="px-3 pb-3">
                <CardTitle value="Help" icon={faQuestionCircle}></CardTitle>
                <ReviewerHelp></ReviewerHelp>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
