import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SubNav from "../menu/SubNav";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import RegistryService from "@/services/registry";
import RegistryListHeader from "../registry/RegistryListHeader";
import Card from "../ui/Card";
import { faPassport } from "@fortawesome/pro-duotone-svg-icons";
import CardTitle from "../ui/CardTitle";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import Footer from "../menu/Footer";
import RegistryListItem from "../registry/RegistryListItem";
import ContentSearchBar from "../ui/ContentSearchBar";
import Empty from "../misc/Empty";
import countriesList from "@/resources/countries.json";
import Alert from "@/components/ui/Alert";

export default function RegistryListPage() {
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const router = useHistory();
  const [initialCountries, setInitialCountries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [data, setData] = useState(null);

  useEffect(async () => {
    await RegistryService.GetIdentityStats()
      .then((data) => {
        setData(data);
        setReady(true);
      })
      .catch((err) => {
        setError(err.message);
      });
    setInitialCountries(countriesList);
    setCountries(countriesList);
    return () => {};
  }, []);

  function setSearch(term) {
    let filteredCountries = initialCountries.filter((reg) =>
      reg.alpha3.toLowerCase().includes(term.toLowerCase())
    );
    setCountries(filteredCountries);
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <div className="max-w-7xl mx-auto relative">
                <div className="dashboard-container">
                  <div className="flex">
                    <Card className="w-full px-3 sm:px-0">
                      <div className="bg-white p-6 border shadow rounded-xl w-full">
                        <RegistryListHeader data={data}></RegistryListHeader>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div className="p-3">
                  <div className="flex justify-between">
                    <div>
                      <CardTitle
                        value="Identity Registry"
                        icon={faPassport}
                      ></CardTitle>
                    </div>
                    <div>
                      <ContentSearchBar
                        onChange={(el) => setSearch(el.target.value)}
                      ></ContentSearchBar>
                    </div>
                  </div>
                  {error ? (
                    <div className="p-3">
                      <Alert type="error" title={error}></Alert>
                    </div>
                  ) : null}

                  {ready ? (
                    <>
                      {" "}
                      <div className="flex flex-wrap items-stretch">
                        {countries.map((registry) => {
                          return (
                            <div
                              onClick={() =>
                                router.push(`/registry/${registry.alpha3}`)
                              }
                              className="w-full sm:w-1/4 p-4 self-auto"
                              key={registry.country}
                            >
                              <div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
                                <RegistryListItem
                                  registry={registry}
                                ></RegistryListItem>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {countries.length === 0 ? (
                        <div className="text-center">
                          <Empty value="No country"></Empty>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
