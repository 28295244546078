import AuthGuard from "@/guard/auth";
import Navbar from "@/components/menu/Navbar";
import IamGuard from "@/guard/iam";
import { IdentityVerificationContextProvider } from "@/context/identity_verification";
import IdentityVerification from "@/components/layout/IdentityVerification";

function IdentitySession() {
  return (
    <AuthGuard>
      <IamGuard page="identity">
        <IdentityVerificationContextProvider>
          <div className="relative min-h-screen flex flex-col">
            <Navbar></Navbar>
            <IdentityVerification></IdentityVerification>
          </div>
        </IdentityVerificationContextProvider>
      </IamGuard>
    </AuthGuard>
  );
}

export default IdentitySession;
