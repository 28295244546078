import { useState, useEffect } from 'react'
import SubNav from '../menu/SubNav'
import Card from '../ui/Card'
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import Footer from '../menu/Footer'
import Alert from '@/components/ui/Alert'
import NetworkService from '@/services/network'
import Empty from '../misc/Empty'
import VerificationStepPlaceholder from '../placeholder/VerificationStepPlaceholder'
import ProjectListItem from '../project/ProjectListItem'
import NewProject from '../project/NewProject'
import Button from '../ui/Button'
import { useHistory } from 'react-router-dom'

export default function NetworkPage() {
	const [error, setError] = useState(null)
	const [ready, setReady] = useState(false)
	const [projects, setProjects] = useState(null)
	const [addProject, setAddProject] = useState(false)
	const router = useHistory()

	useEffect(async () => {
		await NetworkService.GetProjectNetwork()
			.then((data) => {
				console.log('data')
				console.log(data)
				setProjects(data)
				setReady(true)
			})
			.catch((err) => {
				console.log(err)
			})
		return () => { }
	}, [])

	async function updateProjects() {
		await NetworkService.GetProjectNetwork()
			.then((data) => {
				console.log('data')
				console.log(data)
				setProjects(data)
				setAddProject(false)
				setReady(true)
			})
			.catch((err) => {
				console.log(err)
			})
		return () => { }

	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mt-8 mx-auto relative">
					<div className="verification-container">
						<Card className="w-full px-3 mt-5 sm:px-0">
								<div>
									<div className="p-3">
										<div className="flex w-full justify-between">
											<div className="mr-3 mt-1">
												<CardTitle
													value="Projects network"
													icon={faChartNetwork}></CardTitle>
											</div>
											<div className="mr-3 mt-2">
												<Button
													type="submit"
													theme="success"
													onClick={() => setAddProject(true)}
													label="New project"
												/>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}
										{ready ? (
											<>
												{' '}
												<div className="flex mt-5 flex-wrap items-stretch">
													{projects.list.map((project) => {
														return (
															<ProjectListItem select={(project) => router.push('/network/'+project.name)} key={project.name} project={project}></ProjectListItem>
														)
													})}
												</div>
												{projects.list === 0 ? (
													<div className="text-center">
														<Empty value="No Projects"></Empty>
													</div>
												) : null}
											</>
										) : (
											<VerificationStepPlaceholder></VerificationStepPlaceholder>
										)}
									</div>
								</div>
						</Card>
					</div>
					<Footer></Footer>
					{ready ?
						<NewProject
							open={addProject}
							onUpdate={() => updateProjects()}
							onClose={() => setAddProject(false)}></NewProject> : null}
				</div>
			</div>
		</div>
	)
}
