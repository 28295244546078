import { faBrowser } from '@fortawesome/pro-duotone-svg-icons'
import AppDashboardItem from '../apps/AppDashboardItem'
import { useEffect, useState, useContext } from 'react'
import AppsService from '@/services/apps'
import { AuthContext } from '@/context/auth'

export default function Apps() {
	const [apps, setApps] = useState([])
	const [auth] = useContext(AuthContext)

	useEffect(() => {
		AppsService.GetLastApps()
			.then((data) => {
				setApps(data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<div className='flex flex-wrap px-3 pb-3 mt-3'>
			{apps.map((app) => {
				return (
					<div className='w-full list-item cursor-pointer' key={app._key}>
						{auth.iam.admin ? (
						<AppDashboardItem
							name={app.name}
							path={`/apps/${app._key}`}
							logo_url={app.logo_url}
							color='primary'
							hover='gray'
							type={app.type}
							icon={faBrowser}></AppDashboardItem>
						) : null}
					</div>
				)
			})}
		</div>
	)
}
