import { PanelAuth } from "@/services/axios";

export default {
  GetTasks(type) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/list?type=${type}`
    );
  },
  GetStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/stats`
    );
  },
  GetTask(session_id) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}`
    );
  },
  PinResidencyTask(session_id, body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}/pin`,
      body
    );
  },
  Undo(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/step/undo?session_id=${session_id}`,
      {}
    );
  },
  Reject(session_id, rejection_code) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}/reject?rejection_code=${rejection_code}`,
      {}
    );
  },
  ChangeDocumentType(session_id, document_type) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}/change_document_type?document_type=${document_type}`,
      {}
    );
  },
  ChangeDocumentCountry(session_id, country) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}/change_document_country?country=${country}`,
      {}
    );
  },
  Validate(session_id) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/task/${session_id}/validate`,
      {}
    );
  },
  ValidateStep(session_id, step) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/step/validate_step?session_id=${session_id}&step=${step}`,
      {}
    );
  },
  SetIssuingDate(session_id, issuing_date) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/step/issuing_date?session_id=${session_id}&issuing_date=${issuing_date}`,
      {}
    );
  },
  SetPostalAddress(session_id, address_id) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/verifications/residency/step/postal_address?session_id=${session_id}&address_id=${address_id}`,
      {}
    );
  },
};
