import { useParams } from "react-router-dom";
import { faClock, faBan, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faDotCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-responsive-modal";
import { useState } from "react";

export default function CorporateDocument({
  title,
  slug,
  state,
  isPdf,
  corporate_id,
  filename,
}) {
  const { app_id, session_id } = useParams();
  const [open, setOpen] = useState(false);

  function IdentityTitle({ state }) {
    return (
      <div>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-medium text-primary-400">
              {title ? title : "Proof of Residency"}
            </p>
          </div>
          <div>
            <div className="flex">
              {/* <p onClick={() => setOpen(true)} className="text-bluegray-500 hover:text-bluegray-700 ease-in duration-200 transition cursor-pointer text-sm bg-bluegray-100 rounded-md px-2 py-1 mr-3"><FontAwesomeIcon className="mr-0.5" icon={faHistory} /> History</p> */}
              {state === "PENDING" ? (
                <p className="text-orange-500 text-sm bg-orange-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faClock} /> Pending
                  verification
                </p>
              ) : null}
              {state === "NOT_STARTED" ? (
                <p className="text-gray-500 text-sm bg-gray-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faDotCircle} /> Not
                  started
                </p>
              ) : null}
              {state === "REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Rejected
                </p>
              ) : null}
              {state === "FINAL_REJECTED" ? (
                <p className="text-red-500 text-sm bg-red-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faBan} /> Final
                  Rejected
                </p>
              ) : null}
              {state === "VALIDATED" ? (
                <p className="text-green-500 text-sm bg-green-100 rounded-md px-2 py-1">
                  <FontAwesomeIcon className="mr-0.5" icon={faCheck} /> Verified
                </p>
              ) : null}
            </div>
          </div>
        </div>
        {state === "VALIDATED" ? (
          <hr className="my-4 border-bluegray-200" />
        ) : null}
      </div>
    );
  }

  function InfoItem(props) {
    return (
      <div className="mt-2">
        <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
          <div className="mr-3 mt-0.5 flex-shrink-0">
            <img className="h-9" src={`/img/icons/ui/${props.icon}.svg`}></img>
          </div>
          <div className="relative top-[2px]">
            <p className="text-bluegray-400 text-xs">{props.title}</p>
            <p
              className={`text-gray-600 text-sm ${props.capitalize ? "capitalize" : null
                }`}
            >
              {props.value}
            </p>
          </div>
        </div>
      </div>
    );
  }

  function CorporateDocDetails({ slug, isPdf, corporate_id, filename }) {
    return (
      <div>
        <div className="mt-3">
          <p className="mt-6 uppercase tracking-wide font-medium text-sm text-primary-400">
            Document Details
          </p>
          <div className="flex flex-wrap mt-3">
            <div className="w-4/6 pr-3">
              {isPdf ? (
                <iframe className="w-full h-screen" src={filename}></iframe>
              ) : (
                <img src={filename} />
              )}
            </div>
            <div className="w-2/6 pl-3 mt-4">
              <div>
                {slug === "CERTIFICATE_OF_INCORPORATION" ? (
                  <InfoItem
                    capitalize
                    icon={"icon-corporate-certificate"}
                    title="Document"
                    value={"Certificate of Incorporation"}
                  />
                ) : null}
                {slug === "MEMORANDUM_OF_ASSOCIATION" ? (
                  <InfoItem
                    capitalize
                    icon={"icon-corporate-association"}
                    title="Document"
                    value={"Memorandum of Association"}
                  />
                ) : null}
                {slug === "REGISTER_OF_DIRECTORS_SHAREHOLDERS" ? (
                  <InfoItem
                    capitalize
                    icon={"icon-corporate-register"}
                    title="Document"
                    value={"Register of UBOs"}
                  />
                ) : null}
                {slug === "COMPANY_BANK_STATEMENT" ? (
                  <InfoItem
                    capitalize
                    icon={"icon-corporate-bank-statement"}
                    title="Document"
                    value={"Corporate Bank Statement"}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <IdentityTitle title={title} state={state} />
      {state !== "VALIDATED" || state === "REJECTED" || state === "PENDING" ? (
        <CorporateDocDetails
          corporate_id={corporate_id}
          filename={filename}
          title={title}
          slug={slug}
          state={state}
          isPdf={isPdf}
        />
      ) : null}
      <Modal
        open={open}
        showCloseIcon={true}
        closeIcon={true}
        onClose={() => setOpen(false)}
        center
      >
        <p>History</p>
      </Modal>
    </div>
  );
}
