import { useReducer, createContext } from "react";

export const AuthContext = createContext();

const initialState = {
  user: {
    email: "",
    firstname: "",
    lastname: "",
    profile_pic: "",
  },
  iam: {
    admin: false,
    residency: false,
    identity: false,
    aml: false,
    corporate: false,
    facematch: false,
    duplicate: false,
  },
  auth: {
    access_token: "",
    refresh_token: "",
  },
  authenticated: false,
  online_socket: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return action.payload;
    default:
      return state;
  }
};

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};
