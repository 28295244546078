import { PanelAuth } from "@/services/axios";

export default {
  Search(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/support/search`,
      body
    );
  },
  UpdateValue(body) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/support/value`,
      body
    );
  },
  DuplicateSession(body, session, app) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/support/duplicate?session=${session}&app=${app}`,
      body
    );
  },
  BanUser(session) {
    return PanelAuth.put(
      `${process.env.REACT_APP_PANEL_HOST}/v3/support/ban?session=${session}`,
      null
    );
  },
};
