import HeaderButton from '@/components/ui/HeaderButton'
import Card from '@/components/ui/Card'
import {
	faAsterisk,
	faClock,
	faHistory,
	faStream,
	faUndo,
	faThumbtack,
	faWifi,
	faPlug,
	faTint,
	faPhone,
	faBurn,
	faUniversity,
	faCity,
	faGlobeEurope,
} from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import Tooltip from '@/components/ui/Tooltip'
import { useContext, useState } from 'react'
import ResidencyHeaderItem from '@/components/residency/ResidencyHeaderItem'
import PinResidencySidebar from '@/components/residency/PinResidencySidebar'
import AlertPin from '@/components/ui/AlertPin'
import ResidencyService from '@/services/residency'
import * as Notification from '@/components/ui/Notification'
import ResidencyHistory from '@/components/residency/ResidencyHistory'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import ActivityResidencySidebar from '@/components/residency/ActivityResidencySidebar'
import RejectResidencySidebar from '@/components/residency/RejectResidencySidebar'
import EditDocumentTypeSidebar from '@/components/residency/EditDocumentTypeSidebar'
import EditDocumentCountry from '@/components/residency/EditDocumentCountry'
import ResidencySteps from './ResidencySteps'

export default function ResidencyVerificationHeader() {
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)
	const [pinOpen, setPinOpen] = useState(false)
	const [historyOpen, setHistoryOpen] = useState(false)
	const [undoLoading, setUndoLoading] = useState(false)
	const [editDocumentOpen, setEditDocumentOpen] = useState(false)
	const [editCountryOpen, setEditCountryOpen] = useState(false)
	const [activityOpen, setActivityOpen] = useState(false)
	const [rejectOpen, setRejectOpen] = useState(false)

	async function UndoStep() {
		setUndoLoading(true)
		await ResidencyService.Undo(residencyVerification.task.session_id)
			.then(() => {
				setUndoLoading(false)
				window.location.reload()
			})
			.catch((err) => {
				setUndoLoading(false)
				Notification.error(err.message)
			})
		return () => {}
	}
	return (
		<>
			<div className="flex flex-wrap justify-between pb-3">
				<div className="flex">
					<div className="ml-3 sm:ml-0">
						<img
							className="h-8 rounded-lg"
							src={residencyVerification.app.logo_url}></img>
					</div>
					<div className="ml-2">
						<p className="text-white text-2xs uppercase font-bold">
							{residencyVerification.app.name}
						</p>
						<h1 className="text-2xs text-white">
							{residencyVerification.task.session_id}
						</h1>
					</div>
				</div>
				<div className="flex flex-wrap mt-4 sm:mt-0">
					<HeaderButton
						loading={undoLoading}
						onClick={() => UndoStep()}
						currentBg="primary-500"
						icon={faUndo}
						type="primary"
						label="Undo"></HeaderButton>
					<div className="ml-2">
						<HeaderButton
							onClick={() => setActivityOpen(true)}
							currentBg="primary-500"
							icon={faStream}
							badge={residencyVerification.task.activity.length}
							type="darkPrimary"
							label="Activity"></HeaderButton>
					</div>
					<div className="ml-3">
						<HeaderButton
							onClick={() => setHistoryOpen(true)}
							currentBg="primary-500"
							icon={faHistory}
							badge={
								residencyVerification.history
									? residencyVerification.history.length
									: 0
							}
							type="secondary"
							label={'History'}></HeaderButton>
					</div>
					<div className="ml-3">
						{residencyVerification.task.pin === false ? (
							<HeaderButton
								onClick={() => setPinOpen(true)}
								currentBg="primary-500"
								icon={faThumbtack}
								type="pin"
								label="Pin"></HeaderButton>
						) : (
							<HeaderButton
								currentBg="primary-500"
								disabled
								icon={faThumbtack}
								type="pin"
								label="Pinned"></HeaderButton>
						)}
					</div>
					<div className="ml-3">
						<HeaderButton
							currentBg="primary-500"
							icon={faTimes}
							type="error"
							onClick={() => setRejectOpen(true)}
							label="Reject"></HeaderButton>
					</div>
				</div>
			</div>
			<div className="flex flex-wrap">
				<Card className="w-full px-3 sm:px-0">
					<div className="flex p-4 justify-between">
						<div className="flex">
							<div className="p-3 ml-2">
								<h3 className="text-2xl">
									<Tooltip auto={true} content="Firstname">
										<span className="font-light">
											{residencyVerification.verification.details.firstname}{' '}
										</span>
									</Tooltip>
									<Tooltip auto={true} content="Lastname">
										<span className="font-bold">
											{residencyVerification.verification.details.lastname}
										</span>
									</Tooltip>
								</h3>
								<h3 className="text-xl mt-3">
									<Tooltip auto={true} content="Address">
										<span className="font-light">
											{residencyVerification.verification.details.address}{' '}
										</span>
									</Tooltip>
								</h3>
							</div>
						</div>
						<div className="rounded-xl h-full md:w-4/6 w-full">
							<div className="flex flex-wrap">
								<div className="w-full md:w-1/3">
									{residencyVerification.verification.details.document_type ===
									'ELECTRICITY_BILL' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Electricity Bill"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faPlug}></ResidencyHeaderItem>
										) : null}
									{residencyVerification.verification.details.document_type ===
									'INTERNET_BILL' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Internet Bill"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faWifi}></ResidencyHeaderItem>
										) : null}
									{residencyVerification.verification.details.document_type ===
									'LANDLINE_BILL' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Landline Bill"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faPhone}></ResidencyHeaderItem>
										) : null}
									{residencyVerification.verification.details.document_type ===
									'WATER_BILL' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Water Bill"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faTint}></ResidencyHeaderItem>
										) : null}
									{residencyVerification.verification.details.document_type ===
									'GAS_BILL' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Gas Bill"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faBurn}></ResidencyHeaderItem>
										) : null}
									{residencyVerification.verification.details.document_type ===
									'BANK_STATEMENT' ? (
											<ResidencyHeaderItem
												label="Document Type"
												value="Bank Statement"
												editable
												onEdit={() => setEditDocumentOpen(true)}
												icon={faUniversity}></ResidencyHeaderItem>
										) : null}
								</div>
								<div className="w-full md:w-1/3">
									<ResidencyHeaderItem
										label="Document Country"
										editable
										value={residencyVerification.verification.details.country}
										onEdit={() => setEditCountryOpen(true)}
										icon={faGlobeEurope}></ResidencyHeaderItem>
								</div>
								<div className="w-full md:w-1/3">
									<ResidencyHeaderItem
										label="Issuing Date"
										value={
											residencyVerification.verification.details.issuing_date
										}
										icon={faClock}></ResidencyHeaderItem>
								</div>
								<div className="w-full md:w-1/3">
									<ResidencyHeaderItem
										label="City"
										value={residencyVerification.verification.details.city}
										icon={faCity}></ResidencyHeaderItem>
								</div>
								<div className="w-full md:w-1/3">
									<ResidencyHeaderItem
										label="Zip Code"
										value={residencyVerification.verification.details.zipcode}
										icon={faAsterisk}></ResidencyHeaderItem>
								</div>
							</div>
						</div>
					</div>
					<div>
						<ResidencySteps></ResidencySteps>
					</div>
				</Card>
				<div className="w-full">
					{residencyVerification.task.pin ? (
						<div className="mt-8">
							<AlertPin
								type="pin"
								message={residencyVerification.task.pin_details.action}
								details={`${residencyVerification.task.pin_details.note}`}></AlertPin>
						</div>
					) : null}
				</div>
			</div>
			<PinResidencySidebar
				open={pinOpen}
				setOpen={setPinOpen}></PinResidencySidebar>
			<ActivityResidencySidebar
				open={activityOpen}
				setOpen={setActivityOpen}></ActivityResidencySidebar>
			<RejectResidencySidebar
				open={rejectOpen}
				setOpen={setRejectOpen}></RejectResidencySidebar>
			<EditDocumentTypeSidebar
				open={editDocumentOpen}
				setOpen={setEditDocumentOpen}></EditDocumentTypeSidebar>
			<EditDocumentCountry
				open={editCountryOpen}
				setOpen={setEditCountryOpen}></EditDocumentCountry>
			<ResidencyHistory
				open={historyOpen}
				setOpen={setHistoryOpen}></ResidencyHistory>
		</>
	)
}
