import Card from '../ui/Card'

export default function ContentPlaceholder(props) {
	return (
		<>
			{props.ready ? (
				props.children
			) : (
				<>
					<div className="flex flex-wrap justify-between pb-3">
						<div className="flex flex-wrap min-w-full">
							<Card className="w-full px-3 sm:px-0">
								<div className="animate-pulse">
									<div className="p-6">
										<div className="flex">
											<div className="w-1/2">
												<div className="ml-3 w-12 sm:w-48 mt-2 h-4 bg-bluegray-300 rounded-sm"></div>
												<div className="ml-3 w-24 sm:w-64 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
												<div className="ml-3 w-48 sm:w-96 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
											</div>
											<div className="w-1/2">
												<div className="ml-3 w-12 sm:w-48 mt-2 h-4 bg-bluegray-300 rounded-sm"></div>
												<div className="ml-3 w-24 sm:w-64 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
												<div className="ml-3 w-48 sm:w-96 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
											</div>
										</div>
										<div className="flex flex-wrap mt-6">
											<div className="w-1/3 p-2">
												<div className="w-full h-16 bg-bluegray-300 rounded-md"></div>
											</div>
											<div className="w-1/3 p-2">
												<div className="w-full h-16 bg-bluegray-300 rounded-md"></div>
											</div>
											<div className="w-1/3 p-2">
												<div className="w-full h-16 bg-bluegray-300 rounded-md"></div>
											</div>
										</div>
									</div>
								</div>
							</Card>
						</div>
					</div>
				</>
			)}
		</>
	)
}
