import {
	faTimes,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import Button from '@/components/ui/Button'
import Input from '@/components/ui/Input'
import Alert from '@/components/ui/Alert'
import UploadLogo from '@/components/ui/UploadLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import NetworkService from '@/services/network'

export default function FinalReviewConfirm(props) {
	const [error, setError] = useState(null)
	const [valid, setValid] = useState(false)
	const [forged, setForged] = useState(false)
	const [segmented, setSegmented] = useState(false)
	const [expired, setExpired] = useState(false)
	const [information, setInformation] = useState(false)

	useEffect(() => {
		setValid(false)
		setForged(false)
		setSegmented(false)
		setExpired(false)
		setInformation(false)
		setError(null)
	}, [])

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">

						<div className='p-4 createapp-view'>
							<div className="flex justify-between">
								<div>
									<h2 className="text-xl">Confirm Verification Decision</h2>
								</div>
								<div>
									<h2 className="text-xl"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
								</div>
							</div>
							<hr className="my-3" />
							<div>
								{error ? (
									<Alert type="error" title={error}></Alert>
								) : null}
							</div>
							<div className="mt-4">
								<div className="mt-3">
									<p className='text-red-500 mb-2 text-center text-3xl'>Warning</p>
									<label
										className="block text-sm mb-3 font-medium text-primary-700">
										Anima verification
									</label>
									<p className='text-grey-500'>This verification is linked to the Anima project, meaning that the decision is final and will not be modified after you click on this button, are you sure to validate the verification ?</p>

									<p className='text-red-500 mb-2 mt-2'><input onChange={() => setValid(!valid)} type="checkbox" id="scales" name="scales"
										value={valid} className="mr-1"></input> The document must be Valid</p>
									<p className='text-red-500 mb-2'><input onChange={() => setForged(!forged)} type="checkbox" id="scales" name="scales"
										value={forged} className="mr-1"></input> The document must not be Forged</p>
									<p className='text-red-500 mb-2'><input onChange={() => setExpired(!expired)} type="checkbox" id="scales" name="scales"
										value={expired} className="mr-1"></input> The document must not be Expired</p>
									<p className='text-red-500 mb-2'><input onChange={() => setSegmented(!segmented)} type="checkbox" id="scales" name="scales"
										value={segmented} className="mr-1"></input> The document must be well Segmented</p>
									<p className='text-red-500 mb-2'><input onChange={() => setInformation(!information)} type="checkbox" id="scales" name="scales"
										value={information} className="mr-1"></input> All the information must be Valid</p>
								</div>
								<div className="mt-5">
									<Button full disabled={!valid || !forged || !segmented || !information || !expired} theme="success" label="Confirm" onClick={() => props.onValidate()}></Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
