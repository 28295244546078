import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function StepTitle(props) {
	return (
		<h1 className="font-bold px-3 pt-2 pb-5">
			<span className="mr-2.5 text-bluegray-500">
				<FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
			</span>
			<span className="text-primary-500">{props.value}</span>
		</h1>
	)
}
