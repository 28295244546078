import Tooltip from '@/components/ui/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function VisualCheck(props) {
	return (
		<Tooltip auto={true} content={props.title}>
			<span className="inline-block relative mt-3 ml-4">
				<FontAwesomeIcon
					className={`${
						props.reviewed == false ? 'text-gray-400' : null
					} text-xl ${
						props.reviewed && props.valid == true ? 'text-green-500' : null
					} ${
						props.reviewed && props.valid == false ? 'text-red-500' : null
					} mr-4`}
					icon={props.icon}></FontAwesomeIcon>
			</span>
		</Tooltip>
	)
}
