import { useContext, useEffect, useState } from "react";
import Empty from "../misc/Empty";
import TasksPlaceholder from "../placeholder/TasksPlaceholder";
import Sleep from "@/lib/sleep";
import * as Notification from "@/components/ui/Notification";
import CardTitle from "../ui/CardTitle";
import { CorporateContext } from "@/context/corporate";
import CorporateService from "@/services/corporate";
import CorporateTaskItem from "./CorporateTaskItem";

export default function CorporateTasks() {
  const [corporate, corporateDispatch] = useContext(CorporateContext);
  const [ready, setReady] = useState(false);
  const [pinType, setPinType] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  function updatePin(newPin) {
    if (pinType === newPin) {
      setPinType("");
    } else {
      setPinType(newPin);
    }
  }

  useEffect(async () => {
    setReady(false);
    await CorporateService.GetTasks(corporate.selectedTab.key, pinType)
      .then(async (data) => {
        corporateDispatch({
          type: "SET_TASKS",
          payload: {
            tasks: data,
          },
        });
        await Sleep(500);
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }, [corporate.selectedTab, pinType]);

  return (
    <>
      <div className="flex justify-between">
        <div>
          <CardTitle
            value={corporate.selectedTab.title}
            icon={corporate.selectedTab.icon}
          ></CardTitle>
        </div>
      </div>
      {ready ? (
        <div className="flex flex-wrap p-6 pt-3">
          {corporate.tasks.map((task) => {
            return (
              <CorporateTaskItem
                update={(slug) => updatePin(slug)}
                key={task.task.session_id}
                item={task}
              ></CorporateTaskItem>
            );
          })}
          {corporate.tasks.length === 0 ? (
            <Empty value="No tasks"></Empty>
          ) : null}
        </div>
      ) : (
        <div className="p-4">
          <TasksPlaceholder></TasksPlaceholder>
        </div>
      )}
    </>
  );
}
