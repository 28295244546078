export default function RegistryListItem(props) {
	return (
		<div className="p-6">
			<div className="text-center">
				<p className="text-regular font-semibold">{props.manager.firstname} {props.manager.lastname}</p>
				<p className="text-sm">{props.manager.email}</p>
				<ul className="flex text-center">
					{props.manager.apps.map((app, index) => (
						<li className="p-1"  key={index}>
							<img
							className="h-10 rounded-full mx-auto text-center"
							src={app.logo_url}
							alt=""
						/>	
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
