import {
	faBarcodeScan,
	faQrcode,
	faTrash,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'
import RegistryService from '@/services/registry'
import * as Notification from '@/components/ui/Notification'

export default function RegistryFeatureItemMRZ(props) {
	async function deleteFeature() {
		const body = {
			slug: props.data.slug,
			document_slug: props.document,
		}

		await RegistryService.DeleteMrz(body)
			.then(async () => {
				Notification.success(`${props.name} successfully deleted`)
				props.onDelete()
			})
			.catch((err) => {
				Notification.error(err.message)
			})
		return () => {}
	}

	return (
		<div className="p-3 border border-gray-200 shadow-sm items-center rounded-xl transition-25 transition duration-200 ease-in">
			<div className="flex justify-between">
				<div>
					<div
						className="h-2 mt-1 rounded-xl w-8"
						style={{
							backgroundColor: `#${props.data.color}`,
						}}></div>
				</div>
				<div className="relative top--4px">
					<Tooltip auto={true} content="Delete">
						<div onClick={() => deleteFeature()}>
							<FontAwesomeIcon
								className="text-xs cursor-pointer hover:text-red-400 text-gray-500 transition duration-100 ease-in"
								icon={faTrash}></FontAwesomeIcon>
						</div>
					</Tooltip>
				</div>
			</div>
			<div className="flex justify-between">
				<div>
					<p className="capitalize">{props.name} </p>
				</div>
				{props.data.active ? (
					<div>
						<Tooltip auto={true} content={`Format ${props.data.format}`}>
							<span>
								<FontAwesomeIcon
									className="text-sm text-green-600"
									icon={
										props.data.slug === 'mrz_lines' ? faBarcodeScan : faQrcode
									}></FontAwesomeIcon>
							</span>
						</Tooltip>
					</div>
				) : null}
			</div>
		</div>
	)
}
