import { useContext, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import {
  faEye,
  faIdCard,
  faExclamationCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import Alert from "@/components/ui/Alert";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import AnnotedImage from "@/components/ui/AnnotedImage";
import StepTitle from "@/components/ui/StepTitle";

export default function IdentityInfoCrosscheck(props) {
  const [invalidloading, setInvalidLoading] = useState(false);
  const [validloading, setValidLoading] = useState(false);
  const [error] = useState("");
  const [identityVerification] = useContext(IdentityVerificationContext);
  const router = useHistory();

  const [currentIndex, setCurrentIndex] = useState(0);

  const keyMap = {};

  const handlers = {};

  function setNext() {
    if (currentIndex + 1 < identityVerification.cross_checks.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      props.onUpdate();
    }
  }

  async function setCrosscheckInfo(valid) {
    if (valid === true) {
      setValidLoading(true);
    } else {
      setInvalidLoading(true);
    }
    await IdentityService.SetInfoCrosscheck(
      identityVerification.task.session_id,
      identityVerification.cross_checks[currentIndex].slug,
      valid
    )
      .then((data) => {
        setValidLoading(false);
        setInvalidLoading(false);
        if (data.rejected === true) {
          router.push("/verifications/identity");
          return;
        }
        setNext();
      })
      .catch((err) => {
        setValidLoading(false);
        setInvalidLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      {identityVerification.cross_checks.length > 0 ? (
        <div id="template-selection" className="flex flex-wrap">
          <div className={"w-full mb-3 sm:w-1/2 pr-0 sm:pr-2"}>
            <div className="flex justify-between">
              <div>
                <StepTitle value="Document" icon={faIdCard}></StepTitle>
              </div>
            </div>
            <div className="rounded-xl flex bg-bluegray-50 h-full items-center">
              <div className="w-full">
                <AnnotedImage
                  src={
                    identityVerification.cross_checks[currentIndex].side ===
                    "FRONT"
                      ? identityVerification.media.segmented.front
                      : identityVerification.media.segmented.back
                  }
                  single
                  id="user-document"
                  sameColor
                  noStyle
                  margin={20}
                  className="object-contain h-80 w-full"
                  annotations={
                    identityVerification.cross_checks[currentIndex].annotation
                  }
                />
              </div>
            </div>
          </div>
          <div className={"w-full mb-3 sm:w-1/2 pr-0 mt-16 sm:mt-0"}>
            <div className="flex justify-between">
              <div>
                <StepTitle value="Review" icon={faEye}></StepTitle>
              </div>
            </div>
            <div className={"bg-bluegray-50 rounded-xl flex h-full"}>
              <div className="w-full">
                <div className="flex flex-col h-full">
                  <header className="py-5 bg-bluegray-200 text-primary-500 font-regular rounded-t-xl">
                    <div className="flex justify-between">
                      <div>
                        <span className="inline-flex items-center border border-gray-300 px-2.5 py-0.5 rounded-md ml-5 text-sm font-medium bg-gray-100 text-gray-800">
                          {identityVerification.cross_checks[currentIndex].slug}
                        </span>
                      </div>
                      <div>
                        <p className="text-sm relative top-1 right-5">
                          Similarity:{" "}
                          <span
                            className={`font-bold ${
                              identityVerification.cross_checks[currentIndex]
                                .crosscheck_score > 0.5
                                ? "text-orange-500"
                                : "text-red-500"
                            }`}
                          >
                            {identityVerification.cross_checks[currentIndex]
                              .crosscheck_score * 100}
                            %
                          </span>
                          {identityVerification.cross_checks[currentIndex]
                            .crosscheck_score > 0.5 ? (
                            <FontAwesomeIcon
                              className="text-orange-500 ml-1"
                              icon={faExclamationCircle}
                            ></FontAwesomeIcon>
                          ) : (
                            <FontAwesomeIcon
                              className="text-red-500 ml-1"
                              icon={faExclamationCircle}
                            ></FontAwesomeIcon>
                          )}
                        </p>
                      </div>
                    </div>
                  </header>
                  <main className="flex-1 overflow-y-auto">
                    <div className="m-6">
                      <p className="text-xs uppercase text-gray-500 font-bold">
                        OCR Value
                      </p>
                      <p className="mt-1">
                        {
                          identityVerification.cross_checks[currentIndex]
                            .ocr_value
                        }
                      </p>
                    </div>
                    {identityVerification.cross_checks[currentIndex]
                      .mrz_line_crosscheckable === true ? (
                      <div className="m-6 mt-1">
                        <p className="text-xs uppercase text-gray-500 font-bold">
                          MRZ Lines Value
                        </p>
                        <p className="mt-1">
                          {
                            identityVerification.cross_checks[currentIndex]
                              .mrz_line_value
                          }
                        </p>
                      </div>
                    ) : null}
                    {identityVerification.cross_checks[currentIndex]
                      .mrz_barcode_crosscheckable === true ? (
                      <div className="m-6 mt-1">
                        <p className="text-xs uppercase text-gray-500 font-bold">
                          MRZ Barcode Value
                        </p>
                        <p className="mt-1">
                          {
                            identityVerification.cross_checks[currentIndex]
                              .mrz_barcode_value
                          }
                        </p>
                      </div>
                    ) : null}
                  </main>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <div className="flex justify-between bg-bluegray-50 mt-16 p-3 rounded-xl">
              <div></div>
              <div className="flex">
                <Button
                  className="mr-1.5"
                  onClick={() => setCrosscheckInfo(false)}
                  icon={faTimes}
                  theme="error"
                  loading={invalidloading}
                  label="Mismatch"
                />
                <Button
                  onClick={() => setCrosscheckInfo(true)}
                  icon={faCheck}
                  theme="success"
                  loading={validloading}
                  label="Match"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </GlobalHotKeys>
  );
}
