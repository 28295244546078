import Sleep from '@/lib/sleep'
import {
	faStamp,
	faAt,
	faUserTie,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Alert from '../ui/Alert'
import CardTitle from '../ui/CardTitle'

import ManagersService from '@/services/managers'

export default function RegistryNewDocument(props) {
	const [error, setError] = useState(null)
	const { register, handleSubmit, errors } = useForm()
	const [loading, setLoading] = useState(false)

	const formSchema = {
		email: {
			required: 'Email required',
		},
		firstname: {
			required: 'Firstname required',
		},
		lastname: {
			required: 'Lastname required',
		},
	}

	const onSubmit = async (data) => {
		setError(null)

		setLoading(true)

		await Sleep(2000)

		let bodyFormData = new FormData()
		bodyFormData.append('email', data.email)
		bodyFormData.append('firstname', data.firstname)
		bodyFormData.append('lastname', data.lastname)

		await ManagersService.AddManager(bodyFormData)
			.then(() => {
				props.onClose()
			})
			.catch((err) => {
				console.log('err')
				console.log(err)
				setLoading(false)
				setError(err.message)
			})
		return () => {}
	}

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									<div>
										<CardTitle
											value="New Manager"
											icon={faUserTie}></CardTitle>
									</div>
								</div>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div>
										<div className="py-2 px-3">
											{error ? (
												<Alert type="error" title={error}></Alert>
											) : null}
										</div>
										<div className="flex flex-wrap">
											<div className="p-3 w-full">
												<Input
													type="text"
													icon={faAt}
													referrer={register(formSchema.email)}
													label="Email"
													placeholder="john.doe@synaps.io"
													name="email"
													error={errors.email}
													message={errors.email?.message}
												/>
											</div>
											<div className="p-3 w-full sm:w-1/2">
												<Input
													icon={faStamp}
													referrer={register(formSchema.firstname)}
													label="Firstname"
													placeholder="John"
													name="firstname"
													error={errors.firstname}
													message={errors.firstname?.message}
												/>
											</div>
											<div className="p-3 w-full sm:w-1/2">
												<Input
													icon={faStamp}
													referrer={register(formSchema.lastname)}
													label="Lastname"
													placeholder="doe"
													name="lastname"
													error={errors.lastname}
													message={errors.lastname?.message}
												/>
											</div>
										</div>
									</div>

									<div>
										<div className="flex justify-between">
											<div className="p-3"></div>
											<div className="p-3">
												<div className="flex">
													<div className="mr-3">
														<Button
															theme="secondary"
															onClick={() => props.onClose()}
															label="Cancel"
														/>
													</div>
													<div>
														<Button
															type="submit"
															theme="success"
															loading={loading}
															onClick={() => handleSubmit(onSubmit)}
															label="Add Manager"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
