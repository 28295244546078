import { faLayerPlus } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function RegistryFeatureItemNew(props) {
	return (
		<div
			onClick={() => props.onClick()}
			className="p-3 mb-4 border  border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
			<div className="flex justify-between">
				<div>
					<div className="h-2 mt-1 rounded-xl w-8 bg-gray-300"></div>
				</div>
				<div className="relative top--4px">
					<div>
						<FontAwesomeIcon
							className="text-xs cursor-pointer text-gray-500 transition duration-100 ease-in"
							icon={faLayerPlus}></FontAwesomeIcon>
					</div>
				</div>
			</div>
			<div className="flex justify-between">
				<div>
					<p className="capitalize">New</p>
				</div>
			</div>
		</div>
	)
}
