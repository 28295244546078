import { useState } from 'react'
import SubNav from '../menu/SubNav'
import Card from '../ui/Card'
import { faHeadset, faClone, faFileExport } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import Footer from '../menu/Footer'
import Alert from '@/components/ui/Alert'
import Search from '@/components/ui/Search'
import Button from '@/components/ui/Button'
import Duplicate from '@/components/sessions/Duplicate'
import ExportSession from '@/components/sessions/ExportSession'


export default function RegistryListPage() {
	const [error, setError] = useState(null)
	const [duplicate, setDuplicate] = useState(false)
	const [exportSession, setExportSession] = useState(false)

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mt-8 mx-auto relative">
					<div className="verification-container">
						<Card className="w-full px-3 sm:px-0">
							<div>
								<div className="p-3">
									<div className="flex justify-between">
										<div>
											<CardTitle
												value="Find verification"
												icon={faHeadset}></CardTitle>
										</div>
									</div>
									{error ? (
										<div className="p-3">
											<Alert type="error" title={error}></Alert>
										</div>
									) : null}
									<Search></Search>
								</div>
							</div>
						</Card>
						<Card className="w-full px-3 mt-5 sm:px-0">
							<div>
								<div className="p-3">
									<div className="flex justify-between">
										<div>
											<CardTitle
												value="Admin actions"
												icon={faHeadset}></CardTitle>
										</div>
									</div>
									{error ? (
										<div className="p-3">
											<Alert type="error" title={error}></Alert>
										</div>
									) : null}
									<div className='flex flex-wrap px-3 pb-3 mt-5'>
										<div>
											<Button
												className='ml-2'
												onClick={() => setDuplicate(true)}
												icon={faClone}
												theme='primary'
												label='Duplicate session'></Button>
										</div>
										<div>
											<Button
												className='ml-2'
												onClick={() => setExportSession(true)}
												icon={faFileExport}
												theme='primary'
												label='Export app'></Button>
										</div>

									</div>
								</div>
							</div>
						</Card>
					</div>
					<Duplicate open={duplicate}
						onClose={() => setDuplicate(false)}>
					</Duplicate>
					<ExportSession open={exportSession}
						onClose={() => setExportSession(false)}>
					</ExportSession>
					<Footer></Footer>
				</div>
			</div>
		</div>
	)
}
