import { useContext, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import Alert from "@/components/ui/Alert";
import { ResidencyVerificationContext } from "@/context/residency_verification";
import StepTitle from "@/components/ui/StepTitle";
import {
  faCheckCircle,
  faFileInvoice,
  faHouse,
} from "@fortawesome/pro-duotone-svg-icons";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResidencyService from "@/services/residency";
import * as Notification from "@/components/ui/Notification";
import Tooltip from "@/components/ui/Tooltip";

export default function ResidencyAccomodationProof(props) {
  const [successLoading, setSuccessLoading] = useState(false);
  const [error] = useState("");
  const [residencyVerification] = useContext(ResidencyVerificationContext);

  const keyMap = {
    SELECT: ["enter"],
  };

  const handlers = {
    SELECT: () => validateStep("accomodation_proof_verification"),
  };

  async function validateStep(step) {
    setSuccessLoading(true);
    await ResidencyService.ValidateStep(
      residencyVerification.task.session_id,
      step
    )
      .then(() => {
        setSuccessLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        Notification.error(err.message);
        setSuccessLoading(false);
      });
    return () => {};
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div className="flex flex-wrap">
        <div className="w-3/5 h-screen">
          <StepTitle
            value="Accomodation Proof"
            icon={faFileInvoice}
          ></StepTitle>
          <iframe
            className="w-full h-full max-h-screen"
            src={residencyVerification.verification.accomodation.proof.original}
          ></iframe>
        </div>
        <div className="w-2/5">
          <div>
            <StepTitle value="Accomodation Proof" icon={faHouse}></StepTitle>
          </div>
          <div>
            <div>
              <div className="mb-6 px-4">
                <p className="uppercase text-sm font-bold text-gray-500 mt-4">
                  Host
                </p>
                <h3 className="text-2xl">
                  <Tooltip auto={true} content="Firstname">
                    <span className="font-light">
                      {
                        residencyVerification.verification.accomodation
                          .firstname
                      }{" "}
                    </span>
                  </Tooltip>
                  <Tooltip auto={true} content="Lastname">
                    <span className="font-bold">
                      {residencyVerification.verification.accomodation.lastname}
                    </span>
                  </Tooltip>
                </h3>
                <p className="uppercase text-sm font-bold text-gray-500 mt-4">
                  User
                </p>
                <h3 className="text-2xl">
                  <Tooltip auto={true} content="Firstname">
                    <span className="font-light">
                      {residencyVerification.verification.details.firstname}{" "}
                    </span>
                  </Tooltip>
                  <Tooltip auto={true} content="Lastname">
                    <span className="font-bold">
                      {residencyVerification.verification.details.lastname}
                    </span>
                  </Tooltip>
                </h3>
              </div>
            </div>
            <hr className="py-2" />
            <div className="p-4 pt-0 pb-0">
              <p className="uppercase text-sm font-bold text-gray-500 mt-4">
                Instructions
              </p>
              <div>
                <p className="mt-2">
                  <FontAwesomeIcon
                    className="text-green-600 mr-2"
                    icon={faCheckCircle}
                  ></FontAwesomeIcon>
                  Firstname and lastname of the host match the document
                </p>
                <p className="mt-2">
                  <FontAwesomeIcon
                    className="text-green-600 mr-2"
                    icon={faCheckCircle}
                  ></FontAwesomeIcon>
                  Firstname and lastname of the user match the document
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between bg-bluegray-50 mt-24 p-3 rounded-xl">
        <div></div>
        <div className="flex">
          <Button
            loading={successLoading}
            onClick={() => validateStep("accomodation_proof_verification")}
            icon={faCheck}
            theme="success"
            full
            label="Validate"
          ></Button>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
