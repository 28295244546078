import { LoginContext } from "@/context/login";
import { useContext, useState } from "react";
import Alert from "../ui/Alert";
import Label from "../ui/Label";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import AuthService from "@/services/auth";
import Input2FA from "../ui/Input2FA";
import Button from "../ui/Button";
import Sleep from "@/lib/sleep";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import AuthProcess from "@/components/login/AuthProcess";

export default function TwoFactorCode() {
  const [error, setError] = useState(null);
  const [loginState, loginDispatch] = useContext(LoginContext);
  const [loading, setLoading] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  async function Login() {
    const recaptchaToken = await executeRecaptcha("login_page");

    const body = {
      email: loginState.auth.email,
      code: loginState.auth.code,
    };

    const config = {
      headers: {
        "Two-Factor-Code": twoFactorCode,
        Recaptcha: recaptchaToken,
      },
    };

    setLoading(true);

    await Sleep(500);
    await AuthService.Login(body, config)
      .then((data) => {
        setLoading(false);
        loginDispatch({
          type: "SET_LOGIN",
          payload: {
            auth: {
              email: data.email,
              firstname: data.firstname,
              lastname: data.lastname,
              profile_pic: data.profile_pic,
              iam: data.iam,
            },
            currentComponent: <AuthProcess></AuthProcess>,
          },
        });
      })
      .catch((err) => {
        setTwoFactorCode("");
        setLoading(false);
        setError(err.message);
      });
    return () => { };
  }

  return (
    <>
      <div>
        {error ? (
          <Alert type="error" title={error}></Alert>
        ) : (
          <Alert
            type="2FA"
            title="2FA Authentication"
            description={"Enter your authentication code"}
          ></Alert>
        )}
        <div className="flex justify-between">
          <div>
            <Label value="2FA Code"></Label>
          </div>
          <div>
            <Label
              font="light"
              value={
                loginState.auth?.email?.length > 24
                  ? loginState.auth?.email?.slice(0, 24) + "..."
                  : loginState.auth?.email
              }
            ></Label>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Input2FA value={[twoFactorCode, setTwoFactorCode]} />
      </div>
      <div className="mt-4">
        <Button
          full
          onClick={Login}
          icon={faCheck}
          disabled={twoFactorCode.trim().length != 6}
          loading={loading}
          label="Validate"
        />
      </div>
    </>
  );
}
