import {
    faPlus,
    faTimes,
} from '@fortawesome/pro-solid-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Alert from '../ui/Alert'
import UploadLogo from '@/components/ui/UploadLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import NetworkService from '@/services/network'
import IconListSelector from "../ui/IconListSelector";
import {
    faDiscord,
    faTelegram,
    faTwitter,
    faSlack,
} from '@fortawesome/free-brands-svg-icons'

export default function NewProject(props) {
    let supports = [
        {
            label: "Discord",
            icon: faDiscord,
            value: "discord"
        },
        {
            label: "Telegram",
            icon: faTelegram,
            value: "telegram"
        },
        {
            label: "Twitter",
            icon: faTwitter,
            value: "twitter"
        },
        {
            label: "Slack",
            icon: faSlack,
            value: "slack"
        },
        {
            label: "Other",
            icon: faPlus,
            value: "other"
        },
    ]

    const [error, setError] = useState(null)
    const [name, setName] = useState(null)
    const [mail, setMail] = useState(null)
    const [network, setNetwork] = useState(null)
    const [logo, setLogo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [support, setSupport] = useState([])
    const [addFields, setAddedFields] = useState([])

    useEffect(() => {
        setError(null)
        setName(null)
        setNetwork(null)
        setLogo(null)
        setName(props.name)
    }, [])

    function Submit() {
        setLoading(true)

        let bodyFormData = new FormData()
        bodyFormData.append('project_logo', logo)

        NetworkService.UploadProjectNetworkLogo(bodyFormData).then((data) => {
            let body = {
                project_name: name,
                project_network: network,
                logo: data.logo,
                project_mail: mail,
                project_support: addFields
            }

            NetworkService.CreateProjectNetwork(body).then((data) => {
                props.onUpdate()
                setLoading(false)
            }).catch((err) => {
                setError(err.message)
                setLoading(false)
            })
        }).catch((err) => {
            setError(err.message)
            setLoading(false)
        })
    }

    function updateField(value, index) {
        let newFields = addFields

        if (newFields[index] === undefined) {
            newFields.push({
                value: value,
                type: support[index].value
            })
        } else {
            newFields[index].value = value
        }


        setAddedFields(newFields)
    }

    function removeField(index) {
        setSupport(support.splice(index))
    }

    return (
        <Transition
            show={props.open}
            enter="ease-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
                    <div
                        className="fixed inset-0 transition-opacity"
                        aria-hidden="true"
                        onClick={() => props.onClose()}>
                        <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
                    </div>

                    <span
                        className="hidden sm:inline-block sm:align-middle sm:h-screen"
                        aria-hidden="true">
                        &#8203;
                    </span>

                    <div
                        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline">

                        <div className='p-8 createapp-view'>
                            <div className="flex justify-between">
                                <div>
                                    <h2 className="text-xl">Create a new project</h2>
                                </div>
                                <div>
                                    <h2 className="text-xl"><span><FontAwesomeIcon onClick={() => props.onClose()} className="cursor-pointer text-gray-400 hover:text-gray-700 transition duration-100 ease-in" icon={faTimes}></FontAwesomeIcon></span></h2>
                                </div>
                            </div>
                            <div>
                                {error ? (
                                    <Alert type="error" title={error}></Alert>
                                ) : null}
                            </div>
                            <div className="mt-4">
                                <div className="mt-4">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        NAME
                                    </label>
                                    <Input setValue={(value) => setName(value)} value={name} placeholder="TrustExchange" />
                                </div>
                                <div className="mt-4">
                                    <label
                                        className="flex block text-sm mb-3 font-medium text-primary-700">
                                        NETWORK (OPTIONNAL)
                                    </label>
                                    <Input setValue={(value) => setNetwork(value)} value={network} placeholder="synaps" />
                                </div>
                                <div className="mt-4">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        SUPPORT CONTACT MAIL
                                    </label>
                                    <Input setValue={(value) => setMail(value)} value={mail} placeholder="support@trustexchange.com" />
                                </div>

                                <div className="mt-4">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        SUPPORT CONTACT LINK (OPTIONNAL)
                                    </label>
                                    <div className='w-full'>
                                        {support.length > 0 ?
                                            <>
                                                {support.map((field, i) => {
                                                    return (
                                                        <div key={i} className="flex justify-between w-full mb-3" >
                                                            <div className='w-full'>
                                                                <Input icon={field.icon} setValue={(value) => updateField(value, i)} placeholder={field.label} />
                                                            </div>
                                                            <div>
                                                                <FontAwesomeIcon onClick={() => removeField(i)} className="cursor-pointer ml-2 text-gray-400 hover:text-red-700 transition mt-3 duration-100 ease-in" icon={faTimes}></FontAwesomeIcon>
                                                            </div>
                                                        </div>
                                                    )
                                                })}</> : null}
                                    </div>
                                    <IconListSelector
                                        onSelect={(selection) => setSupport(support.concat(selection))}
                                        choices={supports}
                                        action={'Add a new link'}
                                    ></IconListSelector>
                                </div>
                                <div className="mt-4">
                                    <label
                                        className="block text-sm mb-3 font-medium text-primary-700">
                                        LOGO
                                    </label>
                                    <UploadLogo setLogo={(blob) => setLogo(blob)} />
                                </div>
                                <div>
                                    <Button loading={loading} full disabled={!name || !logo} theme="primary" label="Create" onClick={() => Submit()}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition >
    )
}
