import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Tab(props) {
	function setTab(item) {
		if (item.key !== props.selectedKey) {
			props.onClick(item)
		}
	}

	return (
		<div
			onClick={() => setTab(props.item)}
			className={`mr-1 group py-1 px-2.5 pr-1.5 rounded-lg ${
				props.item.group
			} ${
				props.item.key === props.selectedKey
					? `${props.item.textActive}`
					: 'text-gray-500  hover:text-gray-800'
			} ${
				props.item.key === props.selectedKey
					? ''
					: `${props.item.textHover} cursor-pointer`
			} transition duration-100 ease-in`}>
			<FontAwesomeIcon
				className={'mr-2'}
				icon={props.item.icon}></FontAwesomeIcon>
			{props.item.label}
			{props.badge || props.badge === 0 ? (
				<span
					className={`inline-flex items-center relative top--1px px-2 py-0.5 ml-2 rounded-md text-xs font-medium transition duration-100 ease-in ${
						props.item.key === props.selectedKey
							? `${props.item.bgBadge} ${props.item.textBadge}`
							: 'bg-gray-200 text-gray-500'
					}`}>
					{props.badge}
				</span>
			) : null}
		</div>
	)
}
