import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalHotKeys } from "react-hotkeys";
import { faFileInvoice } from "@fortawesome/pro-duotone-svg-icons";
import {
  faChevronUp,
  faChevronDown,
  faMinus,
} from "@fortawesome/pro-solid-svg-icons";
import Alert from "@/components/ui/Alert";
import StepTitle from "@/components/ui/StepTitle";
import { ResidencyVerificationContext } from "@/context/residency_verification";
import ResidencyDocumentPageView from "./ResidencyDocumentPageView";

export default function ResidencyDocumentViewer() {
  const [loading] = useState(false);
  const [error] = useState("");
  const [residencyVerification] = useContext(ResidencyVerificationContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [templateList, setTemplateList] = useState([]);

  const keyMap = {
    GO_NEXT: ["right", "down"],
    GO_BACK: ["left", "up"],
    SELECT: ["enter"],
  };

  function next(event) {
    event.preventDefault();
    setNext();
  }

  function back(event) {
    event.preventDefault();
    setPrev();
  }

  const handlers = {
    GO_NEXT: next,
    GO_BACK: back,
  };

  useEffect(() => {
    let pageList = [];
    residencyVerification.verification.document.pages.map((page) => {
      pageList.push(page);
    });
    setTemplateList(pageList);
    if (templateList.length > 0) {
      setCurrentIndex(0);
    }
    return () => {};
  }, []);

  function setNext() {
    if (!loading) {
      if (currentIndex + 1 < templateList.length) {
        setCurrentIndex(currentIndex + 1);
      }
    }
  }

  function setPrev() {
    if (!loading) {
      if (currentIndex - 1 >= 0) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
      handlers={handlers}
    >
      {error ? (
        <div>
          <Alert type="error" title={error}></Alert>
        </div>
      ) : null}
      <div id="template-selection" className="flex flex-wrap">
        <div className={"w-full mb-3 pr-0 sm:pr-2"}>
          <div className="flex justify-between">
            <div>
              <StepTitle
                value="Proof of residency"
                icon={faFileInvoice}
              ></StepTitle>
            </div>
            <div>
              <span className="inline-flex items-center text-sm px-2 mt-1.5 py-0.5 rounded font-medium bg-gray-100 text-gray-800">
                {currentIndex + 1} / {templateList.length}
              </span>
            </div>
          </div>
          <div
            className={`${
              currentIndex === 0
                ? "cursor-default opacity-75"
                : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
            } w-full p-2 rounded-t-xl text-center transition duration-100 ease-in`}
            onClick={() => setPrev()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={currentIndex === 0 ? faMinus : faChevronUp}
            ></FontAwesomeIcon>
          </div>
          {templateList.length > 0 ? (
            <>
              <ResidencyDocumentPageView
                page={templateList[currentIndex].filename}
                session_id={residencyVerification.task.session_id}
                suggestions={
                  residencyVerification.suggestions[currentIndex]
                    ? residencyVerification.suggestions[currentIndex]
                    : []
                }
                paragraphs={templateList[currentIndex].paragraphs}
              ></ResidencyDocumentPageView>
            </>
          ) : null}
          <div
            className={`${
              currentIndex === templateList.length - 1
                ? "cursor-default opacity-75"
                : "bg-bluegray-50 cursor-pointer hover:bg-bluegray-100"
            } w-full p-2 rounded-b-xl text-center transition duration-100 ease-in`}
            onClick={() => setNext()}
          >
            <FontAwesomeIcon
              className="text-gray-500 text-3xl"
              icon={
                currentIndex === templateList.length - 1
                  ? faMinus
                  : faChevronDown
              }
            ></FontAwesomeIcon>
          </div>
        </div>
      </div>
    </GlobalHotKeys>
  );
}
