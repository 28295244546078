import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import IdentityTasksPage from '@/components/pages/IdentityTasksPage'
import { IdentityContextProvider } from '@/context/identity'

function Identity() {
	return (
		<AuthGuard>
			<IamGuard page='identity'>
				<IdentityContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<IdentityTasksPage></IdentityTasksPage>
					</div>
				</IdentityContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default Identity
