import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { CrosscheckVerificationContextProvider } from '@/context/crosscheck_verification'
import CrosscheckVerification from '@/components/layout/CrosscheckVerification'

function CrosscheckSession() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<CrosscheckVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<CrosscheckVerification></CrosscheckVerification>
					</div>
				</CrosscheckVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default CrosscheckSession
