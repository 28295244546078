import { faUser, faUniversity } from '@fortawesome/pro-duotone-svg-icons'
import CorporateItem from './CorporateItem'
import CorporateMemberItem from './CorporateMemberItem'

export default function CorporateOrganisation(props) {
	return (
		<>
			<ul>
					{Object.entries(props.corporates.corporates).map(
						([key, subCorporate]) => {
							return (
								<li key={key}>
										<CorporateItem
											label="Corporates"
											name={subCorporate.corporate.name}
											documents={subCorporate.document_steps}
											corporate_id={key}
											icon={faUniversity}></CorporateItem>
										
											<CorporateOrganisation
												corporates={
													subCorporate.beneficial_owners_step
												}></CorporateOrganisation>
-								</li>
							)
						}
					)}
				
					{Object.entries(props.corporates.members).map(([key, subMember]) => {
						return (
							<li key={key}>
								<CorporateMemberItem
									label="Members"
									name={subMember.firstname + ' ' + subMember.lastname}
									identity={subMember.identity}
									residency={subMember.residency}
									icon={faUser}></CorporateMemberItem>
							</li>
						)
					})}
				
			</ul>
		</>
	)
}
