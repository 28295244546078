import { PanelAuth } from "@/services/axios";

export default {
  RetreiveGlobalTasksStats() {
    return PanelAuth.get(`${process.env.REACT_APP_PANEL_HOST}/v3/stats/global`);
  },
  RetreiveOwnTasksStats() {
    return PanelAuth.get(`${process.env.REACT_APP_PANEL_HOST}/v3/stats/own`);
  },
  RetreiveCorporatesList() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/stats/corporates`
    );
  },
};
