import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import { CorporateVerificationContextProvider } from '@/context/corporate_verification'
import CorporateVerification from '@/components/layout/CorporateVerification'
import React from 'react'

function CorporateSession() {
	return (
		<AuthGuard>
			<IamGuard page='corporate'>
				<CorporateVerificationContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<CorporateVerification></CorporateVerification>
					</div>
				</CorporateVerificationContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default CorporateSession
