import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function MenuExtraItem(props) {
	let path = '/'
	if (props.path) {
		path = props.path
	}

	return (
		<a href={path}>
			<div
				className={
					'hover:bg-gray-100 cursor-pointer p-3 transition duration-200 ease-in rounded-xl'
				}>
				<p className='text-sm'>
					{' '}
					<FontAwesomeIcon
						className={'text-primary-500 text-lg'}
						icon={props.icon}></FontAwesomeIcon>
					<span className='ml-3'>{props.label}</span>
				</p>
			</div>
		</a>
	)
}
