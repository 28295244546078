import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import NetworkProjectPage from '@/components/pages/NetworkProjectPage'
import IamGuard from '@/guard/iam'

function NetworkIndex() {
	return (
		<AuthGuard>
            <IamGuard page='network'>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<NetworkProjectPage></NetworkProjectPage>
			</div>
            </IamGuard>
		</AuthGuard>
	)
}

export default NetworkIndex
