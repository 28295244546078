import { useEffect, useState } from 'react'
import SubNav from '../menu/SubNav'
import { useHistory } from 'react-router-dom'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import AgentsService from '@/services/agents'
import Card from '../ui/Card'
import {
    faArrowLeft, faCodeBranch,
    faFileInvoice,
    faHeadSide,
    faPassport,
    faStream,
    faUniversity,
    faGlobe,
    faPlusCircle,
    faUserFriends,
} from '@fortawesome/pro-duotone-svg-icons'
import { useParams } from 'react-router-dom'
import Footer from '../menu/Footer'
import Alert from '@/components/ui/Alert'
import Button from '../ui/Button'
import AgentTaskItem from '../agent/AgentTaskItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '../ui/Tooltip'
import AgentPermission from '../agent/AgentPermission'

export default function RegistryListPage() {
    const [ready, setReady] = useState(false)
    const [error, setError] = useState(null)
    const [agent, setAgent] = useState(null)
    const [modal, setModal] = useState(false)
    const router = useHistory()
    const { key } = useParams()

    async function updateAgent() {
        await AgentsService.RetreiveAgent(key)
            .then((data) => {
                setAgent(data)
                setReady(true)
            })
            .catch((err) => {
                setError(err.message)
            })
        return () => { }
    }

    useEffect(async () => {
        await AgentsService.RetreiveAgent(key)
            .then((data) => {
                setAgent(data)
                setReady(true)
            })
            .catch((err) => {
                setError(err.message)
            })
        return () => { }
    }, [])


    return (
        <div>
            <div>
                <SubNav></SubNav>
                <div className="max-w-7xl mx-auto relative">
                    <div className="verification-container">
                        {ready ? (
                            <Card className="w-full px-3 sm:px-0">
                                <div>
                                    <div className="p-3">
                                        <div className="flex justify-between">
                                            <div className="flex justify-center">
                                                <div className="mr-3 w-full mt-1">
                                                    <Button
                                                        type="submit"
                                                        theme="primary"
                                                        onClick={() =>
                                                            router.push('/agents')
                                                        }
                                                        icon={faArrowLeft}
                                                        label="Back"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {error ? (
                                            <div className="p-3">
                                                <Alert type="error" title={error}></Alert>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <div className="mx-auto text-center mt-1 mb-4">
                                                <img
                                                    className="h-200px rounded-full mx-auto text-center"
                                                    src={agent.agent.profile_pic}
                                                    alt=""
                                                />
                                            </div>
                                            <p className="text-regular font-semibold">{agent.agent.firstname} {agent.agent.lastname}</p>
                                            <p className="text-sm">{agent.agent.email}</p>
                                            <div className="flex justify-center">
                                                <ul className="flex justify-center text-center mt-5">
                                                    {agent.agent.iam.map((role, index) => (
                                                        <li className="p-1" key={index}>
                                                            <div className="items-center">
                                                                <a className="group items-center p-1 bg-bluegray-100 rounded-xl text-sm">{role}</a>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="mt-6 ml-2">
                                                    <Tooltip auto={true} content="Modify">
                                                        <div onClick={() => setModal(true)}>
                                                            <FontAwesomeIcon
                                                                className="cursor-pointer hover:text-green-400 text-gray-500 transition duration-100 ease-in"
                                                                icon={faPlusCircle}></FontAwesomeIcon>
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-8 ml-8 mr-8 text-center">
                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Total'
                                                    theme='primary'
                                                    amount_today={agent.today}
                                                    amount_week={agent.week}
                                                    amount_month={agent.month}
                                                    amount_year={agent.year}
                                                    icon={faGlobe}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Identity'
                                                    theme='identity'
                                                    amount_today={agent.today_identity}
                                                    amount_week={agent.week_identity}
                                                    amount_month={agent.month_identity}
                                                    amount_year={agent.year_identity}
                                                    icon={faPassport}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Residency'
                                                    theme='residency'
                                                    amount_today={agent.today_residency}
                                                    amount_week={agent.week_residency}
                                                    amount_month={agent.month_residency}
                                                    amount_year={agent.year_residency}
                                                    icon={faFileInvoice}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Aml'
                                                    theme='aml'
                                                    amount_today={agent.today_aml}
                                                    amount_week={agent.week_aml}
                                                    amount_month={agent.month_aml}
                                                    amount_year={agent.year_aml}
                                                    icon={faStream}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Corporate'
                                                    theme='corporate'
                                                    amount_today={agent.today_corporate}
                                                    amount_week={agent.week_corporate}
                                                    amount_month={agent.month_corporate}
                                                    amount_year={agent.year_corporate}
                                                    icon={faUniversity}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Facematch'
                                                    theme='facematch'
                                                    amount_today={agent.today_facematch}
                                                    amount_week={agent.week_facematch}
                                                    amount_month={agent.month_facematch}
                                                    amount_year={agent.year_facematch}
                                                    icon={faHeadSide}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Duplicate'
                                                    theme='dupplicate'
                                                    amount_today={agent.today_duplicate}
                                                    amount_week={agent.week_duplicate}
                                                    amount_month={agent.month_duplicate}
                                                    amount_year={agent.year_duplicate}
                                                    icon={faUserFriends}></AgentTaskItem>
                                            </div>

                                            <div className="p-3">
                                                <AgentTaskItem
                                                    label='Crosscheck'
                                                    theme='crosscheck'
                                                    amount_today={agent.today_crosscheck}
                                                    amount_week={agent.week_crosscheck}
                                                    amount_month={agent.month_crosscheck}
                                                    amount_year={agent.year_crosscheck}
                                                    icon={faCodeBranch}></AgentTaskItem>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Card>
                        ) : (
                            <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
                        )}
                    </div>
                    <Footer></Footer>
                </div>
                <AgentPermission
                    open={modal}
                    agent={agent}
                    onUpdate={() => updateAgent()}
                    onClose={() => setModal(false)}></AgentPermission>
            </div>
        </div>
    )
}
