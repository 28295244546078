import { useContext, useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import {
	faCalendar,
	faFileInvoice,
	faHouse,
	faMapMarked,
	faMapMarker,
	faNotEqual,
	faPastafarianism,
	faUser,
	faWrench,
} from '@fortawesome/pro-duotone-svg-icons'
import * as Notification from '@/components/ui/Notification'
import ResidencyService from '@/services/residency'
import { useHistory } from 'react-router-dom'
import RejectCard from '../ui/RejectCard'
import { ResidencyVerificationContext } from '@/context/residency_verification'

export default function RejectResidencySidebar({ open, setOpen }) {
	const [pin, setPin] = useState('')
	const [loading, setLoading] = useState(false)
	const router = useHistory()

	const [residencyVerification] = useContext(ResidencyVerificationContext)

	function updatePin(newPin) {
		if (pin === newPin) {
			setPin('')
		} else {
			setPin(newPin)
		}
	}

	async function rejectResidency() {
		setLoading(true)
		await ResidencyService.Reject(residencyVerification.task.session_id, pin)
			.then(() => {
				setLoading(false)
				Notification.success('Residency rejected successfully')
				router.push('/verifications/residency')
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => {}
	}

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-red-500 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										Reject verification
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-red-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className='mt-1'>
									<p className='text-sm text-white'>
										Select the reason of rejection
									</p>
								</div>
							</div>
							<div className='flex-1 flex flex-col justify-between'>
								<div className='px-4 py-2'>
									{residencyVerification.verification.steps.current_step ===
									'accomodation_proof_verification' ? (
										<>
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Accomodation Proof
											</p>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='WRONG_ACCOMODATION_PROOF'
												title='Wrong accomodation proof'
												description='The accomodation proof must contains the firstname and lastname of the host and his signature along with the firstname and lastname of the hosted person.'
												icon={faHouse}></RejectCard>
										</>
									) : null}
									{residencyVerification.verification.steps.current_step ===
									'document_type_verification' ? (
										<>
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Document Type
											</p>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='INVALID_DOCUMENT_TYPE'
												title='Invalid document type'
												description='The current document is not the selected type document'
												icon={faFileInvoice}></RejectCard>
										</>
									) : null}
									{residencyVerification.verification.steps.current_step ===
									'name_verification' ? (
										<>
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Name verification
											</p>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='DOCUMENT_DOES_NOT_CONTAINS_NAME'
												title='Name not found'
												description='The name is not found on the document'
												icon={faUser}></RejectCard>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='DOCUMENT_DOES_NOT_MATCH_NAME'
												title='Name does not match'
												description='The name does not match with the document'
												icon={faNotEqual}></RejectCard>
										</>
									) : null}

									{residencyVerification.verification.steps.current_step ===
									'issuing_date_verification' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Issuing Date
											</p>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='DOCUMENT_TOO_OLD'
												title='Document is too old'
												description='The reviewed document is higher than 3 months old'
												icon={faCalendar}></RejectCard>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='NO_ISSUING_DATE'
												title='No issuing date'
												description='The issuing date is missing'
												icon={faCalendar}></RejectCard>
										</>
									) : null}

									{residencyVerification.verification.steps.current_step ===
									'address_verification' ? (
										<>
											{' '}
											<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
												Address verification
											</p>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='DOCUMENT_ADDRESS_NO_MATCH'
												title='Postal address does not match'
												description='The document address does not match the user address'
												icon={faMapMarker}></RejectCard>
											<RejectCard
												selected={pin}
												setPin={(slug) => updatePin(slug)}
												slug='DOCUMENT_ADDRESS_NOT_FOUND'
												title='No address found'
												description='No valid postal address is present on the document'
												icon={faMapMarked}></RejectCard>
										</>
									) : null}

									<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
										General
									</p>
									<RejectCard
										selected={pin}
										setPin={(slug) => updatePin(slug)}
										slug='DOCUMENT_INCONSISTENT'
										title='Inconsistent document'
										description='Document contains inconsistencies'
										icon={faWrench}></RejectCard>

									<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
										Spam
									</p>
									<RejectCard
										selected={pin}
										setPin={(slug) => updatePin(slug)}
										slug='SPAM_REJECTION'
										title='Spam'
										description='Reject when the user sends too much wrong verification'
										icon={faPastafarianism}></RejectCard>
								</div>
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-end'>
							<Button
								onClick={() => setOpen(false)}
								theme='secondary'
								label='Cancel'></Button>
							{pin !== '' ? (
								<Button
									loading={loading}
									className='ml-2'
									onClick={() => rejectResidency()}
									theme='error'
									label='Reject'></Button>
							) : null}
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
