import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppsService from "@/services/apps";
import Button from "@/components/ui/Button";
import Moment from "react-moment";
import Input from "@/components/ui/Input";
import { useHistory } from "react-router-dom";
import UpdateApp from './UpdateApp'
import { Modal } from 'react-responsive-modal'

export default function AppsItemPage(props) {
  const { app } = useParams();
  const [appStat, setAppStat] = useState(null);
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useHistory();
  const [animaId, setAnimaId] = useState("");
  const [animaName, setAnimaName] = useState("");
  const [animaDescription, setAnimaDescription] = useState("");
  const [animaLoading, setAnimaLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(async () => {
    await AppsService.GetAppDetails(app)
      .then((data) => {
        setAppStat(data);
        setReady(true);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => { };
  }, [app]);

  async function EnableAnima() {
    setAnimaLoading(true);

    await AppsService.EnableAnima(app, animaId, animaName, animaDescription)
      .then(async (data) => {
        setAnimaLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        Notification.error(err.message);
        setAnimaLoading(false);
      });
  }

  async function ExportApp() {
    setLoading(true);

    await AppsService.GetAppExport(app)
      .then(async (data) => {
        console.log(data);
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map((e) => e.join(",")).join("\n");
        let encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "export_" + app + ".csv");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
  }

  return (
    <div className="w-full p-4 self-auto">
      <div className="">
        <div className="p-3">
          {ready ? (
            <>
              <div className="flex">
                <div className="w-2/3 m-2 flex rounded-xl border h-full border-gray-200 shadow-sm p-3">
                  <div className="w-1/2 text-center">
                    <div className="mx-auto text-center mt-1 mb-4">
                      <img
                        className="app-logo rounded-full mx-auto text-center"
                        src={appStat.app.logo_url}
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex mb-5">
                      <div className="font-bold text-xl">
                        {appStat.app.name}
                      </div>
                      <div className="ml-2 text-sm mt-1">
                        #{appStat.app._key}
                      </div>
                    </div>
                    <div className="mt-2 text-md flex">
                      <p className="mr-1">Created at</p>
                      <Moment unix format="YYYY/MM/DD">
                        {appStat.app.created_at / 1000}
                      </Moment>
                    </div>
                    <div className="mt-2 text-md flex">
                      <p className="mr-2">Type:</p>
                      {appStat.app.type === "WORKFLOW" ? (
                        <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                          Individual KYC
                        </p>
                      ) : (
                        <p className="text-gray-500 text-sm bg-gray-100 rounded-xl px-4 py-1">
                          Corporate KYB
                        </p>
                      )}
                    </div>
                    <div className="mt-2 text-md flex">
                      <p className="mr-2">Mode: </p>
                      {appStat.app.sandbox === false ? (
                        <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                          Production
                        </p>
                      ) : (
                        <p className="text-orange-500 text-sm bg-orange-100 rounded-xl px-4 py-1">
                          Sandbox
                        </p>
                      )}
                    </div>
                    <div className="mt-2 text-md flex">
                      <p className="mr-2">Status: </p>
                      {appStat.app.state === 2 ? (
                        <p className="text-green-500 text-sm bg-green-100 rounded-xl px-4 py-1">
                          Active
                        </p>
                      ) : (
                        <p className="text-red-500 text-sm bg-red-100 rounded-xl px-4 py-1">
                          Not Active
                        </p>
                      )}
                    </div>
                    {appStat.app.type === "WORKFLOW" ? (
                      <div className="mt-2 text-md flex">
                        <div>
                          <p className="mr-2">Steps: </p>
                        </div>
                        {appStat.app.steps.map((step, index) => {
                          return (
                            <div key={index}>
                              {step === "IDENTITY" ? (
                                <p className="text-green-500 mx-2 text-sm bg-green-100 rounded-xl px-4 py-1">
                                  IDENTITY
                                </p>
                              ) : null}
                              {step === "RESIDENCY" ? (
                                <p className="text-blue-500 mx-2 text-sm bg-residency-50 rounded-xl px-4 py-1">
                                  RESIDENCY
                                </p>
                              ) : null}
                              {step === "PHONE" ? (
                                <p className="text-crosscheck-500 mx-2 text-sm bg-crosscheck-50 rounded-xl px-4 py-1">
                                  PHONE
                                </p>
                              ) : null}
                              {step === "LIVENESS" ? (
                                <p className="text-red-500 mx-2 text-sm bg-red-100 rounded-xl px-4 py-1">
                                  LIVENESS
                                </p>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="w-1/3  m-2 h-full">
                  <div className="rounded-xl border border-gray-200 shadow-sm p-3">
                    <div className="font-bold text-xl">Consumption</div>
                    {appStat.app.type === "WORKFLOW" ? (
                      <>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Approved KYC:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.validated_verifications}
                          </p>
                        </div>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Started Liveness:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.liveness_initiated}
                          </p>
                        </div>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Started Identity:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.identity_initiated}
                          </p>
                        </div>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Started Residency:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.residency_initiated}
                          </p>
                        </div>
                      </>
                    ) : null}
                    {appStat.app.type === "CORPORATE" ? (
                      <>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Approved KYB:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.validated_verifications}
                          </p>
                        </div>
                        <div className="mt-2 text-md flex">
                          <p className="mr-2">Started Liveness:</p>
                          <p className="text-gray-500 text-sm font-bold bg-gray-100 rounded-xl px-4 py-1">
                            {appStat.consumption.liveness_initiated}
                          </p>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="rounded-xl border border-gray-200 shadow-sm p-3 mt-5">
                    <div className="font-bold text-xl">Action</div>
                    <div className="flex mt-3">
                      <Button
                        type="submit"
                        theme="success"
                        loading={loading}
                        onClick={() => ExportApp()}
                        label={"Export"}
                      />
                      <div className="ml-2">
                      <Button
                         type="submit"
                         theme="pin"
                         loading={loading}
                         onClick={() => setOpen(!open)}
                         label={"Update"}                     
                      />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {appStat.linked_apps.length > 0 ? (
                <div className="m-2 mt-3 rounded-xl border border-gray-200 shadow-sm p-4">
                  <div className="font-bold text-xl">Also check ...</div>
                  <div className="mt-2 text-md flex">
                    {appStat.linked_apps.map((app, index) => {
                      return (
                        <div
                          key={index}
                          className="p-2 m-2 text-center cursor-pointer rounded-xl hover:bg-bluegray-100"
                          onClick={() => router.push("/apps/" + app._key)}
                        >
                          <img
                            className="h-20 rounded-full mx-auto text-center"
                            src={app.logo_url}
                            alt=""
                          />
                          <p className="mt-2">{app.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        {appStat ? (
          <>
           <Modal open={open}
						showCloseIcon={true}
						closeIcon={true}
						onClose={() => setOpen(false)}
						center>
						<UpdateApp id={appStat.app._key} name={appStat.app.name} onClose={() => setOpen(false)} />
					</Modal>
            {" "}
            <h3 className="my-3">DID</h3>
            <div className="p-4 border h-full border-gray-200 shadow-sm items-center rounded-xl my-3 bg-white">
              {appStat.did.active ? (
                <div>
                  <p className="text-gray-500 text-xs uppercase">Status</p>
                  <p className="text-green-500">Active</p>
                </div>
              ) : null}
              {appStat.did.active === false ? (
                <>
                  <p>Enable Anima for this account</p>
                  <div className="my-4 w-1/2">
                    <Input
                      type="text"
                      placeholder="Name"
                      setValue={(value) => setAnimaName(value)}
                    ></Input>
                    <p className="my-2 text-sm">Name of the app</p>
                    <hr />
                    <Input
                      type="text"
                      placeholder="Anima Verifier ID"
                      setValue={(value) => setAnimaId(value)}
                    ></Input>
                    <p className="my-2 text-sm">
                      It should be all lowercase separated with underscore (_)
                    </p>
                    <div className="my-3 bg-gray-200 p-2 rounded-md px-4 text-sm">
                      <p>Valid example: binance_kyc</p>
                      <p>Invalid example: BINANCE-KYC</p>
                    </div>
                    <hr />
                    <Input
                      type="text"
                      placeholder="Description"
                      setValue={(value) => setAnimaDescription(value)}
                    ></Input>
                    <p className="my-2 text-sm">Description of the app</p>
                  </div>
                  <Button
                    disabled={
                      animaId === "" ||
                      animaDescription === "" ||
                      animaName === ""
                    }
                    type="submit"
                    theme="success"
                    loading={animaLoading}
                    onClick={() => EnableAnima()}
                    label={"Enable"}
                  />
                </>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
