import {
    faAngleRight,
    faUserCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SessionContext } from '@/context/session'
import countries from '@/resources/countries_label.json'

export default function IndividualSessionSidebar() {
    const [individual] = useContext(SessionContext)
    const { session_id } = useParams()

    function pushStep(step_id) {
        document.getElementById(step_id).scrollIntoView()
    }

    function AnchorItem({ type, state, step_id }) {
        return (<div className="mx-4 mt-2">
            <div>
                <div onClick={() => pushStep(step_id)} className="flex flex-wrap pb-1.5 justify-between items-center hover:bg-bluegray-50 border border-transparent hover:border-bluegray-100 hover:cursor-pointer transition ease-in duration-200 py-1 px-1.5 rounded-xl">
                    <div className="flex">
                        <div className="mr-3 mt-0.5 flex-shrink-0">
                            {type === 'LIVENESS' ? <img className="h-9" src={'/img/icons/ui/look-in-camera.svg'}></img> : null}
                            {type === 'IDENTITY' ? <img className="h-9" src={'/img/icons/ui/icon-passport.svg'}></img> : null}
                            {type === 'RESIDENCY' ? <img className="h-9" src={'/img/icons/ui/icon-utility-bill.svg'}></img> : null}
                            {type === 'PHONE' ? <img className="h-9" src={'/img/icons/ui/icon-call.svg'}></img> : null}
                            {type === 'AML' ? <img className="h-9" src={'/img/icons/ui/icon-aml.svg'}></img> : null}
                        </div>
                        <div className="relative top-[2px]">

                            {type === 'IDENTITY' ? <p className="text-bluegray-400 text-xs">ID Document</p> : null}
                            {type === 'AML' ? <p className="text-bluegray-400 text-xs">Anti-Money Laundering (AML)</p> : null}
                            {type === 'RESIDENCY' ? <p className="text-bluegray-400 text-xs">Proof of residency</p> : null}
                            {type === 'PHONE' ? <p className="text-bluegray-400 text-xs">Phone number</p> : null}
                            {type === 'LIVENESS' ? <p className="text-bluegray-400 text-xs">Liveness</p> : null}
                            {state === 'PENDING' ? <p className="text-orange-500 text-sm rounded-md">Pending verification</p> : null}
                            {state === 'NOT_STARTED' ? <p className="text-gray-500 text-sm rounded-md">Not started</p> : null}
                            {state === 'REJECTED' ? <p className="text-red-500 text-sm rounded-md">Rejected</p> : null}
                            {state === 'FINAL_REJECTED' ? <p className="text-red-500 text-sm rounded-md">Final Rejected</p> : null}
                            {state === 'VALIDATED' ? <p className="text-green-500 text-sm rounded-md">Verified</p> : null}
                            {state === 'ACTION_REQUIRED' ? <p className="text-orange-500 text-sm rounded-md">Action required</p> : null}
                        </div>
                    </div>
                    <div className="flex">
                        <FontAwesomeIcon className="mr-2 text-gray-500" icon={faAngleRight} />
                    </div>
                </div>
            </div>
        </div>)
    }

    function InfoItem(props) {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                <div className="mr-3 mt-0.5 flex-shrink-0">
                    <img className="h-9" src={props.icon}></img>
                </div>
                <div className="relative top-[2px]">
                    <p className="text-bluegray-400 text-xs">{props.title}</p>
                    <p className="text-gray-600 text-sm">{props.value}</p>
                </div>
            </div>
        </div>)
    }

    function InfoItemPlaceholder() {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl animate-pulse">
                <div className="mr-3 mt-0.5 flex-shrink-0">
                    <div className="w-9 h-9 rounded-lg bg-bluegray-200"></div>
                </div>
                <div className="relative top-[2px]">
                    <div className="mt-2 w-8 h-2 bg-bluegray-200 rounded-lg"></div>
                    <div className="w-32 h-2.5 mt-1 bg-bluegray-300 rounded-lg"></div>
                </div>
            </div>
        </div>)
    }

    function IndividualSidebarPlaceholder() {
        return (<div className="bg-white rounded-xl shadow-sm pt-1 pb-4">
            <div className="rounded-xl m-3 pt-3 pb-3 animate-pulse">
                <div className="h-full mx-auto text-center px-3 pb-3">
                    <FontAwesomeIcon className="text-7xl text-bluegray-300" icon={faUserCircle} />
                </div>
                <div className="mt-3 h-3 w-32 bg-bluegray-400 mx-auto rounded-lg"></div>
                <div className="mt-3 h-2 w-16 bg-bluegray-300 mx-auto rounded-lg"></div>
            </div>
            <hr className="mx-6 mb-3 border-bluegray-100" />
            <div>

                <p className="px-3 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Info</p>
                <div className="mx-4 mt-1.5">
                    <InfoItemPlaceholder />
                    <InfoItemPlaceholder />
                    <InfoItemPlaceholder />
                </div>
            </div>
            <div>
                <p className="px-3 mt-4 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Verifications</p>
                <div className="mx-4 mt-1.5">
                    <InfoItemPlaceholder />
                    <InfoItemPlaceholder />
                    <InfoItemPlaceholder />
                </div>
            </div>
        </div>)
    }


    function CountryItem(props) {
        return (<div className="mt-2">
            <div className="flex border border-transparent py-1 px-1.5 rounded-xl">
                <div className="mr-3 flex-shrink-0">
                    <div className="h-9 w-9" style={{
                        backgroundImage: `url( ${`/img/icons/flags-rounded/${props.country}.svg`}), url(${'/img/icons/ui/icon-round.svg'})`,
                        backgroundSize: '60%, contain',
                        backgroundRepeat: 'no-repeat, no-repeat',
                        backgroundPosition: 'center center, center center',
                    }}></div>
                </div>
                <div className="relative top-[2px]">
                    <p className="text-bluegray-400 text-xs">{props.title}</p>
                    <p className="text-gray-600 text-sm">{props.value}</p>
                </div>
            </div>
        </div>)
    }


    return (
        <>
            {individual.ready === true ? <div className="bg-white rounded-xl shadow-sm pt-1 pb-4">
                <div className="rounded-xl m-3 pt-3">
                    <div className="h-full mx-auto text-center px-3 pb-3">
                        <FontAwesomeIcon className="text-7xl text-bluegray-300" icon={faUserCircle} />
                    </div>
                    <h2 className="text-xl text-primary-800 text-center mb-1 capitalize">{individual.metadata.full_name ? individual.metadata.full_name.toLowerCase() : '-'}</h2>
                    <h2 className="text-sm text-gray-500 text-center mb-4">Individual</h2>
                </div>
                <hr className="mx-6 my-3 border-bluegray-100" />
                <div>

                    <p className="px-3 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Info</p>
                    <div className="mx-4 mt-1.5">
                        {individual.metadata.birth_date ? <InfoItem title="Birth date" value={individual.metadata.birth_date} icon="/img/icons/ui/icon-birthdate.svg" /> : null}
                        {individual.metadata.nationality ? <CountryItem title="Nationality" value={countries[individual.metadata.nationality]} country={individual.metadata.nationality} /> : null}
                        {individual.metadata.full_phone ? <InfoItem title="Phone number" value={individual.metadata.full_phone} icon="/img/icons/ui/icon-call.svg" /> : null}
                        {individual.metadata.full_address ? <InfoItem title="Postal address" value={individual.metadata.full_address} icon="/img/icons/ui/icon-address.svg" /> : null}
                        <InfoItem title="Session ID" value={session_id} icon="/img/icons/ui/icon-sessionid.svg" />
                        {individual.metadata.alias ? <InfoItem title="Alias" value={individual.metadata.alias} icon="/img/icons/ui/icon-alias.svg" /> : null}
                    </div>
                </div>
                <div>
                    <p className="px-3 mt-4 uppercase tracking-wide font-medium text-sm mx-3 text-primary-400">Verifications</p>
                    {individual.ordered_steps.map((step_id) => {
                        return <AnchorItem key={step_id} step_id={step_id} type={individual.steps[step_id].type} state={individual.steps[step_id].verification.state} />
                    })}
                    {individual.aml.active ? <AnchorItem key={'aml'} step_id={'aml'} type={'AML'} state={individual.aml?.action_required ? 'ACTION_REQUIRED' : individual.aml.state} /> : null}
                </div>
            </div> : <IndividualSidebarPlaceholder />}

        </>
    )
}
