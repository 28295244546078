import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ReviewerHelp(props) {
	let themeType = props.theme

	let theme = {
		primary: {
			normal: 'bg-bluegray-50',
			hover: 'hover:bg-primary-100',
			focus: 'focus:ring-primary-500',
			border: 'border-primary-500',
			borderhover: 'hover:border-primary-700',
			text: 'text-white',
			loader: 'text-white',
		},
		indigo: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-indigo-50',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-indigo-500',
			loader: 'text-gray-700',
		},
		orange: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-orange-50',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-orange-500',
			loader: 'text-gray-700',
		},
		gray: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-gray-100',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-gray-500',
			loader: 'text-gray-700',
		},
		blue: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-blue-100',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-blue-500',
			loader: 'text-gray-700',
		},
		emerald: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-emerald-100',
			focus: 'focus:ring-gray-200',
			border: 'border-gray-200',
			borderhover: 'hover:border-gray-300',
			text: 'text-emerald-500',
			loader: 'text-gray-700',
		},
	}

	if (!theme[themeType]) {
		themeType = 'primary'
	}

	const palette = theme[themeType]

	return (
		<div
			className={`bg-bluegray-50 p-3 h-full rounded-xl ${palette.normal} ${palette.hover} cursor-pointer transition duration-200 ease-in`}>
			<div className="py-8 text-center rounded-xl">
				<FontAwesomeIcon
					className={`text-center text-5xl ${palette.text}`}
					icon={props.icon}></FontAwesomeIcon>
			</div>
			<div className="p-3">
				<p className={`uppercase font-bold text-sm  ${palette.text}`}>
					{props.title}
				</p>
				<p className="mt-2 text-gray-600 text-sm">{props.description}</p>
			</div>
		</div>
	)
}
