import { useContext, useEffect, useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import {
	faBuilding,
	faPen,
} from '@fortawesome/pro-duotone-svg-icons'
import { CorporateVerificationContext } from '@/context/corporate_verification'
import * as Notification from '@/components/ui/Notification'
import Input from '../ui/Input'
import CorporateService from '@/services/corporate'

export default function EditRegistrationNumber({ open, setOpen }) {
	const [loading, setLoading] = useState(false)
	const [localValue, setLocalValue] = useState('')
	const [corporateVerification] = useContext(
		CorporateVerificationContext
	)

	useEffect(() => { }, [])


	async function changeRegistrationNumber() {
		setLoading(true)

		let body = {
			registration_number: localValue
		}

		await CorporateService.ChangeRegistrationNumber(
			corporateVerification.task.session_id,
			body)
			.then(() => {
				setLoading(false)
				Notification.success('Registration Number changed successfully')
				window.location.reload()
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => { }
	}

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-primary-500 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										Edit Registration Number
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-primary-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className='mt-1'>
									<p className='text-sm text-white'>
										Edit the registration Number of the company
									</p>
								</div>
							</div>
							<div className='flex-1 flex flex-col justify-between'>
								<div className='px-4 py-2'>
									<>
										<div className='mt-2 mb-2'>
											<Input
												type='text'
												icon={faBuilding}
												placeholder='XXXXXXXX'
												setValue={(value) => setLocalValue(value)}
											/>
										</div>
									</>
								</div>
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-between'>
							<div className='flex'>
								<Button
									onClick={() => setOpen(false)}
									theme='secondary'
									label='Cancel'></Button>
								<Button
									loading={loading}
									className='ml-2'
									onClick={() => changeRegistrationNumber()}
									icon={faPen}
									theme='success'
									label='Change Registration Number'></Button>
							</div>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
