import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import CrosscheckTasksPage from '@/components/pages/CrosscheckTasksPage'
import { CrosscheckContextProvider } from '@/context/crosscheck'

function Crosscheck() {
	return (
		<AuthGuard>
			<IamGuard page='duplicate'>
				<CrosscheckContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<CrosscheckTasksPage></CrosscheckTasksPage>
					</div>
				</CrosscheckContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default Crosscheck
