import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SubNav from "../menu/SubNav";
import Card from "../ui/Card";
import Footer from "../menu/Footer";
import ResidencyVerificationHeader from "../verifications/residency/ResidencyVerificationHeader";
import ResidencyService from "@/services/residency";
import VerificationHeaderPlaceholder from "../placeholder/VerificationHeaderPlaceholder";
import * as Notification from "@/components/ui/Notification";
import useWebSocket from "react-use-websocket";
import AuthService from "@/services/auth";
import { ResidencyVerificationContext } from "@/context/residency_verification";
import ResidencyDocumentType from "../verifications/residency/document_type/ResidencyDocumentType";
import VerificationStepPlaceholder from "../placeholder/VerificationStepPlaceholder";
import ResidencyDocumentName from "../verifications/residency/name_verification/ResidencyDocumentName";
import ResidencyIssuingDate from "../verifications/residency/issuing_date/ResidencyIssuingDate";
import ResidencyAddressVerification from "../verifications/residency/address/ResidencyAddressVerification";
import ResidencyFinalReview from "../verifications/residency/ResidencyFinalReview";
import ResidencyHostIdVerification from "../verifications/residency/host_id/ResidencyHostIdVerification";
import ResidencyAccomodationProof from "../verifications/residency/accomodation_proof/ResidencyAccomodationProof";
import { AuthContext } from "@/context/auth";

export default function ResidencyVerification() {
  const [residencyVerification, residencyVerificationDispatch] = useContext(
    ResidencyVerificationContext
  );
  const [ready, setReady] = useState(false);
  const router = useHistory();
  const { session_id } = useParams();
  const [auth] = useContext(AuthContext);

  const socketUrl = `${process.env.REACT_APP_WS_HOST}/v3/websockets/online`;
  const { readyState, getWebSocket } = useWebSocket(socketUrl, {
    onOpen: async () => {},
    shouldReconnect: () => true,
  });

  useEffect(async () => {
    await getTask();
    return () => {};
  }, []);

  useEffect(async () => {
    if (readyState) {
      getWebSocket().send(
        JSON.stringify({
          auth: auth.auth.access_token,
          email: auth.user.email,
          firstname: auth.user.firstname,
          lastname: auth.user.lastname,
          profile_pic: auth.user.profile_pic,
          service: "RESIDENCY",
          session_id: session_id,
        })
      );
    }
  }, [readyState]);

  async function getTask() {
    await ResidencyService.GetTask(session_id)
      .then(async (data) => {
        if (data.task.service_session_id === "") {
          router.push("/verifications/residency");
          return;
        }
        await residencyVerificationDispatch({
          type: "SET_SESSION",
          payload: data,
        });
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <div>
      <div>
        <SubNav></SubNav>
        <div className="max-w-7xl mx-auto relative">
          <div className="verification-container">
            {ready ? (
              <ResidencyVerificationHeader
                onUpdate={() => getTask()}
              ></ResidencyVerificationHeader>
            ) : (
              <VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
            )}
          </div>
        </div>

        <div className="max-w-7xl mx-auto relative">
          <div className="flex flex-wrap mt-6">
            <Card className="w-full px-3 sm:px-0">
              <div>
                <div>
                  {ready ? (
                    <div>
                      <div className="p-6">
                        {residencyVerification.verification.steps
                          .current_step === "host_id_verification" ? (
                          <ResidencyHostIdVerification
                            onUpdate={() => getTask()}
                          ></ResidencyHostIdVerification>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step ===
                        "accomodation_proof_verification" ? (
                          <ResidencyAccomodationProof
                            onUpdate={() => getTask()}
                          ></ResidencyAccomodationProof>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step === "document_type_verification" ? (
                          <ResidencyDocumentType
                            onUpdate={() => getTask()}
                          ></ResidencyDocumentType>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step === "name_verification" ? (
                          <ResidencyDocumentName
                            onUpdate={() => getTask()}
                          ></ResidencyDocumentName>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step === "issuing_date_verification" ? (
                          <ResidencyIssuingDate
                            onUpdate={() => getTask()}
                          ></ResidencyIssuingDate>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step === "address_verification" ? (
                          <ResidencyAddressVerification
                            onUpdate={() => getTask()}
                          ></ResidencyAddressVerification>
                        ) : null}
                        {residencyVerification.verification.steps
                          .current_step === "final_review" ? (
                          <ResidencyFinalReview
                            onUpdate={() => getTask()}
                          ></ResidencyFinalReview>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <VerificationStepPlaceholder></VerificationStepPlaceholder>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
}
