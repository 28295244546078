import { useEffect, useState } from 'react'
import SubNav from '../menu/SubNav'
import VerificationHeaderPlaceholder from '../placeholder/VerificationHeaderPlaceholder'
import ManagersService from '@/services/managers'
import Card from '../ui/Card'
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons'
import CardTitle from '../ui/CardTitle'
import VerificationStepPlaceholder from '../placeholder/VerificationStepPlaceholder'
import Footer from '../menu/Footer'
import UserListItem from '../managers/UserListItem'
import ContentSearchBar from '../ui/ContentSearchBar'
import Empty from '../misc/Empty'
import Alert from '@/components/ui/Alert'
import Button from '../ui/Button'
import NewManager from '../managers/NewManager'

export default function RegistryListPage() {
	const [ready, setReady] = useState(false)
	const [error, setError] = useState(null)
	const [managers, setManagers] = useState(null)
	const [data, setData] = useState(null)
	const [open, setOpen] = useState(false)

	function openModal() {
		setOpen(true)
	}

	async function close() {
		await ManagersService.RetreiveManagersList()
			.then((data) => {
				setData(data)
				setManagers(data)
				setReady(true)
				setOpen(false)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}

	useEffect(async () => {
		await ManagersService.RetreiveManagersList()
			.then((data) => {
				console.log('data')
				console.log(data)
				setData(data)
				setManagers(data)
				setReady(true)
			})
			.catch((err) => {
				setError(err.message)
			})
		return () => { }
	}, [])

	function setSearch(term) {
		let filteredManager = data.filter((reg) =>
			reg.email.toLowerCase().includes(term.toLowerCase())
		)
		setManagers(filteredManager)
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className="max-w-7xl mx-auto relative">
					<div className="verification-container">
						{ready ? (
							<Card className="w-full px-3 sm:px-0">
								<div>
									<div className="p-3">
										<div className="flex justify-between">
											<div>
												<CardTitle
													value="Managers"
													icon={faUserTie}></CardTitle>
											</div>
											<div className="flex justify-center">
												<div className="mr-3 w-full mt-1">
													<Button
														type="submit"
														theme="success"
														onClick={() => openModal()}
														label="Add manager"
													/>
												</div>
												<ContentSearchBar
													onChange={(el) =>
														setSearch(el.target.value)
													}></ContentSearchBar>
											</div>
										</div>
										{error ? (
											<div className="p-3">
												<Alert type="error" title={error}></Alert>
											</div>
										) : null}

										{ready ? (
											<>
												{' '}
												<div className="flex flex-wrap items-stretch">
													{managers.map((manager) => {
														return (
															<div
																className="w-full sm:w-1/4 p-4 self-auto"
																key={manager.email}>
																<div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
																	<UserListItem
																		manager={manager}></UserListItem>
																</div>
															</div>
														)
													})}
												</div>
												{managers.length === 0 ? (
													<div className="text-center">
														<Empty value="No Managers"></Empty>
													</div>
												) : null}
											</>
										) : (
											<VerificationStepPlaceholder></VerificationStepPlaceholder>
										)}
									</div>
								</div>
							</Card>
						) : (
							<VerificationHeaderPlaceholder></VerificationHeaderPlaceholder>
						)}
					</div>
					<Footer></Footer>
				</div>
			</div>
			<NewManager
				open={open}
				onClose={() => close()}></NewManager>
		</div>
	)
}
