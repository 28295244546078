import { useContext, useState } from "react";
import { FacematchVerificationContext } from "@/context/facematch_verification";
import HeaderButton from "@/components/ui/HeaderButton";
import { faThumbtack } from "@fortawesome/pro-duotone-svg-icons";
import IdentityService from "@/services/identity";
import PinFacematchSidebar from "@/components/facematch/PinFacematchSidebar";
import * as Notification from "@/components/ui/Notification";

export default function FacematchVerificationHeader() {
  const [facematchVerification] = useContext(FacematchVerificationContext);

  const [pinOpen, setPinOpen] = useState(false);

  async function UnpinTask() {
    await IdentityService.UnpinTask(facematchVerification.task.session_id)
      .then(async () => {
        window.location.reload();
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }

  return (
    <>
      <div className="flex flex-wrap justify-between pb-3">
        <div className="flex">
          <div className="ml-3 sm:ml-0">
            <img
              className="h-8 rounded-lg"
              src={facematchVerification.app.logo_url}
            ></img>
          </div>
          <div className="ml-3">
            {facematchVerification.task.pin === false ? (
              <HeaderButton
                onClick={() => setPinOpen(true)}
                currentBg="primary-500"
                icon={faThumbtack}
                type="pin"
                label="Pin"
              ></HeaderButton>
            ) : (
              <HeaderButton
                currentBg="primary-500"
                onClick={() => UnpinTask()}
                icon={faThumbtack}
                type="pin"
                label="Unpin"
              ></HeaderButton>
            )}
          </div>
          <div className="ml-2">
            <p className="text-white text-2xs uppercase font-bold">
              {facematchVerification.app.name}
            </p>
            <h1 className="text-2xs text-white">
              {facematchVerification.task.session_id}
            </h1>
          </div>
        </div>
        <PinFacematchSidebar
          open={pinOpen}
          setOpen={setPinOpen}
        ></PinFacematchSidebar>
      </div>
    </>
  );
}
