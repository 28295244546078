import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IndividualSession from '@/components/sessions/IndividualSession'
import IamGuard from '@/guard/iam'
import { SessionContextProvider } from '@/context/session'

function IndividualSessionRessource() {
    return (
        <AuthGuard>
            <IamGuard page='support'>
                <SessionContextProvider>
                        <div className='relative min-h-screen flex flex-col'>
                            <Navbar></Navbar>
                            <IndividualSession></IndividualSession>
                        </div>
                </SessionContextProvider>
            </IamGuard>
        </AuthGuard>
    )
}

export default IndividualSessionRessource
