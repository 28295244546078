import { IdentityContext } from '@/context/identity'
import IdentityService from '@/services/identity'
import { useContext, useEffect } from 'react'
import Tab from '../ui/Tab'

export default function Tabs() {
	const [identity, identityDispatch] = useContext(IdentityContext)

	useEffect(async () => {
		await IdentityService.GetStats()
			.then((data) => {
				identityDispatch({
					type: 'SET_STATS',
					payload: {
						stats: data,
					},
				})
			})
			.catch(() => {})
		return () => {}
	}, [])

	function setCurrentTab(currentTab) {
		identityDispatch({
			type: 'SET_CURRENT_TAB',
			payload: {
				tab: currentTab,
			},
		})
	}

	return identity.tabs.map((tab) => {
		return (
			<Tab
				badge={identity.stats[tab.key]}
				selectedKey={identity.selectedTab.key}
				key={tab.key}
				item={tab}
				onClick={() => setCurrentTab(tab)}></Tab>
		)
	})
}
