import { useContext } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Moment from 'react-moment'
import { faBan, faCheckCircle, faTimesCircle } from '@fortawesome/pro-duotone-svg-icons'

export default function ResidencyHistory({ open, setOpen }) {

	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className="h-full w-full rounded-xl">
					<form className="h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl">
						<div className="flex-1 h-0 overflow-y-auto">
							<div className="py-6 px-4 bg-primary-500 sm:px-6 rounded-t-xl">
								<div className="flex items-center justify-between">
									<h2
										className="text-lg font-medium text-white"
										id="slide-over-title">
										Verification History
									</h2>
									<div className="ml-3 h-7 flex items-center">
										<button
											onClick={() => setOpen(false)}
											type="button"
											className="bg-primary-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
											<span className="sr-only">Close panel</span>
											<svg
												className="h-6 w-6"
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												aria-hidden="true">
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													strokeWidth="2"
													d="M6 18L18 6M6 6l12 12"
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className="mt-1">
									<p className="text-sm text-white">
										User residency verification history
									</p>
								</div>
							</div>
							<div className="flex-1 flex flex-col justify-between">
								<div className="flex-1 flex flex-col justify-between">
									<div className="flow-root p-8">
										<ul className="-mb-8">
											{!residencyVerification.history ? (
												<p>No history</p>
											) : null}

											{residencyVerification.history &&
												residencyVerification.history.map((item, i) => {
													return (
														<li key={i}>
															<div className="relative pb-8">
																<div className="relative flex space-x-3">
																	<div>
																		<span
																			className={'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'}>
																			{item.state === 0 ? (
																				<FontAwesomeIcon
																					className="text-red-500 text-2xl"
																					icon={
																						faTimesCircle
																					}></FontAwesomeIcon>
																			) : null}
																			{item.state === -1 ? (
																				<FontAwesomeIcon
																					className="text-red-500 text-2xl"
																					icon={faBan}></FontAwesomeIcon>
																			) : null}
																			{item.state === 2 ? (
																				<FontAwesomeIcon
																					className="text-green-500 text-2xl"
																					icon={
																						faCheckCircle
																					}></FontAwesomeIcon>
																			) : null}
																		</span>
																	</div>
																	<div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
																		<div>
																			<p className="text-sm text-gray-800">
																				{item.state === 0
																					? item.rejection.customer_reason
																					: null}
																				{item.state === 2 ? 'Validated' : null}
																				{item.state === -1
																					? item.rejection.customer_reason
																					: null}
																			</p>
																		</div>
																		<div className="text-right text-sm whitespace-nowrap text-gray-500">
																			<time dateTime="2020-09-20">
																				{' '}
																				<Moment unix fromNow>
																					{item.last_updated / 1000}
																				</Moment>
																			</time>
																		</div>
																	</div>
																</div>
															</div>
														</li>
													)
												})}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="flex-shrink-0 px-4 py-4 flex justify-end">
							<Button
								onClick={() => setOpen(false)}
								theme="secondary"
								label="Close"></Button>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
