export default function IdentityReviewInfoStepper(props) {
	return (
		<nav className="flex items-center justify-center" aria-label="Progress">
			<ol className="ml-5 mt-0.5 flex items-center space-x-5">
				{Array(props.total)
					.fill(1)
					.map((el, i) => {
						if (i + 1 > props.current) {
							return (
								<li key={i}>
									<a className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"></a>
								</li>
							)
						} else {
							return (
								<li key={i}>
									<a className="block w-2.5 h-2.5 bg-identity-500 rounded-full hover:bg-identity-900"></a>
								</li>
							)
						}
					})}
			</ol>
		</nav>
	)
}
