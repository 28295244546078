import { PanelAuth } from "@/services/axios";

export default {
  GetIdentityDocument(slug) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/document/${slug}`
    );
  },
  GetIdentityStats() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/stats`
    );
  },
  GetCountryInfo(country) {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/browse/${country}`
    );
  },
  GetFeaturesSearchIndex() {
    return PanelAuth.get(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/indexes/features`
    );
  },
  AddRegistryDoc(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/add`,
      body
    );
  },
  SetDeployment(slug, deploymentType) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/deployment/${slug}?type=${deploymentType}`
    );
  },
  DeleteDocument(slug) {
    return PanelAuth.delete(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/document/${slug}`
    );
  },
  AddInfo(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features/info`,
      body
    );
  },
  DeleteInfo(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features/info/remove`,
      body
    );
  },
  AddFeature(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features`,
      body
    );
  },
  DeleteFeature(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features/remove`,
      body
    );
  },
  AddMrz(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features/mrz`,
      body
    );
  },
  DeleteMrz(body) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/features/mrz/remove`,
      body
    );
  },
  Upload(body, side, slug) {
    return PanelAuth.post(
      `${process.env.REACT_APP_PANEL_HOST}/v3/registry/identity/upload/${slug}?side=${side}`,
      body
    );
  },
};
