import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import SupportPage from '@/components/pages/SupportPage'
import IamGuard from '@/guard/iam'

function SupportIndex() {
	return (
		<AuthGuard>
            <IamGuard page='support'>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<SupportPage></SupportPage>
			</div>
            </IamGuard>
		</AuthGuard>
	)
}

export default SupportIndex
