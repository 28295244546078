import {  faThumbtack } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function PinCard(props) {
	return (
		<div
			onClick={() => props.setPin(props.slug)}
			className="group flex flex-wrap items-center p-3 border my-2 rounded-xl hover:bg-orange-100 hover:border-orange-200 transition ease-in duration-100 cursor-pointer">
			<div className="flex-shrink-0 w-8">
				<FontAwesomeIcon
					className="group-hover:text-orange-400 text-gray-500"
					icon={props.icon}></FontAwesomeIcon>
			</div>
			<div className="flex-1">
				<p className="uppercase group-hover:text-orange-400 text-gray-500 text-xs font-bold">
					{props.title}
				</p>
				<p className="text-sm mt-1">{props.description}</p>
			</div>
			{props.selected === props.slug ? (
				<div className="flex-shrink-0 mr-2">
					<FontAwesomeIcon
						className="text-orange-500"
						icon={faThumbtack}></FontAwesomeIcon>
				</div>
			) : null}
		</div>
	)
}
