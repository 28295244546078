import { useReducer, createContext } from 'react'

export const DuplicateVerificationContext = createContext()

function setSession(state, payload) {
	return {
		...payload,
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_SESSION':
			return setSession(state, action.payload)
		default:
			return state
	}
}

export const DuplicateVerificationContextProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, {})

	return (
		<DuplicateVerificationContext.Provider value={[state, dispatch]}>
			{props.children}
		</DuplicateVerificationContext.Provider>
	)
}
