import { useContext, useState } from 'react'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import { ResidencyVerificationContext } from '@/context/residency_verification'
import StepTitle from '@/components/ui/StepTitle'
import {
	faPassport,
	faUser,
} from '@fortawesome/pro-duotone-svg-icons'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import Button from '@/components/ui/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ResidencyHostIdVerification() {
	const [successLoading] = useState(false)
	const [error] = useState('')
	const [residencyVerification] = useContext(
		ResidencyVerificationContext
	)

	const keyMap = {}

	const handlers = {}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}
			<StepTitle value="Host ID Verification" icon={faPassport}></StepTitle>

			<p className="text-center">
				Verify the identity of the host and come back when it is done
			</p>
			<div className="mx-auto text-center">
				<div
					onClick={() =>
						window.open(
							`/verifications/identity/${residencyVerification.verification.accomodation.identity_session_id}`,
							'_blank'
						)
					}
					className="rounded-lg mt-8 p-6 px-12 inline-block border border-gray-200 mb-6 shadow-sm cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
					<FontAwesomeIcon
						className="text-4xl text-residency-500"
						icon={faUser}></FontAwesomeIcon>
					<p className="mt-3">Verify host identity</p>
				</div>
			</div>
			<div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
				<div></div>
				<div className="flex">
					<Button
						loading={successLoading}
						onClick={() => window.location.reload()}
						icon={faCheck}
						theme="success"
						full
						label="Done"></Button>
				</div>
			</div>
		</GlobalHotKeys>
	)
}
