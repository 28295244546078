import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function VisualCheck(props) {
	if (props.active) {
		return (
			<div className="mt-3 mb-6 p-6 border-gray-200 shadow-sm items-center rounded-xl transition-25 transition duration-200 ease-in border">
				<div className="flex flex-wrap">
					<div className="flex">
						<div>
							<div
								onClick={() => props.onSet(true)}
								className="h-12 w-12 bg-green-500 rounded-full text-center cursor-pointer hover:bg-green-600 ease-in transition duration-200">
								<FontAwesomeIcon
									className="text-white relative text-xl top-3.5"
									icon={faCheck}></FontAwesomeIcon>
							</div>
						</div>
						<div>
							<div
								onClick={() => props.onSet(false)}
								className="ml-3 h-12 w-12 bg-red-500 rounded-full text-center cursor-pointer hover:bg-red-600 ease-in transition duration-200">
								<FontAwesomeIcon
									className="text-white relative text-xl top-3.5"
									icon={faTimes}></FontAwesomeIcon>
							</div>
						</div>
					</div>
					<div className="ml-6">
						<p className="text-primary-500 font-bold">{props.title}</p>
						<p className="text-gray-600">{props.content}</p>
					</div>
				</div>
			</div>
		)
	} else {
		return null
	}
}
