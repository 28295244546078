import { useContext, useEffect, useState } from 'react'
import SlidingPane from 'react-sliding-pane'
import Button from '@/components/ui/Button'
import {
	faAddressCard,
	faCar,
	faExclamationTriangle,
	faIdCard,
	faPassport,
	faPen,
} from '@fortawesome/pro-duotone-svg-icons'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'
import { IdentityVerificationContext } from '@/context/identity_verification'
import * as Notification from '@/components/ui/Notification'
import IdentityService from '@/services/identity'
import { useHistory } from 'react-router-dom'
import DocumentCard from '../ui/DocumentCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function EditDocumentTypeSidebar({ open, setOpen }) {
	const [pin, setPin] = useState('')
	const [subMode, setSubMode] = useState('validation')
	const [loading, setLoading] = useState(false)
	const router = useHistory()

	const [identityVerification] = useContext(IdentityVerificationContext)

	function updatePin(newPin) {
		if (pin === newPin) {
			setPin('')
		} else {
			setPin(newPin)
		}
	}

	useEffect(() => {
		if (
			pin !== '' &&
			JSON.stringify(identityVerification.restricted_documents[identityVerification.verification.document.country]).includes(pin)
		) {
			setSubMode('rejection')
		} else {
			setSubMode('validation')
		}
	}, [pin])

	async function rejectIdentity(rejection) {
		setLoading(true)
		await IdentityService.Reject(
			identityVerification.task.session_id,
			rejection
		)
			.then(() => {
				setLoading(false)
				Notification.success('Identity rejected successfully')
				router.push('/verifications/identity')
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => {}
	}

	async function changeDocumentType() {
		setLoading(true)
		await IdentityService.ChangeDocumentType(
			identityVerification.task.session_id,
			pin
		)
			.then(() => {
				setLoading(false)
				Notification.success('Identity document type changed successfully')
				window.location.reload()
			})
			.catch((err) => {
				Notification.error(err.message)
				setLoading(false)
			})
		return () => {}
	}

	return (
		<>
			<SlidingPane
				className={'rounded-xl'}
				hideHeader={true}
				isOpen={open}
				onRequestClose={() => {
					setOpen(false)
				}}>
				<div className='h-full w-full rounded-xl'>
					<form className='h-full divide-y divide-gray-200 flex flex-col shadow-xl rounded-xl'>
						<div className='flex-1 h-0 overflow-y-auto'>
							<div className='py-6 px-4 bg-primary-500 sm:px-6 rounded-t-xl'>
								<div className='flex items-center justify-between'>
									<h2
										className='text-lg font-medium text-white'
										id='slide-over-title'>
										Edit Document Type
									</h2>
									<div className='ml-3 h-7 flex items-center'>
										<button
											onClick={() => setOpen(false)}
											type='button'
											className='bg-primary-500 rounded-md text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white'>
											<span className='sr-only'>Close panel</span>
											<svg
												className='h-6 w-6'
												xmlns='http://www.w3.org/2000/svg'
												fill='none'
												viewBox='0 0 24 24'
												stroke='currentColor'
												aria-hidden='true'>
												<path
													strokeLinecap='round'
													strokeLinejoin='round'
													strokeWidth='2'
													d='M6 18L18 6M6 6l12 12'
												/>
											</svg>
										</button>
									</div>
								</div>
								<div className='mt-1'>
									<p className='text-sm text-white'>
										Select current document among all available ones
									</p>
								</div>
							</div>
							<div className='flex-1 flex flex-col justify-between'>
								<div className='px-4 py-2'>
									<>
										<p className='pt-2 uppercase text-gray-500 font-bold text-xs'>
											Document Type
										</p>
										<DocumentCard
											selected={pin}
											setPin={(slug) => updatePin(slug)}
											slug='PASSPORT'
											title='Passport'
											icon={faPassport}></DocumentCard>
										<DocumentCard
											selected={pin}
											setPin={(slug) => updatePin(slug)}
											slug='NATIONAL_ID'
											title='National ID'
											icon={faIdCard}></DocumentCard>
										<DocumentCard
											selected={pin}
											setPin={(slug) => updatePin(slug)}
											slug='DRIVER_LICENSE'
											title='Driver License'
											icon={faCar}></DocumentCard>
										<DocumentCard
											selected={pin}
											setPin={(slug) => updatePin(slug)}
											slug='RESIDENT_PERMIT'
											title='Resident Permit'
											icon={faAddressCard}></DocumentCard>
									</>
								</div>
							</div>
						</div>
						<div className='flex-shrink-0 px-4 py-4 flex justify-between'>
							<div className='mt-3 ml-2'>
								{pin !== '' && subMode === 'rejection' ? (
									<p className='text-red-500'>
										<FontAwesomeIcon
											className='mr-2'
											icon={faExclamationTriangle}></FontAwesomeIcon>
										Restricted document type
									</p>
								) : null}
							</div>
							<div className='flex'>
								<Button
									onClick={() => setOpen(false)}
									theme='secondary'
									label='Cancel'></Button>
								{pin !== '' && subMode === 'validation' ? (
									<Button
										loading={loading}
										className='ml-2'
										onClick={() => changeDocumentType()}
										icon={faPen}
										theme='success'
										label='Change document'></Button>
								) : null}
								{pin !== '' && subMode === 'rejection' ? (
									<Button
										loading={loading}
										className='ml-2'
										onClick={() => rejectIdentity('RESTRICTED_DOCUMENT_TYPE')}
										icon={faTimes}
										theme='error'
										label='Reject'></Button>
								) : null}
							</div>
						</div>
					</form>
				</div>
			</SlidingPane>
		</>
	)
}
