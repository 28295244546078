import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function MenuItem(props) {
	let path = '/'
	if (props.path) {
		path = props.path
	}

	let themeType = props.theme

	let theme = {
		primary: {
			normal: 'bg-bluegray-100',
			hover: 'hover:bg-bluegray-100',
			focus: 'focus:ring-bluegray-300',
			border: 'border-bluegray-500',
			borderhover: 'hover:border-primary-700',
			text: 'text-primary-500',
			loader: 'text-primary-500',
		},
		identity: {
			normal: 'bg-identity-100',
			hover: 'hover:bg-identity-100',
			focus: 'focus:ring-identity-300',
			border: 'border-identity-500',
			borderhover: 'hover:border-identity-700',
			text: 'text-identity-500',
			loader: 'text-gray-800',
		},
		residency: {
			normal: 'bg-residency-100',
			hover: 'hover:bg-residency-100',
			focus: 'focus:ring-residency-300',
			border: 'border-residency-500',
			borderhover: 'hover:border-residency-700',
			text: 'text-residency-500',
			loader: 'text-gray-800',
		},
		corporate: {
			normal: 'bg-corporate-100',
			hover: 'hover:bg-corporate-100',
			focus: 'focus:ring-corporate-300',
			border: 'border-corporate-500',
			borderhover: 'hover:border-corporate-700',
			text: 'text-corporate-500',
			loader: 'text-gray-800',
		},
		facematch: {
			normal: 'bg-facematch-100',
			hover: 'hover:bg-facematch-100',
			focus: 'focus:ring-facematch-300',
			border: 'border-facematch-500',
			borderhover: 'hover:border-facematch-700',
			text: 'text-facematch-500',
			loader: 'text-gray-800',
		},
		aml: {
			normal: 'bg-aml-100',
			hover: 'hover:bg-aml-100',
			focus: 'focus:ring-aml-300',
			border: 'border-aml-500',
			borderhover: 'hover:border-aml-700',
			text: 'text-aml-500',
			loader: 'text-gray-800',
		},
		dupplicate: {
			normal: 'bg-dupplicate-100',
			hover: 'hover:bg-dupplicate-100',
			focus: 'focus:ring-dupplicate-300',
			border: 'border-dupplicate-500',
			borderhover: 'hover:border-dupplicate-700',
			text: 'text-dupplicate-500',
			loader: 'text-gray-800',
		},
	}

	if (!theme[themeType]) {
		themeType = 'primary'
	}

	const palette = theme[themeType]

	return (
		<a href={path}>
			<div className='h-full p-3'>
				<div
					className={`${palette.hover} cursor-pointer transition duration-200 ease-in text-center p-3 rounded-xl`}>
					<FontAwesomeIcon
						className={`text-center ${palette.text} text-3xl`}
						icon={props.icon}></FontAwesomeIcon>
					<p className={`mt-3 ${palette.text} uppercase text-xs font-bold`}>
						{props.label}
					</p>
				</div>
			</div>
		</a>
	)
}
