import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import CorporatesPage from '@/components/pages/CorporatesPage'

function CorporatesIndex() {
	return (
		<AuthGuard>
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				<CorporatesPage></CorporatesPage>
			</div>
		</AuthGuard>
	)
}

export default CorporatesIndex
