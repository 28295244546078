import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import IamGuard from '@/guard/iam'
import ResidencyTasksPage from '@/components/pages/ResidencyTasksPage'
import { ResidencyContextProvider } from '@/context/residency'

function Residency() {
	return (
		<AuthGuard>
			<IamGuard page='residency'>
				<ResidencyContextProvider>
					<div className='relative min-h-screen flex flex-col'>
						<Navbar></Navbar>
						<ResidencyTasksPage></ResidencyTasksPage>
					</div>
				</ResidencyContextProvider>
			</IamGuard>
		</AuthGuard>
	)
}

export default Residency
