import { useContext } from 'react'
import { CrosscheckVerificationContext } from '@/context/crosscheck_verification'

export default function CrosscheckVerificationHeader() {
	const [crosscheckVerification] = useContext(CrosscheckVerificationContext)

	return (
		<>
			<div className='flex flex-wrap justify-between pb-3'>
				<div className='flex'>
					<div className='ml-3 sm:ml-0'>
						<img
							className='h-8 rounded-lg'
							src={crosscheckVerification.app.logo_url}></img>
					</div>
					<div className='ml-2'>
						<p className='text-white text-2xs uppercase font-bold'>
							{crosscheckVerification.app.name}
						</p>
						<h1 className='text-2xs text-white'>
							{crosscheckVerification.task.session_id}
						</h1>
					</div>
				</div>
			</div>
		</>
	)
}
