import AnnotedResidency from "@/components/ui/AnnotedResidency";

export default function ResidencyDocumentPageView(props) {
  return (
    <div key={props.page}>
      <div className="mx-auto">
        <AnnotedResidency
          annotations={props.paragraphs}
          id={props.page}
          className="object-contain h-64"
          sameColor
          suggestions={props.suggestions}
          src={props.page}
        ></AnnotedResidency>
      </div>
    </div>
  );
}
