import { useContext, useState } from "react";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { GlobalHotKeys } from "react-hotkeys";
import IdentityService from "@/services/identity";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Button from "@/components/ui/Button";
import StepTitle from "@/components/ui/StepTitle";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";
import Magnifier from "react-magnifier";

export default function IdentityForgery(props) {
  const [loading, setLoading] = useState(false);
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [frontRotation] = useState(0);
  const [backRotation] = useState(0);
  const router = useHistory();

  const keyMap = {
    DEL: ["del", "backspace"],
    SELECT: ["enter"],
  };

  async function setForgeryCheck() {
    setLoading(true);
    await IdentityService.SetForgeryCheck(
      identityVerification.task.session_id,
      {}
    )
      .then(() => {
        setLoading(false);
        props.onUpdate();
      })
      .catch((err) => {
        setLoading(false);
        Notification.error(err.message);
      });
    return () => {};
  }

  async function pinIdentityTask() {
    const body = {
      action: "FORGERY_DOCUMENT_LOOK_FORGED",
      note: "",
    };

    setLoading(true);
    await IdentityService.PinIdentityTask(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        Notification.success("Forgery check requested successfully");
        router.push("/verifications/identity");
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
    return () => {};
  }

  return (
    <GlobalHotKeys
      tabIndex="1"
      className="outline-none"
      allowChanges={true}
      keyMap={keyMap}
    >
      <div className="flex justify-between">
        <div>
          <StepTitle value="Document Forgery Check" icon={faSearch}></StepTitle>
        </div>
      </div>
      <div className="flex flex-wrap mx-3">
        <div className="p-2 w-1/2">
          <div className="mx-auto">
            <p className="ml-1 mb-2">Front</p>
            <div
              className={`object-contain h-128 w-full rotate-${frontRotation}`}
            >
              <Magnifier src={identityVerification.media.segmented.front} />
            </div>
          </div>
        </div>
        <div className="p-2 w-1/2">
          <div className="mx-auto">
            <p className="ml-1 mb-2">Front Forgery Mask</p>
            <div
              className={`object-contain h-128 w-full rotate-${frontRotation}`}
            >
              <img
                draggable={false}
                src={identityVerification.media.forgery.front}
              />
            </div>
          </div>
        </div>
      </div>
      {identityVerification.verification.document.type !== "PASSPORT" ? (
        <div className="flex flex-wrap mx-3">
          <div className="p-2 w-1/2">
            <div className="mx-auto">
              <p className="ml-1 mb-2">Back</p>
              <div
                className={`object-contain h-128 w-full rotate-${backRotation}`}
              >
                <Magnifier src={identityVerification.media.segmented.back} />
              </div>
            </div>
          </div>
          <div className="p-2 w-1/2">
            <div className="mx-auto">
              <p className="ml-1 mb-2">Back Forgery Mask</p>
              <div
                className={`object-contain h-128 w-full rotate-${backRotation}`}
              >
                <img
                  draggable={false}
                  src={identityVerification.media.forgery.back}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}{" "}
      <div className="flex justify-between bg-bluegray-50 mt-8 p-3 rounded-xl">
        <Button
          loading={loading}
          onClick={() => pinIdentityTask()}
          icon={faTimes}
          full
          label="Pin"
          theme="pin"
        ></Button>

        <Button
          loading={loading}
          onClick={() => setForgeryCheck()}
          icon={faCheck}
          full
          label="Next"
          theme="success"
        ></Button>
      </div>
    </GlobalHotKeys>
  );
}
