import { useContext, useEffect, useState } from 'react'
import { IdentityVerificationContext } from '@/context/identity_verification'
import { GlobalHotKeys } from 'react-hotkeys'
import Alert from '@/components/ui/Alert'
import IdentityMrzLines from './IdentityMrzLines'
import IdentityMrzBarcode from './IdentityMrzBarcode'

export default function IdentityMrz(props) {
	const [error] = useState('')
	const [identityVerification] = useContext(
		IdentityVerificationContext
	)

	const keyMap = {
		ENTER: ['enter'],
	}

	useEffect(() => {}, [])

	const handlers = {
		ENTER: () => {},
	}

	return (
		<GlobalHotKeys
			tabIndex="1"
			className="outline-none"
			allowChanges={true}
			keyMap={keyMap}
			handlers={handlers}>
			{error ? (
				<div>
					<Alert type="error" title={error}></Alert>
				</div>
			) : null}

			{identityVerification.mrz_check === 'mrz_lines' ? (
				<IdentityMrzLines onUpdate={() => props.onUpdate()}></IdentityMrzLines>
			) : null}
			{identityVerification.mrz_check === 'mrz_barcode' ? (
				<IdentityMrzBarcode onUpdate={() => props.onUpdate()}></IdentityMrzBarcode>
			) : null}
		</GlobalHotKeys>
	)
}
