import Sleep from '@/lib/sleep'
import {
	faPen,
} from '@fortawesome/pro-duotone-svg-icons'
import { Transition } from '@headlessui/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../ui/Button'
import Input from '../ui/Input'
import Alert from '../ui/Alert'
import CardTitle from '../ui/CardTitle'

export default function UpdateValue(props) {
	const [error, setError] = useState(null)
	const { register, handleSubmit, errors } = useForm()
	const [loading, setLoading] = useState(false)

	const formSchema = {
		value: {
			required: 'Value required',
		},
	}

	const onSubmit = async (data) => {
		setError(null)

		console.log('data')
		console.log(data)

		props.onUpdate(data.new)
	}

	return (
		<Transition
			show={props.open}
			enter="ease-out duration-200"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div className="fixed z-10 inset-0 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen text-center sm:block sm:p-0">
					<div
						className="fixed inset-0 transition-opacity"
						aria-hidden="true"
						onClick={() => props.onClose()}>
						<div className="absolute inset-0 bg-gray-800 opacity-75"></div>
					</div>

					<span
						className="hidden sm:inline-block sm:align-middle sm:h-screen"
						aria-hidden="true">
						&#8203;
					</span>

					<div
						className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
						role="dialog"
						aria-modal="true"
						aria-labelledby="modal-headline">
						<div className="p-4">
							<div>
								<div>
									<div>
										<CardTitle
											value={'Update '+props.value}
											icon={faPen}></CardTitle>
									</div>
								</div>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div>
										<div className="py-2 px-3">
											{error ? (
												<Alert type="error" title={error}></Alert>
											) : null}
										</div>
										<div className="flex flex-wrap">
											<div className="p-3 w-full">
												<Input
													type="text"
													icon={props.icon}
													label={'Old '+props.value}
													name="old"
													value={props.old}
													disabled
												/>
											</div>
											<div className="p-3 w-full">
												<Input
													icon={props.icon}
													referrer={register(formSchema.value)}
													label={'New '+props.value}
													name="new"
													error={errors.value}
													message={errors.value?.message}
												/>
											</div>
										</div>
									</div>

									<div>
										<div className="flex justify-between">
											<div className="p-3"></div>
											<div className="p-3">
												<div className="flex">
													<div className="mr-3">
														<Button
															theme="secondary"
															onClick={() => props.onClose()}
															label="Cancel"
														/>
													</div>
													<div>
														<Button
															type="submit"
															theme="success"
															loading={loading}
															onClick={() => handleSubmit(onSubmit)}
															label={'Update '+props.value}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Transition>
	)
}
