export default function VerificationStepPlaceholder() {
	return (
		<div>
			<div className="animate-pulse">
				<div className="p-3">
					<div className="flex">
						<div>
							<div className="h-24 w-24 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-12 sm:w-48 mt-2 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 sm:w-64 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-48 sm:w-96 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="p-3">
					<div className="flex">
						<div>
							<div className="h-24 w-24 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-12 sm:w-48 mt-2 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 sm:w-64 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-48 sm:w-96 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
				<div className="p-3">
					<div className="flex">
						<div>
							<div className="h-24 w-24 bg-bluegray-300 rounded-md"></div>
						</div>
						<div>
							<div className="ml-3 w-12 sm:w-48 mt-2 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-24 sm:w-64 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
							<div className="ml-3 w-48 sm:w-96 mt-4 h-4 bg-bluegray-300 rounded-sm"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
