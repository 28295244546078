import { faLayerPlus } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IdentityVerificationContext } from "@/context/identity_verification";
import { useContext, useState } from "react";
import Loader from "../ui/Loader";
import IdentityService from "@/services/identity";
import * as Notification from "@/components/ui/Notification";
import { useHistory } from "react-router-dom";

export default function IdentityTemplateElement(props) {
  const [identityVerification] = useContext(IdentityVerificationContext);
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  async function pinIdentityTask() {
    const body = {
      action: "TEMPLATE_REGISTRY_REQUEST_TEMPLATE",
      note: "",
    };

    setLoading(true);
    await IdentityService.PinIdentityTask(
      identityVerification.task.session_id,
      body
    )
      .then(() => {
        setLoading(false);
        Notification.success("Template requested successfully");
        router.push("/verifications/identity");
      })
      .catch((err) => {
        Notification.error(err.message);
        setLoading(false);
      });
    return () => {};
  }

  return (
    <div
      onClick={() => {
        if (props.element.slug === "new_template") {
          pinIdentityTask();
        } else {
          props.onSet(props.element.slug);
        }
      }}
      className="border border-gray-200 shadow-sm items-center cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in"
    >
      {props.element.slug === "new_template" ? (
        <>
          <div className="flex flex-wrap p-4">
            <div className="mx-auto">
              <div className="flex object-contain h-72 w-full items-center">
                {loading ? (
                  <Loader
                    className="mr-2"
                    size="mega"
                    color="gray-400"
                  ></Loader>
                ) : (
                  <FontAwesomeIcon
                    className="text-8xl text-gray-400"
                    icon={faLayerPlus}
                  ></FontAwesomeIcon>
                )}
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-center text-sm p-4 uppercase font-bold text-gray-600">
              <span>Request new template</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="mx-auto">
              <img
                src={props.element.front}
                className="object-contain h-80 w-full"
              />
            </div>
            {props.element.back !== "" ? (
              <div>
                <img
                  src={props.element.back}
                  className="object-contain h-80 w-full"
                />
              </div>
            ) : null}
          </div>
          <div className="w-full">
            <p className="text-center text-sm p-4 uppercase font-bold text-gray-600">
              <span>{props.element.slug}</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
}
