export default function ProjectListItem(props) {
    return (
        <div
            onClick={() =>
                props.select(props.project)
            }
            className="w-full sm:w-1/4 p-4 self-auto"
            key={props.project.name}>
            <div className="border h-full border-gray-200 shadow-sm items-center rounded-xl cursor-pointer hover:shadow-md transition-25 hover:bg-bluegray-100 hover:border-bluegray-300 transition duration-200 ease-in">
                <div className="p-6">
                    <div className="text-center">
                        <div className="mx-auto text-center mt-1 mb-4">
                            <img
                                className="h-20 rounded-full mx-auto text-center"
                                src={props.project.settings.auth.logo}
                                alt=""
                            />
                        </div>
                        <p className="text-regular font-semibold">{props.project.name}</p>
                        <div className="flex justify-center"> {props.project.network !== '' ? <div className="mt-4 mr-2">
                            <a className="mt-2 group items-center p-1 bg-aml-100 rounded-xl text-sm">{props.project.network}</a></div> : null}
                            <div className="mt-4">{props.project.closed === true ?
                                <a className="mt-2 group items-center p-1 bg-dupplicate-100 rounded-xl text-sm">CLOSED</a> :
                                <a className="mt-2 group items-center p-1 bg-identity-100 rounded-xl text-sm">OPENED</a>}</div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
