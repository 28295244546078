import {
	faCabinetFiling,
	faFileAlt,
	faGlobe,
	faPassport,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RegistryListHeaderItem from './RegistryListHeaderItem'

export default function RegistryListHeader(props) {
	return (
		<div className="flex flex-wrap justify-between">
			<div className="w-full sm:w-5/12 px-3 pb-6 sm:pb-0 sm:px-0">
				<div className="flex">
					<div className="mr-5 mt-0.5">
						<FontAwesomeIcon
							className="text-gray-400 text-6xl"
							icon={faCabinetFiling}></FontAwesomeIcon>
					</div>
					<div>
						<h1 className="text-3xl">
							<span className="font-bold">Registry</span>
						</h1>
						<h3 className="text-lg  text-bluegray-600">
							World Documents Database
						</h3>
					</div>
				</div>
			</div>
			<div className="w-full sm:w-7/12">
				<div className="flex flex-wrap">
					<div className="w-full sm:w-1/3">
						<RegistryListHeaderItem
							label="Countries"
							value={props.data.nb_countries}
							icon={faGlobe}></RegistryListHeaderItem>
					</div>
					<div className="w-full sm:w-1/3">
						<RegistryListHeaderItem
							label="Document Types"
							value={props.data.nb_types}
							icon={faPassport}></RegistryListHeaderItem>
					</div>
					<div className="w-full sm:w-1/3">
						<RegistryListHeaderItem
							label="Models"
							value={props.data.nb_docs}
							icon={faFileAlt}></RegistryListHeaderItem>
					</div>
				</div>
			</div>
		</div>
	)
}
