import { useContext } from "react";
import IndividualIdentity from "@/components/sessions/individual/IndividualIdentity";
import IndividualResidency from "@/components/sessions/individual/IndividualResidency";
import IndividualPhone from "@/components/sessions/individual/IndividualPhone";
import IndividualLiveness from "@/components/sessions/individual/IndividualLiveness";
import IndividualAml from "@/components/sessions/individual/IndividualAml";
import { SessionContext } from "@/context/session";

export default function IndividualCore(props) {
  const [individual] = useContext(SessionContext);

  return (
    <>
      {individual.ordered_steps.map((step_id) => {
        return (
          <div
            id={step_id}
            key={step_id}
            className="bg-white rounded-xl shadow-sm p-6 mb-6"
          >
            {individual.steps[step_id].type === "IDENTITY" ? (
              <IndividualIdentity
                onRefresh={() => props.onRefresh()}
                member_id={0}
                did_issued={individual.did_issued}
                reset={individual.steps[step_id].reset}
                step_id={step_id}
                sub_session_id={individual.steps[step_id].session_id}
                step={individual.steps[step_id].verification}
              />
            ) : null}
            {individual.steps[step_id].type === "RESIDENCY" ? (
              <IndividualResidency
                onRefresh={() => props.onRefresh()}
                member_id={0}
                reset={individual.steps[step_id].reset}
                step_id={step_id}
                sub_session_id={individual.steps[step_id].session_id}
                step={individual.steps[step_id].verification}
              />
            ) : null}
            {individual.steps[step_id].type === "PHONE" ? (
              <IndividualPhone
                member_id={0}
                step_id={step_id}
                reset={individual.steps[step_id].reset}
                sub_session_id={individual.steps[step_id].session_id}
                step={individual.steps[step_id].verification}
              />
            ) : null}
            {individual.steps[step_id].type === "LIVENESS" ? (
              <IndividualLiveness
                member_id={0}
                step_id={step_id}
                reset={individual.steps[step_id].reset}
                sub_session_id={individual.steps[step_id].session_id}
                step={individual.steps[step_id].verification}
              />
            ) : null}
          </div>
        );
      })}
      {individual.aml.active ? (
        <div id={"aml"} className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <IndividualAml
            onFinish={() => props.onRefresh()}
            data={individual.aml}
          />
        </div>
      ) : null}
    </>
  );
}
