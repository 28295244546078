import { useContext, useEffect, useState } from "react";
import Empty from "../misc/Empty";
import TasksPlaceholder from "../placeholder/TasksPlaceholder";
import Sleep from "@/lib/sleep";
import * as Notification from "@/components/ui/Notification";
import CardTitle from "../ui/CardTitle";
import { AmlContext } from "@/context/aml";
import AmlService from "@/services/aml";
import AmlTaskItem from "./AmlTaskItem";

export default function AmlTasks() {
  const [aml, amlDispatch] = useContext(AmlContext);
  const [ready, setReady] = useState(false);
  const [pinType, setPinType] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  function updatePin(newPin) {
    if (pinType === newPin) {
      setPinType("");
    } else {
      setPinType(newPin);
    }
  }

  useEffect(async () => {
    setReady(false);
    await AmlService.GetTasks(aml.selectedTab.key, pinType)
      .then(async (data) => {
        amlDispatch({
          type: "SET_TASKS",
          payload: {
            tasks: data,
          },
        });
        await Sleep(500);
        setReady(true);
      })
      .catch((err) => {
        Notification.error(err.message);
      });
    return () => {};
  }, [aml.selectedTab, pinType]);

  return (
    <>
      <div className="flex justify-between">
        <div>
          <CardTitle
            value={aml.selectedTab.title}
            icon={aml.selectedTab.icon}
          ></CardTitle>
        </div>
      </div>
      {ready ? (
        <div className="flex flex-wrap p-6 pt-3">
          {aml.tasks.map((task) => {
            return (
              <AmlTaskItem
                update={(slug) => updatePin(slug)}
                key={task.task.session_id}
                item={task}
              ></AmlTaskItem>
            );
          })}
          {aml.tasks.length === 0 ? <Empty value="No tasks"></Empty> : null}
        </div>
      ) : (
        <div className="p-4">
          <TasksPlaceholder></TasksPlaceholder>
        </div>
      )}
    </>
  );
}
